'use strict';

import _ from 'lodash';

const websiteBase =
	CK.config.name === 'ck-website' ?
	'/' :
	_.get( CK, 'config.environment.clients.website.address', '/' );

module.exports = [ {
		name: 'Support',
		href: 'mailto:support@codekingdoms.com'
	},
	{
		href: websiteBase + 'policies/',
		name: 'Terms'
	},
	{
		href: websiteBase + 'educators/',
		name: 'Educators'
	},
	{
		href: websiteBase + 'jobs/',
		name: 'Jobs'
	},
	// {
	// 	href: websiteBase + 'reviews/',
	// 	name: 'Reviews'
	// },
	{
		href: 'https://faq.codekingdoms.com/',
		name: 'FAQ'
	}
];
