import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';

const ClientModule = require('../system/ClientModule');

/* global Cookies: false */
module.exports = ClientModule.define({
	type: 'CookieManager',

	set: function(key, value, options) {
		key = this._envKey(key);

		const cookie = Cookies.set(key, value, _.extend(this._cookieOptions(), options || {}));

		const MAX_LENGTH = 4093;

		if (cookie && cookie.length > MAX_LENGTH) {
			CK.logger.warn(`Setting cookie of length > ${MAX_LENGTH} will likely fail`, {
				cookieKey: key,
				cookieLength: cookie.length
			});
		}
	},

	get: function(key) {
		key = this._envKey(key);

		return Cookies.get(key);
	},

	_envKey: function(key) {
		return CK.config.environment.name + '-' + key;
	},

	remove: function(key) {
		key = this._envKey(key);

		// Remove with the same options intentionally
		Cookies.remove(key, this._cookieOptions());
	},

	_cookieOptions: function() {
		if (CK.config.environment.name !== CK_ENV.LOCAL) {
			return {
				secure: true,
				domain: '.' + CK.config.environment.domain
			};
		} else if (_.endsWith(document.location.hostname, '.ck.local')) {
			return {
				domain: '.ck.local'
			};
		} else {
			return {};
		}
	}
});
