'use strict';

import _ from 'lodash';

import _fp from 'lodash/fp';

const subscriptionPlans = require('../collections/subscriptionPlans');
const PurchaseSchema = require('./contribs/PurchaseSchema');
const SubscriptionSchema = require('./contribs/SubscriptionSchema');
const ProjectSchema = require('./contribs/ProjectSchema');
const UserProgressMethodsSchema = require('./contribs/UserProgressMethodsSchema');
const UserCampMethodsSchema = require('./contribs/UserCampMethodsSchema');
const UserPurchaseMethodsSchema = require('./contribs/UserPurchaseMethodsSchema');
const UserAccessMethodsSchema = require('./contribs/UserAccessMethodsSchema');
const UserAccessMinecraftMethodsSchema = require('./contribs/UserAccessMinecraftMethodsSchema');
const UserAccessRobloxMethodsSchema = require('./contribs/UserAccessRobloxMethodsSchema');
const UserRobloxSchema = require('./contribs/UserRobloxSchema');
const UserDeprecatedSchema = require('./contribs/UserDeprecatedSchema');
const products = require('../data/products');
const SnippetSchema = require('./contribs/SnippetSchema');
const BadgeSchema = require('./BadgeSchema');

const testCohortSchema = [
	{
		id: darc.types.primary(String),
		control: Boolean,
		dateEntered: Date
	}
];

const raw = _.extend(
	{},

	UserPurchaseMethodsSchema,
	UserCampMethodsSchema,
	UserAccessMethodsSchema,
	UserAccessMinecraftMethodsSchema,
	UserAccessRobloxMethodsSchema,
	UserProgressMethodsSchema,
	UserRobloxSchema,

	UserDeprecatedSchema,

	{
		secondaryEmails: function() {
			return _fp.flow(
				_fp.filter(email => {
					return email && email.length && email !== this.email();
				}),
				_fp.uniq
			)([this.parentEmail(), this.paymentEmail()]);
		}
	},
	{
		id: darc.types.primary(Number),
		name: String,
		url: String,

		joinedProductVersion: Number,
		deprecatedProductVersion: Number,

		// For MINECRAFT product only
		xp: Number,

		lastJoinedMinecraftServerBrand: String,

		fullUrl: function() {
			const env = CK.config.environment.name,
				region = this.minecraftRegion();

			if (!region) {
				return;
			}

			if (!this.url()) {
				return;
			}

			return CK.fn.fullModdingUrl(this.url(), env, region);
		},

		updateEmailField: function(name, value) {
			if (_.isNil(value)) {
				CK.logger.warn('Tried to updateEmailField with nil value', {
					name,
					value,
					userId: this.id()
				});
				return;
			}

			value = value.toLowerCase();

			if (this[name]() !== value) {
				this[name](value);
			}
		},

		isBillingOnlyUser: function() {
			return !this.isCombinedBillingAndEditorUser() && !!this.billingToken();
		},

		willBeCombinedBillingAndEditorUserAfterPurchase: function() {
			return !!(this.isLegacyPaidUser() || this.name());
		},

		/**
		 * Whether this user is a combined billing and editor user. If someone visits the editor to set a name and then converts without having autoRegistered (e.g. in the case of camp students) they will be a combined user.
		 */
		isCombinedBillingAndEditorUser: function() {
			return this.willBeCombinedBillingAndEditorUserAfterPurchase() && !!this.billingToken();
		},

		billingToken: String,
		userToken: String,
		parentBillingToken: String,
		isBillingRestricted: Boolean,
		isCoolmath: Boolean,

		retired: Boolean,

		parentEmail: String,

		paymentEmail: String,

		email: String,

		gender: String,

		dateOfBirth: Date,

		minecraftName: String,
		minecraftPeName: String,

		postcode: String,

		passwordHash: String,
		passwordSalt: String,

		authProviders: [
			{
				type: darc.types.primary(String),
				externalId: String,
				data: Object,
				email: String
			}
		],

		lastOnline: Date,

		testCohorts: testCohortSchema,
		previousTestCohorts: testCohortSchema,

		addTestCohorts: function(testCohorts) {
			for (let id in testCohorts) {
				let val = testCohorts[id];

				let current = this.testCohorts(id);

				CK.logger.debug('🆎', 'AB addTestCohorts processing', {
					userId: this.id(),
					testId: id,
					newCohort: val,
					currentCohort: current.$()
				});

				if (current.$exists()) {
					continue;
				}

				this.testCohorts().$add({
					id,
					control: val.control,
					dateEntered: val.dateEntered || new Date()
				});
			}
		},

		exitTestCohorts: function(abTests) {
			abTests = abTests || require('../collections/ABTests');

			const cohorts = this.testCohorts().$(),
				exited = [];

			if (cohorts) {
				for (let i = cohorts.length - 1; i >= 0; i--) {
					let cohort = cohorts[i];

					const test = abTests(cohort.id).$();

					if (test && test.exitPredicate && test.exitPredicate(this)) {
						this.previousTestCohorts().$add(cohort);
						this.testCohorts().$remove(cohort.id);

						exited.push(cohort);
					}
				}
			}

			if (exited.length) {
				CK.logger.debug('🆎', 'exitTestCohorts caused AB test exits', {
					exited
				});
			}

			return exited;
		},

		mixpanelDistinctId: function() {
			return this.trackingId() || this.id();
		},

		testCohortEngageData: function() {
			const engage = {};

			this.testCohorts().$each(cohort => {
				engage['testCohort_' + cohort.id()] = !cohort.control();
			});

			return engage;
		},

		testSeed: Number,
		trackingId: String,

		inTest: function(testId) {
			const cohort = this.testCohorts(testId);

			if (!cohort.$exists()) {
				return;
			}

			return !cohort.control();
		},

		dateCreated: Date,

		dateStarted: Date,

		hasBeenCampUser: Boolean,

		// For authorising with no password through a URL
		simpleAuthCode: String,

		snippets: [SnippetSchema],

		sentEmails: [String],
		subscriptionCode: String,
		passwordResetCodes: [
			{
				code: darc.types.primary(String),
				expires: Date
			}
		],

		projects: [ProjectSchema],
		badges: [BadgeSchema],

		getDisplayProjects: function() {
			return this.getVertical()
				.projects()
				.$find({
					active: true
				});
		},

		worldTemplates: [
			{
				id: darc.types.primary(String),
				name: String,
				uploaded: Boolean,
				uploadStartDate: Date
			}
		],

		customPlugins: [
			{
				id: darc.types.primary(String),
				name: String,
				uploaded: Boolean,
				uploadStartDate: Date
			}
		],

		minecraftPlugins: function() {
			const minecraftPlugins = require('../collections/minecraftPlugins');

			const allPlugins = [].concat(
				minecraftPlugins().$(),
				_.map(_.cloneDeep(this.customPlugins().$()), plugin => {
					plugin.custom = true;

					return plugin;
				})
			);

			return _.keyBy(allPlugins, 'id');
		},

		// These roles can supplement & override roles granted by subscriptions
		roles: [
			{
				name: darc.types.primary(String),
				properties: Object,
				camps: [
					{
						// Refers to a camp id
						id: darc.types.primary(String),
						url: String,
						groups: [
							{
								// Refers to a group id
								id: darc.types.primary(String),
								endDate: Date
							}
						]
					}
				],

				// TODO CORE-2173: These probably need to die as they aren't used for many users and seem legacy
				parentUserId: Number,
				planId: String,
				userId: Number,
				transferredFrom: Number,
				transferredTo: Number,
				revoked: Boolean
			}
		],

		hasRole: function(roleName, ...args) {
			if (!roleName) {
				throw new Error('No role name');
			}

			const proxyName = `has${_.upperFirst(roleName)}Role`;

			const roleProxy = this[proxyName];

			return roleProxy ? roleProxy.call(this, ...args) : this.roles().$has(roleName);
		},

		credits: [
			_.extend(
				{
					incomplete: Boolean,
					chargeId: String,
					revoked: Boolean,
					startDate: Date,
					fixedEndDate: Date,
					endDate: function() {
						return (
							this.fixedEndDate() ||
							subscriptionPlans(this.planId()).endDate(this.startDate() || this.created())
						);
					}
				},
				PurchaseSchema
			)
		],

		subscriptions: [SubscriptionSchema],
		stripeCustomerId: String,

		// The user's card is expected to be present and valid in Stripe
		validCardExpected: Boolean,

		// Stripe restricts a customer to paying in one currency
		paymentCurrency: String,
		externalBilling: String,
		ltvEstimate: Number,

		braintreeDetails: {
			deviceData: String
		},

		utm: Object,

		cancellationAttempts: [
			{
				category: String,
				followUp: String,
				comments: String,
				date: darc.types.primary(Date),
				// Deprecated
				completedCancellation: Boolean
			}
		],

		paymentDeclines: [
			{
				id: darc.types.primary(String),
				date: Date,
				type: String,
				code: String,
				message: String,
				// @deprecated
				gift: Boolean,
				plan: String
			}
		],

		// Minecraft usernames that the user does not want join-while-not-whitelisted notifications for
		whitelistBlocked: [darc.types.unique(String)],
		offlineMode: Boolean,

		minecraftRegion: String,

		analyticsName: function() {
			let name;

			if (this.isBillingOnlyUser()) {
				name = this.paymentEmail() + ' (billing user)';
			} else if (this.autoRegistered() && !this.name()) {
				name = `${this.id()} (autoRegistered, has not chosen a name yet)`;
			} else {
				name = this.name();
			}

			return name;
		},

		analyticsEmail: function() {
			return this.email() || this.paymentEmail() || this.parentEmail();
		},

		basicEngageData: function() {
			return {
				// See https://mixpanel.com/help/questions/articles/special-or-reserved-properties
				$name: this.analyticsName(),
				$email: this.analyticsEmail(),
				testSeed: this.testSeed(),
				dateStarted: this.dateStarted(),
				gender: this.gender(),
				age: moment().diff(this.dateOfBirth(), 'years'),

				autoRegistered: this.autoRegistered(),

				// Store the user id as the original distinct_id that shows in mixpanel is the anonymous tracking id
				userId: this.id(),
				parentEmail: this.parentEmail(),
				paymentEmail: this.paymentEmail(),

				isLegacyPaidUser: this.isLegacyPaidUser(),
				isBillingOnlyUser: this.isBillingOnlyUser(),

				billingLinkedTo: this.linkedTo(),

				roles: _.compact(this.roles().name()),

				authProviders: _.compact(this.authProviders().type()),

				postcode: this.postcode(),

				minecraftRegion: this.minecraftRegion(),

				minecraftName: this.minecraftName(),
				minecraftPeName: this.minecraftPeName(),

				hasOpenedRobloxEditor: this.hasOpenedRobloxEditor(),
				hasOpenedMinecraftEditor: this.hasOpenedMinecraftEditor(),

				checkMinecraftAnswer: this.checkMinecraftAnswer()
			};
		},

		hasOpenedRobloxEditor: function() {
			return !!this.roblox().token();
		},

		hasOpenedMinecraftEditor: function() {
			return !!this.minecraftRegion();
		},

		hasOpenedEditorForProduct: function(product) {
			if (product === products.ROBLOX) {
				return this.hasOpenedRobloxEditor();
			} else if (product === products.MINECRAFT) {
				return this.hasOpenedMinecraftEditor();
			}
		},

		checkMinecraftAnswer: String,

		isClone: Boolean,

		isLegacyPaidUser: Boolean,

		subUsers: [
			{
				id: darc.types.primary(String),
				userId: Number,
				name: String,
				linkPending: Boolean,
				registrationToken: String,
				giftCode: String,
				lastOnline: Date,
				registrationLink: function() {
					if (!this.registrationToken()) {
						throw new Error(
							'No registrationToken is present for this subUser. Cannot generate a registrationLink.'
						);
					}

					const base = CK.config.environment.clients.website.address;

					if (this.giftCode()) {
						return `${base}redeem/${this.giftCode()}/`;
					} else {
						return `${base}register-with-sub-user-token?userId=${this.$parent().id()}&token=${this.registrationToken()}`;
					}
				}
			}
		],

		hasLinkedASubUser: Boolean,

		pendingLinkInvitation: {
			fromUserId: Number,
			fromEmail: String,
			token: String
		},

		linkedTo: Number,

		campInstructorRegistrationCode: String,

		autoRegistered: Boolean,

		intercomUserHash: String,

		sessionRecordings: [
			{
				url: darc.types.primary(String),
				date: Date
			}
		],

		hasHadFreeMonth: Boolean,

		novationRequired: Boolean,
		novationDate: Date
	}
);

module.exports = darc.compile(raw);
module.exports.Set = darc.compile(darc.types.set(module.exports.schema));

module.exports.raw = raw;
