'use strict';

import _ from 'lodash';

const Module = require('../system/Module');

module.exports = Module.define({
	type: 'PerformanceMonitor',

	init: function(parent, options) {
		this.Module_init(parent);

		this.options = _.defaults(options || {}, {
			intervalMs: 100,
			longDelayMs: 1500,
			minimumUptimeMs: 30
		});
	},

	isEnabled: function() {
		return true;
	},

	getHrTime: function() {
		return Date.now();
	},

	bind_eventLoopMonitor: function() {
		if (!this.isEnabled()) {
			return;
		}

		var start = this.getHrTime();
		this.longDelays = 0;

		this._eventInterval = setInterval(() => {
			let next = this.getHrTime();
			var delta = (next - start) / 1000;

			var delay = delta - this.options.intervalMs;
			delay = Math.max(0, Math.round(delay));

			if (!this.checkDelay(start, delay)) {
				return;
			}

			start = next;

			if (delay > this.options.longDelayMs) {
				this.longDelays++;
				this.handleLongDelay(delay);
			}
		}, this.options.intervalMs);
	},

	checkDelay: function(/*start, delay*/) {
		return true;
	},

	handleLongDelay: function(/*count*/) {
		// abstract
	},

	unbind_eventLoopMonitor: function() {
		if (this._eventInterval) {
			clearInterval(this._eventInterval);
			this._eventInterval = null;
		}
	}
});
