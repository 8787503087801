'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'pirates',
	name: 'Pirates',
	duration: 40,
	secondsDuration: 232,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Learn how to build yourself a pirate mini game.',
		'Arrr beware me hearties, there be pirates and terrible accents in this ere mini mod!',
		'Create a simple pirate themed, skull throwing mini game.'
	],
	difficulty: 'Easy',
	unlockXp: 9000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Pirates',
			chapters: [
				{
					name: 'Why Are Pirates Called Pirates? Because They ARRRRRRRR',
					description: 'Build your very own pirate mini game, skulls and all.',
					secondsOffset: 46,
					badges: []
				}
			]
		}
	]
};
