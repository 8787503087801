import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';
import { randomString } from 'ck-core/source/functions/randomString';

const Transition = require('ck-core/source/system/Transition');

module.exports = Transition.define({
	type: 'SharedClientSetupTransition',

	init_transition: function(options) {
		// TODO: Fix when there is no stuff modifying them
		// if ( CK.config.environment.name !== CK_ENV.PRODUCTION ) {

		// 	CK.fn.deepFreeze( CK.const );
		// }

		// Assign loggerDrains first before using defaultsDeep
		this.options = options || {};

		_.defaultsDeep(this.options, {
			googleAnalytics: true,
			trackingCookie: true,
			disableClientSockets: false,
			clientSockets: {
				autoVerifyWithUsersService: true
			},
			intercom: true
		});
		this.step(this.checkMaintenanceMode)
			.step(this.checkCoolMath)
			.step(this.initLogger)
			.step(this.forceOptions)
			.step(this.addGlobalListeners)
			.step(this.initMobile)
			.step(this.initGoogleAnalytics)
			//.step(this.initTaboolaPixel)
			//.step(this.initTiktokPixel)
			.step(this.initialiseModuleContainer)
			.step(this.initIdleManager)
			.step(this.initCookieManager)
			.step(this.initStaffModeManager)
			.step(this.initManagers)
			.step(this.initClientSocketManager)
			.step(this.initMonitorContext)
			.step(this.initTrackingCookies)
			.step(this.initStats)
			.step(this.initPerformanceMonitor)
			.step([
				this.waitForExternalCss,
				this.loadSprites,
				this.setAffiliateFromQueryString,
				this.initialiseInputManager
			])
			.step(this.initIntercom)
			.step(this.initLogoutListener)
			.step(this.addStaffModeInfo);
	},

	checkCoolMath: function() {
		CK.isCoolMath = () =>
			document.location.href.includes('coolmath') || CK.config.name === 'ck-coolmath';
		if (CK.isCoolMath()) {
			const env = CK.config.environment;
			const clients = env.clients;
			const servers = env.servers;
			CK.config.environment.domain = 'coolmathcoding.com';
			if (CK.config.environment.name === 'test') {
				clients.minecraftSequencer.address = 'https://modding-test.coolmathcoding.com/';
				clients.robloxSequencer.address = 'https://roblox-test.coolmathcoding.com/';
				clients.website.address = 'https://test.coolmathcoding.com/';
				servers.roblox.host = 'service-test.coolmathcoding.com';
			} else if (CK.config.environment.name === 'production') {
				clients.minecraftSequencer.address = 'https://modding.coolmathcoding.com/';
				clients.robloxSequencer.address = 'https://roblox.coolmathcoding.com/';
				clients.website.address = 'https://coolmathcoding.com/';
				servers.roblox.host = 'service-production.coolmathcoding.com';
			}
		}
	},

	checkMaintenanceMode: function() {
		let overrideMaintenanceMode;

		try {
			overrideMaintenanceMode = localStorage.getItem('overrideMaintenanceMode');
		} catch (err) {
			CK.logger.warn(err);
		}

		if (
			(CK.config.environment.MAINTENANCE_MODE === true ||
				CK.config.environment.MAINTENANCE_MODE === CK.config.name) &&
			!overrideMaintenanceMode
		) {
			const refreshStatus = $('<div>').css({
				'text-align': 'center'
			});

			let remainingSeconds = 60,
				refreshInterval;

			const refresh = () => {
				if (remainingSeconds === 0) {
					document.location.href = document.location.href;
					clearInterval(refreshInterval);
					return;
				}

				refreshStatus.text(`Refreshing to check latest status in ${remainingSeconds} seconds...`);

				remainingSeconds -= 1;
			};

			refreshInterval = setInterval(refresh, 1000);

			refresh();

			$('body').append(
				refreshStatus,
				$('<iframe>')
					.attr('src', 'https://status.codekingdoms.com')
					.css({
						height: '100%',
						width: '100%'
					})
			);
			this.stop();
		}
	},

	initMobile: function() {
		CK.mobile = bowser.mobile || bowser.tablet;
	},

	initGoogleAnalytics: function() {
		if (!this.options.googleAnalytics) {
			return;
		}

		/* eslint-disable */
		(function(i, s, o, g, r, a, m) {
			i['GoogleAnalyticsObject'] = r;
			(i[r] =
				i[r] ||
				function() {
					(i[r].q = i[r].q || []).push(arguments);
				}),
				(i[r].l = 1 * new Date());

			(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);

			a.async = 1;

			a.src = g;
			m.parentNode.insertBefore(a, m);
		})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

		if (CK.config.name === 'ck-coolmath') {
			ga('create', 'UA-46774792-2', 'auto'); /* eslint-enable */
		} else {
			ga('create', 'UA-46774792-1', 'auto'); /* eslint-enable */
		}
	},

	initTiktokPixel: function() {

			/* eslint-disable */


			!function (w, d, t) {
			  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
			

			  if (CK.config.name === 'ck-coolmath') {
				ttq.load('C8B6D33U4UMEF5JVG0QG'); // Coolmath Coding TikTok pixel
			  } else {
				ttq.load('C4KDC9L1KC6QQ9D0SB10'); // CK TikTok pixel
			  }
			 
			  ttq.page();
			}(window, document, 'ttq');
		

			/* eslint-enable */
	},

	initTaboolaPixel: function() {
		/* eslint-disable */
		window._tfa = window._tfa || [];
		window._tfa.push({ notify: 'event', name: 'page_view' });
		!(function(t, f, a, x) {
			if (!document.getElementById(x)) {
				t.async = 1;
				t.src = a;
				t.id = x;
				f.parentNode.insertBefore(t, f);
			}
		})(
			document.createElement('script'),
			document.getElementsByTagName('script')[0],
			'//cdn.taboola.com/libtrc/unip/1151526/tfa.js',
			'tb_tfa_script'
		);
		/* eslint-enable */
	},

	initTrackingCookies: function() {
		if (!this.options.trackingCookie) {
			return;
		}

		CK.fn.updateTrackingCookie();
	},

	initManagers: function() {
		const TransformManager = require('../managers/TransformManager');
		const UserManager = require('../managers/UserManager');
		const ClientSpaceManager = require('../managers/ClientSpaceManager');
		const StorageManager = require('../managers/StorageManager');

		var spaceManager = new ClientSpaceManager(CK.moduleContainer);
		CK.moduleContainer.add(spaceManager);
		spaceManager.addDomain('users', require('ck-core/source/schemas/UserSchema'));
		CK.moduleContainer.add(new UserManager(CK.moduleContainer));
		CK.moduleContainer.add(new TransformManager(CK.moduleContainer));
		CK.moduleContainer.add(new StorageManager(CK.moduleContainer));
	},
	initIntercom: function() {
		const IntercomManager = require('../managers/IntercomManager');

		if (!this.options.intercom) {
			return;
		}

		// Don't show intercom on mobile devices
		if (bowser.mobile || bowser.tablet) {
			return;
		}

		CK.moduleContainer.add(new IntercomManager(CK.moduleContainer));
	},
	initMonitorContext: function() {
		const ClientMonitorContextService = require('../services/ClientMonitorContextService');

		CK.moduleContainer.add(new ClientMonitorContextService(CK.moduleContainer));
	},

	initStats: function() {
		const ClientStatsManager = require('../managers/ClientStatsManager');
		return CK.moduleContainer.add(new ClientStatsManager(CK.moduleContainer));
	},
	initPerformanceMonitor: function() {
		const ClientPerformanceMonitorService = require('../services/ClientPerformanceMonitorService');

		CK.moduleContainer.add(new ClientPerformanceMonitorService(CK.moduleContainer));
	},

	initLogger: function() {
		const Logger = require('ck-core/source/services/Logger');
		const ClientConsoleLoggerDrain = require('../services/loggerDrains/ClientConsoleLoggerDrain');

		CK.logger = new Logger({
			drains: this.options.loggerDrains || [new ClientConsoleLoggerDrain()]
		});

		darc.console = {
			debug: CK.logger.log.bind(CK.logger),

			log: CK.logger.log.bind(CK.logger),

			info: CK.logger.info.bind(CK.logger),
			warn: CK.logger.warn.bind(CK.logger),

			error: CK.logger.error.bind(CK.logger)
		};
	},

	initClientSocketManager: function() {
		if (this.options.disableClientSockets) {
			return;
		}
		const ClientSocketManager = require('../managers/ClientSocketManager');

		CK.moduleContainer.add(new ClientSocketManager(CK.moduleContainer, this.options.clientSockets));

		CK.logger.bindDrains();
	},

	addGlobalListeners: function() {
		window.onerror = function(msg, url, line, col, error) {
			if (_.some(CK.const.SPURIOUS_ERROR_PREFIXES, prefix => _.startsWith(msg, prefix))) {
				console.warn(msg, error);
				return;
			}

			if (window.__nightmare) {
				// This will have no effect when devtools is closed (in CI (except for file upload tests...))
				CK.logger.log('Opening debugger due to uncaught error...');

				// eslint-disable-next-line no-debugger
				debugger;
			}

			error = error || {};
			if (_.isObject(error)) {
				error.msg = msg;

				error.line = line;
				error.column = col;

				error.url = url;
			}

			CK.logger.error(error);
		};

		window.addEventListener('unhandledrejection', function(e) {
			// NOTE: e.preventDefault() must be manually called to prevent the default
			// action which is currently to log the stack trace to console.warn
			e.preventDefault();

			// NOTE: use the event detail property for parameters if available
			var reason = _.get(e, 'detail.reason') || _.get(e, 'reason');

			// See Promise.onPossiblyUnhandledRejection for parameter documentation
			_.set(reason, 'tags.unhandledRejection', true);

			if (_.get(reason, 'stack')) {
				reason = reason.stack;
			}

			// TODO: This opened a huge can of error - flavoured worms( especially what looks like pickers / tabs being rapidly closed in the editor ) so downgrade it to log to preserve testing sanity
			CK.logger.log(CK.fn.safeFormat(reason));
			// CK.logger.error( CK.fn.safeFormat( reason ) );
		});

		// Prevent image dragging
		$(document).on('dragstart', function() {
			return false;
		});

		// This is called when link interceptors (HyperlinkBinder and friends) are actually initialised so that links work while the page is loading

		var preventedAnchorDefaults = false;
		CK.preventAnchorDefaults = function() {
			if (preventedAnchorDefaults || CK.config.name === 'ck-roblox-sequencer' || CK.mobile) {
				return;
			}
			preventedAnchorDefaults = true;

			// Prevent hyperlinks
			$('body').on('tap click', 'a', function(e) {
				e.preventDefault();
			});
		};
		if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
			// Open debugger on F12 locally
			$(window).keydown(function(e) {
				if (e.keyCode === 123)
					// eslint-disable-next-line no-debugger
					debugger;
			});

			$(window).keydown(function(e) {
				if (e.ctrlKey && e.keyCode === 67) {
					if ($('.registerForm').length) {
						const registerValues = {
							password: 'magictroll888',
							email: 'fortismere+local' + randomString() + '@codekingdoms.com'
						};

						for (let name in registerValues) {
							let value = registerValues[name];

							const element = $(`.registerForm input[name="${name}"]`);

							if (element.length) {
								element.val(value);
								element
									.parent()
									.data('validatedInput')
									.onChange();
							}
						}
					}
				}
			});
		}
	},
	initialiseModuleContainer: function() {
		const ClientModuleContainer = require('../system/ClientModuleContainer');
		CK.moduleContainer = new ClientModuleContainer();
		CK.moduleContainer.bind();
	},
	initCookieManager: function() {
		const CookieManager = require('../managers/CookieManager');
		CK.moduleContainer.add(new CookieManager(CK.moduleContainer));
	},
	initStaffModeManager: function() {
		const StaffModeManager = require('../managers/StaffModeManager');
		CK.moduleContainer.add(new StaffModeManager(CK.moduleContainer));
	},

	loadSprites: function() {
		return new Promise(fulfil => {
			// BUILD CHECK

			if (!CK.config.graphics.spritesJSON) {
				return fulfil();
			}

			const url = CK.config.rootDirectory + CK.config.graphics.spritesJSON;

			const Assets = require('../system/Assets');
			const CKError = require('ck-core/source/classes/Error');

			$.ajax({
				url,

				dataType: 'json',

				success: function(data) {
					Assets.init(data);
					fulfil();
				},

				error: function(jqXhr, textStatus, errorThrown) {
					if (jqXhr && jqXhr.getAllResponseHeaders && jqXhr.getAllResponseHeaders() === null) {
						CK.logger.log('Sprites JSON load aborted');
					} else {
						CK.logger.warn(
							new CKError({
								message: 'Sprites JSON load failed',
								tags: {
									url,
									textStatus,
									errorThrown
								}
							})
						);
					}

					fulfil();
				}
			});
		});
	},
	waitForExternalCss: async function() {
		// Written with some reference to http://stackoverflow.com/questions/5537622/dynamically-loading-css-file-using-javascript-with-callback-without-jquery
		const waitForExternalCssAsync = function(url, callback) {
			const TIMEOUT_MS = 10000;
			const startTime = Date.now();

			const loadCheckInterval = setInterval(function() {
				const link = $('link[href="' + url + '"]');
				if (!link.length) {
					return;
				}

				let sheet, cssRules;

				if ('sheet' in link[0]) {
					sheet = 'sheet';
					cssRules = 'cssRules';
				} else {
					sheet = 'styleSheet';
					cssRules = 'rules';
				}

				try {
					if (link[0][sheet] && link[0][sheet][cssRules] && link[0][sheet][cssRules].length) {
						clearInterval(loadCheckInterval);
						callback();

						return;
					}
				} catch (e) {
					CK.logger.log('Missing css', e);
				}

				if (Date.now() - startTime > TIMEOUT_MS) {
					clearInterval(loadCheckInterval);
					callback(true);
				}
			}, 50);
		};

		(async () => {
			const promises = [];
			while (!window.ckExternalCss) {
				await Promise.delay(1);
			}

			_.each(window.ckExternalCss, function(css) {
				promises.push(
					new Promise(function(fulfil) {
						waitForExternalCssAsync(css, fulfil);
					})
				);
			});

			return Promise.all(promises);
		})();
	},

	initialiseInputManager: function() {
		const input = require('../services/input');
		input.init();
	},

	setAffiliateFromQueryString: function() {
		var urlArgs = CK.fn.getQueryVariables();

		const StorageManager = require('../managers/StorageManager');
		let storage = StorageManager.mod();

		if (urlArgs.affiliate) {
			storage.set('affiliate', urlArgs.affiliate);
		}
	},

	initIdleManager: function() {
		const IdleManager = require('../managers/IdleManager');
		CK.moduleContainer.add(new IdleManager(CK.moduleContainer));
	},

	initLogoutListener: function() {
		CK.moduleContainer.subscribe(CK.endpoints.message.EVENTS.MESSAGE + 'logout', () => {
			if (!CK.user.$exists()) {
				return;
			}

			const MessageModal = require('../ui/modals/MessageModal');

			MessageModal.create(
				_.extend(
					{
						actions: {
							ok: function() {
								window.location = CK.config.environment.clients.website.address;
							}
						}
					},
					require('./SharedClientSetupTransitionLang')
				)
			);

			const UserManager = require('../managers/UserManager');
			UserManager.mod().logout();
		});
	},

	forceOptions: async function() {
		const queryVariables = CK.fn.getQueryVariables();

		if (queryVariables.forceTestSeed) {
			CK.config.forceTestSeed = parseFloat(queryVariables.forceTestSeed);
		}

		if (queryVariables.ignoreOnboarding) {
			CK.config.ignoreOnboarding = queryVariables.ignoreOnboarding === 'true';
		}

		if (queryVariables.noOnlineMode) {
			CK.config.noOnlineMode = queryVariables.noOnlineMode === 'true';
		}

		if (queryVariables.forceGeoInfo) {
			CK.config.forceGeoInfo = JSON.parse(queryVariables.forceGeoInfo);
		}

		if (queryVariables.forceEmailCaptureModalTimeout) {
			CK.config.forceEmailCaptureModalTimeout = parseInt(
				queryVariables.forceEmailCaptureModalTimeout,
				10
			);
		}

		if (queryVariables.disableVideoAutoplay && queryVariables.disableVideoAutoplay !== 'false') {
			CK.config.disableVideoAutoplay = true;
		}

		// N.B. USE THE FOLLOWING ARGUMENTS WITH EXTREME CAUTION - will put ANYONE into the group, even if they've registered and paid
		const abTests = require('ck-core/source/collections/ABTests');

		if (queryVariables.forceTestCohort) {
			let abTest = abTests(queryVariables.forceTestCohort);

			if (abTest.$exists() && abTest.active()) {
				CK.config.forceTestCohort = queryVariables.forceTestCohort;
			} else {
				CK.logger.log('Trying to force test cohort that is not active or does not exist');
			}
		}

		if (queryVariables.forceControlCohort) {
			let abTest = abTests(queryVariables.forceControlCohort);

			if (abTest.$exists() && abTest.active()) {
				CK.config.forceControlCohort = queryVariables.forceControlCohort;
			} else {
				CK.logger.log('Trying to force control cohort that is not active or does not exist');
			}
		}

		if (queryVariables.disableMinecraftProvisioning) {
			CK.config.disableMinecraftProvisioning = true;
		}
	},

	addStaffModeInfo: function() {
		const StaffModeManager = require('../managers/StaffModeManager');
		const staffModeManager = StaffModeManager.mod();
		const UserManager = require('../managers/UserManager');
		const userManager = UserManager.mod();

		const staffInfo = $('<div class="staffInfo">');
		staffInfo.hide();

		const updateStaffInfo = function() {
			const staffSession = staffModeManager.getSession();

			staffInfo.hide();

			if (staffSession && staffSession.userId) {
				const loggedInId = CK.user.id();

				if (CK.user.$exists() && staffSession.userId !== loggedInId) {
					staffInfo.text(`Acting as ${loggedInId} - ${CK.user.analyticsName()}. `);
					staffInfo.append(
						$(`<a>`)
							.text(`Back to staff panel`)
							.click(async function() {
								const Frame = require('../ui/Frame');

								let base;

								if (CK.config.name === 'ck-website') {
									base = '/';
								} else {
									base = CK.config.environment.clients.website.address;
								}

								Frame.mod().navigate(`${base}staff/user/?id=${loggedInId}`);
							})
					);
					staffInfo.append('  ');
					staffInfo.append(
						$(`<a>`)
							.text(`Switch to staff ${staffSession.userId}`)
							.click(async function() {
								await userManager.logout();

								await userManager.verifySession(staffSession);

								const Frame = require('../ui/Frame');

								Frame.mod().navigate(
									`${CK.config.environment.clients.website.address}staff/user/?id=${loggedInId}`
								);
							})
					);
					staffInfo.show();
				} else if (!CK.user.$exists()) {
					staffInfo.html(
						$(`<a>`)
							.text(`Clear staff cookie for ${staffSession.userId}`)
							.click(function() {
								staffModeManager.clearSession();

								updateStaffInfo();
							})
					);
					staffInfo.show();
				}
			}
		};

		updateStaffInfo();

		CK.user.$bind(updateStaffInfo);

		$('body').append(staffInfo);
	}
});
