window.CK = {}; window.CK.config = {
    "exports": {},
    "SVG_DATA_URL_PREFIX_LENGTH": 36,
    "CK_HASH_IDENTIFIER": ".ckh.",
    "environment": {
        "domain": "codekingdoms.com",
        "campDomainBase": ".codekingdoms.com",
        "name": "production",
        "servers": {
            "users": {
                "host": "service-production.codekingdoms.com",
                "protocol": "https",
                "port": 443,
                "projectPath": "users",
                "path": "/users"
            },
            "monitor": {
                "host": "service-production.codekingdoms.com",
                "protocol": "https",
                "port": 443,
                "projectPath": "monitor",
                "path": "/monitor"
            },
            "roblox": {
                "host": "service-production.codekingdoms.com",
                "protocol": "https",
                "port": 443,
                "projectPath": "roblox/server",
                "path": "/roblox"
            },
            "sentinel": {
                "projectPath": "minecraft/sentinel"
            },
            "minecraftProxy-us": {
                "host": "minecraft-proxy-production-us.codekingdoms.com",
                "protocol": "https",
                "port": 443,
                "projectPath": "minecraft/proxy",
                "path": ""
            },
            "minecraftProxy-eu": {
                "host": "minecraft-proxy-production-eu.codekingdoms.com",
                "protocol": "https",
                "port": 443,
                "projectPath": "minecraft/proxy",
                "path": ""
            },
            "minecraftProxy-ap": {
                "host": "minecraft-proxy-production-ap.codekingdoms.com",
                "protocol": "https",
                "port": 443,
                "projectPath": "minecraft/proxy",
                "path": ""
            },
            "logstash": {
                "protocol": "https",
                "host": "service-production.codekingdoms.com",
                "port": 443,
                "path": "/logstash"
            }
        },
        "clients": {
            "status": {
                "address": "https://status.codekingdoms.com/"
            },
            "website": {
                "address": "https://codekingdoms.com/"
            },
            "minecraftSequencer": {
                "address": "https://modding.codekingdoms.com/"
            },
            "robloxSequencer": {
                "address": "https://roblox.codekingdoms.com/"
            },
            "camps": {
                "address": "https://camps.codekingdoms.com"
            },
            "coolmath": {
                "address": "https://coolmathcoding.com/"
            }
        },
        "STRIPE_PUBLISHABLE_KEY": "pk_live_cyq6qSUYlftsYYhx9cJLdxIO",
        "GOOGLE_SIGNON_CLIENT_ID": "408113533509-hkvbq2o47gsdl7bhsu3qbp79ru6fs0hf.apps.googleusercontent.com",
        "FACEBOOK_SIGNON_APP_ID": "1435215689864509"
    },
    "name": "ck-website",
    "release": "1465-7913661960",
    "rootDirectory": "/",
    "graphics": {
        "spritesJSON": "graphics/sprites.ckh.21de815.json",
        "spritesheets": []
    },
    "builtCss": [
        "/ck.css"
    ],
    "audio": {}
};