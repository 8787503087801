'use strict';

import _ from 'lodash';

module.exports = function( mainPlans ) {
	const plans = [ {
			id: 'ck-test-recurring-1',
			active: true,
			interval: 'month',
			intervalCount: 1,
			price: [ {
					currency: 'gbp',
					value: 1399
				},
				{
					currency: 'eur',
					value: 1999
				},
				{
					currency: 'usd',
					value: 2499
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-1',
			active: true,
			oneOff: true,
			interval: 'month',
			intervalCount: 3,
			price: [ {
					currency: 'gbp',
					value: 3499
				},
				{
					currency: 'eur',
					value: 5499
				},
				{
					currency: 'usd',
					value: 5999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-family-1',
			active: true,
			family: true,
			oneOff: true,
			interval: 'month',
			intervalCount: 3,
			price: [ {
					currency: 'gbp',
					value: 3499
				},
				{
					currency: 'eur',
					value: 5499
				},
				{
					currency: 'usd',
					value: 5999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-2',
			active: true,
			oneOff: true,
			interval: 'month',
			intervalCount: 6,
			price: [ {
					currency: 'gbp',
					value: 3499
				},
				{
					currency: 'eur',
					value: 5499
				},
				{
					currency: 'usd',
					value: 5999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-12',
			active: true,
			oneOff: true,
			interval: 'month',
			intervalCount: 12,
			price: [ {
					currency: 'gbp',
					value: 3499
				},
				{
					currency: 'eur',
					value: 5499
				},
				{
					currency: 'usd',
					value: 5999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-lifetime',
			active: true,
			oneOff: true,
			intervalCount: 10,
			interval: 'year',
			lifetime: true,
			price: [ {
					currency: 'gbp',
					value: 3499
				},
				{
					currency: 'eur',
					value: 5499
				},
				{
					currency: 'usd',
					value: 5999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-recurring-2',
			active: true,
			interval: 'month',
			intervalCount: 1,
			price: [ {
					currency: 'gbp',
					value: 1499
				},
				{
					currency: 'usd',
					value: 1999
				},
				{
					currency: 'eur',
					value: 1799
				},
				{
					currency: 'cad',
					value: 2499
				},
				{
					currency: 'aud',
					value: 2499
				}
			],
			freeTrialDays: 7,
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-recurring-3',
			active: true,
			interval: 'month',
			intervalCount: 1,
			price: [ {
					currency: 'gbp',
					value: 999
				},
				{
					currency: 'usd',
					value: 1499
				},
				{
					currency: 'eur',
					value: 1299
				},
				{
					currency: 'cad',
					value: 1999
				},
				{
					currency: 'aud',
					value: 1999
				}
			],
			freeTrialDays: 7,
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-recurring-4',
			active: true,
			interval: 'month',
			intervalCount: 1,
			price: [ {
					currency: 'gbp',
					value: 999
				},
				{
					currency: 'usd',
					value: 1499
				},
				{
					currency: 'eur',
					value: 1299
				},
				{
					currency: 'cad',
					value: 1999
				},
				{
					currency: 'aud',
					value: 1999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-recurring-5',
			active: true,
			interval: 'month',
			intervalCount: 3,
			price: [ {
					currency: 'gbp',
					value: 2999
				},
				{
					currency: 'eur',
					value: 3899
				},
				{
					currency: 'usd',
					value: 4499
				},
				{
					currency: 'cad',
					value: 5999
				},
				{
					currency: 'aud',
					value: 5999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-recurring-7',
			active: true,
			interval: 'month',
			intervalCount: 1,
			price: [ {
					currency: 'gbp',
					value: 999
				},
				{
					currency: 'usd',
					value: 1499
				},
				{
					currency: 'eur',
					value: 1299
				},
				{
					currency: 'cad',
					value: 1999
				},
				{
					currency: 'aud',
					value: 1999
				}
			],
			freeTrialDays: 30,
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		},
		{
			id: 'ck-test-recurring-8',
			active: true,
			interval: 'year',
			intervalCount: 1,
			price: [ {
					currency: 'gbp',
					value: 5999
				},
				{
					currency: 'usd',
					value: 8999
				},
				{
					currency: 'eur',
					value: 6999
				},
				{
					currency: 'cad',
					value: 9999
				},
				{
					currency: 'aud',
					value: 9999
				}
			],
			roles: [ CK.const.ROLES.MINECRAFT_25 ]
		}
	];

	const v3PlansToCopyForTests = [
		'ck-v4-modding-monthly-recurring',
		'ck-v4-bundle-monthly-recurring',
		'ck-v4-modding-3-monthly-recurring',
		'ck-v4-modding-12-months',
		'ck-v4-roblox-12-months',
		'ck-v4-bundle-12-months',
		'ck-v4-modding-12-months-family',
		'ck-v4-roblox-12-months-family',
		'ck-v4-bundle-12-months-family',
		'ck-v4-modding-lifetime',
		'ck-v4-roblox-lifetime',
		'ck-v4-bundle-lifetime',
		'ck-v4-bundle-lifetime-family',
		'ck-v4-roblox-lifetime-family'
	];

	for ( let planId of v3PlansToCopyForTests ) {
		const plan = _.cloneDeep(
			_.find( mainPlans, {
				id: planId
			} )
		);

		plan.active = true;

		plan.id = plan.id.replace( /-v4-/, '-test-' );

		plans.push( plan );
	}

	plans.push(
		_.defaults( {
				id: 'ck-test-gift'
			},
			_.find( plans, {
				id: 'ck-test-1'
			} )
		)
	);

	plans.push(
		_.defaults( {
				id: 'ck-test-extraChild'
			},
			_.find( plans, {
				id: 'ck-test-1'
			} )
		)
	);

	plans.push(
		_.defaults( {
				id: 'ck-test-family'
			},
			_.find( plans, {
				id: 'ck-test-1'
			} )
		)
	);

	plans.push(
		_.defaults( {
				id: 'ck-test-selfRedeemed',
				selfRedeemable: true
			},
			_.find( plans, {
				id: 'ck-test-1'
			} )
		)
	);

	return plans;
};
