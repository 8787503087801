'use strict';

module.exports = {
	brands: [require('../../../../data/brands').MINECRAFT_JAVA],
	id: 'dance-to-the-moosic',
	name: 'Dance to the Moosic',
	duration: 180,
	secondsDuration: 3052,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Create a cow themed nightclub where your players have to dance in time to the moo-sic!',
		"Anyone who says going to a disco full of cows isn't fun has either never tried it, or is just wrong. In this course, you'll be making music and getting your players to dance to the beat 'til the cows come home.",
		'This course involves randomising events and adding a score system to detect the movement of players in a cow-themed dancing game. '
	],

	difficulty: 'Hard',
	unlockXp: 28000,

	videos: [
		{
			name: 'Dance To The Moosic',
			chapters: [
				{
					name: 'Beat It!',
					description: 'This chapter shows you how to add a beat to your world.',
					badges: ['communication-3', 'methods-1'],
					secondsOffset: 128
				},
				{
					name: 'Cow Deep Is Your Love?',
					description: 'This chapter shows you how to repeat the music in an interval.',
					badges: ['timer-2'],
					secondsOffset: 258
				},
				{
					name: 'We Will Rock Moo',
					description: 'This chapter shows you how to add extra beats to your club.',
					badges: ['generalisation-1', 'timer-1'],
					secondsOffset: 356
				},
				{
					name: `Cow That's What I call Moosic`,
					description: 'This chapter teaches you how to pick a random move.',
					badges: ['random-1', 'parameters-3'],
					secondsOffset: 451
				},
				{
					name: 'Teach Me Cow To Dougie',
					description:
						'This chapter shows you how to use switch statements for your different moves.',
					badges: ['switch-1', 'variables-2'],
					secondsOffset: 726
				},
				{
					name: 'I Know This Love Is Moo',
					description:
						'This chapter teaches you how to use a for loop to pick a move for all players.',
					badges: ['methods-1', 'loops-1', 'parameters-1'],
					secondsOffset: 930
				},
				{
					name: 'Moo York Moo York',
					description: 'In this chapter you make a start on increasing the difficulty of your game',
					badges: ['fields-1', 'fields-2'],
					secondsOffset: 1122
				},
				{
					name: `Can't Live If Living Is Without Moo`,
					description: 'Learn how to increase the difficulty of your game with the beat!',
					badges: ['evaluation-1', 'fields-2'],
					secondsOffset: 1232
				},
				{
					name: 'Moo Two',
					description: 'In this chapter you regulate the difficulty increase.',
					badges: ['timer-2', 'variables-2'],
					secondsOffset: 1350
				},
				{
					name: 'Udderly Crazy',
					description: 'This chapter shows you how to improve your existing levelling up system.',
					badges: ['logic-2', 'evaluation-3'],
					secondsOffset: 1628
				},
				{
					name: 'Dairy Goes',
					description: 'In this chapter you head back to the player file to add a score system!',
					badges: ['booleans-3', 'communication-1'],
					secondsOffset: 1742
				},
				{
					name: 'The Steaks Have Never Been Higher',
					description: 'This chapter splits your methods up to make the code easier to work with.',
					badges: ['timer-1', 'evaluation-1'],
					secondsOffset: 2044
				},
				{
					name: 'A Man Threw Butter At Me. How Dairy.',
					description: 'This chapter shows you how to kick players out when they lose.',
					badges: ['fields-2', 'communication-2'],
					secondsOffset: 2287
				},
				{
					name: `It's Pasture Bedtime`,
					description:
						'This chapter adds else statements to add conditions to when a player loses.',
					badges: ['nesting-1', 'conditions-2'],
					secondsOffset: 2514
				},
				{
					name: 'Deja Moo',
					description:
						'This chapter teaches you how to run a game over check at the end of the game.',
					badges: ['loops-2', 'flow-1'],
					secondsOffset: 2704
				}
			]
		}
	]
};
