'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'test-project',
	name: 'Test Project',
	duration: 120,
	secondsDuration: 1675,
	active: false,
	isCourse: true,
	shortCourse: false,
	noTutorial: true,
	hasPdf: false,
	hasCampPdf: false,
	unlockXp: 3000,
	difficulty: 'Easy',
	descriptions: [],

	additionalFiles: [
		{
			path: '/Hammer',
			superclass: 'BaseTool'
		},
		{
			path: '/Barrel',
			superclass: 'BaseObject'
		},
		{
			path: '/ClearButton',
			superclass: 'BaseLocalUi'
		},
		{
			path: '/Dynamite',
			superclass: 'BaseObject'
		},
		{
			path: '/DynamiteSlingshot',
			superclass: 'BaseTool'
		},
		{
			path: '/FlashingBall',
			superclass: 'BaseObject'
		},
		{
			path: '/FreezeButton',
			superclass: 'BaseLocalUi'
		},
		{
			path: '/FreezePlayer',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Block',
			superclass: 'BaseObject'
		},
		{
			path: '/IceBlock',
			superclass: 'BaseObject'
		},
		{
			path: '/Kong',
			superclass: 'BaseObject'
		},
		{
			path: '/Orb',
			superclass: 'BaseObject'
		},
		{
			path: '/Player',
			superclass: 'BasePlayer'
		},
		{
			path: '/PlayerButtonClick',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Sheep',
			superclass: 'BaseCharacter'
		},
		{
			path: '/Tool',
			superclass: 'BaseTool'
		}
	],

	videos: [
		{
			name: 'Treasure Island',
			chapters: [
				{
					name: 'Get (Ship)wrecked',
					description: 'Get to grips with the code inside the Ore file',
					secondsOffset: 24,
					badges: ['syntax-1', 'evaluation-1'],
					noCodeChapter: true
				}
			]
		}
	]
};
