'use strict';

const assets = (module.exports = {
	_data: {},

	init: function(data) {
		this._data = data;
	},

	get: function(name, suppressWarning) {
		var sprite = assets._data[name];

		if (!sprite) {
			if (!suppressWarning && name) {
				CK.logger.log('Sprite not found:', name);
			}
			return;
		}

		if (sprite.image && !sprite.urlEncoded) {
			sprite.image =
				sprite.image.substring(0, CK.config.SVG_DATA_URL_PREFIX_LENGTH) +
				encodeURIComponent(
					sprite.image.substring(CK.config.SVG_DATA_URL_PREFIX_LENGTH, sprite.image.length)
				);
			sprite.urlEncoded = true;
		}
		if (sprite.url && !sprite.absoluteUrl) {
			sprite.url = CK.config.rootDirectory + 'graphics/' + sprite.url;
			sprite.absoluteUrl = true;
		}

		return sprite;
	}
});
