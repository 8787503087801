'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'goal-in-one',
	name: 'Goal In One',
	duration: 120,
	secondsDuration: 2941,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: false,
	hasCampPdf: false,
	position: 10,
	unlockXp: 1400,
	comingSoon: false,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Player',
			superclass: 'BasePlayer'
		},
		{
			path: '/Ball',
			superclass: 'BaseObject'
		},
		{
			path: '/Flag',
			superclass: 'BaseObject'
		},
		{
			path: '/Checkpoint/CheckpointPlayer',
			superclass: 'BasePlayer'
		},
		{
			path: '/Checkpoint/CheckpointTile',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 102,
			lookAhead: true,
			message:
				'Test out our completed Goal In One course by visiting https://www.roblox.com/games/1914387508/Goal-In-One'
		}
	],

	descriptions: [
		`Learn how to build your own golf course from scratch!`,
		`Build a multi-hole course for you and your friends to play on!`,
		`Learn how to use Roblox Studio to alter the properties of parts`
	],

	videos: [
		{
			name: 'Goal In One',
			chapters: [
				{
					name: 'A Goal New World',
					description: 'Create a checkpoint and spawn point and attach their scripts',
					secondsOffset: 69,
					badges: [],
					noCodeChapter: true
				},
				{
					name: 'Goal Finger',
					description: 'Write the code that gives a player a ball when they spawn',
					secondsOffset: 413,
					badges: ['conditions-1', 'methods-2']
				},
				{
					name: 'Goaling In The Deep',
					description: 'Build your very own golf course map',
					secondsOffset: 1052,
					badges: [],
					noCodeChapter: true
				},
				{
					name: 'Birdie Is The Word',
					description:
						'Write code to make the player die everytime your ball fall off your course!',
					secondsOffset: 1441,
					badges: ['parameters-2', 'fields-1']
				},
				{
					name: "Let's Par-ty",
					description:
						'Build a flagpole and write code that makes your ball disappear when it reaches it',
					secondsOffset: 1737,
					badges: ['conditionals-1', 'methods-2']
				},
				{
					name: 'Game Of Throw-ins',
					description: "Write some code so that you don't die everytime you reach a flag",
					secondsOffset: 2065,
					badges: ['booleans-1', 'methods-2']
				},
				{
					name: "Murder On Zidane's Floor",
					description: 'Add code that means you teleport to the next hole in your course.',
					secondsOffset: 2471,
					badges: ['variables-2']
				}
			]
		}
	]
};
