'use strict';

import _ from 'lodash';

const minecraftDefinitions = [
	require('./activities/minecraft/courses/sheep-city'),
	require('./activities/minecraft/courses/blocky-races'),
	require('./activities/minecraft/courses/building'),
	require('./activities/minecraft/courses/dance-to-the-moosic'),
	require('./activities/minecraft/courses/egg-wars'),
	require('./activities/minecraft/courses/epic-mods'),
	require('./activities/minecraft/courses/flappy-block'),
	require('./activities/minecraft/courses/heist-and-seek'),
	require('./activities/minecraft/courses/high-noon-saloon'),
	require('./activities/minecraft/courses/horror'),
	require('./activities/minecraft/courses/hungry-games'),
	require('./activities/minecraft/courses/lucky-blocks'),
	require('./activities/minecraft/courses/rise-of-the-werebunnies'),
	require('./activities/minecraft/courses/simple-lucky-blocks'),
	require('./activities/minecraft/courses/spartan-school'),
	require('./activities/minecraft/courses/spleef'),
	require('./activities/minecraft/courses/swoop-de-loop'),
	require('./activities/minecraft/courses/the-great-blockish-bake-off'),
	require('./activities/minecraft/courses/the-walls')
];

const minecraftMiniModDefinitions = [
	require('./activities/minecraft/mini-mods/avengers'),
	require('./activities/minecraft/mini-mods/bouncing-around'),
	require('./activities/minecraft/mini-mods/bowing-n-glowing'),
	require('./activities/minecraft/mini-mods/construction-collection'),
	require('./activities/minecraft/mini-mods/dance-to-the-twoosic'),
	require('./activities/minecraft/mini-mods/mob-madness'),
	require('./activities/minecraft/mini-mods/no-place-like-home'),
	require('./activities/minecraft/mini-mods/one-in-the-chamber'),
	require('./activities/minecraft/mini-mods/parkour'),
	require('./activities/minecraft/mini-mods/pirates'),
	require('./activities/minecraft/mini-mods/player-v-player'),
	require('./activities/minecraft/mini-mods/ravine-runner'),
	require('./activities/minecraft/mini-mods/server-mania'),
	require('./activities/minecraft/mini-mods/trolling-1'),
	require('./activities/minecraft/mini-mods/trolling-2'),
	require('./activities/minecraft/mini-mods/welcome-to-the-mob'),
	require('./activities/minecraft/mini-mods/wizard-wonder')
];

const robloxDefinitions = [
	require('./activities/roblox/barrel-roll'),
	require('./activities/roblox/ninja-obby'),
	require('./activities/roblox/snowclones'),
	require('./activities/roblox/alien-attack'),
	require('./activities/roblox/wrestleball'),
	require('./activities/roblox/platform-game-design'),
	require('./activities/roblox/riddle-ruins'),
	require('./activities/roblox/game-dev-tycoon'),
	require('./activities/roblox/zombie-smash'),
	require('./activities/roblox/prison-escape'),
	require('./activities/roblox/stranger-hill'),
	require('./activities/roblox/terrain-tools'),
	require('./activities/roblox/nightlights'),
	require('./activities/roblox/box-car-racer'),
	require('./activities/roblox/clean-slate'),
	require('./activities/roblox/music-room'),
	require('./activities/roblox/goal-in-one'),
	require('./activities/roblox/treasure-island'),
	require('./activities/roblox/publishing-pro'),
	require('./activities/roblox/marketing-crash-course'),
	require('./activities/roblox/epic-prison-escape'),
	require('./activities/roblox/picnic-pinball'),
	require('./activities/roblox/test-project')
];

const definitions = minecraftDefinitions
	.concat(minecraftMiniModDefinitions)
	.concat(robloxDefinitions);

// TODO: Move to a property of the schema and do it automatically in initialisation
_.each(definitions, activity => {
	CK.fn.assignOneIndexedIds(activity.videos);

	_.each(activity.videos, video => {
		let isFullChapter = _.get(video.chapters, '0.badges');

		// Need the offset to compensate for chapter with secondsOffset = 0 being added dynamically
		CK.fn.assignOneIndexedIds(video.chapters, isFullChapter ? 0 : 1);
	});
});

module.exports = require('../data/createCollection')(
	require('../schemas/ActivitySchema'),
	definitions
);
