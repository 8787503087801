'use strict';

const CKError = require('../classes/Error');

module.exports = function() {
	return {
		subscribe: function(event, callback, self) {
			self = self || this;

			if (!callback) {
				return CK.logger.warn(
					'Callback not passed to EventService in subscribe for event: ' + event + ' on ' + self
				);
			}

			if (!this._events) this._events = {};

			var events = this._events;

			if (!events[event]) events[event] = [];
			var eventListeners = events[event];

			// Check for duplicate subscriptions and warn

			for (let listener of eventListeners) {
				if (listener && listener.callback === callback && listener.self === self) {
					CK.logger.warn(
						'Duplicate subscription for event',
						event,
						'on',
						this,
						':',
						callback,
						'with self',
						self
					);
				}
			}

			const listener = {
				self,
				callback
			};

			eventListeners.push(listener);

			return this;
		},

		once: function(event, callback, self) {
			var t = this;
			var wrapped = function() {
				var args = new Array(arguments.length);

				for (let i = 0; i < args.length; ++i) {
					//i is always valid index in the arguments object

					args[i] = arguments[i];
				}

				t.unsubscribe(event, wrapped, self);

				return callback.apply(this, args);
			};

			this.subscribe(event, wrapped, self);
		},
		unsubscribe: function(event, callback) {
			if (!this._events) {
				return;
			}

			if (!callback) {
				delete this._events[event];
				return;
			}

			const group = this._events[event];

			var found = false;

			for (var i in group) {
				let listener = group[i];

				if (listener && listener.callback === callback) {
					found = true;
					break;
				}
			}

			if (found) {
				group[i] = null;
			} else {
				CK.logger.warn(
					'Could not unsubscribe',
					callback,
					'from',
					event,
					'on',
					this,
					'as it is not subscribed'
				);
			}
		},

		/**

		 * Listeners can force success by returning true, which will stop subsequent listeners from hearing the event.

		 * Listeners returning undefined will allow the event to continue being dispatched to listeners.

		 * Listeners returning false can force failure by returning false, which will stop subsequent listeners from hearing the event.

		 */
		publish: function(event, data) {
			if (!this._events) this._events = {};

			var args = [data];

			for (let i = 2; i < arguments.length; i++) {
				args.push(arguments[i]);
			}

			let parts = event.split('.'),
				result;

			for (let x = parts.length; x > 0; x--) {
				let name = parts.join('.');
				let group = this._events[name];

				for (let i in group) {
					let listener = group[i];

					if (!listener) {
						continue;
					}

					let callback = group[i].callback;

					if (!callback) {
						CK.logger.log(
							new CKError({
								message: 'Callback is falsy for event',
								tags: {
									event,
									name,
									group: CK.fn.safeFormat(group),
									i
								}
							})
						);
						continue;
					}

					result = callback.apply(group[i].self, args);

					if (result === false || result === true) {
						return result;
					}
				}

				parts.pop();
			}
			return result;
		},

		clearEvents: function() {
			delete this._events;
		},

		type: 'EventService'
	};
};
