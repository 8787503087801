'use strict';

// @deprecated
module.exports = {
	// User Ids of extra accounts
	extraChildren: [Number],

	preAuthFailed: Boolean,

	simpleRegister: Boolean,

	confirmedEmail: Boolean
};
