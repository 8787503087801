'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'platform-game-design',
	name: 'Platform Game Design',
	duration: 240,
	secondsDuration: 4270,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasCampPdf: true,
	hasPdf: true,
	position: 10,
	unlockXp: 17500,
	difficulty: 'Hard',

	additionalFiles: [
		{
			path: '/Coin',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/LevelEnd',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/Enemy',
			superclass: 'BaseCharacter'
		},
		{
			path: '/Platformer/PlayerCamera',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Platformer/PlayerControls',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Common/PlayerScore',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Common/Killer',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/Checkpoint/CheckpointTile',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/Checkpoint/CheckpointPlayer',
			superclass: 'BaseLocalPlayer'
		}
	],

	cues: [
		{
			name: 'message',
			time: 720,
			lookAhead: true,
			message:
				'Test out our completed Platform Game Design course by visiting https://web.roblox.com/games/1261156237/Platform-Game'
		}
	],

	descriptions: [
		`Avoid red obstacles and collect coins in this 2D platform Roblox game.`,
		`Platform Game Design is structured in a slightly different way to a lot of the other Code Kingdoms Roblox courses as it primarily focuses on game design, as opposed to practical coding. Some coding is required in this course but we would recommend it as most suitable for confident budding programmers or experienced Roblox players with an interest in game design. `,
		`This course is designed to teach you how to make a 2D platform game and learn game design theories that can be applied to any game you make in future.`
	],

	videos: [
		{
			name: 'Platform Game Design',
			chapters: [
				{
					name: 'Coins',
					description:
						'The code in this chapter gives the player a point whenever they collect a coin.',
					secondsOffset: 773,
					badges: ['classes-1', 'parameters-1', 'variables-1']
				},
				{
					name: 'Level endings',
					description:
						'The code in this chapter makes the end of each level a teleport to take the player to the next level.',
					secondsOffset: 954,
					badges: ['physics-1', 'operators-2']
				},
				{
					name: 'Enemies: Part 1',
					description: 'The code in this chapter makes the enemies walk back and forth.',
					secondsOffset: 1173,
					badges: ['operators-1', 'booleans-2', 'variables-2']
				},
				{
					name: 'Enemies: Part 2',
					description: 'The code in this chapter kills the player when they touch an enemy.',
					secondsOffset: 1513,
					badges: ['workspace-1', 'workspace-2', 'conditions-2', 'booleans-2']
				},
				{
					name: 'Camera',
					description: 'The code in this chapter sets the camera up to make the game 2D.',
					secondsOffset: 1809,
					badges: ['operators-2', 'camera-2']
				},
				{
					name: 'Camera improvements',
					description: 'The code in this chapter improves how the camera works for the game.',
					secondsOffset: 2072,
					badges: ['camera-1']
				},
				{
					name: 'Drift',
					description: 'The code in this chapter makes the camera drift behind the player.',
					secondsOffset: 2180,
					badges: ['physics-1', 'operators-2', 'camera-3']
				},
				{
					name: 'Controls',
					description: 'The code in this chapter sets up the custom platform game controls.',
					secondsOffset: 2454,
					badges: ['physics-1', 'parameters-1']
				},
				{
					name: 'Left and right',
					description: 'The code in this chapter allows the player to move left and right.',
					secondsOffset: 2626,
					badges: ['conditions-1', 'input-2', 'input-3']
				},
				{
					name: 'Jumping',
					description: 'The code in this chapter makes the player jump.',
					secondsOffset: 2924,
					badges: ['physics-1', 'conditions-1', 'input-3']
				},
				{
					name: 'New heights',
					description: 'The code in this chapter lets the player jump varying heights.',
					secondsOffset: 3094,
					badges: ['physics-1', 'parameters-2', 'physics-3']
				}
			]
		}
	]
};
