'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands')
			.MINECRAFT_JAVA /*, require( '../../../../data/brands' ).MINECRAFT_PE*/
	], // Not supported in pocket
	id: 'avengers',
	name: 'Avengers',
	duration: 40,
	secondsDuration: 425,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Avengers... Gather! Try this super (hero) mini mod to gain special powers.',
		'Ever wanted to gain super powers to take Minecraft to the next level? This mod will show you how!',
		'Reinforce your programming skills by learning how to fly, bend time and harness thunder!'
	],
	difficulty: 'Medium',
	unlockXp: 22500,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Avengers',
			chapters: [
				{
					name: 'Strange Things Are Happening',
					description: 'Learn how to bend time!',
					secondsOffset: 22,
					badges: []
				},
				{
					name: 'Foiled Again!',
					description: 'Ever wanted to be able to fly? This chapter teaches you how.',
					secondsOffset: 143,
					badges: []
				},
				{
					name: 'Thor-ly not?!',
					description: 'Become Thor, God of Thunder! Make lightning strike wherever you mine.',
					secondsOffset: 255,
					badges: []
				}
			]
		}
	]
};
