import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';

module.exports = function(Schema, data) {
	if (!Schema.Set) {
		throw new Error('Collection missing: ' + name);
	}

	const primary = _.findKey(Schema.schema, 'primary');

	if (primary) {
		const duplicates = _.pickBy(_.groupBy(data, primary), group => group.length > 1);

		for (let key in duplicates) {
			CK.logger.warn('Data in createCollection has duplicates: ' + key, {
				key
			});
		}
	}

	var cursor = new Schema.Set({});

	if (_.get(CK, 'config.environment.name') === CK_ENV.LOCAL) {
		cursor = cursor.$enforceType('typeEnforcer');
	}

	cursor = cursor.$(_.filter(data, _.isPlainObject));

	return cursor.$proxy();
};
