import * as React from 'react';
import { Trackers } from '../trackers/Trackers';

export interface AppContext
	extends Readonly<{
			trackers: Trackers;
		}> {}

const context = React.createContext<AppContext | null>(null);

export const AppContextProvider = context.Provider;

export const AppContextConsumer = context.Consumer;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withAppContext<P extends { appContext?: AppContext }, R = Omit<P, 'appContext'>>(
	Component: React.ComponentClass<P> | React.StatelessComponent<P>
): React.SFC<R> {
	return function BoundComponent(props: R) {
		return (
			<AppContextConsumer>
				{value => <Component {...props} appContext={value} />}
			</AppContextConsumer>
		);
	};
}
