'use strict';

/**
 * @deprecated
 */
module.exports = {
	MINECRAFT: 'modding',
	ROBLOX: 'roblox',
	BUNDLE: 'bundle'
};
