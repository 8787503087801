'use strict';

const v3PlanPrefix = require('../data/v3PlanPrefix');
const planSizes = require('../data/planSizes');

module.exports = function(planType) {
	return `${v3PlanPrefix}${planType.product}-${planType.duration}${
		planType.size === planSizes.FAMILY ? `-${planType.size}` : ``
	}`;
};
