'use strict';

import _ from 'lodash';
import { STRIPE_STATUS } from 'ck-core/source/data/StripeStatus';

const subscriptionPlans = require('../../collections/subscriptionPlans');
const PurchaseSchema = require('./PurchaseSchema');

module.exports = _.extend(
	{
		// See https://stripe.com/docs/api/node#subscription_object
		// One of STRIPE_STATUS
		status: String,
		cancel_at_period_end: Boolean,
		current_period_end: Date,
		next_payment_attempt: Date,

		// @deprecated
		gift: Boolean,

		// If the trial_end is being used to delay the start of this plan rather than for a genuine initial trial
		pendingWithTrial: Boolean,

		// Deprecated
		promotion: String,

		closed: Boolean,

		paypalRevoked: Boolean,

		// @deprecated
		unfreezeDate: Date,

		isTrialling: function() {
			return !!this && this.status() === STRIPE_STATUS.TRIALLING && !this.pendingWithTrial();
		},

		isActive: function() {
			const plan = subscriptionPlans(this.planId());

			const isPastDue = this.status() === STRIPE_STATUS.PAST_DUE;
			const isCurrentRecurring =
				!plan.oneOff() &&
				(this.status() === STRIPE_STATUS.ACTIVE || this.status() === STRIPE_STATUS.TRIALLING);
			const isCurrentOneOff =
				plan.oneOff() &&
				this.current_period_end() &&
				this.current_period_end().getTime() > Date.now() &&
				this.status() !== STRIPE_STATUS.CANCELLED;

			// Don't let lapsed trials continue to play while overdue
			const isTrial = !!plan.freeTrialDays();

			const isComplete = this.status() !== STRIPE_STATUS.INCOMPLETE;

			// When the subscription ends Stripe will let us know to change the status to cancelled
			// However for oneOff subscriptions we need to check the end date
			return isComplete && (isCurrentOneOff || isCurrentRecurring || (isPastDue && !isTrial));
		},

		willRenew: function() {
			return (
				!this.cancel_at_period_end() &&
				!subscriptionPlans(this.planId()).oneOff() &&
				this.status() !== STRIPE_STATUS.CANCELLED &&
				this.status() !== STRIPE_STATUS.INCOMPLETE
			);
		},

		endDate: function(created) {
			return subscriptionPlans(this.planId()).endDate(created || this.created());
		}
	},
	PurchaseSchema
);
