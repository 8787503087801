'use strict';

module.exports = {
	id: darc.types.primary(String),
	planId: String,
	created: Date,
	coupon: String,
	/* user id */
	transferredFrom: Number,
	/* user id */
	transferredTo: Number,

	// User ID the purchase is linked from
	linkedFrom: Number,

	extraChildren: Number
};
