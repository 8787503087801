'use strict';

import _ from 'lodash';

const SpaceManager = require('./../managers/SpaceManager');
const Extendable = require('./Extendable');

const Socket = (module.exports = Extendable.define({
	type: 'Socket',

	init_Socket: function(parent, options) {
		this.options = _.defaults(options || {}, {
			endpoints: CK.endpoints,

			spaceManager: SpaceManager.mod()
		});
		this._listeners = [];
	},

	bind_listeners: function() {
		_.each(this.options.endpoints, (handler, name) => {
			this._addListener(
				name,
				Socket.endpointListener.call(this, handler, name, this.options.spaceManager)
			);
		});
	},

	unbind_listeners: function() {
		_.each(this._listeners, val => {
			this._removeListener(val.event, val.handler);
		});
		this._listeners = [];
	},

	_addListener: function(event, handler) {
		this._ioSocket.on(event, handler);
		this._listeners.push({
			event: event,
			handler: handler
		});
	},

	_removeListener: function(event, handler) {
		this._ioSocket.removeListener(event, handler);
	},

	isConnected: function() {
		return this._ioSocket && this._ioSocket.connected;
	}
}));

Socket.endpointListener = function(handler, name, spaceManager) {
	return (request, callback) => {
		if (name === 'disconnect') {
			callback = CK.fn.zero;
		}

		callback = callback || CK.fn.zero;

		if (name === Socket.PING_PAYLOAD) {
			return callback(null, Socket.PING_RESPONSE);
		}

		var flow = new CK.classes.Flow(name, request, this, spaceManager);

		flow
			.process(handler)
			.then((...args) => {
				if (args[0] !== CK.classes.Flow.NO_RESPONSE) {
					callback.call(null, null, ...args);
				}
			})
			.catch(err => {
				callback(err.serialize ? err.serialize() : err);
			});
	};
};

Socket.PING_PAYLOAD = 'pingu';
Socket.PING_RESPONSE = 'pongu';
