'use strict';

import _ from 'lodash';

module.exports = {
	_activeIntents: {},
	mergedContext: {},

	keyCodesToKeys: {
		8: 'backspace',
		9: 'tab',
		13: 'enter',
		16: 'shift',
		17: 'ctrl',
		18: 'alt',
		27: 'escape',
		32: 'space',
		37: 'left',
		38: 'up',
		39: 'right',
		40: 'down',
		46: 'delete',
		48: '0',
		49: '1',
		50: '2',
		51: '3',
		52: '4',
		53: '5',
		54: '6',
		55: '7',
		56: '8',
		57: '9',
		65: 'a',
		66: 'b',
		67: 'c',
		68: 'd',
		69: 'e',
		70: 'f',
		71: 'g',
		72: 'h',
		73: 'i',
		74: 'j',
		75: 'k',
		76: 'l',
		77: 'm',
		78: 'n',
		79: 'o',
		80: 'p',
		81: 'q',
		82: 'r',
		83: 's',
		84: 't',
		85: 'u',
		86: 'v',
		87: 'w',
		88: 'x',
		89: 'y',
		90: 'z',
		106: '*',
		107: '+',
		109: '-',
		110: '.',
		111: '/',
		186: ';',
		187: '=',
		188: ','
	},

	keysToKeyCodes: {},

	MODIFIERS: ['alt', 'ctrl', 'meta', 'shift'].sort(),

	_keyAction: function(shortcutName, e) {
		e.preventDefault();

		if (this.mergedContext[shortcutName].id) {
			if (this._activeIntents[shortcutName]) return;
			// CK.clock.add( this.mergedContext[ shortcutName ].id, this.mergedContext[ shortcutName ], CK.phases.INPUT, 0 );

			this._activeIntents[shortcutName] = this.mergedContext[shortcutName].id; //CK.logger.log( 'adding intent: ' + this._activeIntents[ shortcutName ] );
		} else {
			this.mergedContext[shortcutName].call(this);
		}

		// Stop propagation of the handled event
		return false;
	},

	_nameWithModifiers: function(e) {
		var name = '';

		_.each(this.MODIFIERS, mod => {
			if (e[mod + 'Key']) {
				name += mod + '+';
			}
		});

		var keyName = this.keyCodesToKeys[e.which];

		if (!keyName) {
			// 			CK.logger.log( 'No support for key code', e.which );
			return;
		}

		name += keyName;

		return name;
	},

	init: function() {
		_.each(this.keyCodesToKeys, (key, keyCode) => {
			this.keysToKeyCodes[key] = parseInt(keyCode);
		});

		this.blurred = false;

		$(window)
			.bind('keydown', e => {
				if (this.blurred) return;

				var shortcutName = this._nameWithModifiers(e);

				if (!shortcutName) {
					return;
				}

				if (this.mergedContext[shortcutName]) {
					this._keyAction(shortcutName, e);
				}
			})
			.bind('keyup', e => {
				var keyName;
				if (this.keyCodesToKeys[e.which]) keyName = this.keyCodesToKeys[e.which];
				else return;

				if (this._activeIntents[keyName]) {
					e.preventDefault();

					//CK.logger.log( 'key up deleting intent: ' + this._activeIntents[ keyName ] );
					// CK.clock.remove( this._activeIntents[ keyName ] );

					delete this._activeIntents[keyName];
				}
			});

		window.addEventListener('focus', () => {
			this.blurred = false;
		});
		window.addEventListener('blur', () => {
			this.blurred = true;

			// Check for intent
			for (var keyName in this._activeIntents) {
				//CK.logger.log( 'blur deleting intent: ' + this._activeIntents[ keyName ] );

				// CK.clock.remove( this._activeIntents[ keyName ] );

				delete this._activeIntents[keyName];
			}
		});
	},

	makeIntent: function(id, fn) {
		fn.id = id;

		return fn;
	},
	changeContext: function(newContext) {
		var oldContextKeys = _.keys(this.mergedContext);

		// Stop intents that are no longer running

		_.each(oldContextKeys, key => {
			if (this.mergedContext[key] !== newContext[key]) {
				if (this._activeIntents[key]) {
					// CK.clock.remove( this._activeIntents[ key ].id );

					delete this._activeIntents[key];
				}
			}
		});

		const SHORTCUT_USES_MODIFIER = /.\+./;
		_.each(_.keys(newContext), name => {
			const oldName = name;

			// Ensure modifiers are sorted in names

			if (SHORTCUT_USES_MODIFIER.test(name)) {
				var keyName;

				const keyIsPlus = _.endsWith(name, '+');
				// If it's the + key take it off the name so the split works
				if (keyIsPlus) {
					name = name.substring(0, name.length - 1);

					keyName = '+';
				}
				var parts = name.split('+');

				if (!keyName) {
					keyName = parts.pop();
				}
				name = parts.sort().join('+') + '+' + keyName;
				if (name !== oldName) {
					newContext[name] = newContext[oldName];

					delete newContext[oldName];
				}
			}
		});

		this.mergedContext = newContext;
	}
};
