'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'bouncing-around',
	name: 'Bouncing around',
	duration: 40,
	secondsDuration: 391,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Hop around your world with this mini mod.',
		'This mod shows you a combination of tricks to move your player around the world, along with some random explosions!',
		'Learn how to use random to set up unexpected events and velocity to make your players double jump.'
	],
	difficulty: 'Medium',
	unlockXp: 20500,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Bouncing Around',
			chapters: [
				{
					name: 'Jump, Jump and Away!',
					description: 'Write code to make your players double jump!',
					secondsOffset: 6,
					badges: []
				},
				{
					name: 'Now You See Me ... ',
					description:
						'Learn to teleport at the click of a button... or mouse. Or tap of a screen.',
					secondsOffset: 205,
					badges: []
				},
				{
					name: 'Mining Roulette ',
					description:
						"The tension! With every block you mine there'll be a chance of it exploding.",
					secondsOffset: 315,
					badges: []
				}
			]
		}
	]
};
