'use strict';

import _ from 'lodash';

import './MessageModal.less';

const CKImage = require('../image');
const Button = require('../Button');
const ModalView = require('../../system/ModalView');

// @abstract
const MessageModal = (module.exports = ModalView.define({
	// N.B. "MessageModal" denotes a modal that accepts no input.
	// If you want a ConfirmModal, please make this separately and do not inherit from this class.

	type: 'MessageModal',

	init_ui: function(parent, options) {
		this.title = $('<div>').addClass('title');
		this.message = $('<div>').addClass('message');

		options = _.defaultsDeep(options || {}, {
			okButtonText: 'OK',
			okColour: 'blue',
			actions: {
				ok: CK.fn.zero
			},
			title: '',
			message: '',
			image: null,
			closeOnBackgroundTap: true,
			html: true
		});

		this.dom.addClass('message');

		this.setup({
			closeOnBackgroundTap: options.closeOnBackgroundTap
		});

		if (options.html) {
			this.title.html(options.title);
			this.message.html(options.message);
		} else {
			this.title.text(options.title);
			this.message.text(options.message);
		}

		this.button = new Button(options.okButtonText, 'ok', {
			colour: options.okColour,
			icon: options.okButtonIcon
		}).addClass('okButton');

		if (options.titleBgColour && this.header) {
			this.header.addClass(options.titleBgColour);
		}

		if (options.image) {
			this.image = CKImage(options.image).addClass('image');

			this.header = $('<div class="header">')
				.append(this.image)
				.append(this.title);
			this.content
				.append(this.header)
				.append(this.message)
				.append(this.button.dom);
		} else {
			this.content
				.append(this.title)
				.append(this.message)
				.append(this.button.dom);
		}
		this.onOk = () => {
			var result;

			try {
				result = options.actions.ok();
				return result;
			} finally {
				// If the result of the action is a thenable then wait for it to complete to close

				if (result && result.then) {
					Promise.resolve(result).finally(this.close.bind(this));
				} else {
					this.close();
				}
			}
		};
		this.actions(
			this._class,
			_.extend({}, options.actions, {
				ok: this.onOk
			})
		);

		this.inputs({
			enter: this.onOk
		});
	}
}));

MessageModal.create = function(options) {
	const module = this.newInstance([CK.moduleContainer, options]);
	CK.moduleContainer.add(module);

	return module;
};
