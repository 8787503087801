'use strict';

import _ from 'lodash';

const ClientModule = require('../system/ClientModule');

const staffRole = require('ck-core/source/data/roles/staffRole');
const COOKIE_NAME = 'staffSession';

module.exports = ClientModule.define({
	type: 'StaffModeManager',

	init_StaffModeManager: function() {
		const CookieManager = require('./CookieManager');
		this.cookieManager = CookieManager.mod();

		if (!this.cookieManager) {
			throw new Error('StaffModeManager depends on CookieManager');
		}
	},

	getSession: function() {
		const cookie = this.cookieManager.get(COOKIE_NAME);

		if (!cookie) {
			return;
		}

		let data;

		try {
			data = JSON.parse(cookie);
		} catch (err) {
			CK.logger.error(err);

			this.clearSession();
		}

		return data;
	},

	setSession: function(session) {
		if (_.some(session.roles, role => role.name === staffRole.name)) {
			CK.logger.debug('Updating staff session to', {
				session
			});

			this.cookieManager.set(COOKIE_NAME, session);
		}
	},

	clearSession: function() {
		CK.logger.debug('Clearing staff session');
		this.cookieManager.remove(COOKIE_NAME);
	}
});
