'use strict';

import _ from 'lodash';

import './MessageModal.less';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactCKImage from './ReactCKImage';
import Modal, { CLOSE_BUTTON_OPTION } from './Modal.jsx';

export default class MessageModal extends React.Component {
	render() {
		let className = classnames('MessageModal', this.props.className);

		return (
			<Modal
				modalClass={className}
				handleClose={this.props.handleClose}
				closeButtonOption={this.props.closeButtonOption}
			>
				<div className="title">{this.props.title}</div>
				<div className="message">{this.props.message}</div>
				<div className="buttonWrapper">{this.props.buttons}</div>
				<ReactCKImage name="generic-white-cloud" forceImgTag={true} extraClasses={['cloudLeft']} />
				<ReactCKImage
					name="generic-white-cloud"
					forceImgTag={true}
					extraClasses={['cloudMiddle']}
				/>
				<ReactCKImage
					name="generic-blue-pipe-right"
					forceImgTag={true}
					extraClasses={['pipeRight']}
				/>
			</Modal>
		);
	}
}

MessageModal.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	closeButtonOption: PropTypes.oneOf(_.values(CLOSE_BUTTON_OPTION)),
	handleClose: PropTypes.func,
	buttons: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

MessageModal.defaultProps = {
	closeButtonOption: CLOSE_BUTTON_OPTION.CROSS
};
