import { LoggerContext } from 'ck-core/source/types/LoggerContext';
import { Logger } from 'ck-core/source/types/Logger';

type LoggerContextFactory = () => LoggerContext;

export class LogSource implements Logger {
	logger: Logger;
	getContext: LoggerContextFactory;
	constructor(getContext: LoggerContextFactory, logger = CK.logger) {
		this.getContext = getContext;
		this.logger = logger;
	}

	trace(...args: any[]) {
		return this.logger.trace(this.getContext(), ...args);
	}

	debug(...args: any[]) {
		return this.logger.debug(this.getContext(), ...args);
	}

	log(...args: any[]) {
		return this.logger.log(this.getContext(), ...args);
	}

	info(...args: any[]) {
		return this.logger.info(this.getContext(), ...args);
	}

	warn(...args: any[]) {
		return this.logger.warn(this.getContext(), ...args);
	}

	error(...args: any[]) {
		return this.logger.error(this.getContext(), ...args);
	}

	critical(...args: any[]) {
		return this.logger.critical(this.getContext(), ...args);
	}
}
