'use strict';

import { CURRENCY } from 'ck-core/source/data/Currency';

const GeoInfoStateSchema = require('../schemas/GeoInfoStateSchema');
const ClientModule = require('ck-core-client/source/system/ClientModule');

module.exports = ClientModule.define({
	type: 'GeoInfo',

	init_geoInfo: function() {
		this.state = this.assign(
			this.type,
			CK.config.forceGeoInfo || {
				country: 'gb',
				currency: CURRENCY.GBP
			},
			GeoInfoStateSchema.schema
		);
	},

	bind_getGeoInfo: function() {
		CK.logger.debug('Getting geoInfo...');

		CK.clientSockets.users
			.emit('geoInfo')
			.then(result => {
				if (result && result.currency) {
					if (CK.config.forceGeoInfo) {
						this.state.$(CK.config.forceGeoInfo);
					} else {
						this.state.$(result);
						CK.logger.debug('Got GeoInfo', result, this.state.$());
					}
				}
			})
			.catch(err => {
				CK.logger.error(err);
			});
	}
});
