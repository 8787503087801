'use strict';

import _ from 'lodash';

import React from 'react';
import ReactDOM from 'react-dom';
import UserManager from '../managers/UserManager';
import ModalsOverlay from './ModalsOverlay.jsx';
import { AppContextProvider } from '../context';
import { Trackers } from 'ck-core-client/source/trackers/Trackers';
import {
	FACEBOOK_STANDARD_EVENT,
	FACEBOOK_CUSTOM_EVENT
} from 'ck-core-client/source/trackers/Facebook';
import {
	TIKTOK_STANDARD_EVENT,
	TIKTOK_CUSTOM_EVENT
} from 'ck-core-client/source/trackers/TikTok';
const Extendable = require('ck-core/source/system/Extendable');
const CKError = require('ck-core/source/classes/Error');
const Errors = require('ck-core/source/services/Errors');
const PageContainer = require('./PageContainer');
const View = require('../system/View');
const ContextContributor = require('ck-core/source/services/ContextContributor');
const ClientModule = require('../system/ClientModule');
const nprogress = require('nprogress');
const UtmTracking = require('../services/UtmTrackingService');

const Frame = (module.exports = ClientModule.define(ContextContributor, View, {
	type: 'Frame',

	defaultPage: 'home',

	pagePrefix: '',

	// TODO: Remove
	init_frameGlobal: function() {
		CK.frame = this;
	},

	init_ui: function() {
		this.appContext = {
			trackers: new Trackers()
		};
		require('nprogress/nprogress.css');

		this.dom.addClass('frame');
		this.pageContainer = this.createPageContainer();

		this.navigate(this.getDefaultPage());
	},

	init_routes: function(parent, options) {
		this.routes = options.routes;

		this.pages = {};

		this.pageFiles = {};

		for (let context of CK.pageContexts) {
			let files = context.keys();

			for (let file of files) {
				this.pageFiles[this._pageName(file)] = true;

				let name = this._routeForPageClass(file);

				if (this.pages[name]) {
					CK.logger.debug('Page', name, 'was overwritten');
				}

				// These do not callback with an error
				this.pages[name] = () => {
					return new Promise(fulfil => {
						context(file)(fulfil);
					});
				};
			}
		}
	},

	setupModalsOverlay: function() {
		const overlayElement = React.createElement(ModalsOverlay, {
			ref: overlay => {
				if (overlay) {
					this._modalsOverlay = overlay;
				}
			}
		});
		this.reactModalsWrapper = $('.reactModalWrapper', this.dom);
		ReactDOM.render(overlayElement, this.reactModalsWrapper[0]);
	},

	getModalsOverlay() {
		return this._modalsOverlay;
	},

	checkRoutes: function() {
		for (let path in this.routes) {
			const name = this.routes[path];

			if (!this.pageFiles[name]) {
				CK.logger.warn('Route points to page which does not exist', {
					path,
					name
				});
			}
		}
	},

	_pageName: function(file) {
		let match = file.match(/([^\/]+?)Page\.(j|t)sx?$/);

		if (!match) {
			throw new Error('Non-matching page - ' + file);
		}

		return match[1];
	},

	_routeForPageClass: function(file) {
		return _.kebabCase(this._pageName(file));
	},

	createPageContainer: function() {
		return new PageContainer(this);
	},

	getDefaultPage: function() {
		// Navigate
		var path = document.location.pathname;

		if (this.pagePrefix && _.startsWith(document.location.hash, this.pagePrefix)) {
			path += document.location.hash.substring(this.pagePrefix.length);
		} else {
			path += document.location.search;
			path += document.location.hash;
		}

		var defaultPage = path.substring(CK.config.rootDirectory.length - 1);

		if (!defaultPage) {
			defaultPage = this.defaultPage;
		}

		return defaultPage;
	},

	_handleExternalLink: function(href) {
		if (_.startsWith(href, 'http:') || _.startsWith(href, 'https:')) {
			document.location.href = href;
		} else if (_.startsWith(href, 'mailto:')) {
			document.location = href;
		} else {
			return false;
		}

		return true;
	},

	navigate: async function(href) {
		const previousHref = this.currentHref,
			previousUrlArgs = this.currentUrlArgs;

		// TODO Is this actually needed?
		await Promise.delay(0);

		if (this.fatalFrameError) {
			throw new Error('fatalFrameError');
		}

		if (this._handleExternalLink(href)) {
			return;
		}

		try {
			if (this.playNavigateSound) {
				this.playNavigateSound();
			}

			nprogress.start();
			$('body').addClass('pageLoading');

			if (_.startsWith(href, '..')) {
				let parts = _.compact(this.currentHref.split('/'));

				let newParts = _.compact(href.split('/'));

				let i = 0;

				while (newParts[0] === '..') {
					i++;

					newParts.shift();
				}

				newParts = parts
					.slice(0, parts.length - i)
					.concat(newParts)
					.join('/');

				if (newParts.length) {
					href = '/' + newParts + '/';
				} else {
					href = '/';
				}
			} else if (_.startsWith(href, './')) {
				href = this.currentHref + href.substring(2);
			} else if (!_.startsWith(href, '/')) {
				href = this.currentHref + href;
			}

			let hashIndex = href.indexOf('#');
			if (hashIndex !== -1) {
				href = href.substring(0, hashIndex);
			}

			const urlArgs = CK.fn.getQueryVariables(href);

			if (await this.open(href, urlArgs)) {
				if (
					this.hasUrlChanged(previousHref, this.currentHref, previousUrlArgs, this.currentUrlArgs)
				) {
					// Home page doesn't have a link
					if (href === this.defaultPage) {
						history.pushState(href, null, CK.config.rootDirectory);
					} else {
						href = this.calculateFullUrl(href);

						history.pushState(href, null, CK.config.rootDirectory + this.pagePrefix + href);
					}
				}
			}
		} finally {
			nprogress.done();
			$('body').removeClass('pageLoading');
		}

		if (!this._preloaded) {
			this._preloaded = true;

			setTimeout(() => {
				for (let name of CK.pagePreloads) {
					try {
						name = this._routeForPageClass(name);

						CK.logger.debug('Preloading', name);
						this.pages[name]();
					} catch (err) {
						CK.logger.warn(err);
					}
				}
			}, 2000);
		}
	},

	bind_history: function() {
		var t = this;

		window.onpopstate = function() {
			if (_.isNil(history.state)) {
				return;
			}
			if (_.isObject(history.state)) {
				CK.logger.warn(
					new CKError({
						message: 'History state is object',
						tags: {
							state: CK.fn.safeFormat(history.state)
						}
					})
				);

				return;
			}

			t.open(history.state, CK.fn.getQueryVariables());

			if (this.playNavigateSound) {
				this.playNavigateSound();
			}
		};
	},

	open: async function(href, urlArgs) {
		href = href.split('?')[0];

		var rootDirectoryString = CK.config.rootDirectory.slice(1) || '';

		if (rootDirectoryString.length && href.startsWith(rootDirectoryString)) {
			return await this.open(href.slice(rootDirectoryString.length), urlArgs);
		}
		if (this._handleExternalLink(href)) {
			return false;
		}

		if (!href) {
			href = '';
		}

		// TODO: What is this hack for?
		if (href.match(/^[A-Za-z0-9\/\-\._]+$/)) {
			return await this.openPage(href, urlArgs);
		} else if (href === '#logout') {
			// Needs to be callable from any page
			await UserManager.mod().logout();
		} else if (href.startsWith('#')) {
			if (this.pageContainer.currentPage && this.pageContainer.currentPage.controller) {
				this.pageContainer.currentPage.controller[href.substring(1)]();
			}
		} else if (href === '') {
			return await this.openPage(this.defaultPage, urlArgs);
		} else {
			return await this.openPage('error/', urlArgs);
		}
	},
	calculateFullUrl: function(href) {
		if (href.startsWith('/')) {
			return href.substring(1);
		} else if (href.startsWith('#')) {
			return href;
		} else {
			var fullPath = document.location.pathname;

			var currentDirectory = fullPath.substring(CK.config.rootDirectory.length);

			return currentDirectory + href;
		}
	},

	/*
		Given a hyperlink, calculate the page class component which should be

		used to display the page, along with the arguments that should be
		passed to it and any parent page classes which can be used to extract
		page options from.

		The urlPath by default contains elements of the path below the final
		path class. For example, the path edit-leader/56674 will provide the

		EditLeader page with the urlPath [56674]. If you want to preserve the
		parent path, for example projects/myluckymod/code/, make the parent
		getChild method return [ChildPage, true]. This will then pass the

		urlPath [ 'myluckymod', 'code', ... ] to the ChildPage.

		The urlArgs contains all the query parameters in the url in an object
	*/
	pageClassForHref: async function(pageHref, urlArgs) {
		let hrefClasses = [];

		if (pageHref === '') {
			pageHref = this.defaultPage;
		}

		var pageParts = _.compact(pageHref.split('/'));

		let pageName = pageParts[0];

		if (!pageName) {
			pageName = this.defaultPage;
		}

		var PageClass;

		const chunkLoadFailureListener = function(event) {
			let reason = _.get(event, 'reason') || _.get(event, 'detail.reason');

			if (/Loading chunk/.test(reason)) {
				pageHref = '/' + pageHref;

				event.preventDefault();

				CK.logger.log('Forcing href to', pageHref, 'due to chunk load failure', reason);

				if (_.startsWith(window.location.hash, '#!')) {
					window.location.hash = '#!' + (pageHref.startsWith('/') ? pageHref : '/' + pageHref);
					window.location.reload();
				} else {
					window.location.pathname = pageHref;
				}
			}
		};

		window.addEventListener('unhandledrejection', chunkLoadFailureListener);

		let originalError, secondaryError;

		try {
			const pageRequire = this.pages[pageName];

			if (pageRequire) {
				PageClass = await pageRequire();
			}
		} catch (err) {
			originalError = err;

			CK.logger.error(err);
		} finally {
			window.removeEventListener('unhandledrejection', chunkLoadFailureListener);
		}

		if (!PageClass) {
			// Check if it matches a route

			for (let route in this.routes) {
				let className = this.routes[route];
				if (_.startsWith(pageHref, route)) {
					if (typeof className === 'function') {
						className = className();
					}

					// Remove from the page parts the matching parts in the URL

					// so that the pageChild is calculated correctly

					pageParts.splice(0, route.split('/').length - 1);

					try {
						let pageConstructor = this.pages[_.kebabCase(className)];

						if (pageConstructor) {
							PageClass = await pageConstructor();
						}
					} catch (err) {
						secondaryError = err;

						throw err;
					}

					break;
				}
			}
		}

		if (!PageClass) {
			throw new Errors.PageNotFound({
				tags: {
					pageHref,
					pageName,
					originalError,
					secondaryError,
					routeKeys: _.keys(this.routes),
					routesExist: !!this.routes
				}
			});
		}

		hrefClasses.push([pageParts[0], PageClass]);

		let urlPath = pageParts.slice(1);

		let PageChildClass = PageClass;

		for (let i = 1; i < pageParts.length; i++) {
			let pageChild = pageParts[i];

			if (!PageChildClass) {
				return;
			}

			if (pageChild === undefined || pageChild.length === 0) {
				break;
			} else {
				let preserveArgs = false;

				if (PageChildClass.childPage) {
					if (Extendable.isClass(PageChildClass.childPage)) {
						PageChildClass = PageChildClass.childPage;
					} else {
						[PageChildClass, preserveArgs] = PageChildClass.childPage(...urlPath);
					}

					if (!preserveArgs) {
						urlPath = pageParts.slice(i);
					}
				} else {
					break;
				}
			}

			hrefClasses.push([pageParts.slice(0, i + 1).join('/'), PageChildClass]);
		}

		return {
			PageClass: PageChildClass,

			hrefClasses: hrefClasses,

			args: [urlPath, urlArgs]
		};
	},
	refresh: function(pageOptions) {
		let href = this.currentHref;
		this.currentPage = null;
		this.currentHref = null;
		this.currentUrlArgs = null;

		this.openPage(href, CK.fn.getQueryVariables(), pageOptions);
	},

	sendAnalytic: function(pageViewData) {
		this.appContext.trackers.trackAnalytic('PageView', pageViewData);
		this.appContext.trackers.trackTwitterPixel('PageView', pageViewData);
	},

	hasUrlChanged: function(previousHref, href, previousUrlArgs, urlArgs) {
		return previousHref !== href || !_.isEqual(previousUrlArgs, urlArgs);
	},

	openPage: async function(pageHref, urlArgs, pageOptions) {
		// Ensure all paths don't start with a "/" as they are all absolute by this point
		if (_.startsWith(pageHref, '/')) {
			pageHref = pageHref.substring(1);
		}

		if (this.hasUrlChanged(this.currentHref, pageHref, this.currentUrlArgs, urlArgs)) {
			var pageViewData = {
				path: '/' + pageHref
			};

			// Log time taken for initial load
			if (!this._navigated) {
				if (
					typeof performance !== 'undefined' &&
					performance.timing &&
					performance.timing.navigationStart
				) {
					pageViewData.loadingTime = Date.now() - performance.timing.navigationStart;
				}

				this._navigated = true;
			}
			this.sendAnalytic(pageViewData);
		}

		this.currentHref = pageHref;
		this.currentUrlArgs = urlArgs;

		if (window.ga !== undefined) {
			window.ga('send', 'pageview', pageHref);
		}

		this.appContext.trackers.trackFacebookPixel(FACEBOOK_STANDARD_EVENT.VIEW_CONTENT);
		this.appContext.trackers.trackTiktokPixel(TIKTOK_STANDARD_EVENT.VIEW_CONTENT);

		const utmService = UtmTracking.mod();

		if (utmService) {
			let utmContext = utmService.getContext();

			const isAdwords = utmContext && utmContext.conversion_mp_source === 'Adwords';

			if (!isAdwords) {
				this.appContext.trackers.trackFacebookPixel(FACEBOOK_CUSTOM_EVENT.NON_ADWORDS_VIEW_CONTENT);
			}
		}

		let PageClass, args;

		try {
			let result = await this.pageClassForHref(pageHref, urlArgs);

			PageClass = result.PageClass;
			args = result.args;
		} catch (err) {
			if (_.get(err, 'tags.pageName') !== 'error') {
				await this.open('error/badURL/', {
					error:
						CK.fn.safeFormat(err) +
						'\n\n' +
						CK.fn.safeFormat(err.tags) +
						'\n\n' +
						CK.fn.safeFormat(err.stack)
				});
			}
			throw err;
		}

		let currentPageType = this.currentPage ? this.currentPage.type : null;

		if (!PageClass.NAME || currentPageType !== PageClass.NAME) {
			scrollTo(0, 0);
		}

		let page, title, fullWidth, fullscreen, redirect, createReactElement;

		if (PageClass.newInstance) {
			page = PageClass.newInstance([this, ...args]);

			({ title, fullWidth, fullscreen, redirect } = page);
		} else {
			/*  eslint-disable react/display-name */
			createReactElement = () => {
				return React.createElement(AppContextProvider, { value: this.appContext }, [
					React.createElement(PageClass.default, {
						args,
						frame: this,
						ref: pageInstance => pageInstance && (this.pageInstance = pageInstance)
					})
				]);
			};
			/*  eslint-enable react/display-name */

			page = createReactElement();

			({ title, fullscreen, fullWidth, redirect } = PageClass);
		}

		if (redirect) {
			this.navigate(redirect);
			return;
		}

		if (fullscreen) {
			$('body').addClass('fullscreen');
		} else {
			$('body').removeClass('fullscreen');
		}

		document.title = typeof title === 'function' ? title() : title;

		this.pageContainer.setPage(
			page,
			_.defaults(pageOptions || {}, {
				fullWidth: fullWidth
			}),
			createReactElement
		);

		this.currentPage = page;

		this.broadcast(Frame.EVENTS.NAVIGATE);

		if (page.controller && page.controller.openVideo && urlArgs && urlArgs.openVideo) {
			page.controller.openVideo(urlArgs.openVideo);
		}

		return true;
	},

	getContext: function() {
		return {
			currentPath: this.currentHref ? '/' + this.currentHref : document.location.pathname
		};
	}
}));

Frame.EVENTS = {
	NAVIGATE: 'Frame.navigate'
};
