'use strict';

import _ from 'lodash';
import { randomString } from 'ck-core/source/functions/randomString';

const MonitorContextService = require('ck-core/source/services/MonitorContextService');
const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define(MonitorContextService, {
	type: 'ClientMonitorContext',

	init_ClientMonitorContextService: function() {
		this._sessionId = randomString(16);
	},

	getContext: function() {
		var context = {};

		context.persistentTrackingId = CK.persistentTrackingId;
		context.sessionId = this._sessionId;

		context.appName = CK.config.name;

		context.mobile = bowser.mobile;

		context.tablet = bowser.tablet;

		context.release = CK.config.release;
		context.online = navigator.onLine;
		context.browserName = bowser.name;
		context.browserVersion = bowser.name + ' ' + bowser.version;

		if (window.screen) {
			context.screenHeight = window.screen.height;
			context.screenWidth = window.screen.width;
		}

		context.windowHeight = $(window).height();
		context.windowWidth = $(window).width();

		context.minecraftRegion = CK.user.minecraftRegion();

		if (CK.user.$exists()) {
			context.name = CK.user.analyticsName();
			context.email = CK.user.analyticsEmail();
			context.mainEmail = CK.user.email();
			context.parentEmail = CK.user.parentEmail();
			context.paymentEmail = CK.user.paymentEmail();
			context.isTrialling = CK.user.isTrialling();
			context.isCampUser = CK.user.isCampUser();
			context.age = CK.user.dateOfBirth() ? moment().diff(CK.user.dateOfBirth(), 'years') : null;

			context.userId = CK.user.id();
			context.testSeed = CK.user.testSeed();
			_.extend(context, CK.user.testCohortEngageData());

			context.planId = CK.user.getCurrentPlanId();

			const activeSubscription = CK.user.getActiveSubscription();

			if (activeSubscription) {
				context.planPeriodEnd = activeSubscription.current_period_end();
			}

			context.expiryDate = CK.user.getExpiryDate();
			context.nextInvoiceDate = CK.user.getNextInvoiceDate();

			context.gender = CK.user.gender();
			context.minecraftName = CK.user.minecraftName();
		} else if (CK.trackingData) {
			context.testSeed = CK.trackingData.testSeed;

			if (CK.trackingData.testCohorts) {
				for (let id in CK.trackingData.testCohorts) {
					context['testCohort_' + id] = !CK.trackingData.testCohorts[id].control;
				}
			}

			const UserManager = require('../managers/UserManager');
			const session = UserManager.mod().getSession();

			if (session) {
				context.userId = session.userId;
			}
		}

		this.addContributorContext(context);

		return context;
	}
});
