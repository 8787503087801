'use strict';

import PropTypes from 'prop-types';

export const ItemProps = PropTypes.shape({
	name: PropTypes.string.isRequired,
	href: PropTypes.string,
	classes: PropTypes.arrayOf(PropTypes.string),
	target: PropTypes.string
});

export default {
	entry: ItemProps.isRequired,
	handleClick: PropTypes.func.isRequired,
	frame: PropTypes.object
};
