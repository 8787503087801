'use strict';

import _ from 'lodash';

const GeoInfoService = require('../services/GeoInfoService');
const MenuService = require('ck-core-client/source/services/MenuService');
const UtmTrackingService = require('ck-core-client/source/services/UtmTrackingService');
const AnalyticsManager = require('ck-core-client/source/managers/AnalyticsManager');
const Transition = require('ck-core/source/system/Transition');

module.exports = Transition.define({
	type: 'CoreWebSetupTransition',

	init_steps: function(options) {
		_.defaults(this, options || {}, {
			getFrameInstance: null,
			postLogin: () => {}
		});

		this.step(this.initAnalytics)
			.step(this.initMenu)
			.step(this.initGeoInfo)
			.step(this.waitForUserLogin)
			.step(this.postLogin)
			.step(this.pushHomepage)
			.step(CK.fn.checkForceTestCohort)
			.step(this.doneLoading);
	},

	initAnalytics: function() {
		CK.moduleContainer.add(new AnalyticsManager(CK.moduleContainer));
		CK.moduleContainer.add(new UtmTrackingService(CK.moduleContainer));
	},

	initMenu: function() {
		CK.moduleContainer.add(new MenuService(CK.moduleContainer, this.menuEntries));
	},
	initGeoInfo: function() {
		CK.moduleContainer.add(new GeoInfoService(CK.moduleContainer));
	},

	pushHomepage: function() {
		CK.moduleContainer.add(this.getFrameInstance());
	},

	waitForUserLogin: async function() {
		const UserManager = require('ck-core-client/source/managers/UserManager');
		const userManager = UserManager.mod();
		const session = userManager.getSession();

		if (session) {
			await CK.clientSockets.users.waitForVerify();
		} else {
			var urlArgs = CK.fn.getQueryVariables();

			if (urlArgs.authEmail && urlArgs.authCode) {
				const LoginController = require('ck-core-client/source/controllers/LoginController');

				LoginController.simpleAuthenticate(urlArgs.authEmail, urlArgs.authCode);
			}
		}
	}
});
