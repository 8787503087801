'use strict';

const PageContainer = require('ck-core-client/source/ui/PageContainer');

import Page from 'ck-core-client/source/ui/Page';
import ReactDOM from 'react-dom';

module.exports = PageContainer.define({
	type: 'WebPageContainer',

	init_PageContainer: function(parent, options) {
		this.dom = options.dom;
		this.content = $(options.dom).children('.content');

		CK.user.$bind(this.updateFromCursorChange.bind(this));

		const mod = CK.moduleContainer.module('Camp');

		if (mod) {
			mod.state.$bind(this.updateFromCursorChange.bind(this));
		}

		this.isFirstPage = true;
	},

	setPage: function(page, opts, createReactElement) {
		this.createReactElement = null;

		const newPageIsReactComponent = !page.instanceOf || !page.instanceOf(Page);

		if (this.isFirstPage) {
			// Remove potentially ghetto pre-rendererd content to ensure render works correctly
			this.content[0].innerHTML = '';

			this.isFirstPage = false;
		}

		if (this._currentPage) {
			if (this._currentPage.dom) {
				this._currentPage.hide();
				this._currentPage.cleanup();
			} else if (!newPageIsReactComponent) {
				ReactDOM.unmountComponentAtNode(this.content[0]);
			}
		}

		this._currentPage = page;

		if (!newPageIsReactComponent) {
			this._currentPage.setContainer(this);
			this._currentPage.bind();

			this.content.append(this._currentPage.dom);
		} else {
			this.createReactElement = createReactElement;
			this.reactRender();
		}
	},

	reactRender: function() {
		if (this.createReactElement) {
			this._currentPage = this.createReactElement();
			ReactDOM.render(this._currentPage, this.content[0]);
		}
	},

	updateFromCursorChange: function() {
		// There is some seriously bad behaviour where reactRender is called by the user 'login' during checkout
		// and wipes out the page by re-rendering it, preventing the user from seeing the confirmation stage

		// I tried changing this to use forceUpdate to prevent props and state being wiped out to no avail
		// So we do this really stupid hack to prevent the checkout being wiped out.
		if (CK.checkoutStagesMounted) {
			return;
		}
		this.reactRender();
	},

	bind_PageContainer: function() {
		setTimeout(() => {
			// TODO: Massive hack to get around extendableContainer binding issue which causes initial page actions not to propagate to WebsiteFrame
			if (this._currentPage && this._currentPage.bind_Actions) {
				this._currentPage.bind_Actions();
			}
		}, 10);

		if (this._currentPage && this._currentPage.bind) {
			this._currentPage.bind();
		}
	}
});
