'use strict';

import _ from 'lodash';

const ErrorMessageModal = require('./modals/messageModals/ErrorMessageModal');

module.exports = require('../system/ClientModule').define(require('./Component'), {
	init: function(...args) {
		this.redirect = this.getUserRedirect();

		if (this.redirect) {
			return;
		}

		this.Component_init(...args);
	},

	getUserRedirect: function() {
		if (this.requireRole) {
			// Check logged in
			const hasUser = CK.user.$exists(),
				userHasRole = CK.user.hasRole(this.requireRole.name || this.requireRole);

			if (typeof this.requireRole === 'function') {
				const hasRole = this.requireRole();

				if (hasRole === false) {
					return '/';
				} else if (hasRole === true || hasRole === undefined) {
					return;
				} else {
					return hasRole;
				}
			} else if (this.requireRole === CK.const.ROLES.LOGGED_OUT && hasUser) {
				return '/account/';
			} else if (this.requireRole !== CK.const.ROLES.LOGGED_OUT && !hasUser) {
				return '/login/';
			} else if (hasUser && this.requireRole !== CK.const.ROLES.LOGGED_IN && !userHasRole) {
				ErrorMessageModal.create(require('./PageLang'));
				return '/';
			}
		}
	},

	init_page: function() {
		this.dom = $('<div class="page">');
		this.dom.addClass(_.snakeCase(this.type));
	},

	setContainer: function(container) {
		this._container = container;
	},

	cleanup_container: function() {
		this._container = null;
	},

	refreshScroller: function() {
		if (!this.scrollable) {
			return;
		}

		if (!this._container) {
			return;
		}

		this._container.refreshScroller();
	},

	type: 'Page'
});
