'use strict';

import './ReactProgressBar.less';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactCKImage from 'ck-core-client/source/ui/ReactCKImage';

export default class ReactProgressBar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			percent: props.percent
		};
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			percent: nextProps.percent
		});
	}

	componentDidMount() {
		if (this.props.slowlyIncrement) {
			const increment = () => {
				this.incrementTimeout = setTimeout(() => {
					increment();
					this.setState({
						percent: this.state.percent + Math.random() * 5 + 1
					});
				}, Math.random() * 3000 + 1000);
			};

			increment();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.incrementTimeout);
	}

	render() {
		const { percent } = this.state;
		const { showLabel, colour = 'purple', extraClasses, endColour = 'green' } = this.props;

		const className = classnames('ProgressBar', percent === 100 ? endColour : colour);
		const style = {
			width: Math.max(0, Math.min(100, percent)) + '%'
		};

		return (
			<div className={`${extraClasses}`}>
				{showLabel && (
					<div className="ProgressBar__label">
						{percent === 100 ? (
							<ReactCKImage
								className="ProgressBar__image"
								name="tick-green"
								options={{ width: '1.5em', height: '1.5em' }}
							/>
						) : (
							`${percent}%`
						)}
					</div>
				)}
				<div className={className}>
					<div className="bar" style={style} />
				</div>
			</div>
		);
	}
}

ReactProgressBar.propTypes = {
	percent: PropTypes.number.isRequired,
	colour: PropTypes.string,
	endColour: PropTypes.string,
	slowlyIncrement: PropTypes.bool,
	showLabel: PropTypes.bool,
	extraClasses: PropTypes.string
};
