'use strict';

import _ from 'lodash';

const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define({
	type: 'LocalStorageBackend',

	set: function(key, value) {
		localStorage.setItem(key, String(value));
	},

	get: function(key) {
		return localStorage.getItem(key);
	},

	remove: function(key) {
		return localStorage.removeItem(key);
	},

	keys: function() {
		return _.keys(localStorage);
	}
});
