'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'hungry-games',
	name: 'The Hungry Games',
	duration: 150,
	secondsDuration: 4631,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Build your own Hunger Games style server to make the game how YOU want it to be!',
		'Add game rules, timers and a shrinking border with code to make your Hunger Games world perilous for any victims trapped inside!',
		'This course will show you how to develop a PVP server. You will use conditionals and loops to create gamephases so you can control the fate of your players in the arena.'
	],

	difficulty: 'Easy',
	unlockXp: 8000,

	videos: [
		{
			name: 'Hungry Games',
			chapters: [
				{
					name: 'Appertif For Destruction',
					description:
						'This chapter shows you how to kick the player from the server when they die',
					badges: ['methods-1', 'communication-1'],
					secondsOffset: 82
				},
				{
					name: 'Another One Bites The Crust',
					description: 'This chapter shows you how to set up borders and the start of your game',
					badges: ['methods-1', 'timer-1'],
					secondsOffset: 394
				},
				{
					name: 'No Pain, No Grain',
					description: 'This chapter introduces game phases to your game',
					badges: ['logic-1', 'conditions-2', 'timer-2'],
					secondsOffset: 795
				},
				{
					name: `I'm Scotching You`,
					description: 'In this step you will change the rules of the game depending on the phase',
					badges: ['communication-1'],
					secondsOffset: 1226
				},
				{
					name: 'Donut Give Up',
					description:
						'In this step you will prevent players from joining when a game is in progress',
					badges: ['logic-1', 'timer-1'],
					secondsOffset: 1314
				},
				{
					name: 'You Are On A Roll',
					description: 'This chapter is where you will add a game over check and reset',
					badges: ['communication-3', 'booleans-2'],
					secondsOffset: 1557
				},
				{
					name: 'Loafing Around',
					description: 'In this step you will add additional game phases',
					badges: ['switch-2', 'timer-2'],
					secondsOffset: 1891
				},
				{
					name: 'Born And Bread',
					description:
						'This chapter introduces the for loop to run the startPlaying method for all the players',
					badges: ['loops-2', 'methods-2'],
					secondsOffset: 2372
				},
				{
					name: `You're Toast`,
					description:
						'This chapter adds more code to teleport the player at the start of the game',
					badges: ['parameters-1', 'inventory-1'],
					secondsOffset: 2764
				},
				{
					name: 'Olive The Food Puns',
					description: 'This chapter adds a survivor count to the game so you can declare a winner',
					badges: ['nesting-1', 'loops-2'],
					secondsOffset: 3008
				},
				{
					name: 'All In Good Thyme',
					description:
						'This chapter shows you how to add a selection of spawn points to start your players at',
					badges: ['arrays-1', 'arrays-3'],
					secondsOffset: 3317
				},
				{
					name: 'Lettuce Eat!',
					description:
						'This chapter shows you how to finish off your mod by making sure everyone starts in the right place',
					badges: ['arrays-2', 'operators-1', 'conditions-1'],
					secondsOffset: 3901
				}
			]
		}
	]
};
