'use strict';

import _ from 'lodash';

// Disable eslint as the ordering is just dodgy in this file
/* eslint-disable */

const Extendable = (module.exports = (function() {
	var CORE_CONTRIBUTIONS = [require('./MixinContrib')()];

	var bindPromiseChangeListener = function(spec) {
		if (!spec.bound) {
			spec.promise.$get(spec.path).$bind(spec.listener);
			spec.bound = true;
		}
	};

	var unbindPromiseChangeListener = function(spec) {
		if (spec.bound) {
			spec.promise.$get(spec.path).$unbind(spec.listener);
			spec.bound = false;
		}
	};

	var prototypeMethods = {
		instanceOf: function(type) {
			if (_.isObject(type)) {
				type = type.NAME;
			}

			if (_.isString(this.NAME)) return false;

			if (_.isObject(type)) type = type.NAME;

			return _.includes(this._types, type);
		},

		getOwnMethodNames: function() {
			return getOwnMethodNames(this, this.type);
		},
		/**
		 * Bind to a change in data

		 */
		promiseChange: function(promise, path, listener, self, options) {
			options = _.defaults(options || {}, {
				bindImmediately: false
			});

			if (!promise)
				return CK.logger.error('Attempt to bind to falsy promise with listener', listener);

			if (typeof listener !== 'function')
				return CK.logger.error('Attempt to bind with falsy listener', listener);
			self = self || this;
			if (!this._promiseDataListeners) this._promiseDataListeners = [];
			var spec = {
				promise: promise,

				path: path,

				listener: listener,
				self: self
			};

			this._promiseDataListeners.push(spec);

			if (options.bindImmediately || this.bound) {
				bindPromiseChangeListener(spec);
			}
		},
		bind_promiseChangeListeners: function() {
			_.each(this._promiseDataListeners, bindPromiseChangeListener);
		},

		unbind_promiseChangeListeners: function() {
			_.each(this._promiseDataListeners, unbindPromiseChangeListener);
		},
		toString: function() {
			return '[Extendable ' + this.type + ']';
		},

		assign: function(name, data, schema, options) {
			options = _.defaults(options || {}, {
				cursor: null
			});

			if (!this._assignments) this._assignments = {};

			var cursor = options.cursor;

			if (!cursor) {
				const Schema = darc.compile(schema);
				cursor = new Schema(data);
			}

			if (this._assignments[name]) {
				CK.logger.warn('Assignment overwriting', name);
			}

			this._assignments[name] = cursor;
			return cursor;
		}
	};

	var getOwnMethodNames = function(instance, name) {
		var methods = [];

		// TODO: __declarer is often incorrect, use prototype instead or fix declarer

		_.each(
			Object.getPrototypeOf(instance),
			function(fn, property) {
				if (fn.__declarer === name) methods.push(property);
			},
			this
		);

		return methods;
	};

	var staticMethods = {
		mod: function() {
			return CK.moduleContainer.module(this);
		},

		get: function() {
			return CK.moduleContainer.get(this);
		},

		define: function() {
			var args = [this.prototype];

			for (var i = 0; i < arguments.length; i++) {
				args.push(arguments[i]);
			}

			return extendableDefine.apply(this, args);
		},

		getOwnMethodNames: function(instance) {
			return getOwnMethodNames(instance, this.NAME);
		},

		isInstance: function(instance) {
			return instance && instance.instanceOf && instance.instanceOf(this);
		},

		isClass: function(cls) {
			return cls && cls.TYPES && _.includes(cls.TYPES, this.NAME);
		},

		getSuperClassList: function(classes) {
			classes = classes || [];

			if (this.super) {
				this.super.getSuperClassList(classes);

				if (!_.includes(classes, this.super)) {
					classes.push(this.super);
				}
			}

			_.each(this.mixins, function(mixin) {
				if (mixin.getSuperClassList) {
					mixin.getSuperClassList(mixin);
				}

				if (!_.includes(classes, mixin)) {
					classes.push(mixin);
				}
			});

			return classes;
		},

		newInstance: function(args) {
			/* jshint -W058 */

			return new (this.bind.apply(this, [null].concat(args)))();
		}
	};

	var toString = function() {
		return '[Class ' + this.NAME + ']';
	};

	var uid = 0;

	// define is a reserved global for AMD
	var extendableDefine = function() {
		let Extendable = function() {
			if (!module.exports.isInstance(this))
				throw new Error('Extendable missing the new constructor: ' + className);

			this._id = uid++;

			this.init.apply(this, arguments);
		};

		var fields = {
			type: 'Extendable',

			_types: ['Extendable']
		};
		_.extend.apply(_, [fields, prototypeMethods].concat(CORE_CONTRIBUTIONS));

		fields.populateExtendableTraits();

		var className;

		var overridingClassName = fields.type;
		var addNewType = function(type) {
			if (!_.includes(fields._types, type)) fields._types.push(type);
		};
		var originalPrototype = {};

		var mixinClasses = [];

		var mixedInClasses = [this];

		for (var i = 0; i < arguments.length; i++) {
			var classFields = arguments[i];

			var fromPrototype = false;

			if (!classFields) {
				CK.logger.warn('Undefined extendable for argument ' + i);
				continue;
			}
			if (classFields.NAME) {
				fromPrototype = true;

				classFields = classFields.prototype;
			} else {
				originalPrototype = classFields;
			}

			mixinClasses.push(classFields);
			className = classFields.type;
			if (!className) {
				throw new Error('ClassHasNoType');
			}
			// Incorporate classes that haven't already been merged
			if (!_.includes(fields._types, classFields.type)) {
				var superMap = {};

				for (var j in classFields) {
					// Don't modify type information
					if (j === 'type') continue;

					if (j === '_types') continue;

					// Don't override if the method doesn't have a declarer

					// as we assume it is in the supertype hierarchy of the former

					// TODO: Make trait functions have __declarer and then check

					// for all methods that it isn't in the overriden type's _types list

					// e.g if B extends A and C extends A don't override B methods

					// with A methods from C when making a D which extends B,C
					if (fromPrototype && !classFields[j].__declarer) {
						continue;
					}

					// Map to super

					if (
						overridingClassName &&
						fields[j] &&
						typeof fields[j] === 'function' &&
						fields[j] !== classFields[j]
					) {
						superMap[overridingClassName + '_' + j] = fields[j];
					}

					fields[j] = classFields[j];

					if (typeof fields[j] === 'function') {
						fields[j].__declarer = className;
					}
				}

				for (j in superMap) {
					// Approximate method equality using their string representations to

					// detect if methods which come from the same class actually do

					if (fields[j] && fields[j].toString() !== superMap[j].toString()) {
						throw 'InconsistentMethodHierarchy: ' + j;
					}
					fields[j] = superMap[j];
				}
			}
			overridingClassName = classFields.type;
			_.each(classFields._types, addNewType);
			addNewType(className);
			mixedInClasses.push(arguments[i]);
		}

		// Don't include our parent or ourselves in the list of mixins

		if (this.prototype === arguments[0]) {
			mixinClasses.shift();
		}

		mixinClasses.pop();

		fields.type = className;
		fields._class = Extendable;
		Extendable.prototype = fields;
		Extendable.NAME = className;

		Extendable.TYPES = fields._types;

		Extendable.super = this;

		Extendable.mixins = mixinClasses;

		Extendable.originalPrototype = originalPrototype;

		Extendable.toString = toString;

		_.extend(Extendable, staticMethods);

		// Inherit static methods
		_.eachRight(mixedInClasses, superClass => {
			_.each(Object.getOwnPropertyNames(superClass), name => {
				if (
					name === 'length' ||
					name === 'name' ||
					name === 'caller' ||
					name === 'callee' ||
					name === 'arguments'
				)
					return;

				if (!Extendable[name]) {
					Extendable[name] = superClass[name];
				}
			});
		});
		return Extendable;
	};
	let Extendable = function() {
		throw "Can't instance an empty module. Use Extendable.define instead.";
	};
	_.extend(Extendable, staticMethods);
	Extendable.define = extendableDefine;
	Extendable.originalPrototype = prototypeMethods;
	Extendable.TYPES = ['Extendable'];
	Extendable.NAME = 'Extendable';
	return Extendable;
})());
