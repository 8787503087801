'use strict';

module.exports = {
	HOME: {
		name: 'Home',
		href: '/'
	},
	GET_STARTED: {
		name: 'Get Started',
		href: '/get-started/'
	},
	PARENTS: {
		name: 'Parents',
		href: '/parents/'
	},
	MINECRAFT: {
		name: 'Minecraft',
		href: '/code-your-own-minecraft-mods/'
	},
	ROBLOX: {
		name: 'Roblox',
		href: '/roblox-coding/'
	},
	REVIEWS: {
		name: 'Reviews',
		href: '/reviews/'
	},
	METHOD: {
		name: 'About',
		href: '/about-us/'
	},
	COURSES: {
		name: 'Courses',
		href: '/courses/'
	},
	FAQ: {
		name: 'FAQ',
		href: 'https://faq.codekingdoms.com'
	},
	PRICING: {
		name: 'Pricing',
		href: '/plans/'
	},
	UPGRADE: {
		name: 'Upgrade',
		href: '/plans/',
		classes: [ 'userTool' ]
	}
};
