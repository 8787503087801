'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'box-car-racer',
	name: 'Box Car Racer',
	duration: 120,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 30,
	unlockXp: 1000,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Player',
			superclass: 'BasePlayer'
		},
		{
			path: '/Checkpoint',
			superclass: 'BaseObject'
		},
		{
			path: '/FinishLine',
			superclass: '/Checkpoint'
		},
		{
			path: '/StartLine',
			superclass: '/Checkpoint'
		},
		{
			path: '/Box',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 63,
			lookAhead: true,
			message:
				'If you want to give the finished Box Car Racer game a try, just visit https://www.roblox.com/games/1770163044/Box-Car-Racer'
		}
	],

	descriptions: [
		`Create a box racing game where the fastest racer wins!`,
		`Learn how to code checkpoints, a finish line and write clean code in this fast-paced box racing game!`,
		`This Roblox course shows you how to structure code well and add a finish line to the track using inheritance.`
	],

	videos: [
		{
			name: 'Box Car Racer',
			chapters: [
				{
					name: 'The Box And The Furious',
					description: 'Add methods to set up the player at the start of the race.',
					secondsOffset: 73,
					badges: ['methods-1', 'methods-2', 'classes-1', 'fields-1']
				},
				{
					name: '2 Boxy 2 Furious',
					description: 'Set up the start line as a checkpoint.',
					secondsOffset: 569,
					badges: ['operators-1', 'operators-2', 'templates-1']
				},
				{
					name: 'Tokyo Drift',
					description: 'Set up the checkpoint system.',
					secondsOffset: 935,
					badges: ['parameters-2', 'parameters-3', 'booleans-2']
				},
				{
					name: 'Fast Boxes',
					description: 'Complete your race by adding a finish line.',
					secondsOffset: 1537,
					badges: ['classes-2', 'classes-3']
				}
			]
		}
	]
};
