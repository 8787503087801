'use strict';

import _ from 'lodash';

const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define({
	type: 'MemoryStorageBackend',

	init_MemoryStorage: function() {
		this._data = {};
	},

	set: function(key, value) {
		this._data[key] = value;
	},

	get: function(key) {
		return this._data.hasOwnProperty(key) ? this._data[key] : null;
	},

	keys: function() {
		return _.keys(this._data);
	}
});
