'use strict';

import _ from 'lodash';

const SpaceManager = require('../../managers/SpaceManager');

const getGlobalCamp = function() {
	const campState = CK.moduleContainer.get('Camp');

	if (campState && campState.id()) {
		return campState;
	}

	return null;
};

module.exports = {
	isCampLeader: function(camp) {
		camp = camp || getGlobalCamp();

		return (
			this.roles().$has({
				name: CK.const.ROLES.CAMP_LEADER.name
			}) &&
			(!camp || camp.leaders().$has(this.id()))
		);
	},

	isCampInstructor: function(camp) {
		return this.hasCampLeaderRole(camp) && !this.isCampSuperuser();
	},

	hasCampLeaderRole: function(camp) {
		return this.isCampSuperuser() || this.isCampLeader(camp) || this.isCampAdmin(camp);
	},

	isCampAdmin: function(camp) {
		camp = camp || getGlobalCamp();

		return (
			this.roles().$has({
				name: CK.const.ROLES.CAMP_ADMIN.name
			}) &&
			(!camp || camp.admins().$has(this.id()))
		);
	},

	hasCampAdminRole: function(camp) {
		return this.isCampSuperuser() || this.isCampAdmin(camp);
	},

	isCampSuperuser: function() {
		return this.roles().$has({
			name: CK.const.ROLES.CAMP_SUPERUSER.name
		});
	},

	hasCampSuperuserRole: function() {
		return this.isCampSuperuser();
	},

	isCampStudent: function() {
		return (
			this.roles().$has({
				name: CK.const.ROLES.CAMP_STUDENT.name
			}) && !this.isCampSuperuser()
		);
	},

	isCampUser: function() {
		// Exclude superusers from being considered camp users
		return !!((this.isCampStudent() || this.hasCampLeaderRole()) && !this.hasCampSuperuserRole());
	},

	isActiveCampStudent: function() {
		const maxDate = _.max(
			this.roles(CK.const.ROLES.CAMP_STUDENT.name)
				.camps()
				.groups()
				.endDate()
		);

		return this.isCampStudent() && maxDate > Date.now();
	},

	isFreeCampUser: function() {
		return this.isCampUser() && !this.hasHadAPlan();
	},

	campEngageData: async function() {
		const campsForRole = async roleName => {
			let camps = this.roles(roleName).camps();

			const campIds = camps.id() || [];
			const campNames = await Promise.all(
				_.map(campIds, id => {
					return SpaceManager.mod()
						.camps(id)
						.name();
				})
			);

			return _.zipObject(campIds, campNames);
		};

		const { campsAsStudent, campsAsLeader, campsAsAdmin } = await Promise.props({
			campsAsStudent: campsForRole(CK.const.ROLES.CAMP_STUDENT.name),
			campsAsLeader: campsForRole(CK.const.ROLES.CAMP_LEADER.name),
			campsAsAdmin: campsForRole(CK.const.ROLES.CAMP_ADMIN.name)
		});

		return {
			isCampUser: this.isCampUser(),
			isFreeCampUser: this.isFreeCampUser(),

			campIdsAsStudent: _.keys(campsAsStudent),
			campNamesAsStudent: _.values(campsAsStudent),

			campIdsAsInstructor: _.uniq([..._.keys(campsAsLeader), ..._.keys(campsAsAdmin)]),
			campNamesAsInstructor: _.uniq([..._.values(campsAsLeader), ..._.values(campsAsAdmin)])
		};
	}
};
