export enum CURRENCY {
	GBP = 'gbp',
	USD = 'usd',
	EUR = 'eur',
	AUD = 'aud',
	CAD = 'cad'
}

export const CURRENCY_SYMBOL: { [currency in CURRENCY]: string } = {
	[CURRENCY.GBP]: '£',
	[CURRENCY.USD]: '$',
	[CURRENCY.EUR]: '€',
	[CURRENCY.AUD]: 'A$',
	[CURRENCY.CAD]: 'C$'
};

export const CURRENCY_SYMBOL_HTML: { [currency in CURRENCY]: string } = {
	[CURRENCY.GBP]: '&pound;',
	[CURRENCY.USD]: '&#36;',
	[CURRENCY.EUR]: '&euro;',
	[CURRENCY.AUD]: 'A&#36;',
	[CURRENCY.CAD]: 'C&#36;'
};
