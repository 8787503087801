'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'simple-lucky-blocks',
	name: 'Simple Lucky Blocks',
	projectNamePrefix: 'simple',
	duration: 75,
	secondsDuration: 2178,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	difficulty: 'Easy',
	thumbnail: 'thumbnail-simple-lucky-blocks',
	descriptions: [
		'Learn how to make your own basic lucky blocks server.',
		'Create 6 of your very own lucky block effects including the super bounce, custom items and a pig army.',
		'This course teaches you how to use methods, switch statements and cases to randomise lucky block effects.'
	],

	unlockXp: 4500,

	videos: [
		{
			name: 'Simple Lucky Blocks',
			chapters: [
				{
					name: 'Respawn Block',
					description:
						'This chapter shows you how to reset your spawn point when you mine a lucky block',
					badges: ['parameters-1', 'methods-1', 'ui-1'],
					secondsOffset: 56
				},
				{
					name: 'Superpowers',
					description: 'This chapter shows you how to add new superpower effects',
					badges: ['general-1', 'methods-1'],
					secondsOffset: 430
				},
				{
					name: 'Mob Spawning',
					description: 'This chapter shows you how to spawn some new mobs in your world',
					badges: ['mob-1', 'generalisation-1'],
					secondsOffset: 739
				},
				{
					name: 'Super Bounce',
					description:
						'This chapter teaches you how to add the superbounce so you can spring in the air!',
					badges: ['data-1', 'operators-2'],
					secondsOffset: 1042
				},
				{
					name: 'Super Bow',
					description:
						'This chapter gives you the power of the super bow, a bow with overpowered arrows',
					badges: ['inventory-1', 'generalisation-1'],
					secondsOffset: 1197
				},
				{
					name: 'Explosion',
					description: 'This chapter shows you how to make your final effect: an explosion!',
					badges: ['timer-1', 'communication-2'],
					secondsOffset: 1484
				},
				{
					name: 'Which Block Will It Be?',
					description:
						'This chapter teaches you how to use switch statements to randomise the effects',
					badges: ['random-1', 'switch-1', 'general-2', 'algorithms-1'],
					secondsOffset: 1749
				},
				{
					name: 'Final Cases',
					description:
						'This chapter finishes off your lucky blocks by adding extra cases for each effect',
					badges: ['parameters-1', 'algorithms-2', 'generalisation-3'],
					secondsOffset: 2034
				}
			]
		}
	]
};
