'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands')
			.MINECRAFT_JAVA /*, require( '../../../../data/brands' ).MINECRAFT_PE*/
	], // Not supported in pocket
	id: 'bowing-n-glowing',
	name: "Bowing 'n' Glowing",
	duration: 40,
	secondsDuration: 243,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Build a mini game with bows and a shiny twist!',
		'Learn how to make a mini game that involves bows and (you guessed it) glowing!',
		'Create a basic PVP mini game that can be played in any world.'
	],
	difficulty: 'Medium',
	unlockXp: 19000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: "Bowing 'n' Glowing",
			chapters: [
				{
					name: "Glow Get 'em",
					description: 'Build a mini game with a shiny twist.',
					secondsOffset: 24,
					badges: []
				}
			]
		}
	]
};
