'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'welcome-to-the-mob',
	name: 'Welcome to the Mob',
	duration: 40,
	secondsDuration: 561,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Welcome to the mob!',
		"We'll be making police sheep and then sending pigs flying through the air!",
		'Create mob stacks and super snowman in the ultimate mob mod.'
	],
	difficulty: 'Easy',
	unlockXp: 15000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Welcome To The Mob',
			chapters: [
				{
					name: 'Looking Sheepish',
					description:
						"We're in need of some flashing law enforcement you say? Well it's a good job this chapter teaches you to make police sheep.",
					secondsOffset: 19,
					badges: []
				},
				{
					name: 'Stack Of All Mobs, Master Of None',
					description: "You know what's scary? Mobs. You know what's even scarier? A mob stack!",
					secondsOffset: 300,
					badges: []
				},
				{
					name: 'Pigs Can Fly?!',
					description: "In this chapter we'll be making pigs fly. Yes, really.",
					secondsOffset: 403,
					badges: []
				}
			]
		}
	]
};
