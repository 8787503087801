'use strict';

const products = require('./products');

/**
 * @deprecated
 */
module.exports = {
	[products.MINECRAFT]: {
		name: 'Minecraft Coding',
		image: `platform-${products.MINECRAFT}`
	},
	[products.ROBLOX]: {
		name: 'Roblox Coding',
		image: `platform-${products.ROBLOX}`
	},
	[products.BUNDLE]: {
		name: 'Bundle',
		image: `platform-${products.BUNDLE}`
	}
};
