'use strict';

import _ from 'lodash';

const ContextContributor = require('./ContextContributor');
const Extendable = require('./../system/Extendable');

/* @abstract */
const MonitorContext = (module.exports = Extendable.define({
	type: 'MonitorContext',

	addContributorContext: function(context) {
		_.each(CK.moduleContainer.modules(ContextContributor), module => {
			var moduleContext = module.getContext();

			if (_.isPlainObject(moduleContext)) {
				_.extend(context, moduleContext);
			}
		});
	}
}));
