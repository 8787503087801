'use strict';

const Errors = require('../../services/Errors');

module.exports = {
	isVideoCodeComplete: function(tutorialId, videoId) {
		if (!this.$exists()) {
			throw new Errors.NoUser();
		}

		return this.projects().$has({
			tutorialId,
			progress: {
				video: {
					$gt: videoId - 1
				}
			}
		});
	}
};
