'use strict';

import _ from 'lodash';
import { LOGIN_WITH_TOKEN_ERROR } from 'ck-core/source/data/LoginWithTokenError';
import { CK_ENV } from 'ck-core/source/data/ckEnv';

const SpaceManager = require('ck-core/source/managers/SpaceManager');
const ClientModule = require('../system/ClientModule');
const AuthService = require('ck-core/source/services/AuthService');
const CookieManager = require('./CookieManager');
const Time = require('ck-core/source/services/Time');
const CKError = require('ck-core/source/classes/Error');
const StaffModeManager = require('./StaffModeManager');

const sessionCookieName = 'ckSession';

const UserManager = (module.exports = ClientModule.define({
	type: 'UserManager',

	init_UserManager: function() {
		this._users = SpaceManager.mod().users();

		CK.user = this._users.$eq(0);

		this.assign('user', null, null, {
			cursor: CK.user
		});
	},

	removeSession: function() {
		const cookieManager = CookieManager.mod();

		cookieManager.remove(sessionCookieName);
		CK.moduleContainer.publish(UserManager.EVENTS.SESSION_CHANGE);
	},

	getSession: function() {
		const cookieManager = CookieManager.mod();

		const cookie = cookieManager.get(sessionCookieName);
		if (cookie) {
			try {
				return JSON.parse(cookie);
			} catch (err) {
				CK.logger.error(err);

				this.removeSession();
			}
		}
	},

	_postLogin: function() {
		CK.fn.removeTrackingCookie();
		CK.fn.removeDateStartedCookie();
	},

	setSessionCookie: function(session) {
		const oldCookie = this.getSession();

		if (oldCookie && oldCookie.id === session.id && oldCookie.setupUsername) {
			session.setupUsername = oldCookie.setupUsername;
		}

		CookieManager.mod().set(sessionCookieName, session, {
			// Allow the cookie to be sent when our site is embedded within an iframe
			// See https://blog.chromium.org/2019/10/developers-get-ready-for-new.html
			SameSite: CK.config.environment.name !== CK_ENV.LOCAL ? 'None' : undefined,
			expires: new Date(session.expires)
		});

		const staffModeManager = StaffModeManager.mod();

		if (staffModeManager) {
			staffModeManager.setSession(session);
		}
	},

	authenticateWithEndpoint: async function(endpoint, request, customLogoutHandler, emitOptions) {
		emitOptions = _.defaults(emitOptions || {}, {
			waitForVerify: false
		});

		CK.logger.info('🔑 Authenticate', {
			endpoint,
			request
		});

		if (!customLogoutHandler && CK.user.$exists()) {
			await this.logout();
		}

		request = _.defaults(request || {}, {
			timezone: new Date().getTimezoneOffset()
		});

		const authTimeout = setTimeout(() => {
			if (CK.clientSockets.users.isConnected()) {
				CK.logger.warn(
					new CKError({
						message: 'An authentication request is taking too long',
						tags: {
							request,
							endpoint
						}
					})
				);
			}
		}, Time.SECOND_MS * 10);

		let session, model, result;

		try {
			result = await CK.clientSockets.users.emit(endpoint, request, emitOptions);

			({ session, model } = result);
		} finally {
			clearTimeout(authTimeout);
		}

		if (customLogoutHandler) {
			await customLogoutHandler();
		}

		this.setSessionCookie(session);

		AuthService.addUserModel(model);

		this._postLogin();

		CK.moduleContainer.publish(UserManager.EVENTS.AUTHENTICATE);
		CK.moduleContainer.publish(UserManager.EVENTS.SESSION_CHANGE);

		CK.logger.info('🔑 Authenticate done ✅', {
			endpoint,
			request,
			userId: model.id,
			name: model.name,
			email: model.email
		});

		return result;
	},

	// TODO: We should make it so authenticate and simpleAuthenticate can also lock while joining to avoid sync races, however we don't know the id at this point
	// so it is tricky to co-ordinate the lock with SpaceManager.handleSync
	authenticate: async function(request) {
		return this.authenticateWithEndpoint('authenticate', request);
	},

	simpleAuthenticate: async function(request) {
		return this.authenticateWithEndpoint('simpleAuthenticate', request);
	},

	autoRegister: async function(request) {
		return this.authenticateWithEndpoint('autoRegister', request);
	},

	verifySession: async function(session) {
		CK.logger.info('🔑 verifySession', {
			session
		});

		try {
			// CORE-156 Remove when this is fixed
			const verifyTimeout = setTimeout(() => {
				CK.logger.error('Took too long to verifySession, reloading', {
					session
				});

				// Crude way of waiting for the log buffer to (hopefully) flush
				setTimeout(() => {
					window.location.reload();
				}, 5000);
			}, 120000);

			try {
				const newSession = await AuthService.verify(session);

				if (newSession) {
					this.setSessionCookie(newSession);
				}
			} finally {
				clearTimeout(verifyTimeout);
			}

			this._postLogin();
		} catch (err) {
			await this.logout();

			throw err;
		}
	},

	authenticateWithToken: async function(userId, token, endpoint, customLogoutHandler) {
		CK.logger.log(`🔑 ${endpoint}`, {
			userId,
			token
		});

		await this.authenticateWithEndpoint(
			endpoint,
			{
				userId,
				token
			},
			customLogoutHandler
		);
	},

	authenticateWithBillingToken: async function(userId, token) {
		try {
			// Do not logout so that switching on the account page will work
			return await this.authenticateWithToken(
				userId,
				token,
				'authenticateWithBillingToken',
				() => {}
			);
		} catch (err) {
			CK.logger.error(err);

			if (err.message === LOGIN_WITH_TOKEN_ERROR.EXPIRED) {
				document.location.href = '/login/expired';
			} else {
				document.location.href = '/login/';
			}
		}
	},

	authenticateWithUserToken: function(userId, token) {
		return this.authenticateWithToken(userId, token, 'authenticateWithUserToken');
	},

	authenticateWithEmailLoginToken: async function(userId, token) {
		try {
			return await this.authenticateWithToken(userId, token, 'authenticateWithEmailLoginToken');
		} catch (err) {
			CK.logger.error(err);

			if (err.message === LOGIN_WITH_TOKEN_ERROR.EXPIRED) {
				document.location.href = '/login/expired';
			} else if (err.message === LOGIN_WITH_TOKEN_ERROR.INVALID) {
				document.location.href = '/login/invalid';
			} else if (err.message === LOGIN_WITH_TOKEN_ERROR.ALREADY_USED) {
				document.location.href = '/login/alreadyUsed';
			} else {
				document.location.href = '/login/';
			}
		}
	},

	authenticateAsSubUser: async function(subUserId) {
		try {
			this.skipSessionRedirects = true;

			const userId = CK.user.id();

			CK.logger.log('🔑 authenticateAsSubUser', {
				userId,
				subUserId
			});

			const customLogoutHandler = async () => {
				// Logout billing user
				await this.logout(userId);
			};

			await this.authenticateWithEndpoint(
				'authenticateAsSubUser',
				{
					subUserId
				},
				customLogoutHandler
			);
		} finally {
			this.skipSessionRedirects = false;
		}
	},

	remoteLogout: async function(userId) {
		if (userId) {
			const spaceRoot = 'users/' + userId;
			await SpaceManager.mod().leave(spaceRoot);

			await Promise.map(_.keys(CK.clientSockets), async name => {
				// Already left by SpaceManager leave
				if (name === 'users') {
					return;
				}

				CK.clientSockets[name].emit(
					'leave',
					{
						root: spaceRoot
					},
					{
						buffer: false
					}
				);
			});
		}
	},

	logout: async function(userId) {
		userId = userId || CK.user.id();

		CK.logger.info('🔑 Logout', {
			userId
		});

		this.removeSession();
		CK.moduleContainer.broadcast(UserManager.EVENTS.LOGOUT);

		CK.fn.updateTrackingCookie();

		await this.remoteLogout(userId);
	}
}));

UserManager.EVENTS = {
	AUTHENTICATE: 'UserManager.authenticate',
	LOGOUT: 'UserManager.logout',
	SESSION_CHANGE: 'UserManager.sessionChange'
};
