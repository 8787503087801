'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands')
			.MINECRAFT_JAVA /*, require( '../../../../data/brands' ).MINECRAFT_PE*/
	], // Not supported in pocket
	id: 'server-mania',
	name: 'Server Mania',
	duration: 40,
	secondsDuration: 728,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Change things up on your server.',
		'Improve your server with these added extras.',
		'Learn how to change your name, send players to prison and add welcome messages in this server based mini mod.'
	],
	difficulty: 'Medium',
	unlockXp: 24000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Server Mania',
			chapters: [
				{
					name: 'Guess Who?',
					description: 'Change your name!',
					secondsOffset: 15,
					badges: []
				},
				{
					name: 'Sky High',
					description: 'Send your players to sky jail.',
					secondsOffset: 198,
					badges: []
				},
				{
					name: 'Shop Around',
					description: 'Learn how to set up your own custom shop.',
					secondsOffset: 342,
					badges: []
				},
				{
					name: 'Welcome, player x',
					description: 'Code specific player welcome messages.',
					secondsOffset: 546,
					badges: []
				}
			]
		}
	]
};
