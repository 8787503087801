'use strict';

module.exports = require('ck-core/source/functions/makeLang')({
	RESET_PASSWORD: {
		CHECK_EMAIL: {
			TITLE: 'Reset Email Sent',
			MESSAGE:
				`We\'ve sent an email to the address associated with your account with a link to reset your password.<br><br>If you have any other problems, please feel free to contact us at ${CK.config.name === 'ck-coolmath' ? '<a href="mailto:help@coolmathcoding.com">help@coolmathcoding.com</a>' : '<a href="mailto:support@codekingdoms.com">support@codekingdoms.com</a>'}.`
		},
		BUTTON: 'Reset',
		FAILED_NO_EMAIL: {
			TITLE: 'RESET ERROR',
			MESSAGE:
				"The account with that username has no email address, so we can't send you a reset password email! If you're part of a group, talk to your group leader."
		},
		FAILED: {
			TITLE: 'RESET ERROR',
			MESSAGE: 'The CK username or email provided did not match any we have on record.'
		},
		MULTIPLE_POSSIBLE: {
			TITLE: 'Choose an Account',
			MESSAGE:
				'We found multiple possible accounts, please choose the account you want to send a reset email for:'
		}
	},
	LOGIN: {
		FAILED: 'Login Failed',
		ERRORS: {
			BadUserOrPassword: 'Incorrect username or password. Please try again.',
			GoogleAuthEmailNotVerified:
				'Please verify your email address with Google to use it as a login.',
			NativeAuthenticationUnavailable:
				'There is no password set for that email address, please use the provider you signed up with to login.',
			FacebookEmailNotGranted:
				'Please grant access to your email address in the Facebook login dialog in order to use Facebook with us.'
		},
		TRY_ALTERNATIVE_TITLE: 'Choose an Account',
		TRY_ALTERNATIVE_USERNAME:
			"We've found your email address linked to more than one account, please choose which one you'd like to login to.<br>",
		SERVER_ERROR: 'Sorry, login failed, the error has been reported.',
		INVALID_SIMPLE_AUTH: 'You need to login using a password.',
		LINK_SENT: {
			TITLE: 'Login link sent',
			MESSAGE: 'Please check your emails for the login link.'
		},
		LINK_ERROR: {
			TITLE: 'Login link not sent',
			MESSAGE: 'Please check that the email is valid, or login with a password.'
		}
	}
});
