'use strict';

import _ from 'lodash';

const Language = require('../services/Language');

const replaceSubs = function(data, substitutes) {
	if (_.isString(data)) {
		return Language.mapVariables(data, substitutes);
	} else {
		return Language.mapAllVariables(data, substitutes);
	}
};

/**
 * @deprecated
 */
module.exports = function(langData) {
	Object.setPrototypeOf(langData, {
		replaceSubs
	});

	return langData;
};
