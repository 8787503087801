'use strict';

import _ from 'lodash';

import {
	CURRENCY
} from 'ck-core/source/data/Currency';

const fixedPlans = [ {
		id: 'ck-modding-flexible-6',
		interval: 'month',
		intervalCount: 6,
		price: [ {
				currency: 'gbp',
				value: 4999
			},
			{
				currency: 'usd',
				value: 6999
			},
			{
				currency: 'eur',
				value: 5999
			},
			{
				currency: 'cad',
				value: 7999
			},
			{
				currency: 'aud',
				value: 7999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		oneOff: true
	},
	{
		id: 'ck-modding-flexible-12',
		interval: 'month',
		intervalCount: 12,
		price: [ {
				currency: 'gbp',
				value: 5999
			},
			{
				currency: 'usd',
				value: 8999
			},
			{
				currency: 'eur',
				value: 6999
			},
			{
				currency: 'cad',
				value: 9999
			},
			{
				currency: 'aud',
				value: 9999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		oneOff: true
	},
	{
		id: 'ck-modding-lifetime',
		intervalCount: 10,
		interval: 'year',
		lifetime: true,
		price: [ {
				currency: 'gbp',
				value: 12999
			},
			{
				currency: 'usd',
				value: 18999
			},
			{
				currency: 'eur',
				value: 14999
			},
			{
				currency: 'cad',
				value: 19999
			},
			{
				currency: 'aud',
				value: 19999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		oneOff: true
	},
	{
		id: 'ck-modding-flexible-3-v2',
		interval: 'month',
		intervalCount: 3,
		price: [ {
				currency: 'gbp',
				value: 3999
			},
			{
				currency: 'usd',
				value: 4999
			},
			{
				currency: 'eur',
				value: 4999
			},
			{
				currency: 'cad',
				value: 6999
			},
			{
				currency: 'aud',
				value: 6999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		oneOff: true
	},
	{
		id: 'ck-modding-flexible-6-v2',
		interval: 'month',
		intervalCount: 6,
		price: [ {
				currency: 'gbp',
				value: 6999
			},
			{
				currency: 'usd',
				value: 8999
			},
			{
				currency: 'eur',
				value: 8999
			},
			{
				currency: 'cad',
				value: 11999
			},
			{
				currency: 'aud',
				value: 11999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		oneOff: true
	},
	{
		id: 'ck-modding-flexible-12-v2',
		interval: 'month',
		intervalCount: 12,
		price: [ {
				currency: 'gbp',
				value: 7999
			},
			{
				currency: 'usd',
				value: 9999
			},
			{
				currency: 'eur',
				value: 9999
			},
			{
				currency: 'cad',
				value: 12999
			},
			{
				currency: 'aud',
				value: 12999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		oneOff: true
	}
];

let recurringPlanWithTrial = {
	id: 'ck-modding-recurring-month-trial',
	active: true,
	interval: 'month',
	intervalCount: 1,
	price: [ {
			currency: 'gbp',
			value: 999
		},
		{
			currency: 'usd',
			value: 1499
		},
		{
			currency: 'eur',
			value: 1299
		},
		{
			currency: 'cad',
			value: 1999
		},
		{
			currency: 'aud',
			value: 1999
		}
	],
	freeTrialDays: 30,
	roles: [ CK.const.ROLES.MINECRAFT_25 ],
	oneOff: false
};

let recurringAnnualPlan = {
	id: 'ck-modding-recurring-6',
	interval: 'year',
	intervalCount: 1,
	preAuthUnitPrice: true,
	remindRenewal: true,
	price: [ {
			currency: 'gbp',
			value: 5999
		},
		{
			currency: 'usd',
			value: 8999
		},
		{
			currency: 'eur',
			value: 6999
		},
		{
			currency: 'cad',
			value: 9999
		},
		{
			currency: 'aud',
			value: 9999
		}
	],
	freeTrialDays: 30,
	roles: [ CK.const.ROLES.MINECRAFT_25 ],
	oneOff: false
};

const moddingRecurring9 = {
	id: 'ck-modding-recurring-9',
	active: true,
	interval: 'month',
	intervalCount: 1,
	giftCardImage: 'gift-card-1',
	price: [ {
			currency: 'gbp',
			value: 999
		},
		{
			currency: 'usd',
			value: 1499
		},
		{
			currency: 'eur',
			value: 1299
		},
		{
			currency: 'cad',
			value: 1999
		},
		{
			currency: 'aud',
			value: 1999
		}
	],
	freeTrialDays: 30,
	roles: [ CK.const.ROLES.MINECRAFT_25 ],
	oneOff: false
};

const plans = ( module.exports = [ {
		id: 'ck-chicken',
		oneOff: true,
		interval: 'year',
		intervalCount: 1,
		price: [ {
				currency: 'gbp',
				value: 3499
			},
			{
				currency: 'eur',
				value: 5499
			},
			{
				currency: 'usd',
				value: 5999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-1',
		interval: 'month',
		intervalCount: 3,
		oneOff: true,
		price: [ {
				currency: 'gbp',
				value: 4999
			},
			{
				currency: 'usd',
				value: 6999
			},
			{
				currency: 'eur',
				value: 6999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-2',
		interval: 'month',
		intervalCount: 6,
		oneOff: true,
		price: [ {
				currency: 'gbp',
				value: 5999
			},
			{
				currency: 'usd',
				value: 8999
			},
			{
				currency: 'eur',
				value: 7999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-3',
		interval: 'month',
		intervalCount: 12,
		oneOff: true,
		price: [ {
				currency: 'gbp',
				value: 9999
			},
			{
				currency: 'usd',
				value: 13999
			},
			{
				currency: 'eur',
				value: 12999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-4',
		interval: 'month',
		intervalCount: 1,
		oneOff: true,
		price: [ {
				currency: 'gbp',
				value: 1999
			},
			{
				currency: 'usd',
				value: 2999
			},
			{
				currency: 'eur',
				value: 2499
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-5',
		interval: 'month',
		intervalCount: 6,
		oneOff: true,
		price: [ {
				currency: 'gbp',
				value: 4999
			},
			{
				currency: 'usd',
				value: 6999
			},
			{
				currency: 'eur',
				value: 5999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-5-promo-1',
		interval: 'month',
		intervalCount: 9,
		oneOff: true,
		price: [ {
				currency: 'gbp',
				value: 4999
			},
			{
				currency: 'usd',
				value: 6999
			},
			{
				currency: 'eur',
				value: 5999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-recurring-1',
		interval: 'month',
		intervalCount: 1,
		price: [ {
				currency: 'gbp',
				value: 1499
			},
			{
				currency: 'usd',
				value: 2499
			},
			{
				currency: 'eur',
				value: 1999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-recurring-2',
		interval: 'month',
		intervalCount: 1,
		price: [ {
				currency: 'gbp',
				value: 1499
			},
			{
				currency: 'usd',
				value: 1999
			},
			{
				currency: 'eur',
				value: 1799
			},
			{
				currency: 'cad',
				value: 2499
			},
			{
				currency: 'aud',
				value: 2499
			}
		],
		freeTrialDays: 7,
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'demo',
		oneOff: true,
		free: true,
		interval: 'month',
		intervalCount: 1,
		roles: [ CK.const.ROLES.MINECRAFT_DEMO_16 ]
	},
	{
		id: 'ck-modding-recurring-3',
		interval: 'month',
		intervalCount: 1,
		price: [ {
				currency: 'gbp',
				value: 999
			},
			{
				currency: 'usd',
				value: 1499
			},
			{
				currency: 'eur',
				value: 1299
			},
			{
				currency: 'cad',
				value: 1999
			},
			{
				currency: 'aud',
				value: 1999
			}
		],
		freeTrialDays: 7,
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	{
		id: 'ck-modding-recurring-4',
		active: true,
		interval: 'month',
		intervalCount: 1,
		price: [ {
				currency: 'gbp',
				value: 999
			},
			{
				currency: 'usd',
				value: 1499
			},
			{
				currency: 'eur',
				value: 1299
			},
			{
				currency: 'cad',
				value: 1999
			},
			{
				currency: 'aud',
				value: 1999
			}
		],
		roles: [ CK.const.ROLES.MINECRAFT_25 ]
	},
	moddingRecurring9
] );

plans.push(
	_.defaults( {
			id: 'ck-modding-gift',
			giftOnly: true
		},
		_.find( plans, {
			id: 'ck-modding-2'
		} )
	)
);

plans.push(
	_.defaults( {
			id: 'ck-modding-gift-5',
			giftOnly: true
		},
		_.find( plans, {
			id: 'ck-modding-5'
		} )
	)
);

plans.push(
	_.defaults( {
			id: 'ck-modding-gift-5-promo-1',
			giftOnly: true
		},
		_.find( plans, {
			id: 'ck-modding-5-promo-1'
		} )
	)
);

// Sliding plans
let roundToNearest = ( monthlyPrice, months, percentOff ) => {
	const totalPrice = monthlyPrice * months;
	const reducedPrice = totalPrice * ( 100 - percentOff ) / 100;

	const roundedPrice = Math.round( reducedPrice / 100 );

	return roundedPrice * 100 - 1;
};

const SLIDING_GIFT_PREFIX = 'ck-modding-sliding-gift-m';
const SLIDING_GIFT_DATA = [ {
		months: 1,
		percentOff: 0
	},
	{
		months: 3,
		percentOff: 15
	},
	{
		months: 6,
		percentOff: 30
	},
	{
		months: 9,
		percentOff: 40
	},
	{
		months: 12,
		percentOff: 50
	}
];

for ( let slidingPlan of SLIDING_GIFT_DATA ) {
	const BASE_GBP = 999,
		BASE_EUR = 1299,
		BASE_USD = 1499,
		BASE_CAD = 1999,
		BASE_AUD = 1999;

	plans.push( {
		id: SLIDING_GIFT_PREFIX + slidingPlan.months,
		interval: 'month',
		intervalCount: slidingPlan.months,
		price: [ {
				currency: CURRENCY.GBP,
				value: roundToNearest( BASE_GBP, slidingPlan.months, slidingPlan.percentOff )
			},
			{
				currency: CURRENCY.EUR,
				value: roundToNearest( BASE_EUR, slidingPlan.months, slidingPlan.percentOff )
			},
			{
				currency: CURRENCY.USD,
				value: roundToNearest( BASE_USD, slidingPlan.months, slidingPlan.percentOff )
			},
			{
				currency: CURRENCY.CAD,
				value: roundToNearest( BASE_CAD, slidingPlan.months, slidingPlan.percentOff )
			},
			{
				currency: CURRENCY.AUD,
				value: roundToNearest( BASE_AUD, slidingPlan.months, slidingPlan.percentOff )
			}
		],
		oneOff: true,
		giftOnly: true,
		roles: [ CK.const.MINECRAFT_25 ]
	} );
}

const extraChildDiscount = 0.75;

const extraChildPlans = fixedPlans.map( plan => {
	let price = plan.price.map( price => {
		return _.defaults( {
				value: Math.floor( price.value * ( 1 - extraChildDiscount ) )
			},
			price
		);
	} );

	return _.defaults( {
			id: plan.id + '-extraChild',
			interval: 'month',
			price
		},
		plan
	);
} );

// TODO: Remove once we confirm that not having prices for a subscription doesn't make things go wrong
const creditPrices = [ {
		currency: 'gbp',
		value: 0
	},
	{
		currency: 'usd',
		value: 0
	},
	{
		currency: 'eur',
		value: 0
	},
	{
		currency: 'cad',
		value: 0
	},
	{
		currency: 'aud',
		value: 0
	}
];

let recurringThreeMonthlyPlan = {
	id: 'ck-modding-recurring-8',
	interval: 'month',
	intervalCount: 3,
	price: [ {
			currency: 'gbp',
			value: 2999
		},
		{
			currency: 'usd',
			value: 4499
		},
		{
			currency: 'eur',
			value: 3899
		},
		{
			currency: 'cad',
			value: 5999
		},
		{
			currency: 'aud',
			value: 5999
		}
	],
	roles: [ CK.const.ROLES.MINECRAFT_25 ],
	oneOff: false
};

const recurringMonthlyPlan = {
	id: 'ck-modding-recurring-7',
	active: true,
	interval: 'month',
	intervalCount: 1,
	price: [ {
			currency: 'gbp',
			value: 999
		},
		{
			currency: 'usd',
			value: 1499
		},
		{
			currency: 'eur',
			value: 1299
		},
		{
			currency: 'cad',
			value: 1999
		},
		{
			currency: 'aud',
			value: 1999
		}
	],
	roles: [ CK.const.ROLES.MINECRAFT_25 ],
	oneOff: false
};

plans.push(
	..._.flatten( [
		recurringPlanWithTrial,
		recurringThreeMonthlyPlan,
		recurringMonthlyPlan,
		recurringAnnualPlan,
		extraChildPlans,
		_.map(
			[
				recurringPlanWithTrial,
				recurringThreeMonthlyPlan,
				recurringMonthlyPlan,
				moddingRecurring9
			].concat( fixedPlans ),
			plan => {
				let planId = plan.id;

				// TODO: Rename ck-modding-recurring-month-trial to ck-modding-recurring-5 and remove this
				if ( planId === 'ck-modding-recurring-month-trial' ) {
					planId = 'ck-modding-recurring-5';
				}

				return _.defaults( {
						id: planId + '-selfRedeemed'
					},
					plan, {
						selfRedeemable: true,
						interval: 'month',
						active: false,
						roles: [ CK.const.ROLES.MINECRAFT_25 ]
					}
				);
			}
		),
		fixedPlans,
		_.map( fixedPlans, plan =>
			_.defaults( {
					id: plan.id + '-gift',
					interval: 'month',
					active: false,
					roles: [ CK.const.ROLES.MINECRAFT_25 ]
				},
				plan
			)
		),
		{
			id: 'ck-modding-credit-1',
			active: false,
			oneOff: true,
			roles: [ CK.const.ROLES.MINECRAFT_25 ],
			interval: 'month',
			intervalCount: 1,
			price: creditPrices
		},
		{
			id: 'ck-modding-credit-3',
			active: false,
			oneOff: true,
			roles: [ CK.const.ROLES.MINECRAFT_25 ],
			interval: 'month',
			intervalCount: 3,
			price: creditPrices
		},
		{
			id: 'ck-modding-credit-12',
			active: false,
			oneOff: true,
			roles: [ CK.const.ROLES.MINECRAFT_25 ],
			interval: 'month',
			intervalCount: 12,
			price: creditPrices
		}
	] )
);

let v3Plans = [ {
	"id": "ck-v3-modding-1-month",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"interval": "month",
	"intervalCount": 1,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 999
	}, {
		"currency": "usd",
		"value": 1499
	}, {
		"currency": "eur",
		"value": 1299
	}, {
		"currency": "cad",
		"value": 1999
	}, {
		"currency": "aud",
		"value": 1999
	} ]
}, {
	"id": "ck-v3-modding-1-month-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"interval": "month",
	"intervalCount": 1,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 1499
	}, {
		"currency": "usd",
		"value": 2299
	}, {
		"currency": "eur",
		"value": 1999
	}, {
		"currency": "cad",
		"value": 2999
	}, {
		"currency": "aud",
		"value": 2999
	} ]
}, {
	"id": "ck-v3-modding-monthly-recurring",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"interval": "month",
	"intervalCount": 1,
	"price": [ {
		"currency": "gbp",
		"value": 999
	}, {
		"currency": "usd",
		"value": 1499
	}, {
		"currency": "eur",
		"value": 1299
	}, {
		"currency": "cad",
		"value": 1999
	}, {
		"currency": "aud",
		"value": 1999
	} ]
}, {
	"id": "ck-v3-modding-monthly-recurring-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"interval": "month",
	"intervalCount": 1,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 1499
	}, {
		"currency": "usd",
		"value": 2299
	}, {
		"currency": "eur",
		"value": 1999
	}, {
		"currency": "cad",
		"value": 2999
	}, {
		"currency": "aud",
		"value": 2999
	} ]
}, {
	"id": "ck-v3-modding-3-monthly-recurring",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"interval": "month",
	"intervalCount": 3,
	"price": [ {
		"currency": "gbp",
		"value": 2499
	}, {
		"currency": "usd",
		"value": 3999
	}, {
		"currency": "eur",
		"value": 3499
	}, {
		"currency": "cad",
		"value": 4499
	}, {
		"currency": "aud",
		"value": 4499
	} ]
}, {
	"id": "ck-v3-modding-3-monthly-recurring-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"interval": "month",
	"intervalCount": 3,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 3799
	}, {
		"currency": "usd",
		"value": 5999
	}, {
		"currency": "eur",
		"value": 5299
	}, {
		"currency": "cad",
		"value": 6799
	}, {
		"currency": "aud",
		"value": 6799
	} ]
}, {
	"id": "ck-v3-modding-3-months",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"interval": "month",
	"intervalCount": 3,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 2499
	}, {
		"currency": "usd",
		"value": 3999
	}, {
		"currency": "eur",
		"value": 3499
	}, {
		"currency": "cad",
		"value": 4499
	}, {
		"currency": "aud",
		"value": 4499
	} ]
}, {
	"id": "ck-v3-modding-3-months-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"interval": "month",
	"intervalCount": 3,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 3799
	}, {
		"currency": "usd",
		"value": 5999
	}, {
		"currency": "eur",
		"value": 5299
	}, {
		"currency": "cad",
		"value": 6799
	}, {
		"currency": "aud",
		"value": 6799
	} ]
}, {
	"id": "ck-v3-modding-12-months",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"interval": "month",
	"intervalCount": 12,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 5999
	}, {
		"currency": "usd",
		"value": 8999
	}, {
		"currency": "eur",
		"value": 6999
	}, {
		"currency": "cad",
		"value": 9999
	}, {
		"currency": "aud",
		"value": 9999
	} ]
}, {
	"id": "ck-v3-modding-12-months-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"interval": "month",
	"intervalCount": 12,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 8999
	}, {
		"currency": "usd",
		"value": 13499
	}, {
		"currency": "eur",
		"value": 10499
	}, {
		"currency": "cad",
		"value": 14999
	}, {
		"currency": "aud",
		"value": 14999
	} ]
}, {
	"id": "ck-v3-modding-annually",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"interval": "year",
	"intervalCount": 1,
	"price": [ {
		"currency": "gbp",
		"value": 5999
	}, {
		"currency": "usd",
		"value": 8999
	}, {
		"currency": "eur",
		"value": 6999
	}, {
		"currency": "cad",
		"value": 9999
	}, {
		"currency": "aud",
		"value": 9999
	} ]
}, {
	"id": "ck-v3-modding-annually-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"interval": "year",
	"intervalCount": 1,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 8999
	}, {
		"currency": "usd",
		"value": 13499
	}, {
		"currency": "eur",
		"value": 10499
	}, {
		"currency": "cad",
		"value": 14999
	}, {
		"currency": "aud",
		"value": 14999
	} ]
}, {
	"id": "ck-v3-modding-lifetime",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding ",
	"intervalCount": 10,
	"interval": "year",
	"lifetime": true,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 12999
	}, {
		"currency": "usd",
		"value": 18999
	}, {
		"currency": "eur",
		"value": 14999
	}, {
		"currency": "cad",
		"value": 19999
	}, {
		"currency": "aud",
		"value": 19999
	} ]
}, {
	"id": "ck-v3-modding-lifetime-family",
	"active": true,
	"roles": [ {
		"name": "minecraft25",
		"properties": {
			"maxPlayers": 25
		}
	} ],
	"description": "Minecraft Coding (family)",
	"intervalCount": 10,
	"interval": "year",
	"lifetime": true,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 19499
	}, {
		"currency": "usd",
		"value": 28499
	}, {
		"currency": "eur",
		"value": 22499
	}, {
		"currency": "cad",
		"value": 29999
	}, {
		"currency": "aud",
		"value": 29999
	} ]
}, {
	"id": "ck-v3-roblox-1-month",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"interval": "month",
	"intervalCount": 1,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 999
	}, {
		"currency": "usd",
		"value": 1499
	}, {
		"currency": "eur",
		"value": 1299
	}, {
		"currency": "cad",
		"value": 1999
	}, {
		"currency": "aud",
		"value": 1999
	} ]
}, {
	"id": "ck-v3-roblox-1-month-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"interval": "month",
	"intervalCount": 1,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 1499
	}, {
		"currency": "usd",
		"value": 2299
	}, {
		"currency": "eur",
		"value": 1999
	}, {
		"currency": "cad",
		"value": 2999
	}, {
		"currency": "aud",
		"value": 2999
	} ]
}, {
	"id": "ck-v3-roblox-monthly-recurring",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"interval": "month",
	"intervalCount": 1,
	"price": [ {
		"currency": "gbp",
		"value": 999
	}, {
		"currency": "usd",
		"value": 1499
	}, {
		"currency": "eur",
		"value": 1299
	}, {
		"currency": "cad",
		"value": 1999
	}, {
		"currency": "aud",
		"value": 1999
	} ]
}, {
	"id": "ck-v3-roblox-monthly-recurring-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"interval": "month",
	"intervalCount": 1,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 1499
	}, {
		"currency": "usd",
		"value": 2299
	}, {
		"currency": "eur",
		"value": 1999
	}, {
		"currency": "cad",
		"value": 2999
	}, {
		"currency": "aud",
		"value": 2999
	} ]
}, {
	"id": "ck-v3-roblox-3-monthly-recurring",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"interval": "month",
	"intervalCount": 3,
	"price": [ {
		"currency": "gbp",
		"value": 2499
	}, {
		"currency": "usd",
		"value": 3999
	}, {
		"currency": "eur",
		"value": 3499
	}, {
		"currency": "cad",
		"value": 4499
	}, {
		"currency": "aud",
		"value": 4499
	} ]
}, {
	"id": "ck-v3-roblox-3-monthly-recurring-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"interval": "month",
	"intervalCount": 3,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 3799
	}, {
		"currency": "usd",
		"value": 5999
	}, {
		"currency": "eur",
		"value": 5299
	}, {
		"currency": "cad",
		"value": 6799
	}, {
		"currency": "aud",
		"value": 6799
	} ]
}, {
	"id": "ck-v3-roblox-3-months",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"interval": "month",
	"intervalCount": 3,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 2499
	}, {
		"currency": "usd",
		"value": 3999
	}, {
		"currency": "eur",
		"value": 3499
	}, {
		"currency": "cad",
		"value": 4499
	}, {
		"currency": "aud",
		"value": 4499
	} ]
}, {
	"id": "ck-v3-roblox-3-months-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"interval": "month",
	"intervalCount": 3,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 3799
	}, {
		"currency": "usd",
		"value": 5999
	}, {
		"currency": "eur",
		"value": 5299
	}, {
		"currency": "cad",
		"value": 6799
	}, {
		"currency": "aud",
		"value": 6799
	} ]
}, {
	"id": "ck-v3-roblox-12-months",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"interval": "month",
	"intervalCount": 12,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 5999
	}, {
		"currency": "usd",
		"value": 8999
	}, {
		"currency": "eur",
		"value": 6999
	}, {
		"currency": "cad",
		"value": 9999
	}, {
		"currency": "aud",
		"value": 9999
	} ]
}, {
	"id": "ck-v3-roblox-12-months-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"interval": "month",
	"intervalCount": 12,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 8999
	}, {
		"currency": "usd",
		"value": 13499
	}, {
		"currency": "eur",
		"value": 10499
	}, {
		"currency": "cad",
		"value": 14999
	}, {
		"currency": "aud",
		"value": 14999
	} ]
}, {
	"id": "ck-v3-roblox-annually",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"interval": "year",
	"intervalCount": 1,
	"price": [ {
		"currency": "gbp",
		"value": 5999
	}, {
		"currency": "usd",
		"value": 8999
	}, {
		"currency": "eur",
		"value": 6999
	}, {
		"currency": "cad",
		"value": 9999
	}, {
		"currency": "aud",
		"value": 9999
	} ]
}, {
	"id": "ck-v3-roblox-annually-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"interval": "year",
	"intervalCount": 1,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 8999
	}, {
		"currency": "usd",
		"value": 13499
	}, {
		"currency": "eur",
		"value": 10499
	}, {
		"currency": "cad",
		"value": 14999
	}, {
		"currency": "aud",
		"value": 14999
	} ]
}, {
	"id": "ck-v3-roblox-lifetime",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding ",
	"intervalCount": 10,
	"interval": "year",
	"lifetime": true,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 12999
	}, {
		"currency": "usd",
		"value": 18999
	}, {
		"currency": "eur",
		"value": 14999
	}, {
		"currency": "cad",
		"value": 19999
	}, {
		"currency": "aud",
		"value": 19999
	} ]
}, {
	"id": "ck-v3-roblox-lifetime-family",
	"active": true,
	"roles": [ {
		"name": "roblox"
	} ],
	"description": "Roblox Coding (family)",
	"intervalCount": 10,
	"interval": "year",
	"lifetime": true,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 19499
	}, {
		"currency": "usd",
		"value": 28499
	}, {
		"currency": "eur",
		"value": 22499
	}, {
		"currency": "cad",
		"value": 29999
	}, {
		"currency": "aud",
		"value": 29999
	} ]
}, {
	"id": "ck-v3-bundle-1-month",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"interval": "month",
	"intervalCount": 1,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 1499
	}, {
		"currency": "usd",
		"value": 2299
	}, {
		"currency": "eur",
		"value": 1999
	}, {
		"currency": "cad",
		"value": 2999
	}, {
		"currency": "aud",
		"value": 2999
	} ]
}, {
	"id": "ck-v3-bundle-1-month-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"interval": "month",
	"intervalCount": 1,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 2299
	}, {
		"currency": "usd",
		"value": 3399
	}, {
		"currency": "eur",
		"value": 2999
	}, {
		"currency": "cad",
		"value": 4499
	}, {
		"currency": "aud",
		"value": 4499
	} ]
}, {
	"id": "ck-v3-bundle-monthly-recurring",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"interval": "month",
	"intervalCount": 1,
	"price": [ {
		"currency": "gbp",
		"value": 1499
	}, {
		"currency": "usd",
		"value": 2299
	}, {
		"currency": "eur",
		"value": 1999
	}, {
		"currency": "cad",
		"value": 2999
	}, {
		"currency": "aud",
		"value": 2999
	} ]
}, {
	"id": "ck-v3-bundle-monthly-recurring-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"interval": "month",
	"intervalCount": 1,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 2299
	}, {
		"currency": "usd",
		"value": 3399
	}, {
		"currency": "eur",
		"value": 2999
	}, {
		"currency": "cad",
		"value": 4499
	}, {
		"currency": "aud",
		"value": 4499
	} ]
}, {
	"id": "ck-v3-bundle-3-monthly-recurring",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"interval": "month",
	"intervalCount": 3,
	"price": [ {
		"currency": "gbp",
		"value": 3799
	}, {
		"currency": "usd",
		"value": 5999
	}, {
		"currency": "eur",
		"value": 5299
	}, {
		"currency": "cad",
		"value": 6799
	}, {
		"currency": "aud",
		"value": 6799
	} ]
}, {
	"id": "ck-v3-bundle-3-monthly-recurring-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"interval": "month",
	"intervalCount": 3,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 5699
	}, {
		"currency": "usd",
		"value": 8999
	}, {
		"currency": "eur",
		"value": 7899
	}, {
		"currency": "cad",
		"value": 10199
	}, {
		"currency": "aud",
		"value": 10199
	} ]
}, {
	"id": "ck-v3-bundle-3-months",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"interval": "month",
	"intervalCount": 3,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 3799
	}, {
		"currency": "usd",
		"value": 5999
	}, {
		"currency": "eur",
		"value": 5299
	}, {
		"currency": "cad",
		"value": 6799
	}, {
		"currency": "aud",
		"value": 6799
	} ]
}, {
	"id": "ck-v3-bundle-3-months-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"interval": "month",
	"intervalCount": 3,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 5699
	}, {
		"currency": "usd",
		"value": 8999
	}, {
		"currency": "eur",
		"value": 7899
	}, {
		"currency": "cad",
		"value": 10199
	}, {
		"currency": "aud",
		"value": 10199
	} ]
}, {
	"id": "ck-v3-bundle-12-months",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"interval": "month",
	"intervalCount": 12,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 8999
	}, {
		"currency": "usd",
		"value": 13499
	}, {
		"currency": "eur",
		"value": 10499
	}, {
		"currency": "cad",
		"value": 14999
	}, {
		"currency": "aud",
		"value": 14999
	} ]
}, {
	"id": "ck-v3-bundle-12-months-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"interval": "month",
	"intervalCount": 12,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 13499
	}, {
		"currency": "usd",
		"value": 20299
	}, {
		"currency": "eur",
		"value": 15799
	}, {
		"currency": "cad",
		"value": 22499
	}, {
		"currency": "aud",
		"value": 22499
	} ]
}, {
	"id": "ck-v3-bundle-annually",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"interval": "year",
	"intervalCount": 1,
	"price": [ {
		"currency": "gbp",
		"value": 8999
	}, {
		"currency": "usd",
		"value": 13499
	}, {
		"currency": "eur",
		"value": 10499
	}, {
		"currency": "cad",
		"value": 14999
	}, {
		"currency": "aud",
		"value": 14999
	} ]
}, {
	"id": "ck-v3-bundle-annually-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"interval": "year",
	"intervalCount": 1,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 13499
	}, {
		"currency": "usd",
		"value": 20299
	}, {
		"currency": "eur",
		"value": 15799
	}, {
		"currency": "cad",
		"value": 22499
	}, {
		"currency": "aud",
		"value": 22499
	} ]
}, {
	"id": "ck-v3-bundle-lifetime",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle ",
	"intervalCount": 10,
	"interval": "year",
	"lifetime": true,
	"oneOff": true,
	"price": [ {
		"currency": "gbp",
		"value": 19499
	}, {
		"currency": "usd",
		"value": 28499
	}, {
		"currency": "eur",
		"value": 22499
	}, {
		"currency": "cad",
		"value": 29999
	}, {
		"currency": "aud",
		"value": 29999
	} ]
}, {
	"id": "ck-v3-bundle-lifetime-family",
	"active": true,
	"roles": [ {
		"name": "bundle"
	} ],
	"description": "Bundle (family)",
	"intervalCount": 10,
	"interval": "year",
	"lifetime": true,
	"oneOff": true,
	"family": true,
	"price": [ {
		"currency": "gbp",
		"value": 29299
	}, {
		"currency": "usd",
		"value": 42799
	}, {
		"currency": "eur",
		"value": 33799
	}, {
		"currency": "cad",
		"value": 44999
	}, {
		"currency": "aud",
		"value": 44999
	} ]
} ];

_.each( plans, plan => ( plan.active = false ) );


plans.push( ...v3Plans );
