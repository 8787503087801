'use strict';

import _ from 'lodash';

const Time = require('ck-core/source/services/Time');
const ClientModule = require('../system/ClientModule');

const Idle = (module.exports = ClientModule.define({
	type: 'IdleManager',

	init_IdleManager: function(parent, options) {
		this.options = _.defaults(options || {}, {
			idleTime: Time.MINUTE_MS * 15
		});

		this._resetIdle = _.debounce(this._resetIdle.bind(this), 50);

		this._lastActive = Date.now();

		this.ACTIVE_EVENTS = 'mousemove keydown mousedown touchstart';

		this._setIdleTimeout();
	},

	_setIdleTimeout: function() {
		clearTimeout(this._idleTimeout);

		this._idleTimeout = setTimeout(() => {
			this.broadcast(this._class.EVENTS.IDLE);
		}, this.options.idleTime);
	},

	bind_IdleManager: function() {
		$(document.body).on(this.ACTIVE_EVENTS, this._resetIdle);
	},

	unbind_IdleManager: function() {
		$(document.body).off(this.ACTIVE_EVENTS, this._resetIdle);
	},

	_resetIdle: function() {
		var unidled = this.isIdle();

		if (unidled) {
			this.broadcast(this._class.EVENTS.UNIDLE);
		}

		this._lastActive = Date.now();

		this._setIdleTimeout();
	},

	isIdle: function() {
		return this._lastActive <= Date.now() - this.options.idleTime;
	}
}));

Idle.EVENTS = {
	IDLE: 'IdleManager.idle',
	UNIDLE: 'IdleManager.unIdle'
};
