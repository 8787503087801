'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'ravine-runner',
	name: 'Ravine Runner',
	duration: 40,
	secondsDuration: 582,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Run and leave a trail of destruction behind you.',
		'Destroy the ground you stand on and learn how to create your own structures!',
		'Practise your structure building with this medium mini mod.'
	],
	difficulty: 'Medium',
	unlockXp: 17500,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Ravine Runner',
			chapters: [
				{
					name: 'Trail of Destruction',
					description: 'Write code that leaves a trail of destruction behind you.',
					secondsOffset: 13,
					badges: []
				},
				{
					name: 'Lav-ahhhhhh',
					description: 'Create platforms of bedrock and lava.',
					secondsOffset: 375,
					badges: []
				}
			]
		}
	]
};
