'use strict';

module.exports = require('ck-core/source/functions/makeLang')({
	MODAL: {
		MESSAGE: 'Do you have an existing account that you would like to use?',
		TITLE: 'You are currently logged out.'
	},
	BUTTON: {
		YES: 'Login using my account',
		NO: 'Create an account'
	}
});
