'use strict';

import _ from 'lodash';

module.exports = function(test, options) {
	let { testSeed, user, abTests, currentCohorts } = options || {};

	const currentCohort = _.get(currentCohorts, test.id);

	if (currentCohort) {
		return currentCohort;
	}

	if (!test.entryPredicate || test.entryPredicate(options)) {
		const primary = _.some(test.ranges, range => {
			// Invert range for backwards compatibility
			// e.g testGroup F maps to [0.83, 1)
			let [upper, lower] = range;

			lower = 1 - lower;
			upper = 1 - upper;

			return testSeed >= lower && testSeed < upper;
		});

		return {
			id: test.id,
			control: !primary
		};
	}
};
