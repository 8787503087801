'use strict';

import _ from 'lodash';

import './index.less';

import React from 'react';
import PropTypes from 'prop-types';
export class PromoCountdown extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			countdownRemainingMs: this.calculateRemainingMs()
		};
	}

	clearCountdown() {
		if (this.countdownInterval) {
			clearInterval(this.countdownInterval);
			this.countdownInterval = null;
		}
	}

	startCountdown(props) {
		if (props.countdownEnd) {
			this.clearCountdown();
			this.countdownInterval = setInterval(() => {
				const countdownRemainingMs = this.calculateRemainingMs();

				this.setState({
					countdownRemainingMs
				});
			}, 1000);
		}
	}

	calculateRemainingMs() {
		return Math.max(0, this.props.countdownEnd.getTime() - Date.now());
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.countdownEnd) {
			if (!moment(nextProps.countdownEnd).isSame(this.props.countdownEnd)) {
				this.startCountdown(nextProps);
			}
		} else {
			this.clearCountdown();
		}
	}

	componentDidMount() {
		this.startCountdown(this.props);
	}

	componentWillUnmount() {
		this.clearCountdown();

		if (this.revealTimeout) {
			clearTimeout(this.revealTimeout);
			this.revealTimeout = null;
		}
	}

	render() {
		const duration = moment.duration(this.state.countdownRemainingMs);

		const pad = val => _.padStart('' + val, 2, '0');

		const className = this.props.compact ? 'PromoCountdown--compact' : 'PromoCountdown';

		return (
			<div className={className}>
				<div className="values">
					<div>{pad(duration.days())}</div>
					<div>{pad(duration.hours())}</div>
					<div>{pad(duration.minutes())}</div>
					<div>{pad(duration.seconds())}</div>
				</div>
				<div className="legend">
					<div>Days</div>
					<div>Hours</div>
					<div>Minutes</div>
					<div>Seconds</div>
				</div>
			</div>
		);

		// return <div className={className}>Up to 50% discount. Limited time only.</div>;
	}
}

PromoCountdown.propTypes = {
	countdownEnd: PropTypes.instanceOf(Date),
	compact: PropTypes.bool
};
