import _ from 'lodash';
import Bluebird from 'bluebird';

export type PollPredicate = () => boolean | void | Promise<boolean | void>;
export interface PollOptions {
	timeout?: number;
	interval?: number;
	asyncPollTimeout?: number;
}

export function poll(fn: PollPredicate, options?: PollOptions) {
	options = _.defaults(options || {}, {
		timeout: 1500,
		interval: 50
	});

	options.asyncPollTimeout = options.asyncPollTimeout || options.timeout;

	const { timeout, interval, asyncPollTimeout } = options;

	if (typeof asyncPollTimeout === 'undefined' || !_.isFinite(asyncPollTimeout)) {
		throw new Error('asyncPollTimeout is not finite');
	}

	if (typeof timeout === 'undefined' || !_.isFinite(timeout)) {
		throw new Error('timeout is not finite');
	}

	if (!_.isFinite(interval)) {
		throw new Error('interval is not finite');
	}

	var pollTimeout: any;

	const originalError = new Error('Poll timed out');

	return new Promise((fulfil, reject) => {
		const startTime = Date.now();

		const poller = () => {
			const startNextPoll = (err?: Error) => {
				if (timeout > 0 && Date.now() - startTime > timeout) {
					err = err || new Error(`CK.fn.poll() timed out after ${timeout}ms`);

					err.message = `${originalError.message}

${originalError.stack}

${err.message}`;

					reject(err);
				} else {
					pollTimeout = setTimeout(poller, interval);
				}
			};

			try {
				let result = fn();

				if (result !== false) {
					if (result && result.then) {
						// Duck typing to check if it's a Bluebird Promise
						if (!(result as any).timeout) {
							result = Bluebird.resolve(result);
						}

						Bluebird.resolve(result)
							.timeout(asyncPollTimeout)
							.then(res => {
								if (res !== false) {
									fulfil(res);
								} else {
									startNextPoll();
								}
							})
							.catch((err: Error) => {
								if (err instanceof Bluebird.TimeoutError) {
									startNextPoll();
								} else {
									startNextPoll(err);
								}
							});
					} else {
						fulfil(result);
					}
				} else {
					startNextPoll(new Error('Falsy'));
				}
			} catch (err) {
				startNextPoll(err || new Error('Returned falsy for too long'));
			}
		};

		poller();
	}).finally(() => {
		clearTimeout(pollTimeout);
	});
}
