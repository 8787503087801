import './StartCodingModal.less';
import React from 'react';
import MessageModal from 'ck-core-client/source/ui/MessageModal';
import RoundButton from 'ck-core-client/source/ui/RoundButton';
import Frame from 'ck-core-client/source/ui/Frame';
import PropTypes from 'prop-types';
import Lang from 'ck-website/source/ui/StartCodingModalLang';

export default class StartCodingModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: true
		};

		if (CK.user.$exists()) {
			this.state.show = false;
			this.goToPlatformSelect();
		}
	}

	goToPlatformSelect() {
		this.props.handleClose();
		Frame.mod().navigate('/platform-select/');
	}

	render() {
		const yesButton = (
			<RoundButton
				key={'yesButton'}
				size="small"
				text={Lang.BUTTON.YES}
				handleClick={() => {
					this.props.handleClose();
					Frame.mod().navigate('/login/');
				}}
			/>
		);
		const noButton = (
			<RoundButton
				key={'noButton'}
				size="small"
				text={Lang.BUTTON.NO}
				handleClick={() => {
					this.props.handleClose();
					Frame.mod().navigate('/plans/?trial=true');
				}}
			/>
		);

		return (
			this.state.show && (
				<MessageModal
					className="StartCodingModal"
					message={Lang.MODAL.MESSAGE}
					handleClose={() => {
						this.props.handleClose();
					}}
					title={Lang.MODAL.TITLE}
					buttons={[yesButton, noButton]}
				/>
			)
		);
	}
}

StartCodingModal.propTypes = {
	handleClose: PropTypes.func
};
