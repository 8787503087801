'use strict';

const RandomStream = (module.exports = function(time, seed) {
	this.carry = time;
	this.x = seed;
	this.max = Math.pow(2, 32);
	this.a = 3636507990;
});

RandomStream.prototype = {
	// Return either a float [lo, hi) or an integer [lo, hi] if integer is true
	pick: function(lo, hi, integer) {
		lo = lo === undefined ? 0 : lo;
		hi = hi === undefined ? 1 : hi;

		this.carry = (this.a + this.x + this.carry) / this.max;
		this.x = this.max - 1 - (this.a * this.x + this.carry) % this.max;
		var num = this.x / this.max;

		if (integer) {
			return Math.floor(num * (hi - lo + 1) + lo);
		} else {
			return num * (hi - lo) + lo;
		}
	}
};

RandomStream.default = new RandomStream(Date.now() / 1000, Math.random());
RandomStream.new = function() {
	return new RandomStream(Date.now() / 1000, Math.random());
};

RandomStream.copy = function(randomStream) {
	return new RandomStream(randomStream.carry, randomStream.x);
};

RandomStream.fork = function() {
	return new RandomStream(this.pick(0, this.max), this.pick());
};
