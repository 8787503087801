'use strict';

import _ from 'lodash';

import './FooterPromo.less';

import React from 'react';
import classnames from 'classnames';
import Frame from './Frame';
import Assets from '../system/Assets';
import RoundButton from './RoundButton';
import { PromoCountdown } from '../components/PromoCountdown';
import { PromoPropType } from '../classes/Promo';
export default class FooterPromo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showCta: true
		};

		this.setupRevealTimeout();
	}
	setupRevealTimeout() {
		if (this.revealTimeout === undefined) {
			this.revealTimeout = setTimeout(
				() =>
					this.setState({
						showCta: false
					}),
				3000
			);
		}
	}

	render() {
		const classes = classnames('FooterPromo', this.props.id);

		let promoLang = this.props.lang.footer;

		// if (CK.fn.userCurrency && CK.fn.userCurrency() === 'gbp') {
		// 	// 	// GBP/ UK users to get different copy

		// 	promoLang = {
		// 		intro: "Don't miss our exclusive Christmas deals!",
		// 		title: 'Christmas Sale',
		// 		button: 'Show me the deals'
		// 	};
		// }

		const backgroundAsset = Assets.get(`promo-${this.props.id}-footer-background`);

		let { intro, title } = promoLang;

		if (!this.state.showCta) {
			if (this.props.countdownEnd) {
				intro = promoLang.intro;
				title = <PromoCountdown countdownEnd={this.props.countdownEnd} />;
			} else {
				intro = promoLang.intro;

				let noOfHours = moment(this.props.endDate).diff(moment(this.props.startDate), 'hours');

				let hoursRemaining = moment(this.props.endDate).diff(moment(), 'hours');

				const pad = val => _.padStart('' + val, 2, '0');

				let remaining = pad(Math.floor((this.props.remaining / noOfHours) * hoursRemaining));

				// title = remaining + ' Deals remaining';

				title = 'Limited Deals remaining';

				// title = `${this.props.remaining} deal${this.props.remaining !== 1 ? 's' : ''} ${
				// 	this.props.remainingSuffix
				// }`;
			}
		}

		return (
			<div
				className={classes}
				style={{
					backgroundImage: `url(${_.get(backgroundAsset, 'url')})`
				}}
			>
				<div className="intro">{intro}</div>
				<div className="title">{title}</div>
				<div className="buttonWrapper">
					<RoundButton
						hoverAnimation="hvr-bounce-in"
						size="medium"
						colour="blue"
						text={promoLang.button}
						handleClick={() => Frame.mod().navigate('/plans/')}
					/>
				</div>
			</div>
		);
	}
}

FooterPromo.propTypes = PromoPropType.isRequired;
