'use strict';

import _ from 'lodash';

import './modal.less';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RoundButton from './RoundButton';
import ReactCKImage from './ReactCKImage';

export const CLOSE_BUTTON_OPTION = {
	NONE: 'none',
	SMALL_ROUND: 'small round',
	CROSS: 'cross'
};

export default class Modal extends React.Component {
	render() {
		const cancelClick = e => {
			e.stopPropagation();
		};

		const className = classnames('LegacyModal', this.props.modalClass);

		const crossCloseButton = (
			<div className="closeButton" onClick={this.props.handleClose}>
				<ReactCKImage name="close-button" />
			</div>
		);

		const smallRoundCloseButton = (
			<RoundButton
				size={'small'}
				buttonClass="roundCloseButton"
				handleClick={this.props.handleClose}
				text="Close"
				colour="blue"
			/>
		);

		return (
			<div className={className} onClick={this.props.handleClose}>
				{this.props.closeButtonOption === CLOSE_BUTTON_OPTION.CROSS && crossCloseButton}
				<div className="wrapper" onClick={cancelClick}>
					{this.props.children}
					{this.props.closeButtonOption === CLOSE_BUTTON_OPTION.SMALL_ROUND &&
						smallRoundCloseButton}
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	modalClass: PropTypes.string.isRequired,
	handleClose: PropTypes.func,
	closeButtonOption: PropTypes.oneOf(_.values(CLOSE_BUTTON_OPTION)),
	children: PropTypes.node,
	noCloseOnBlur: PropTypes.bool
};

Modal.defaultProps = {
	closeButtonOption: CLOSE_BUTTON_OPTION.CROSS
};
