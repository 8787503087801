'use strict';

module.exports = function(sortedValues, percentile) {
	var pos = sortedValues.length * percentile / 100 + 0.5;

	var lower = Math.floor(pos) - 1;

	if (lower < 0) {
		return sortedValues[0];
	}
	var upper = lower + 1;
	var fraction = pos % 1;

	// Nothing to interpolate up to
	if (upper >= sortedValues.length) {
		return sortedValues[lower];
	}

	return (1 - fraction) * sortedValues[lower] + fraction * sortedValues[upper];
};
