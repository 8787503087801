'use strict';

const createErrorClass = require('./createErrorClass');

module.exports = function(errors, defOptions) {
	if (errors[defOptions.name]) {
		throw new Error('Duplicate error definition with name: ' + defOptions.name);
	}

	const err = createErrorClass(defOptions);

	errors[defOptions.name] = err;
};
