'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'alien-attack',
	name: 'Alien Attack',
	duration: 120,
	secondsDuration: 1833,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 30,
	unlockXp: 2000,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Orb',
			superclass: 'BaseObject'
		},
		{
			path: '/ArenaPlayer',
			superclass: 'BasePlayer'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'If you want to give the finished Alien Attack game a try, just visit https://www.roblox.com/games/1252589977/Alien-Attack'
		},
		{
			name: 'urgentMessage',
			time: 234,
			lookAhead: true,
			message:
				'Because the explosions go off straight away, they will have already happened by the time your character spawns. If you select "Run" from the dropdown menu under "Play", you can test the game without spawning a character so you can see them.'
		}
	],

	descriptions: [
		`Hop from orb to orb in the arena to gain points and avoid the alien explosions!`,
		`You’ve been abducted by aliens! In this game, players must avoid the exploding alien orbs to try and survive for as long as possible, with scores ticking up the longer you stay alive.`,
		`This Roblox course shows you how to add a score system and randomise events using loops, both of which are great features for any Roblox game. You will also learn about some game development concepts such as iteration.`
	],

	videos: [
		{
			name: 'Alien Attack',
			// Total XP: 850
			chapters: [
				{
					name: 'Explosions',
					description: 'The code in this chapter makes all of the alien orbs explode at once.',
					secondsOffset: 100,
					badges: ['classes-1']
				},
				{
					name: 'Loops',
					description: 'The code in this chapter makes the orbs explode over and over.',
					secondsOffset: 258,
					badges: ['time-1', 'loops-1']
				},
				{
					name: 'Unpredictability',
					description: 'The code in this chapter makes the individual orbs explode at random.',
					secondsOffset: 441,
					badges: ['conditions-1', 'random-1']
				},
				{
					name: 'Warnings',
					description:
						'The code in this chapter turns the orbs red before they explode, then back to blue afterwards.',
					secondsOffset: 724,
					badges: ['presentation-1']
				},
				{
					name: 'Starting score',
					description: 'The code in this chapter adds a score system to the game.',
					secondsOffset: 945,
					badges: ['ui-1']
				},
				{
					name: 'Points',
					description: 'The code in this chapter awards points to players over time.',
					secondsOffset: 1103,
					badges: ['loops-1', 'variables-2']
				},
				{
					name: 'Losers',
					description:
						'The code in this chapter checks to see if a player is alive before awarding points.',
					secondsOffset: 1345,
					badges: ['variables-1', 'classes-2']
				},
				{
					name: 'Highscore',
					description: 'The code in this chapter keeps track of the highest score.',
					secondsOffset: 1515,
					badges: ['variables-2', 'booleans-2']
				}
			]
		}
	]
};
