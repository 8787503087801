'use strict';

import _ from 'lodash';

const CKError = (module.exports = function(options) {
	if (_.isString(options)) {
		options = {
			message: options
		};
	}

	options = _.defaults(options || {}, {
		// Soft errors are not logged to Sentry
		// They are generally assumed to be user mistakes such as a bad login
		soft: false,

		// Tags can assign extra information to the error which can be used
		// when formatting the message and are also logged to Sentry
		tags: undefined,

		// The message can be a string or a function which returns a string
		// This allows it to be formatted using data from tags etc.
		message: undefined,

		// The name is fixed for a particular type of Error
		name: 'CKError'
	});

	if (typeof options.message === 'function') {
		try {
			options.message = options.message.call(options);
		} catch (e) {
			CK.logger.error('Error creating message for', options.name, e);
		}
	}

	if (options.name === 'CKError' && options.message) {
		options.name = options.message;
	}

	var e = Error.call(this, options.message || options.name);
	this.stack = e.stack;

	this.name = options.name;
	this.message = options.message || options.name;
	this.soft = options.soft;
	this.testSoft = options.testSoft;
	this.silent = options.silent;
	this.tags = options.tags;

	if (options.stack) {
		this.stack = options.stack;
	}
});

CKError.prototype = Object.create(Error.prototype);
CKError.constructor = CKError;

CKError.prototype.serialize = function() {
	return {
		name: this.name,
		message: this.message,
		tags: this.tags
	};
};
