'use strict';

const products = require('../../data/products');
import { LogSource } from '../../classes/LogSource';

module.exports = {
	showRobloxConversionCtas: function() {
		return !this.isPayingRobloxUser() && !this.isCampUser() && !this.isCampSuperuser();
	},

	isPayingRobloxUser: function() {
		return this.hasActivePlanForProduct(products.ROBLOX);
	},

	canUseRoblox: function() {
		return !this.robloxDenyStatus();
	},

	robloxDenyStatus: function() {
		const logger = new LogSource(() => ({
			subjects: ['verifyEditorAccess']
		}));

		const hasRole =
			this.roles().$has({
				name: require('../../data/roles/bundleRole').name
			}) ||
			this.roles().$has({
				name: require('../../data/roles/robloxRole').name
			});

		logger.trace('[verifyEditorAccess] checking obvious cases where there is no deny status');

		if (hasRole) {
			return;
		}

		if (this.hasActivePlanForProduct(products.ROBLOX)) {
			return;
		}

		if (this.isCampSuperuser() || this.isCampAdmin() || this.isCampLeader()) {
			return;
		}

		const campDenyStatus = this.campStudentDenyStatus();

		if (campDenyStatus || campDenyStatus === null) {
			return campDenyStatus;
		}
	}
};
