'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'dance-to-the-twoosic',
	name: 'Dance to the Twoosic',
	duration: 40,
	secondsDuration: 367,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Spawn a flashing dance floor to show off your moves.',
		'Enjoy Dance to the Moosic? Welcome to Dance to the Twoosic! The flashiest sequel so far.',
		'Create a dance floor structure that can easily fit with the code for Dance to the Moosic.'
	],
	difficulty: 'Hard',
	unlockXp: 38000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Dance To The Twoosic',
			chapters: [
				{
					name: 'Grooovy Moooves',
					description: 'Create a flashing dance floor to show off your moooves.',
					secondsOffset: 67,
					badges: []
				}
			]
		}
	]
};
