'use strict';

import './NotificationBar.less';

import React from 'react';
import PropTypes from 'prop-types';

export default class NotificationBar extends React.Component {
	render() {
		return (
			<div className="NotificationBar">
				{this.props.text}

				{this.props.onAccept && <button onClick={this.props.onAccept}>✅</button>}
				{this.props.onDecline && <button onClick={this.props.onDecline}>❌</button>}
			</div>
		);
	}
}

NotificationBar.propTypes = {
	text: PropTypes.string.isRequired,
	onAccept: PropTypes.func,
	onDecline: PropTypes.func
};
