'use strict';

import _ from 'lodash';

const startCodingClass = require('ck-core-client/source/data/startCodingClass');

const startCodingClasses = ['userTool', startCodingClass];

module.exports = _.extend(
	{
		START_CODING: {
			name: 'Start coding',
			classes: startCodingClasses,
			modal: require('../ui/StartCodingModal')
		},
		START_CODING_BILLING_ONLY: {
			name: 'Start coding',
			href:
				'/account/' +
				CK.fn.createQueryString({
					message: 'Choose an account below to start coding.',
					startCoding: true,
					panel: 'family'
				}),
			classes: startCodingClasses
		}
	},
	require('ck-core-client/source/data/MenuLinks')
);
