'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'building',
	name: "Minecraft Builder's Tools",
	projectNamePrefix: 'building',
	duration: 240,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	cues: [
		{
			name: 'urgentMessage',
			time: 422,
			lookAhead: true,
			message:
				'In the switch statement you need to use the string "WOODEN_PICKAXE" not "WOOD_PICKAXE"'
		},
		{
			name: 'urgentMessage',
			time: 694,
			lookAhead: true,
			message:
				'In the switch statement you need to use the string "WOODEN_SHOVEL" not "WOOD_SPADE"'
		},
		{
			name: 'urgentMessage',
			time: 1113,
			lookAhead: true,
			message:
				'In the switch statement you need to use the string "CARROT_ON_A_STICK" not "CARROT_STICK"'
		},
		{
			name: 'urgentMessage',
			time: 3895,
			lookAhead: true,
			message:
				'In the switch statement you need to use the string "GOLDEN_SHOVEL" not "GOLD_SPADE"'
		},
	],

	descriptions: [
		"Create builder's tools to make your building powers greater than you ever thought possible!",
		'Want to be able to create your own spectacular Minecraft world, without having to spend 3 years building it? In this course you will create some handy tools that can provide you some great shortcuts so you can make buildings and structures twice as impressive, in half the time!',
		'Use mathematical calculations, offsets and axes to create building shortcuts such as duplicating buildings, copying mobs or deleting large areas of blocks.'
	],

	difficulty: 'Hard',
	unlockXp: 40000,

	videos: [
		{
			name: "Builder's Tools",
			chapters: [
				{
					name: 'Solid Foundations',
					description:
						"Set the player's game mode and set up the beginnings of our switch statement.",
					badges: ['switch-2', 'inventory-1', 'communication-1'],
					secondsOffset: 151
				},
				{
					name: `Brick 'n' Mix`,
					description: 'Add a new method to handle saving blocks.',
					badges: ['methods-1', 'fields-2'],
					secondsOffset: 471
				},
				{
					name: 'This Is Not A Drill',
					description: 'Add a new method that handles pasting blocks.',
					badges: ['switch-2', 'switch-3', 'general-1'],
					secondsOffset: 669
				},
				{
					name: 'Fauna And Fauna',
					description: 'Create a new method that handles mob spawning.',
					badges: ['fields-2', 'general-1', 'communication-2'],
					secondsOffset: 804
				},
				{
					name: 'Spade In Chelsea',
					description: 'Add a new method to handle pasting entities.',
					badges: ['switch-2', 'general-1', 'communication-2'],
					secondsOffset: 1073
				},
				{
					name: 'The Insulation Game',
					description: 'Make your entities spawn above ground.',
					badges: ['operators-2', 'variables-1'],
					secondsOffset: 1243
				},
				{
					name: 'Cut It Out',
					description: 'Add a new method to equip players with their builders tools.',
					badges: ['inventory-2', 'generalisation-2', 'communication-3'],
					secondsOffset: 1416
				},
				{
					name: 'Nail On The Head',
					description: 'Add in a default to control whether or not you can mine.',
					badges: ['switch-3', 'flow-1'],
					secondsOffset: 1666
				},
				{
					name: `Tool's Gold`,
					description: 'Add in a method for deleting areas.',
					badges: ['conditions-1', 'conditions-2', 'communication-2', 'methods-1'],
					secondsOffset: 1772
				},
				{
					name: 'Copying Clusters',
					description: 'Adding a new method to copy and paste an area of blocks.',
					badges: ['double-1', 'double-2', 'operators-3', 'loops-3', 'nesting-2', 'variables-2'],
					secondsOffset: 2256
				},
				{
					name: 'April Tools',
					description: 'Finish your copy and paste method.',
					badges: ['logic-2', 'variables-2', 'methods-2'],
					secondsOffset: 3073
				},
				{
					name: 'Raising The Roof',
					description: 'Make a tool in this chapter that adds extra floors to buildings.',
					badges: ['generalisation-3', 'operations-3', 'operations-2'],
					secondsOffset: 3477
				}
			]
		}
	]
};
