'use strict';

const time = (module.exports = {});

time.SECOND_MS = 1000;
time.MINUTE_MS = time.SECOND_MS * 60;
time.HOUR_MS = time.MINUTE_MS * 60;
time.DAY_MS = time.HOUR_MS * 24;
time.WEEK_MS = time.DAY_MS * 7;
time.YEAR_MS = time.DAY_MS * 365;
