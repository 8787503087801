'use strict';

import _ from 'lodash';

const ModuleContainer = require('./ModuleContainer');
const Extendable = require('./Extendable');

const ExtendableContainer = (module.exports = require('./Extendable').define({
	bind_all: function() {
		this._extendables().forEach(function(extendable) {
			extendable.bind();
		});
	},

	unbind_all: function() {
		this._extendables().forEach(function(extendable) {
			extendable.unbind();
		});
	},

	_extendables: function() {
		var list = [];

		_.each(Object.getOwnPropertyNames(this), name => {
			if (
				typeof name !== 'function' &&
				Extendable.isInstance(this[name]) &&
				!_.startsWith(name, ExtendableContainer.OWNER_PREFIX)
			) {
				if (this[name]._parent && this[name]._parent !== this) {
					// CK.logger.warn( 'Not including Extendable at field', name, 'because its parent does not match this' );
					return;
				}

				if (ModuleContainer.isInstance(this[name])) {
					throw new Error('_extendables contains ModuleContainer in ' + this.type + ' ' + name);
				}

				list.push(this[name]);
			}
		});

		return list;
	},

	cleanup_extendables: function() {
		var t = this;

		this._extendables().forEach(function(value) {
			value.cleanup();
			// TODO Kingdom review
			t[value.name] = null;
		});

		this._extendableList = null;
	},

	type: 'ExtendableContainer'
}));

module.exports.OWNER_PREFIX = '_';
