'use strict';

import _ from 'lodash';
import { STRIPE_STATUS } from 'ck-core/source/data/StripeStatus';

const subscriptionPlans = require('../../collections/subscriptionPlans');
const CKError = require('../../classes/Error');
const products = require('../../data/products');

module.exports = {
	shouldConvertAsAutoRegistered: function() {
		return this.autoRegistered() && this.name() && !this.hasHadAPlan() && !this.isLegacyPaidUser();
	},

	trackSubUserLink: function() {
		if (
			this.isCombinedBillingAndEditorUser() ||
			this.isLegacyPaidUser() ||
			this.hasLinkedASubUser() ||
			!this.subUsers().$size() ||
			_.every(this.subUsers().$(), subUser => !subUser.userId || subUser.linkPending)
		) {
			return;
		}

		const credit = this.getLatestCredit();

		if (credit) {
			const planId = credit.planId;

			if (planId) {
				let startDate = new Date();

				this.hasLinkedASubUser(true);

				this.credits(credit.id).startDate(startDate);
			}
		} else if (this.hasActiveSubscription()) {
			this.hasLinkedASubUser(true);
		}
	},

	hasHadAPlan: function() {
		return this.subscriptions().$size() + this.credits().$size() > 0;
	},

	getCurrentSubscription: function() {
		return this.getActiveOrPastDueSubscription() || this.getPendingSubscription();
	},

	hasActiveSubscription: function() {
		return !!this.getActiveSubscription();
	},

	getActiveSubscription: function(query) {
		var output = null;

		var iterator = sub => {
			if (sub.isActive()) {
				// Free subscriptions are de-prioritised
				if (!output || subscriptionPlans(output.planId()).free()) {
					output = sub;
				}
			}
		};

		if (query) {
			this.recurringSubscriptions()
				.$find(query)
				.$each(iterator);
		} else {
			this.recurringSubscriptions().$each(iterator);
		}

		return output;
	},

	getActiveOrPastDueSubscription: function() {
		var subscription =
			this.getActiveSubscription() ||
			this.recurringSubscriptions().$one({
				status: STRIPE_STATUS.PAST_DUE
			});

		if (subscription.$exists()) {
			return subscription;
		} else {
			return null;
		}
	},

	getNextInvoiceDate: function() {
		const subscriptions = _.filter(
			this.recurringSubscriptions().$(),
			sub =>
				!sub.cancel_at_period_end &&
				sub.status !== STRIPE_STATUS.CANCELLED &&
				sub.status !== STRIPE_STATUS.INCOMPLETE
		);

		const max = _.max(_.map(subscriptions, sub => sub.current_period_end));

		if (!max) {
			return null;
		}

		var maxPeriodEnd = moment(max);

		if (!maxPeriodEnd.isValid()) {
			return null;
		}

		return maxPeriodEnd.toDate();
	},

	isNoCardUser: function() {
		return (
			this.$exists() &&
			!this.validCardExpected() &&
			!this.hasActiveCredit() &&
			!this.isCampUser() &&
			this.subscriptions().$size() === 0
		);
	},

	isPastDue: function() {
		return (
			!this.hasActiveSubscription() &&
			_.includes(this.subscriptions().status(), STRIPE_STATUS.PAST_DUE)
		);
	},

	legacyOneOffSubscriptions: function() {
		return this.subscriptions().$filter(sub => {
			if (sub && sub.planId()) {
				return subscriptionPlans(sub.planId()).oneOff();
			}
		});
	},

	recurringSubscriptions: function() {
		return this.subscriptions().$filter(sub => {
			if (sub && sub.planId()) {
				return !subscriptionPlans(sub.planId()).oneOff();
			}
		});
	},

	getPendingSubscription: function() {
		if (!this.subscriptions().$size()) {
			return null;
		}

		const subscriptions = this.subscriptions()
			.$find({
				status: STRIPE_STATUS.TRIALLING,
				pendingWithTrial: true
			})
			.$sort('created', darc.Order.ASCENDING);

		if (subscriptions.$size() > 1) {
			CK.logger.warn(
				new CKError({
					message: 'User has multiple pending subscriptions',
					tags: {
						userId: this.id(),
						subscriptions: subscriptions.$()
					}
				})
			);
		}

		return subscriptions.$size() ? subscriptions.$eq(0) : null;
	},

	getLatestCredit: function() {
		let credits = _.reject(_.cloneDeep(this.credits().$()) || [], 'revoked');

		for (let credit of credits) {
			credit.endDate = this.credits(credit.id).endDate();
		}

		this.legacyOneOffSubscriptions().$each(sub => {
			// These have all lapsed and so can be safely ignored
			if (sub.promotion() || !sub.created() || sub.planId() === 'demo') {
				return;
			}

			credits.push({
				startDate: sub.created(),
				endDate: sub.endDate(),
				planId: sub.planId()
			});
		});

		// TODO CORE-1580: Is there even any point in the code up to line 256?
		const product = _.first(this.getActiveProducts(credits));

		if (product) {
			credits = _.filter(credits, credit => {
				const plan = subscriptionPlans(credit.planId);

				let isFamilyCredit = !!plan.family() || !!credit.extraChildren;

				return (
					plan &&
					plan.product() === product &&
					(this.linkedTo() || !!this.isFamilyUser() === isFamilyCredit)
				);
			});
		}

		return _.maxBy(credits, 'endDate');
	},

	getCurrentCredit: function() {
		const nonRevokedCredits = _.cloneDeep(_.reject(this.credits().$(), 'revoked'));

		for (const credit of nonRevokedCredits) {
			credit.endDate = this.credits(credit.id).endDate();
		}

		const currentCredits = _.filter(
			nonRevokedCredits,
			credit => credit.startDate < Date.now() && credit.endDate > Date.now()
		);
		return currentCredits.length > 0 ? _.maxBy(currentCredits, 'startDate') : null;
	},

	getCreditEndDate: function() {
		const latestCredit = this.getLatestCredit();
		return _.get(latestCredit, 'endDate', null);
	},

	getActiveCreditEndDate: function() {
		const date = this.getCreditEndDate();
		return date && date > new Date() ? date : null;
	},

	hasActiveCredit: function() {
		return !!this.getActiveCreditEndDate();
	},

	getCurrentPlanInfo: require('./getCurrentPlanInfo'),

	getCurrentPlanId: function() {
		const activeSubscription = this.getActiveSubscription();
		const latestCredit = this.getLatestCredit();
		const latestCreditPlanId = latestCredit ? latestCredit.planId : null;

		if (activeSubscription) {
			if (latestCreditPlanId && activeSubscription.pendingWithTrial()) {
				return latestCreditPlanId;
			} else {
				return activeSubscription.planId();
			}
		}

		return latestCreditPlanId;
	},

	getExpiryDate: function() {
		const activeSubscription = this.getActiveSubscription();

		if (activeSubscription && !activeSubscription.cancel_at_period_end()) {
			return null;
		}

		return (
			_.max(
				_.filter([
					activeSubscription && activeSubscription.current_period_end(),
					this.getCreditEndDate(),
					this.recurringSubscriptions()
						.$find({
							status: {
								$not: STRIPE_STATUS.INCOMPLETE
							}
						})
						.$sort('current_period_end', darc.Order.DESCENDING)
						.$eq(0)
						.current_period_end()
				])
			) || null
		);
	},

	isLead: function() {
		return !this.isCampUser() && !this.hasHadAPlan();
	},

	getTrialInfo: function() {
		var trialEndDate = this.getActiveSubscription().current_period_end();

		var trialDays = subscriptionPlans(this.getActiveSubscription().planId()).freeTrialDays();

		var trialEndsIn = moment(trialEndDate).diff(moment(), 'days');

		var trialDaysIn = trialDays - trialEndsIn;

		return {
			trialDaysIn,
			trialEndsIn
		};
	},

	isFamilyUser: function() {
		return this.extraChildren().$size() >= 1 || this.subUsers().$size() > 1;
	},

	hasLifetimePlan: function() {
		for (let purchase of this.activePurchases()) {
			if (!purchase.planId) {
				continue;
			}

			if (subscriptionPlans(purchase.planId).lifetime()) {
				return true;
			}
		}

		return false;
	},

	purchases: function() {
		return _.reject([].concat(this.subscriptions().$(), this.credits().$()), _.isNil);
	},

	activePurchases: function() {
		const purchases = [];

		this.subscriptions().$each(sub => {
			if (sub.isActive()) {
				purchases.push(sub.$());
			}
		});

		this.credits().$each(credit => {
			if (credit.endDate() > new Date() && !credit.revoked() && !credit.incomplete()) {
				purchases.push(credit.$());
			}
		});

		return purchases;
	},

	hasHadAPlanForProduct: function(product) {
		const allProducts = this.getActiveProducts(this.purchases());

		if (_.includes(allProducts, products.BUNDLE)) {
			return true;
		}

		return product && _.includes(allProducts, product);
	},

	hasActivePlanForProduct: function(product) {
		const activeProducts = this.getActiveProducts();

		if (_.includes(activeProducts, products.BUNDLE)) {
			return true;
		}

		return product && _.includes(activeProducts, product);
	},

	getLatestActivePurchase: function() {
		const purchases = this.activePurchases();

		return _.maxBy(purchases, 'created');
	},

	getLatestActiveProduct: function() {
		return this.getActiveProducts([this.getLatestActivePurchase()])[0];
	},

	getActiveProducts: function(purchases) {
		purchases = purchases || this.activePurchases();

		const activeProducts = _.uniq(
			_.compact(
				_.map(purchases, purchase => {
					if (!purchase || !purchase.planId) {
						return;
					}

					return subscriptionPlans(purchase.planId).product();
				})
			)
		).sort();

		return activeProducts;
	},

	currentPlanType: function() {
		const planSizes = require('../../data/planSizes');
		const planDurations = require('../../data/planDurations');

		let duration;

		const latestActivePurchase = this.getLatestActivePurchase();

		if (this.hasLifetimePlan()) {
			duration = planDurations.LIFETIME;
		} else if (latestActivePurchase) {
			const planId = latestActivePurchase.planId;

			if (planId) {
				const plan = subscriptionPlans(planId);

				duration = plan.durationKey();
			}
		}

		return _.omitBy(
			{
				product: this.getLatestActiveProduct(),
				size: this.isFamilyUser() ? planSizes.FAMILY : planSizes.INDIVIDUAL,
				duration
			},
			_.isNil
		);
	}
};
