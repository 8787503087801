'use strict';

const Errors = require('./Errors');
const SpaceManager = require('./../managers/SpaceManager');

module.exports = {
	verify: async function(session, server) {
		if (!session) {
			throw new Error('No session provided for verify');
		}
		let model,
			newSession,
			tries = 0,
			maxTries = 5;

		try {
			while (!model && tries < maxTries) {
				tries++;
				try {
					({ model, session: newSession } = await Promise.resolve(
						CK.clientSockets.users.emit(
							'verifySession',
							{
								session,
								server
							},
							{
								supersede: true,
								supersedeKey: session.id
							}
						)
					).timeout(30000));
				} catch (err) {
					if (err instanceof Errors.Superseded) {
						continue;
					}

					if (!(err instanceof Promise.TimeoutError)) {
						throw err;
					}
					CK.logger.warn(err);

					await Promise.delay(500);
				}
			}

			if (!model) {
				throw new Error('Could not verify session after maxTries');
			}

			this.addUserModel(model);

			return newSession;
		} catch (err) {
			if (err instanceof Errors.Superseded) {
				return;
			}

			throw err;
		}
	},

	addUserModel: function(model) {
		SpaceManager.mod().addModelToRegister('users/' + model.id, model);
	}
};
