import moment from 'moment';

export function parseIsoDate(str: string | Date): Date {
	if (str instanceof Date) {
		return str;
	}

	const m = moment(str, moment.ISO_8601);
	if (!m.isValid()) {
		throw new Errors.BadRequest({
			message: 'Bad Date',
			tags: {
				dateString: str
			}
		});
	}

	return m.toDate();
}

parseIsoDate.verifierReplaces = true;
