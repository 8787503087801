import _ from 'lodash';
import { randomString } from 'ck-core/source/functions/randomString';
import { generateTrackingData } from 'ck-core/source/functions/trackingData';
import { trackFacebookPixel } from '../trackers/Facebook';
import { trackTwitterPixel } from '../trackers/Twitter';
import { trackAdwordsConversion } from '../trackers/Adwords';

const CKError = require('ck-core/source/classes/Error');
const CookieManager = require('../managers/CookieManager');
const trackingCookieName = require('../data/trackingCookieName');
const persistentTrackingCookieName = require('../data/persistentTrackingCookieName');
const genericPromo = require('ck-website/source/data/genericPromo');
const addHours = require('date-fns/add_hours');
const addMinutes = require('date-fns/add_minutes');
const promoCountdownCookieId = 'promoCountdown';

_.extend(CK.fn, {
	inTest: function(testId, abTests) {
		abTests = abTests || require('ck-core/source/collections/ABTests');

		const abTest = abTests(testId);

		if (!abTest.$exists()) {
			throw new CKError({
				message: 'ABTest does not exist',
				tags: {
					testId
				}
			});
		}

		if (CK.user && CK.user.$exists()) {
			return CK.user.inTest(testId);
		}

		const anonymousStatus = _.get(CK, `trackingData.testCohorts.${testId}.control`);

		return anonymousStatus === undefined ? anonymousStatus : !anonymousStatus;
	},

	updateTrackingCookie: function(date, abTests, cookieManager) {
		date = date || new Date();

		cookieManager = cookieManager || CookieManager.mod();

		let persistentTrackingId = cookieManager.get(persistentTrackingCookieName);

		const originalPersistentTrackingId = persistentTrackingId;

		if (!persistentTrackingId) {
			persistentTrackingId = randomString(32);

			cookieManager.set(persistentTrackingCookieName, persistentTrackingId);

			CK.logger.debug('Initialised persistent ID', persistentTrackingId);
		}

		CK.persistentTrackingId = persistentTrackingId;

		let trackingData;
		const existingCookie = cookieManager.get(trackingCookieName);

		const defaultTrackingData = generateTrackingData();

		if (existingCookie) {
			trackingData = JSON.parse(existingCookie);
		}

		trackingData = _.defaults(trackingData || {}, defaultTrackingData);

		if (CK.config.forceTestSeed !== undefined) {
			trackingData.testSeed = CK.config.forceTestSeed;
			trackingData.testCohorts = {};
		}

		const isValidTestSeed = require('ck-core/source/functions/isValidTestSeed');

		if (!isValidTestSeed(trackingData.testSeed)) {
			CK.logger.warn('Tracking data test seed was invalid, replaced with a default value', {
				oldTestSeed: trackingData.testSeed
			});
			trackingData.testSeed = defaultTrackingData.testSeed;
		}

		const testCohortsToEnter = require('ck-core/source/functions/testCohortsToEnter')({
			abTests,
			testSeed: trackingData.testSeed,
			currentCohorts: trackingData.testCohorts,
			originalPersistentTrackingId
		});

		for (let id in testCohortsToEnter) {
			let cohort = testCohortsToEnter[id];

			cohort.dateEntered = date;
		}

		if (CK.config.forceTestSeed) {
			_.extend(trackingData.testCohorts, testCohortsToEnter);
		} else {
			_.defaults(trackingData.testCohorts, testCohortsToEnter);
		}

		let setCohort = (id, inTest) => {
			CK.logger.debug('🆎', 'Forcing user into AB cohort', {
				id,
				inTest
			});

			let existingCohortData = trackingData.testCohorts[id];

			if (existingCohortData) {
				existingCohortData.control = !inTest;
			} else {
				trackingData.testCohorts[id] = {
					id,
					dateEntered: new Date(),
					control: !inTest
				};
			}
		};

		if (CK.config.forceTestCohort) {
			setCohort(CK.config.forceTestCohort, true);
		}

		if (CK.config.forceControlCohort) {
			setCohort(CK.config.forceControlCohort, false);
		}

		CK.trackingData = trackingData;
		cookieManager.set(trackingCookieName, trackingData);

		// Asychronously improve cohort dateEntered with cookie dateStarted
		CK.fn.createDateStartedCookieIfMissing(cookieManager).then(serverDate => {
			if (!_.get(CK, 'trackingData.testCohorts')) {
				return;
			}

			for (let id in CK.trackingData.testCohorts) {
				CK.trackingData.testCohorts[id].dateEntered = serverDate;
			}

			cookieManager.set(trackingCookieName, CK.trackingData);
		});

		return trackingData;
	},

	createDateStartedCookieIfMissing: async function(cookieManager) {
		cookieManager = cookieManager || CookieManager.mod();

		var dateStarted;
		var existingCookie = cookieManager.get('dateStarted');

		if (existingCookie) {
			dateStarted = JSON.parse(existingCookie);

			dateStarted.date = new Date(dateStarted.date);

			CK.dateStarted = dateStarted;
		} else {
			const serverTime = await CK.clientSockets.users.emit('getServerTime');

			serverTime.date = new Date(serverTime.date);

			cookieManager.set('dateStarted', serverTime);

			CK.dateStarted = serverTime;
		}

		return CK.dateStarted.date;
	},

	removeTrackingCookie: function() {
		CookieManager.mod().remove(trackingCookieName);
		delete CK.trackingData;
	},

	removeDateStartedCookie: function() {
		CookieManager.mod().remove('dateStarted');
		delete CK.dateStarted;
	},

	updateCountdownCookie: function(date, cookieManager) {
		date = date ? new Date(date) : new Date();
		cookieManager = cookieManager || CookieManager.mod();

		const existingCookie = cookieManager.get(promoCountdownCookieId);

		if (existingCookie) {
			// Check the expiry and the flag
			let promoCountdown;

			try {
				promoCountdown = JSON.parse(existingCookie);
			} catch (e) {
				// Corrupt cookie - delete accordingly
				cookieManager.remove(promoCountdownCookieId);
				promoCountdown = null;
			}

			if (!promoCountdown || promoCountdown.fullyExpired) {
				return;
			}
			const endDate = new Date(promoCountdown.endDate);

			if (endDate < date) {
				if (promoCountdown.isSecondCountdown) {
					cookieManager.set(promoCountdownCookieId, {
						endDate,
						fullyExpired: true
					});
				} else {
					// Start second countdown
					let secondEndDate = new Date(date.setHours(date.getHours() + 10));

					secondEndDate = addMinutes(secondEndDate, 120 * Math.random());

					cookieManager.set(promoCountdownCookieId, {
						endDate: secondEndDate,
						isSecondCountdown: true
					});
				}
			}
		} else {
			let endDate = addHours(new Date(date), 20);

			endDate = addMinutes(endDate, 240 * Math.random());

			cookieManager.set(promoCountdownCookieId, {
				endDate
			});
		}
	},

	getActiveCountdownPromoCookie: function(cookieManager = CookieManager.mod()) {
		const cookie = cookieManager.get(promoCountdownCookieId);

		if (cookie) {
			let cookieData;

			try {
				cookieData = JSON.parse(cookie);
			} catch (e) {
				// Corrupt cookie - delete accordingly
				cookieManager.remove(promoCountdownCookieId);
				cookieData = null;
			}

			if (!cookieData || cookieData.fullyExpired) {
				return;
			}

			return {
				endDate: new Date(cookieData.endDate),
				isSecondCountdown: cookieData.isSecondCountdown
			};
		}
	},

	getQueryVariables: function(href) {
		let query;

		if (href) {
			let searchIndex = href.indexOf('?');

			if (searchIndex === -1) {
				return {};
			}

			query = href.substring(href.indexOf('?') + 1);

			let hashIndex = query.indexOf('#');

			if (hashIndex !== -1) {
				query = query.substring(0, hashIndex);
			}
		} else {
			query = window.location.search.substring(1);
		}

		var vars = query.split('&');

		var result = {};

		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');

			result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
		}
		return result;
	},

	getCoverage: function() {
		return {
			name: CK.config.name + '_' + Date.now() + '_' + randomString(),
			data: window.__coverage__
		};
	},

	camelCaseToHyphen: function(string) {
		string = string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

		string = string.replace(/[.,\/#!$%\^&\*;:'{}=\-_`~()]/g, '');
		return string.replace(/\s+/g, '-');
	},

	secondsToFormattedMinutesSeconds: function(seconds) {
		var secNum = parseInt(seconds, 10);
		// don't forget the second param
		var hours = Math.floor(secNum / 3600);

		var minutes = Math.floor((secNum - hours * 3600) / 60);
		seconds = secNum - hours * 3600 - minutes * 60;
		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		var time = minutes + ':' + seconds;

		return time;
	},

	checkForceTestCohort: async function() {
		if (CK.user.$exists()) {
			if (CK.config.forceTestCohort) {
				await CK.clientSockets.users.emit('forceTestCohort', {
					id: CK.config.forceTestCohort,
					inTest: true
				});
			}

			if (CK.config.forceControlCohort) {
				await CK.clientSockets.users.emit('forceTestCohort', {
					id: CK.config.forceControlCohort,
					inTest: false
				});
			}
		}
	},

	getNavHandler: function(path) {
		const Frame = require('../ui/Frame');
		if (Frame.mod() && Frame.mod().navigate) {
			return () => {
				Frame.mod().navigate(path);
			};
		}
	},

	isNonGenericPromo: function(promo) {
		return promo && genericPromo && promo.id !== genericPromo.id && promo.id !== 'coolmathgeneric';
	}
});
