'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'wizard-wonder',
	name: 'Wizard Wonder',
	duration: 40,
	secondsDuration: 614,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Learn how to make unbreakable items.',
		'Well you may not be a real wizard, but you can be a coding wizard, right?',
		'Try this mini mod to learn how to make a magic wand and a super powerful sword.'
	],
	difficulty: 'Easy',
	unlockXp: 11000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Wizard Wonder',
			chapters: [
				{
					name: 'Just Wave Your Wand',
					description: 'Learn how to create a magic wand that sends fireballs flying',
					secondsOffset: 39,
					badges: []
				},
				{
					name: 'Sword Game',
					description:
						"A normal sword? Hah! Not for us. In this chapter you'll create a sword with knockback 1000.",
					secondsOffset: 258,
					badges: []
				},
				{
					name: 'Unbreakable Items',
					description: 'This chapter shows you how to make unbreakable items.',
					secondsOffset: 437,
					badges: []
				}
			]
		}
	]
};
