'use strict';

import _ from 'lodash';

const Button = require('../ui/Button');
const UserManager = require('../managers/UserManager');
const ErrorMessageModal = require('../ui/modals/messageModals/ErrorMessageModal');
const Errors = require('ck-core/source/services/Errors');
const MessageModal = require('../ui/modals/MessageModal');
const ModuleController = require('../system/ModuleController');
const SubUserHasNoPasswordError = require('ck-core/source/errors/SubUserHasNoPasswordError');
const MultiplePossibleUsersError = require('ck-core/source/errors/MultiplePossibleUsersError');

const LoginController = (module.exports = ModuleController.define({
	type: 'LoginController',

	sendEmailLoginLink: async function(userId) {
		const loginRequest = userId
			? {
					userId
			  }
			: {
					email: this._parent.form.getValue().username
			  };

		try {
			await CK.clientSockets.users.emit('requestEmailLoginLink', loginRequest);

			MessageModal.create({
				title: require('./LoginControllerLang').LOGIN.LINK_SENT.TITLE,
				message: require('./LoginControllerLang').LOGIN.LINK_SENT.MESSAGE
			});
		} catch (err) {
			const users = _.get(err, 'tags.possibleUsers');

			if (users && users.length) {
				const list = $('<ul>');
				for (const user of users) {
					let { displayName, id } = user;

					list.append(
						$('<li>').append(
							$('<a>')
								.attr('href', '#retry:' + id)
								.append(displayName)
						)
					);
				}

				const title = require('./LoginControllerLang').LOGIN.TRY_ALTERNATIVE_TITLE;

				const message = $('<div class="wrapper">').append(
					$('<p>').append(require('./LoginControllerLang').LOGIN.TRY_ALTERNATIVE_USERNAME),
					$('<div class="scroll_list light">').append(list)
				);

				ErrorMessageModal.create({
					title,
					message,
					image: 'modal-warning',
					html: true,
					okButtonText: 'Cancel',
					actions: {
						retry: function(userId) {
							this.close();
							LoginController.sendEmailLoginLink(parseInt(userId, 10));
						}
					}
				});
			} else {
				ErrorMessageModal.create({
					title: require('./LoginControllerLang').LOGIN.LINK_ERROR.TITLE,
					message: require('./LoginControllerLang').LOGIN.LINK_ERROR.MESSAGE,
					image: 'modal-warning'
				});
			}

			return {
				status: Button.STATUS.RETRY
			};
		}
	},

	login: async function(staff) {
		var data = this._parent.form.getValue();

		try {
			const request = {};

			if (staff) {
				const StaffModeManager = require('../managers/StaffModeManager');

				const staffModeManager = StaffModeManager.mod();

				if (staffModeManager) {
					request.staffSession = staffModeManager.getSession();
				}
			}

			return await LoginController.authenticate(
				_.trim(data.username || data.email),
				data.password,
				request
			);
		} catch (err) {
			return {
				status: Button.STATUS.RETRY
			};
		}
	},

	forgot: function() {
		var parent = this._parent;

		var frame = parent._parent;

		frame.navigate('/forgot-password/');
	},

	reset: function(userId) {
		const parent = this._parent;

		const request = {
			emailOrName: parent.form.getValue().emailOrName,
			userId
		};

		const t = this;

		return CK.clientSockets.users
			.emit('sendPasswordResetEmail', request)
			.then(function() {
				MessageModal.create({
					title: require('./LoginControllerLang').RESET_PASSWORD.CHECK_EMAIL.TITLE,
					message: require('./LoginControllerLang').RESET_PASSWORD.CHECK_EMAIL.MESSAGE
				});

				return {
					message: require('./LoginControllerLang').RESET_PASSWORD.BUTTON,
					status: Button.STATUS.SUCCESS
				};
			})
			.catch(err => {
				if (err instanceof Errors.NoEmailForAccount) {
					ErrorMessageModal.create({
						title: require('./LoginControllerLang').RESET_PASSWORD.FAILED_NO_EMAIL.TITLE,
						message: require('./LoginControllerLang').RESET_PASSWORD.FAILED_NO_EMAIL.MESSAGE,

						image: 'modal-warning'
					});
				} else if (err.name === MultiplePossibleUsersError.errorClassName) {
					const users = _.get(err, 'tags.possibleUsers');

					const list = $('<ul>');
					for (const user of users) {
						let { displayName, id } = user;

						list.append(
							$('<li>').append(
								$('<a>')
									.attr('href', '#retry:' + id)
									.append(displayName)
							)
						);
					}

					let title = require('./LoginControllerLang').RESET_PASSWORD.MULTIPLE_POSSIBLE.TITLE;

					let message = $('<div class="wrapper">').append(
						$('<p>').append(
							require('./LoginControllerLang').RESET_PASSWORD.MULTIPLE_POSSIBLE.MESSAGE
						),
						$('<div class="scroll_list light">').append(list)
					);

					ErrorMessageModal.create({
						title,
						message,
						image: 'modal-warning',
						html: true,
						okButtonText: 'Cancel',
						actions: {
							retry: function(userId) {
								this.close();
								t.reset(parseInt(userId, 10));
							}
						}
					});
				} else {
					ErrorMessageModal.create({
						title: require('./LoginControllerLang').RESET_PASSWORD.FAILED.TITLE,
						message: require('./LoginControllerLang').RESET_PASSWORD.FAILED.MESSAGE,
						image: 'modal-warning'
					});
				}

				return {
					status: Button.STATUS.RETRY
				};
			});
	}
}));

LoginController.showServerError = err => {
	ErrorMessageModal.create({
		title: require('./LoginControllerLang').LOGIN.FAILED,
		message: require('./LoginControllerLang').LOGIN.SERVER_ERROR,
		image: 'modal-warning'
	});

	CK.logger.critical(err);
};

LoginController.authenticate = async function(emailOrName, password, request) {
	try {
		return await UserManager.mod().authenticate(
			_.defaults({}, request || {}, {
				emailOrName,
				password
			})
		);
	} catch (err) {
		if (
			err instanceof Errors.BadUserOrPassword ||
			err instanceof Errors.GoogleAuthEmailNotVerified ||
			err instanceof Errors.FacebookEmailNotGranted ||
			err instanceof Errors.NativeAuthenticationUnavailable ||
			err instanceof Errors.FacebookSignonHasNoEmail ||
			err.name === SubUserHasNoPasswordError.errorClassName
		) {
			CK.logger.log(err);

			let title = require('./LoginControllerLang').LOGIN.FAILED;
			let message = require('./LoginControllerLang').LOGIN.ERRORS[err.name] || err.message;

			const users = _.get(err, 'tags.possibleUsers');

			if (users && users.length) {
				const list = $('<ul>');
				for (const user of users) {
					let { displayName, id } = user;

					list.append(
						$('<li>').append(
							$('<a>')
								.attr('href', '#retry:' + id)
								.append(displayName)
						)
					);
				}

				title = require('./LoginControllerLang').LOGIN.TRY_ALTERNATIVE_TITLE;

				message = $('<div class="wrapper">').append(
					$('<p>').append(require('./LoginControllerLang').LOGIN.TRY_ALTERNATIVE_USERNAME),
					$('<div class="scroll_list light">').append(list)
				);
			}

			ErrorMessageModal.create({
				title,
				message,
				image: 'modal-warning',
				html: true,
				okButtonText: 'Cancel',
				actions: {
					retry: function(userId) {
						this.close();
						LoginController.authenticate(
							null,
							password,
							_.defaults(
								{
									userId: parseInt(userId, 10)
								},
								request
							)
						);
					}
				}
			});

			CK.trackAnalytic('LoginSoftFailure');
		} else {
			LoginController.showServerError(err);
		}

		throw err;
	}
};

LoginController.simpleAuthenticate = async function(email, code) {
	try {
		return await UserManager.mod().simpleAuthenticate({
			email,
			code
		});
	} catch (err) {
		if (err instanceof Errors.InvalidSimpleAuthAttempt) {
			ErrorMessageModal.create({
				title: require('./LoginControllerLang').LOGIN.FAILED,
				message: require('./LoginControllerLang').LOGIN.INVALID_SIMPLE_AUTH,
				image: 'modal-warning',
				html: true
			});
		} else {
			LoginController.showServerError(err);
		}
	}
};
