'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'player-v-player',
	name: 'Player V Player',
	duration: 40,
	secondsDuration: 532,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Want to improve your PVP games? This is the place to be.',
		'Create team armour and make kits for your players to use!',
		'Learn how to create extra features such as team armour that work in any PVP mini game.'
	],
	difficulty: 'Easy',
	unlockXp: 13000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Player-v-player',
			chapters: [
				{
					name: 'Dressed To Impress',
					description: "In this chapter you'll make team armour!",
					secondsOffset: 14,
					badges: []
				},
				{
					name: 'All Kitted Out',
					description: 'Make kits for your players using a custom command.',
					secondsOffset: 207,
					badges: []
				}
			]
		}
	]
};
