import { CK_ENV } from 'ck-core/source/data/ckEnv';
import { facebookInit } from './FacebookInit';
import { ObjectOf } from 'ck-core/source/types/ObjectOf';

const PIXEL_ID = '1249154151764897';

export enum FACEBOOK_STANDARD_EVENT {
	ADD_PAYMENT_INFO = 'AddPaymentInfo',
	ADD_TO_CART = 'AddToCart',
	ADD_TO_WISHLIST = 'AddToWishlist',
	COMPLETE_REGISTRATION = 'CompleteRegistration',
	INITIATE_CHECKOUT = 'InitiateCheckout',
	LEAD = 'Lead',
	PURCHASE = 'Purchase',
	SEARCH = 'Search',
	VIEW_CONTENT = 'ViewContent'
}

export enum FACEBOOK_CUSTOM_EVENT {
	ADD_CARD_DETAILS = 'AddCardDetails',
	VIEW_CARD_FORM = 'ViewCardForm',
	SELECT_PLAN = 'SelectPlan',
	SELECT_PLAN_SIZE = 'SelectPlanSize',
	VIEW_PLANS = 'ViewPlans',
	JOIN_SERVER = 'JoinServer',
	RUN_BUTTON_FIRST_SUCCESS = 'RunButtonFirstSuccess',
	STUDIO_FIRST_CONNECTED = 'StudioFirstConnected',
	CREATE_PROJECT = 'CreateProject',
	PROJECT_PROGRESS_UPDATED = 'ProjectProgressUpdated',
	COMPLETED_ONBOARDING = 'CompletedOnboarding',
	NON_ADWORDS_VIEW_CONTENT = 'NonAdwordsViewContent'
}

export type FACEBOOK_EVENT = FACEBOOK_STANDARD_EVENT | FACEBOOK_CUSTOM_EVENT;

export function trackFacebookPixel(
	name: FACEBOOK_EVENT,
	options?: ObjectOf<any>,
	eventMetadata?: any
) {
	const fbq = facebookInit(PIXEL_ID);

	// Stub in tests
	if (typeof window !== 'undefined' && CK.facebookTracks) {
		CK.facebookTracks.push({
			name,
			options
		});
	}

	if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
		CK.logger.debug(
			'🔇 Not posting facebookPixelTrack as not on production or test',
			name,
			options
		);
		return;
	}

	if (CK.isCoolMath()) {
		CK.logger.debug(
			'🔇 Not posting facebookPixelTrack as on Coolmath not Code Kingdoms',
			name,
			options
		);
		return;
	}

	try {
		options = options || {};

		if (fbq) {
			if (name === 'Purchase' && options) {
				fbq('init', PIXEL_ID, {});
			}

			if (Object.values(FACEBOOK_STANDARD_EVENT).includes(name)) {
				fbq('track', name, options, eventMetadata);
			} else {
				fbq('trackCustom', name, options, eventMetadata);
			}
		}
	} catch (err) {
		CK.logger.error(err);
	}
}
