'use strict';

import _ from 'lodash';

import './RoundButton.less';

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCKImage from './ReactCKImage';

export default class RoundButton extends React.Component {
	render() {
		let className = classnames(
			'RoundButton',
			this.props.colour,
			this.props.size,
			this.props.hoverAnimation,
			this.props.buttonClass,
			_.pick(this.props, ['pending', 'disabled', 'error'])
		);

		return (
			<button
				ref={e => (this.dom = e)}
				type="button"
				disabled={this.props.disabled}
				className={className}
				onClick={this.props.handleClick}
				id={this.props.buttonId}
			>
				{!this.props.pending && this.props.icon && <ReactCKImage name={this.props.icon} />}
				{!this.props.pending && this.props.text}
				{this.props.pending && (
					<ReactCKImage
						name="loading-wheel"
						options={{ ignoreDimensions: true }}
						extraClasses={['buttonLoadingWheel']}
					/>
				)}
			</button>
		);
	}
}

RoundButton.defaultProps = {
	colour: 'blue',
	size: 'medium'
};

RoundButton.propTypes = {
	text: PropTypes.string.isRequired,
	colour: PropTypes.string,
	size: PropTypes.string,
	buttonClass: PropTypes.string,
	// TODO CORE-585
	handleClick: PropTypes.func,
	disabled: PropTypes.bool,
	pending: PropTypes.bool,
	error: PropTypes.bool,
	icon: PropTypes.string,
	buttonId: PropTypes.string,
	hoverAnimation: PropTypes.string
};
