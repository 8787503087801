'use strict';

import _ from 'lodash';

_.extend(CK.const, {
	BOOTSTRAP: {
		XS_DEVICE_MAX_WIDTH: 768
	},

	WEBSITE_FOOTER: 'flow-footer-sample'
});
