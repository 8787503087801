import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';
import { STRIPE_STATUS } from 'ck-core/source/data/StripeStatus';

const subscriptionPlans = require('../../collections/subscriptionPlans');
const verticals = require('../../data/verticals');
const products = require('../../data/products');
const difficulty = require('../../data/courseDifficulties').NAMES;

module.exports = {
	showConversionCtas: function(product) {
		if (product === products.MINECRAFT) {
			return this.showMinecraftConversionCtas();
		} else if (product === products.ROBLOX) {
			return this.showRobloxConversionCtas();
		}
	},

	// Returns undefined if the camp cannot be checked (usually server-side code)
	hasReadOnlyAccess: function(product) {
		const camp = CK.moduleContainer.module('Camp');

		if (!camp) {
			return;
		}

		return !!camp
			.groupForStudentUser(this)
			.settings()
			[product]()
			.studentsReadOnlyOnLeave();
	},

	canSwitchBrands: function() {
		return (
			!this.isFreeCampUser() ||
			_.some(
				[
					CK.const.ROLES.CAMP_ADMIN.name,
					CK.const.ROLES.CAMP_LEADER.name,
					CK.const.ROLES.CAMP_STUDENT.name
				],
				name => _.get(this.roles(name).$(), 'properties.canSwitchBrands')
			)
		);
	},

	isTrialling: function() {
		let subscription = this.getActiveSubscription({
			status: STRIPE_STATUS.TRIALLING
		});

		if (subscription && subscription.$exists()) {
			return subscription.isTrialling();
		}

		return false;
	},

	hasCancelledTrial: function() {
		return _.some(
			this.subscriptions().$(),
			sub => sub.cancel_at_period_end && subscriptionPlans(sub.planId).hasTrial()
		);
	},

	allowCreateCourse: function(courseId, vertical) {
		let courseLimit = this.getLimit(CK.const.LIMITS.COURSES, vertical);

		if (courseLimit && !_.includes(courseLimit, courseId)) {
			return false;
		}

		return true;
	},

	allowCreateWorld: function(worldId, vertical) {
		const verticals = require('../../data/verticals');

		if (vertical === verticals.ROBLOX) {
			// TODO: BLOX-433
			return true;
		}

		let worldLimit = this.getLimit(CK.const.LIMITS.WORLDS, vertical);

		if (worldLimit && !_.includes(worldLimit, worldId)) {
			return false;
		}

		return true;
	},

	campStudentDenyStatus: function() {
		let campDenyStatus;

		_.each(this.roles().$(), role => {
			if (role.name === CK.const.ROLES.CAMP_STUDENT.name) {
				campDenyStatus = require('../../data/editorDenyStatus').CAMP_NO_LICENCED_GROUPS;

				_.each(role.camps, camp => {
					if (campDenyStatus === null) {
						return false;
					}

					_.each(camp.groups, group => {
						if (group.endDate && group.endDate.getTime() > Date.now()) {
							campDenyStatus = null;
							return false;
						}
					});
				});

				return false;
			}
		});

		return campDenyStatus;
	},

	isFreeUser: function(vertical = verticals.MINECRAFT) {
		// This will return false for the case where they have been a free user and subsequently converted
		return (
			(vertical === verticals.MINECRAFT &&
				this.getMinecraftDenyStatus() === CK.const.MINECRAFT_DENY_STATUS.FREE) ||
			(vertical === verticals.ROBLOX && !this.isPayingRobloxUser())
		);
	},

	getLimit: function(limitName, vertical) {
		// TODO CORE-2053
		if (this.isActiveCampStudent()) {
			switch (limitName) {
				case CK.const.LIMITS.PROJECT_COUNT:
					return null;

				case CK.const.LIMITS.COURSES:
				case CK.const.LIMITS.WORLDS:
					var camp = CK.moduleContainer.module('Camp');
					if (camp) {
						const brandManager = CK.moduleContainer.module('BrandManager');
						let activity = camp
							.groupForStudentUser(CK.user)
							.settings()
							[brandManager.getProduct()]()
							.studentsActivity();

						if( activity === 'all' ) {
							return null;
						}

						const studentsActivities = camp
							.groupForStudentUser(CK.user)
							.settings()
							[brandManager.getProduct()]()
							.studentsActivities() || "";

						const activities = _.compact([...(studentsActivities.split(',')), activity]);

						if(activities.length === 0 ) {
							return null;
						}

						return activities;
					} else {
						return null;
					}
			}
		}

		if (this.hasCampLeaderRole()) {
			return null;
		}

		if (!this.isCampUser()) {
			const verticals = require('../../data/verticals');
			const products = require('../../data/products');

			if (vertical === verticals.ROBLOX && this.isPayingRobloxUser()) {
				return null;
			} else if (
				vertical === verticals.MINECRAFT &&
				this.hasActivePlanForProduct(products.MINECRAFT)
			) {
				return null;
			}
		}

		const denyStatus = this.getMinecraftDenyStatus();

		if (
			!(
				vertical === verticals.MINECRAFT &&
				denyStatus &&
				denyStatus !== CK.const.MINECRAFT_DENY_STATUS.FREE
			) &&
			!this.isFreeUser(vertical)
		) {
			return null;
		}

		let result = CK.const.LIMIT_VALUES[limitName];

		// TEST
		if (CK.config.environment.name !== CK_ENV.PRODUCTION && this.inTest('testFree')) {
			switch (limitName) {
				case CK.const.LIMITS.SERVER_TIME:
					return result;
			}
		}

		if (this.isTrialling()) {
			return null;
		}

		switch (limitName) {
			case CK.const.LIMITS.COURSES:
			case CK.const.LIMITS.WORLDS:
				var activities = require('../../collections/activities');
				return activities()
					.$find({
						difficulty: difficulty.Starter,
						active: true
					})
					.id();

			case CK.const.LIMITS.PROJECT_COUNT:
				return 1;

			case CK.const.LIMITS.SERVER_TIME:
				return null;
		}

		if (result === undefined) {
			CK.logger.warn("Attempting to use a limit which doesn't exist", limitName);
			return null;
		}

		return result;
	}
};
