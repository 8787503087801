'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'clean-slate',
	name: 'Clean Slate',
	duration: 90,
	secondsDuration: 2533,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 10,
	unlockXp: 2100,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/FadingTile',
			superclass: 'BaseObject'
		},
		{
			path: '/FinishPlate',
			superclass: 'BaseObject'
		},
		{
			path: '/Game',
			superclass: 'BaseObject'
		},
		{
			path: '/Player',
			superclass: 'BasePlayer'
		}
	],

	cues: [
		{
			name: 'message',
			time: 147,
			lookAhead: true,
			message:
				'Test out our completed Clean Slate course by visiting https://www.roblox.com/games/1747119309/Clean-Slate'
		}
	],
	descriptions: [
		`Learn how to write clean code so you can collaborate with others!`,
		`Make this Spleef-style game even better by cleaning and improving the structure of the code!`,
		`Try this course to get to grips with debugging and refactoring code.`
	],

	videos: [
		{
			name: 'Clean Slate',
			chapters: [
				{
					name: 'Clean, Mean, Coding Machine',
					description: "Work out what Mike's poorly written variables do, and rename them",
					secondsOffset: 113,
					badges: ['generalisation-1', 'fields-1']
				},
				{
					name: 'Slate Expectations',
					description: 'Split the huge OnTouched method into smaller ones',
					secondsOffset: 660,
					badges: ['decomposition-1', 'methods-1']
				},
				{
					name: "Hope These Titles Don't Get Slated",
					description: 'Make methods for adding and removing speed boosts',
					secondsOffset: 1149,
					badges: ['methods-2']
				},
				{
					name: 'The Slate Gatsby',
					description: 'Make a method which causes tiles to flash out instead of fading',
					secondsOffset: 1377,
					badges: ['methods-2', 'loops-3']
				},
				{
					name: 'Grapes of Math',
					description:
						'Use abstraction to make your code cleaner - add a calculation to work out how your tiles should flash',
					secondsOffset: 1744,
					badges: ['abstraction-1', 'scope-1']
				},
				{
					name: 'Clean Eyed Monster',
					description: 'Finish tidying up your code - there are still some bits we can improve!',
					secondsOffset: 2121,
					badges: ['scope-1', 'fields-1']
				}
			]
		}
	]
};
