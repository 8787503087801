'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'snowclones',
	name: 'Snowclones',
	duration: 80,
	secondsDuration: 1375,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 20,
	unlockXp: 650,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/FreezeButton',
			superclass: 'BaseLocalUi'
		},
		{
			path: '/ClearButton',
			superclass: 'BaseLocalUi'
		},
		{
			path: '/FreezePlayer',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/IceBlock',
			superclass: 'BaseLocalObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'Want to try out our completed Snowclones game? Try here: https://www.roblox.com/games/1889273146/Snowclones'
		}
	],

	descriptions: [
		`Freeze yourself as few times as possible to reach the top of this snowy puzzle platform game`,
		`Carefully make your way around the edge of a skyscraper without falling off, using your freeze power to navigate the various obstacles as you go. The aim of the game is to get to the top of the course using the smallest number of freezes.`,
		`This is a simple Roblox game which teaches basic Lua coding concepts and helps you get used to coding in the Code Editor.`
	],

	videos: [
		{
			// Total XP: 600
			name: 'Snowclones',
			chapters: [
				{
					name: 'Printing a message',
					description: 'This chapter teaches you how to run code inside a method.',
					secondsOffset: 165,
					badges: ['debugging-1', 'ui-2']
				},
				{
					name: "Player's name",
					description: 'This chapter shows you how to call a method inside another method.',
					secondsOffset: 342,
					badges: ['debugging-1']
				},
				{
					name: 'Ice block',
					description:
						'This chapter teaches you how to spawn a block of ice where the player is standing.',
					secondsOffset: 602,
					badges: ['workspace-1', 'templates-1']
				},
				{
					name: 'Clear the ice',
					description: 'The code in this chapter clears the ice when you press a button.',
					secondsOffset: 763,
					badges: ['loops-2']
				},
				{
					name: 'Scores',
					description: "This chapter shows you how to add a score system to track player's scores.",
					secondsOffset: 985,
					badges: ['ui-1', 'operators-1', 'variables-2']
				},
				{
					name: 'Freezing conditions',
					description:
						'The code in this chapter ensures blocks of ice can only be made if the player is alive.',
					secondsOffset: 1207,
					badges: ['booleans-1']
				}
			]
		}
	]
};
