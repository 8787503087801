'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'no-place-like-home',
	name: 'No Place Like Home',
	duration: 40,
	secondsDuration: 152,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Learn how to send your players home using a custom command.',
		"You don't have to be Dorothy or stuck in Oz to learn how to find your way home, just try this mini mod instead!",
		'Try this mini mod for an introduction to custom commands.'
	],
	difficulty: 'Easy',
	unlockXp: 5000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'No Place Like Home',
			chapters: [
				{
					name: "I Don't Think We're In Kansas Anymore",
					description: 'Learn how to send your players home using a custom command.',
					secondsOffset: 16,
					badges: []
				}
			]
		}
	]
};
