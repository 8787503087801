'use strict';

import _ from 'lodash';

import upgradeBanner from 'ck-website/graphics/promo/promo-upgrade.svg';

const Promo = require( 'ck-core-client/source/classes/Promo' ).default;

const menuLang = {
	intro: 'Our biggest savings ever',
	title: 'Black Friday Sale',
	button: 'Show me the deals'
};

const plansLang = {
	TITLE: "Massive deals in our Black Friday sale!",
	SUBTITLE: 'Learn to code Minecraft & Roblox, for ages 8-14'
};

const footerLang = menuLang;

const endOfFirstPhase = new Date( '2023-11-25T03:00:00Z' );
const endOfSecondPhase = new Date( '2023-11-27T03:00:00Z' );
const endOfThirdPhase = new Date( '2023-11-28T03:00:00Z' );
const endOfFourthPhase = new Date( '2023-12-04T03:00:00Z' );

module.exports = new Promo( {
	id: 'blackfriday',
	upgradeBanner: upgradeBanner,
	affiliate: 'blackfriday2023',
	phases: [ {
			startDate: new Date( '2023-11-04T00:00:00Z' ),
			lang: {
				menu: {
					intro: 'Our biggest savings ever',
					title: 'Early Black Friday sale',
					button: 'Show me the deals',
				},
				plans: plansLang,
				footer: _.extend( {}, footerLang, {
					intro: 'Early Black Friday sale - limited discounts available'
				} )
			},
			countdownEnd: endOfFirstPhase

		},
		{
			startDate: endOfFirstPhase,
			lang: {
				menu: menuLang,
				plans: plansLang,
				footer: _.extend( {}, footerLang, {
					intro: 'Our biggest savings ever this Black Friday weekend - grab a deal while you can!'
				} )
			},
			countdownEnd: endOfSecondPhase
		},
		{
			startDate: endOfSecondPhase,
			lang: {
				menu: menuLang,
				plans: plansLang,
				footer: _.extend( {}, footerLang, {
					intro: 'Extended for Cyber Monday - our biggest savings ever!'
				} )
			},
			countdownEnd: endOfThirdPhase
		},
		{
			startDate: endOfThirdPhase,
			lang: {
				menu: menuLang,
				plans: plansLang,
				footer: _.extend( {}, footerLang, {
					intro: 'Last chance to grab our biggest deals ever - Cyber Week final extension!'
				} )
			},
			countdownEnd: endOfFourthPhase,
			endDate: endOfFourthPhase
		}
	]
} );