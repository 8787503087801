'use strict';

import 'ck-core-web';

require('./source/globals/WebsiteFunctions');

CK.pageContexts.push(
	require.context('bundle?lazy&name=[name]!./source/pages', true, /Page\.(j|t)sx?$/)
);
CK.pagePreloads.push('CheckoutPage.jsx');

require('./lib/libraries/js/ouibounce.js');
require('./lib/libraries/css/ouibounce.min.css');
require('./lib/libraries/css/slick.css');
require('./lib/libraries/css/slick-theme.css');
require('react-table/react-table.css');
