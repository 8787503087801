'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'horror',
	name: 'The Haunted Mansion',
	duration: 120,
	secondsDuration: 4152,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Troll your friends with three spooky mods to scare them in a haunted house!',
		'Feeling scary wary and want things a little odd? Come on in and join the fun and make this little mod. It’s not one for the cowards, it’s a spooktacular affair! With zombies, traps and so much more, you’ll be a horror extraordinaire!',
		'Create three troll mods using more complex programming constructs including intervals and return types. Make a zombie track you, a leaky lava room and an infinite staircase in this spooky single player mod.'
	],

	difficulty: 'Medium',
	unlockXp: 25000,

	videos: [
		{
			name: 'Haunted Mansion',
			chapters: [
				{
					name: 'The Stair To Nowhere',
					description: 'Learn how to give players a sword, and create a field for a trap location.',
					badges: ['fields-1', 'parameters-1'],
					secondsOffset: 59
				},
				{
					name: 'Ghost Your Own Server',
					description:
						'This chapter shows you how to teleport players when they activate the stair trap.',
					badges: ['methods-2', 'conditions-1', 'booleans-2', 'operators-2'],
					secondsOffset: 461
				},
				{
					name: 'The People Under The Stairs',
					description: 'Learn how to clean your code to make your stair trap more reliable.',
					badges: ['logic-2', 'operators-2'],
					secondsOffset: 763
				},
				{
					name: 'Shut Your Trap',
					description: 'Use booleans for your if statement, and build an isNearLocation method.',
					badges: ['general-1', 'logic-2', 'variables-1'],
					secondsOffset: 1047
				},
				{
					name: 'Frying Tonight',
					description: 'This chapter shows you how to build a lava room trap.',
					badges: ['fields-2', 'decomposition-1', 'methods-2'],
					secondsOffset: 1472
				},
				{
					name: 'Firestarter',
					description: 'Add a time delay to your lava room trap.',
					badges: ['timer-2', 'debugging-1', 'loops-2', 'conditions-1'],
					secondsOffset: 1966
				},
				{
					name: `He's Behind You!`,
					description: 'Learn how to add zombies which spawn regularly.',
					badges: ['mob-1', 'debugging-1'],
					secondsOffset: 2316
				},
				{
					name: 'It Follows',
					description: 'In this chapter, make the zombies spawn behind players.',
					badges: ['operators-2', 'variables-1', 'variables-2'],
					secondsOffset: 2692
				},
				{
					name: 'The Enderzombie',
					description: 'Learn how to make the zombies freeze.',
					badges: ['timer-2', 'conditions-2', 'logic-1'],
					secondsOffset: 3082
				},
				{
					name: `It's Alive!`,
					description: 'Learn how to mke the zombies freeze whenever looked at.',
					badges: ['methods-2', 'mob-3'],
					secondsOffset: 3408
				},
				{
					name: 'The Final Chapter',
					description: 'This chapter shows you how to make the zombie turn invisible if looked at.',
					badges: ['operators-2', 'evaluation-1', 'mob-3'],
					secondsOffset: 3938
				}
			]
		}
	]
};
