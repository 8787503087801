'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'picnic-pinball',
	name: 'Picnic Pinball',
	duration: 120,
	secondsDuration: 1381,
	active: true,
	isCourse: true,
	shortCourse: false,
	position: 9,
	hasCampPdf: false,
	unlockXp: 3550,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/Object',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 9,
			lookAhead: true,
			message:
				'Test out our completed Picnic Pinball course by visiting https://www.roblox.com/games/2126253052/Picnic-Pinball'
		},
		{
			name: 'message',
			time: 10,
			lookAhead: true,
			message: "There's no coding in this course, so make the video fullscreen!"
		}
	],

	descriptions: [
		'Learn how to model objects using Roblox Studio!',
		"Using primitives, and Roblox's union and negate tools, create ramps so that you can take the pinball for a spin! Be careful of the food on the table.",
		'This course teaches how to model basic objects and models, using different parts, as well as tools such as union and negate. It also covers hit boxes, and how to use them, which allows different objects to interact with each other.'
	],

	videos: [
		{
			chapters: [
				{
					name: 'He was a sk8r boi',
					description: 'Build a ramp!',
					badges: [],
					secondsOffset: 128,
					noCodeChapter: true
				},
				{
					name: 'Lady and the Ramp',
					description: 'Customise ramps, and make more!',
					badges: [],
					secondsOffset: 402,
					noCodeChapter: true
				},
				{
					name: 'Firing on all Cylinders',
					description: 'Make a capsule, using parts!',
					badges: [],
					secondsOffset: 593,
					noCodeChapter: true
				},
				{
					name: 'Table Manners',
					description: 'Learn about, and use, hit boxes!',
					badges: [],
					secondsOffset: 932,
					noCodeChapter: true
				}
			]
		}
	]
};
