'use strict';

import _ from 'lodash';

// @deprecated
_.extend(CK.const, {
	ROLES: {
		LOGGED_IN: 'loggedIn',
		LOGGED_OUT: 'loggedOut',

		MINECRAFT_3: {
			name: 'minecraft3',
			properties: {
				maxPlayers: 3
			}
		},

		MINECRAFT_25: require('../data/roles/minecraft25Role'),

		MINECRAFT_DEMO_16: {
			name: 'minecraftDemo',
			properties: {
				maxPlayers: 16
			}
		},

		CAMP_STUDENT: {
			name: 'campStudent'
		},

		CAMP_LEADER: {
			name: 'campLeader',
			properties: {
				canUseMinecraft: true // N.B. this means they can use it regardless of subscription
			}
		},

		CAMP_ADMIN: {
			name: 'campAdmin',
			properties: {
				canUseMinecraft: true // N.B. this means they can use it regardless of subscription
			}
		},

		CAMP_SUPERUSER: {
			name: 'campSuperuser',
			properties: {
				canUseMinecraft: true // N.B. this means they can use it regardless of subscription
			}
		}
	}
});
