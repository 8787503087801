'use strict';

module.exports = {
	brands: [require('../../../../data/brands').MINECRAFT_JAVA],
	id: 'swoop-de-loop',
	name: 'Swoop de Loop',
	duration: 180,
	secondsDuration: 2719,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Learn how to create rings which give players a superboost when gliding.',
		'Swoop around the map using elytra and get a much needed boost every time you surge through a hoop! Learn how to code hoops that you can place in any world!',
		'Try this advanced course and learn how to create rings using mathematical knowledge which give players a super boost when they glide through the centre.'
	],

	difficulty: 'Hard',
	unlockXp: 36000,

	videos: [
		{
			name: 'Swoop de Loop',
			chapters: [
				{
					name: 'Push The Button',
					description:
						'This chapter is a simple first step which shows you how to make something happen when you press a button.',
					badges: ['communication-1', 'inventory-1', 'methods-1'],
					secondsOffset: 84
				},
				{
					name: 'Ring Of The Hill',
					description: 'This chapter shows you how to add conditionals to your code.',
					badges: ['nesting-1', 'conditions-1', 'booleans-2'],
					secondsOffset: 196
				},
				{
					name: 'Ring Me To Life',
					description: 'This chapter shows you how to give the players a speed boost.',
					badges: ['operators-2', 'methods-1', 'variables-1'],
					secondsOffset: 398
				},
				{
					name: 'Pretty Fly',
					description: 'This chapter teaches you how to regulate the speed boost.',
					badges: ['operators-3', 'inventory-1'],
					secondsOffset: 623
				},
				{
					name: 'You Rang?',
					description: 'This chapter teaches you how to add commands.',
					badges: ['commands-1', 'commands-2', 'methods-1'],
					secondsOffset: 817
				},
				{
					name: 'The Lion Ring',
					description: 'This chapter teaches you how to make a basic structure on command.',
					badges: ['loops-3', 'variables-1'],
					secondsOffset: 953
				},
				{
					name: 'Too Much Of A Good Ring',
					description: 'This chapter turns your line of blocks into a platform.',
					badges: ['loops-3', 'nesting-2'],
					secondsOffset: 1279
				},
				{
					name: 'Hooping The Colour',
					description: 'This chapter changes the shape of your structure so it is more rounded.',
					badges: ['operators-3', 'logic-2'],
					secondsOffset: 1389
				},
				{
					name: 'Chicken Noodle Swoop',
					description:
						'This chapter shows you how to change your structure to have two different block types.',
					badges: ['nesting-2', 'conditions-3', 'operators-2'],
					secondsOffset: 1746
				},
				{
					name: 'Turn A Blind Fly',
					description: 'This chapter shows you how to add arguments to your commands.',
					badges: ['command-1', 'command-3'],
					secondsOffset: 2075
				},
				{
					name: 'The Round Of Music',
					description: 'This chapter teaches you how to make your rings appear on a specific axis.',
					badges: ['variables-1', 'variables-2', 'generalisation-2', 'generalisation-3'],
					secondsOffset: 2307
				},
				{
					name: 'Nothing Is Certain But Death And Axis',
					description:
						'This chapter teaches you how to make your rings appear on either the X, Y or Z axis depending on which command you use.',
					badges: ['commands-3', 'booleans-3'],
					secondsOffset: 2506
				}
			]
		}
	]
};
