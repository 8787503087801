'use strict';

/**
 * @deprecated
 */
module.exports = {
	MONTH: '1-month',
	MONTHLY: 'monthly-recurring',
	THREE_MONTHS: '3-months',
	THREE_MONTHLY: '3-monthly-recurring',
	YEAR: '12-months',
	YEARLY: 'annually',
	LIFETIME: 'lifetime'
};
