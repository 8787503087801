'use strict';

import _ from 'lodash';

import upgradeBanner from 'ck-website/graphics/promo/promo-upgrade.svg';

const Promo = require( 'ck-core-client/source/classes/Promo' ).default;

const menuLang = {
	intro: 'Learn coding at home with Minecraft and Roblox',
	title: '1st Month Offer',
	button: 'Show me the deals'
};

const plansLang = {
	TITLE: "Choose your coding plan",
	SUBTITLE: 'Join others learning to code with their favorite games'
};

const footerLang = menuLang;

const endOfFirstPhase = new Date( '2022-11-01T03:00:00Z' );


module.exports = new Promo( {

	id: 'coolmathgeneric', // This id currently hides the menu bar as a genernicPromo flag
	upgradeBanner: upgradeBanner,
	affiliate: 'coolmathdiscountedprices',
	phases: [ {
			startDate: new Date( '2021-08-15T00:00:00Z' ),
			lang: {
				menu: menuLang,
				plans: plansLang,
				footer: _.extend( {}, footerLang, {
					intro: 'Learn coding from home for less - grab a deal today!'
				} )
			},
			countdownEnd: endOfFirstPhase,
			endDate: endOfFirstPhase
		},

	]
} );
