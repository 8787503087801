'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'publishing-pro',
	name: 'Publishing Pro',
	duration: 45,
	secondsDuration: 1309,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: false,
	hasCampPdf: false,
	unlockXp: 3400,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/Object',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 10,
			lookAhead: true,
			message: 'This course has no coding steps! Full screen the video to watch and follow along!'
		},
		{
			name: 'urgentMessage',
			time: 634,
			lookAhead: true,
			message: 'The way publishing games works in Roblox has changed. This part of the video is no longer up to date. For an up to date guide, please follow this link: https://faq.codekingdoms.com/en/articles/2439851-how-do-i-publish-my-game-to-roblox'
		}
	],

	descriptions: [
		`Publish a game on Roblox!`,
		`You're a pro game dev! In this course, you'll learn how to publish your own games.`,
		`This Roblox course shows you how to use Roblox's publishing tools.`
	],

	videos: [
		{
			name: 'Publishing',
			chapters: [
				{
					name: 'Nintendone',
					description: 'Go over the basics to make sure that your game is ready for publishing',
					secondsOffset: 52,
					badges: [],
					noCodeChapter: true
				},
				{
					name: 'Beware Before You Share',
					description: 'Make sure that your game fits in with the Roblox rules and guidelines',
					secondsOffset: 228,
					badges: [],
					noCodeChapter: true
				},
				{
					name: 'Finish Him',
					description: 'Publish your game!',
					secondsOffset: 585,
					badges: [],
					noCodeChapter: true
				},
				{
					name: 'Everything Is Awesome',
					description: 'Learn how to customise more advanced settings',
					secondsOffset: 1116,
					badges: [],
					noCodeChapter: true
				}
			]
		}
	]
};
