export const ALPHABET_LOWER = 'abcdefghijklmnopqrstuvwxyz';
export const ALPHABET_UPPER = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const NUMERIC = '0123456789';
export const ALPHANUMERIC_UPPER = ALPHABET_UPPER + NUMERIC;
export const ALPHANUMERIC_LOWER = ALPHABET_LOWER + NUMERIC;
export const ALPHANUMERIC = ALPHABET_LOWER + ALPHABET_UPPER + NUMERIC;

export function randomString(length?: number, alphabet?: string) {
	let str = '';
	length = length || 16;
	alphabet = alphabet || ALPHANUMERIC;
	for (var i = 0; i < length; i++) {
		const num = Math.floor(Math.random() * alphabet.length);
		str += alphabet.charAt(num);
	}
	return str;
}
