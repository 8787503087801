'use strict';

import _ from 'lodash';

const Promo = require('ck-core-client/source/classes/Promo').default;

const Time = require('ck-core/source/services/Time');

const menuLang = {
	intro: "Time's running out!",
	title: 'Sale now on',
	button: 'Show me the deals'
};

const plansLang = {
	TITLE: 'Sale now on!',
	SUBTITLE: "Time's running out to save on our memberships!"
};

const footerLang = menuLang;

module.exports = new Promo({
	id: 'generic',
	affiliate: 'genericpromo',
	phases: [
		{
			startDate: new Date(Date.now() - 3 * Time.DAY_MS),
			endDate: new Date(Date.now() - 2 * Time.DAY_MS), // Ensure this is in the past so that it is guaranteed not to be active
			lang: {
				menu: menuLang,
				plans: plansLang,
				footer: _.extend({}, footerLang, {
					intro: `Quick, grab a deal while you can!`
				})
			}
		}
	]
});
