'use strict';

const isAnonymousUser = require('../functions/entryPredicates/isAnonymousUser');
const isNewLead = require('../functions/entryPredicates/isNewLead');

module.exports = [
	{
		id: 'LandingPageFlowProcess',
		ranges: [
			[0, 0] // Only available when forced through URL arg
		],
		entryPredicate: isAnonymousUser
	},

	{
		id: 'LandingPageEducational',
		ranges: [
			[0, 0] // Only available when forced through URL arg
		],
		entryPredicate: isAnonymousUser
	},
	{
		id: 'ParentsLandingPageMobile',
		ranges: [
			[0, 0] // Only available when forced through URL arg
		],
		entryPredicate: isAnonymousUser
	},
	{
		id: 'CheaperThreeMonthRecurringPlan',
		ranges: [
			[0, 0] // Currently everyone goes into the control group
		],
		entryPredicate: isNewLead
	},

	{
		id: '12MonthCreditForTests',
		ranges: [
			[0, 0] // Only available when forced through URL arg
		]
	},

	{
		id: 'DirectToTrialFlow',
		ranges: [
			[0, 0] // Only available when forced through URL arg - for certain experimental ad sets on AdWords
		]
	},

	{
		id: 'EmailCaptureIncentive',
		ranges: [
			[0, 0] // Only available when forced through URL arg
		],
		entryPredicate: isAnonymousUser
	}
];
