'use strict';

import _ from 'lodash';

const Extendable = require('ck-core/source/system/Extendable');
const Frame = require('../ui/Frame');
const Scroller = require('../ui/scroller');

const Hyperlink = (module.exports = Extendable.define({
	init_HyperlinkBinder: function(controller, dom) {
		this._dom = dom;

		var t = this;

		Hyperlink.currentHits = Hyperlink.currentHits || [];

		var openExternalUrl = (url, target) => {
			if (_.includes(Hyperlink.currentHits, url)) {
				return;
			}

			if (target === '_blank') {
				const newWindow = window.open(url);

				if (newWindow) {
					newWindow.opener = null;
				}
			} else {
				window.location.href = url;
			}

			return false;
		};

		this.handleHref = function(href, target, event) {
			const isMailto = _.startsWith(href, 'mailto:');

			if (_.startsWith(href, 'http:') || _.startsWith(href, 'https:') || isMailto) {
				if (event && !t.hitCooldownPeriod(event.target)) {
					CK.logger.trace(
						'Ignoring tap due to cooldown period for external URL',
						event.target,
						Hyperlink.currentHits
					);
					return;
				}

				return openExternalUrl(href, target);
			}

			if (!_.startsWith(href, '#') && Frame.mod()) {
				if (event && !t.hitCooldownPeriod(event.target)) {
					CK.logger.trace(
						'Ignoring tap due to cooldown period',
						event.target,
						Hyperlink.currentHits
					);
					return;
				}

				Frame.mod().navigate(href);

				return;
			}

			return true;
		};

		this.handle = function(event) {
			const element = $(this);

			if (element.hasClass('ignoreBinder')) {
				CK.logger.trace('Not handling click for element which ignores binder');
				return;
			}

			const href = element.attr('href');

			if (!href || href === '#') {
				CK.logger.trace('Ignoring tap due to no href', event, element);

				return true;
			}

			event.preventDefault();

			Hyperlink.currentEvent = event;

			if (element.hasClass('disabled') || element.hasClass('pending')) {
				CK.logger.trace('Ignoring tap due to disabled or pending class', event, element);

				return;
			}

			if (Scroller.isScrolling) {
				CK.logger.trace('Ignoring tap while scrolling', event, element);

				return;
			}

			event.handledSelect = true;

			if (!t.handleHref(href, element.attr('target'), event)) {
				return;
			}

			const parts = href.substring(1).split(':');

			const field = parts.splice(0, 1)[0];

			if (parts[0] === '$') {
				parts[0] = element;
			}

			if (controller[field]) {
				if (!t.hitCooldownPeriod(event.target)) {
					CK.logger.trace(
						'Ignoring tap due to cooldown period at controller stage',
						event.target,
						element,
						Hyperlink.currentHits
					);

					return;
				}

				try {
					CK.logger.debug('Clicking hyperlink', {
						field
					});

					const result = controller[field].apply(controller[field].self || controller, parts);

					if (element.hasClass('asyncLink') && result && result.then) {
						element.addClass('pending');
						Promise.resolve(result).finally(() => {
							element.removeClass('pending');
						});
					}

					const model = $(this).data('model');

					if (model && model.onAction) {
						model.onAction(result);
					}
				} catch (err) {
					CK.logger.warn(err);
				}
			} else {
				const element = $(`#${field}`);

				if (element.length) {
					try {
						element[0].scrollIntoView();
					} catch (err) {
						CK.logger.log('scrollIntoView not supported', err);
					}
				}
			}
		};

		this.handleKey = function(e) {
			if (e.which === 13) {
				t.handle.call(this, e);
			}
		};
	},
	bind_hyperlinks: function(legacyDom) {
		CK.preventAnchorDefaults();
		// TODO: this._dom is preferred but legacy code will still call bind with e so currently needs to override this._dom here
		var dom = (this._legacyDom = legacyDom || this._dom);
		if (dom.attr('href')) {
			dom.on('tap click', this.handle);
		}

		dom.on('tap click', 'a', this.handle);

		dom.on('keydown', 'a', this.handleKey);

		dom.on('click', 'a', this.preventDefault);
	},
	unbind_hyperlinks: function(legacyDom) {
		this._legacyDom = null;

		var dom = legacyDom || this._dom;

		dom.off('tap click', this.handle);

		dom.off('tap click', 'a', this.handle);

		dom.off('click', 'a', this.preventDefault);

		dom.off('keydown', 'a', this.handleKey);
	},

	hitCooldownPeriod: function(hit) {
		const DELAY = 300;

		if (this.hot) return false;

		if (!hit) {
			// Disable all links temporarily

			this.hot = true;

			setTimeout(() => {
				this.hot = false;
			}, DELAY);

			return true;
		} else {
			if (_.includes(Hyperlink.currentHits, hit)) return false;

			Hyperlink.currentHits.push(hit);

			setTimeout(() => {
				var i = Hyperlink.currentHits.indexOf(hit);

				if (i > -1) {
					Hyperlink.currentHits.splice(i, 1);
				}
			}, DELAY);
			return true;
		}
	},

	preventDefault: function(e) {
		e.preventDefault();
	},
	cleanup_HyperlinkBinder: function() {
		if (this._legacyDom) this.unbind(this._legacyDom);
	},
	type: 'HyperlinkBinder'
}));
