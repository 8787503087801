'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'stranger-hill',
	name: 'Stranger Hill',
	duration: 60,
	secondsDuration: 2028,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 10,
	unlockXp: 400,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/SwapperPlayer',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/SwapperButton',
			superclass: 'BaseLocalUi'
		},
		{
			path: '/SpellBook',
			superclass: 'BaseLocalObject'
		}
	],

	cues: [
		{
			//TODO: Update this
			name: 'message',
			time: 125,
			lookAhead: true,
			message:
				'Test out our completed Stranger Hill course by visiting https://www.roblox.com/games/1582954751/Stranger-Hill'
		}
	],

	descriptions: [
		`Travel through time and space while learning to code with this dimension swapping Roblox game!`,
		`Teleport between two different realities to solve puzzles and collect items.`,
		`This course introduces the basics of using Roblox Studio tools to create your own games.`
	],

	videos: [
		{
			name: 'Stranger Hill',
			chapters: [
				{
					name: 'See You On The Otherside',
					description:
						'This chapter shows you how to program the swap button and teleport to a new world.',
					secondsOffset: 135,
					badges: ['methods-1', 'ui-2', 'operators-2']
				},
				{
					name: 'These Go Up To Eleven',
					description: 'This chapter teaches you how to swap back to reality.',
					secondsOffset: 659,
					badges: ['conditions-2', 'operators-2']
				},
				{
					name: 'Strange Things Are Happening To Me',
					description: 'This chapter introduces you to some basic Roblox Studio skills.',
					secondsOffset: 1009,
					badges: ['workspace-1', 'camera-1'],
					noCodeChapter: true
				},
				{
					name: 'People Are Strange',
					description: 'This chapter teaches you how to create an obstacle for your world.',
					secondsOffset: 1460,
					badges: ['workspace-1', 'presentation-1', 'physics-1'],
					noCodeChapter: true
				},
				{
					name: 'Phone Home',
					description: 'This chapter shows you the snapping tools in Roblox Studio',
					secondsOffset: 1742,
					badges: ['workspace-1', 'physics-1'],
					noCodeChapter: true
				}
			]
		}
	]
};
