'use strict';

import './index.less';

import React from 'react';
import PropTypes from 'prop-types';
import ReactCKImage from 'ck-core-client/source/ui/ReactCKImage';
import { PromoCountdown } from '../PromoCountdown';
import Frame from '../../ui/Frame';
import classnames from 'classnames';

interface Props {
	countdownEnd: Date;
}

interface State {
	hideBar: boolean;
	flipped: boolean;
}

export class GenericPromoHeader extends React.Component<Props, State> {
	state: State = {
		hideBar: false,
		flipped: false
	};

	constructor(props: Props) {
		super(props);

		setInterval(() => {
			this.setState({
				flipped: !this.state.flipped
			});
		}, 3000);
	}

	render() {
		const classname = this.state.hideBar ? 'GenericPromoHeader--hide' : 'GenericPromoHeader';
		const textWrapperClassname = this.state.flipped
			? 'GenericPromoHeader__contentWrapper__textWrapper--flipped'
			: '';
		return (
			<div className={classname}>
				<div
					className="GenericPromoHeader__contentWrapper"
					onClick={() => {
						Frame.mod().navigate('/plans/');
					}}
				>
					<div className="GenericPromoHeader__contentWrapper__mobileImage">
						<ReactCKImage name={'generic-promo-menu-mobile'} extraClasses={['img-responsive']} />
					</div>
					<div className="GenericPromoHeader__contentWrapper__leftImage">
						<ReactCKImage name={'generic-promo-menu-left'} extraClasses={['img-responsive']} />
					</div>
					<div
						className={classnames(
							'GenericPromoHeader__contentWrapper__textWrapper',
							textWrapperClassname
						)}
					>
						<div className="GenericPromoHeader__contentWrapper__textWrapper-front">
							<div className="GenericPromoHeader__contentWrapper__textWrapper__text">
								Save up to <b>50%</b> in our launch sale!
							</div>
							<div className="GenericPromoHeader__contentWrapper__textWrapper__textMobile">
								Save up to <b>50%</b>!
							</div>
						</div>
						<div className="GenericPromoHeader__contentWrapper__textWrapper-back">
							<div className="GenericPromoHeader__contentWrapper__textWrapper__countdown">
								<PromoCountdown countdownEnd={this.props.countdownEnd} compact={true} />
							</div>
						</div>
					</div>
					<div className="GenericPromoHeader__contentWrapper__rightImage">
						<ReactCKImage name={'generic-promo-menu-right'} extraClasses={['img-responsive']} />
					</div>
				</div>
				<div
					className="GenericPromoHeader__closeButton"
					onClick={() => {
						this.setState({ hideBar: true });
					}}
				>
					<ReactCKImage name={'generic-promo-menu-cross'} extraClasses={['img-responsive']} />
				</div>
			</div>
		);
	}
}
