'use strict';

const FileSchema = require('./FileSchema');
const pocketWorldSuffix = require('../../data/pocketWorldSuffix');

module.exports = {
	id: darc.types.primary(String),
	name: String,
	active: Boolean,

	description: String,
	tutorialId: String,

	// TODO: Maybe delete
	/* When making an addon a specific video from a course is selected */
	videoId: Number,

	worldId: String,

	isPocket: Boolean,

	// Set for all brands
	setForAllBrands: Boolean,

	// React-ui-specific keys
	skipsUsed: Number,

	/** @deprecated */
	secondsOffset: Number,

	// Prevent badge points from being awarded for video when they skip a step
	blockNextPointsAward: Boolean,

	// Always return a worldId for a built-in world (from the worlds collection)
	standardWorldId: function() {
		let worldId = CK.fn.canonicalWorldId(this.worldId() || 'default');

		if (worldId.endsWith(pocketWorldSuffix)) {
			worldId = worldId.replace(new RegExp(`${pocketWorldSuffix}$`), '');
		}

		if (worldId === 'blank' || worldId.startsWith(CK.const.CUSTOM_ASSET_ID_PREFIX)) {
			worldId = 'default';
		}

		return worldId;
	},

	dateCreated: Date,
	dateModified: Date,
	dateRun: Date,

	// For legacy projects we display a modal asking whether they want to fork the project when they open it
	deprecatedProductVersion: Number,

	// The version in which this project was created, as a migration from a previously deprecated project
	migratedProductVersion: Number,

	// If the project was created part-way through a course
	initialChapterOffset: Number,

	progress: {
		video: Number,
		step: Number
	},

	progressCount: Number,

	backgroundImage: String,

	files: [FileSchema],

	plugins: [
		{
			id: darc.types.primary(String)
		}
	],

	checkpointRevisionId: String,

	completedFilesForStep: function(videoId, step) {
		// Return a list of filenames of those files whose step exceeds or matches the step given in arguments

		var filesCompleted = [];
		this.files()
			.$find({
				active: {
					$not: false
				}
			})
			.$each(file => {
				if (file.progress().$exists()) {
					var fileVideo = file.progress().video();

					var fileStep = file.progress().step();

					if (fileVideo > videoId || (fileVideo === videoId && fileStep >= step)) {
						filesCompleted.push(file.name());
					}
				}
			});

		return filesCompleted;
	},

	currentOrLastRun: function() {
		let lastRunProject = this.$find({
			active: {
				$not: false
			}
		})
			.$sort('dateRun', darc.Order.DESCENDING)
			.$eq(0);

		// If they haven't run a project before use modify date instead
		if (!lastRunProject.dateRun()) {
			return this.$find({
				active: {
					$not: false
				}
			})
				.$sort('dateModified', darc.Order.DESCENDING)
				.$eq(0);
		}

		return lastRunProject;
	}
};
