'use strict';

import _ from 'lodash';

const subscriptionPlans = require('../../collections/subscriptionPlans');
const CKError = require('../../classes/Error');
const products = require('../../data/products');

module.exports = {
	isCampStudentWithoutPocket: function() {
		return this.isCampStudent() && !this.hasActivePlanForProduct(products.MINECRAFT);
	},

	showMinecraftConversionCtas: function() {
		return !this.isPayingMinecraftUser() && !this.isCampUser() && !this.isCampSuperuser();
	},

	isPayingMinecraftUser: function() {
		return this.hasActivePlanForProduct(products.MINECRAFT);
	},

	canUseMinecraft: function() {
		let denyStatus = this.getMinecraftDenyStatus();

		switch (denyStatus) {
			case require('../../data/editorDenyStatus').CAMP_NO_LICENCED_GROUPS:
				if (this.hasReadOnlyAccess(products.MINECRAFT) === false) {
					return false;
				} else {
					// No camp, probably server code, assume that they have readonly
					// access to allow provisioning of their server
					return true;
				}

			case CK.const.MINECRAFT_DENY_STATUS.FREE:
				// Server limit is checked during provision
				return true;
		}

		return !denyStatus;
	},

	getMinecraftDenyStatus: function() {
		// TODO: Remove this once we are sure nulls aren't in the roles of a user on master chief
		if (_.includes(this.roles().$(), null)) {
			CK.logger.error(
				new CKError({
					message: 'Null in the roles for a user',
					tags: {
						roles: this.roles().$()
					}
				})
			);
		}

		if (
			this.roles().$has({
				name: require('../../data/roles/bundleRole').name
			})
		) {
			return null;
		}

		let sub = this.getActiveSubscription();

		let latestCredit = this.getLatestCredit();

		if (sub && sub.isTrialling() && sub.cancel_at_period_end() && !latestCredit) {
			return CK.const.MINECRAFT_DENY_STATUS.CANCELLED_SUBSCRIPTION;
		}

		if (this.hasActivePlanForProduct(products.MINECRAFT)) {
			return;
		}

		if (_.some(this.roles().$(), role => _.get(role, 'properties.canUseMinecraft'))) {
			return;
		}

		const campDenyStatus = this.campStudentDenyStatus();

		if (campDenyStatus || campDenyStatus === null) {
			return campDenyStatus;
		}

		if (!this.hasHadAPlan() && !this.isCampUser()) {
			return CK.const.MINECRAFT_DENY_STATUS.FREE;
		}

		if (
			this.credits()
				.$find({
					revoked: true
				})
				.$size()
		) {
			return CK.const.MINECRAFT_DENY_STATUS.REVOKED;
		}

		if (this.isNoCardUser()) {
			return CK.const.MINECRAFT_DENY_STATUS.NO_CARD;
		}

		return CK.const.MINECRAFT_DENY_STATUS.NO_SUBSCRIPTION;
	},

	// Returns the role with the highest user maximum on a given user for use when provisioning servers
	getActiveServerRole: function() {
		const getMaxPlayerRoles = roles => {
			return _.filter(roles || [], role => {
				return role.properties && role.properties.maxPlayers;
			});
		};

		var serverRoles = getMaxPlayerRoles(this.roles().$());

		const activeSubscription = this.getActiveSubscription();

		if (activeSubscription) {
			const plan = subscriptionPlans(activeSubscription.planId());

			serverRoles = serverRoles.concat(getMaxPlayerRoles(plan.roles().$()));
		}

		if (this.hasActiveCredit()) {
			const latestCredit = this.getLatestCredit();

			const plan = subscriptionPlans(latestCredit.planId);

			serverRoles = serverRoles.concat(getMaxPlayerRoles(plan.roles().$()));
		}

		if (this.isCampUser()) {
			serverRoles.push(_.cloneDeep(CK.const.ROLES.MINECRAFT_25));
		}

		const activeRole = _.maxBy(serverRoles, 'properties.maxPlayers');

		if (!activeRole && this.canUseMinecraft()) {
			return _.cloneDeep(CK.const.ROLES.MINECRAFT_25);
		}

		return activeRole;
	},

	canSeeModOfTheMonths: function() {
		let brandManager = CK.moduleContainer.module('BrandManager');

		if (brandManager && brandManager.isPocket()) {
			return false;
		}

		// Must have more than 3 projects
		if (this.projects().$size() < 3) {
			return false;
		}

		// Must have been using CK for a week before it appears
		if (
			moment()
				.subtract(1, 'w')
				.isBefore(this.dateCreated())
		) {
			return false;
		}

		return true;
	},

	getServerRechargeDate: function() {
		const created = this.dateCreated();

		const weeksSince = moment().diff(created, 'weeks');

		return moment(created)
			.add(weeksSince + 1, 'weeks')
			.toDate();
	},

	getServerRechargePeriod: function() {
		return moment.duration(1, 'week');
	},

	allowCreateProject: function(vertical) {
		let projectCountLimit = this.getLimit(CK.const.LIMITS.PROJECT_COUNT, vertical);

		if (
			_.isNumber(projectCountLimit) &&
			this.getVertical(vertical)
				.projects()
				.$find({
					active: true
				})
				.$size() >= projectCountLimit
		) {
			return false;
		}

		return true;
	}
};
