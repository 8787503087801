'use strict';

const filesize = require('filesize');
const errors = (module.exports = {});

const addError = require('./addError').bind(null, errors);

addError({
	name: 'AbstractMethod'
});

addError({
	name: 'PaidSubscriptionAlreadyActive',
	soft: true
});

addError({
	name: 'CardDeclined',
	message: 'Card Declined',
	soft: true
});

addError({
	name: 'BraintreeDeclined',
	soft: true
});

addError({
	name: 'UnknownBraintreeDeclined',
	testSoft: true
});

addError({
	name: 'BraintreeRequestInvalid'
});

addError({
	name: 'PaymentFailed',
	message: 'An unknown error occurred when processing the payment, please try again later.'
});

addError({
	name: 'BadUserOrPassword',
	soft: true
});

addError({
	name: 'BadSubscriptionCode',
	soft: true
});

addError({
	name: 'EmailExists',
	message: 'This email is already in use, please choose another.',
	soft: true
});

addError({
	name: 'LimitedUser',
	message: 'This user is limited in the current action.',
	soft: true
});

addError({
	name: 'UsernameExists',
	soft: true
});

addError({
	name: 'NoUser'
});

addError({
	name: 'FileDoesNotExist'
});

addError({
	name: 'FileAlreadyExists'
});

addError({
	name: 'InvalidFilename',
	soft: true
});

addError({
	name: 'InvalidCoupon',
	message: 'Invalid Coupon',
	soft: true
});

addError({
	name: 'ProjectDoesNotExist'
});

addError({
	name: 'ProjectAlreadyExists'
});

addError({
	name: 'InvalidProjectName',
	soft: true
});

addError({
	name: 'InvalidSession'
});

addError({
	name: 'InvalidRegistration'
});

addError({
	name: 'InvalidAccountDetails'
});

addError({
	name: 'NoInternet',
	soft: true
});

addError({
	name: 'InvalidTrackingData'
});

addError({
	name: 'BadRequest',
	soft: true
});

addError({
	name: 'ServerError'
});

addError({
	name: 'SlowTransitionStep'
});

addError({
	name: 'WorldUploadTooLarge',
	message: `The world file must be smaller than ${filesize(CK.const.WORLD_UPLOAD_SIZE_MAX)}`
});

addError({
	name: 'WorldUploadFormatIncorrect',
	message: 'The world file must be a ZIP.'
});

addError({
	name: 'WorldArchiveLayoutInvalid',
	message:
		'The world archive is not in a recognisable format, please update it so that all world folders (containing level.dat files) are at the base of the archive.'
});

addError({
	name: 'UncompressedWorldArchiveTooLarge',
	message: `The world archive is too large when uncompressed, its contents must be smaller than a total of ${filesize(
		CK.const.WORLD_ARCHIVE_UNCOMPRESSED_SIZE_MAX
	)}`
});

addError({
	name: 'NoEmailForAccount'
});

addError({
	name: 'TargetUserNotFound',
	message: "Sorry, we couldn't find the user you entered to transfer your purchase to.",
	soft: true
});

addError({
	name: 'MainAccountTransferred',
	message:
		'Now that your main account has been transferred to a new user, please login as that user to continue administering your accounts.',
	soft: true
});

addError({
	name: 'CreditRevoked',
	message: 'Sorry, you cannot use this purchase as it is no longer active.',
	soft: true
});

addError({
	name: 'RoleNotAvailable',
	message:
		'Sorry, you cannot transfer this purchase as it has already been transferred, please contact support so we can resolve your issue.',
	soft: true
});

addError({
	name: 'TargetUserSame',
	message: 'Sorry, you cannot send the purchase to yourself!',
	soft: true
});

addError({
	name: 'TargetUserHasPaymentDetails',
	message:
		'Sorry, you cannot send the subscription to this user because they already have a purchase. Please contact support so we can resolve your issue.',
	soft: true
});

addError({
	name: 'SubscriptionNotActive',
	message:
		"Sorry, we couldn't find your purchase, have you already transferred it to someone else? Please contact support so we can resolve your issue.",
	soft: true
});

addError({
	name: 'AlreadySignedUpForNewsletter',
	soft: true
});

addError({
	name: 'NoModel'
});

addError({
	name: 'ModelHasNoPrimaryKey'
});

addError({
	name: 'NoUserUrl'
});

addError({
	name: 'ECSCapacityReached',
	soft: true
});

addError({
	name: 'StudentAlreadyLinked'
});

addError({
	name: 'BadGift'
});

addError({
	name: 'GiftRedeemed',
	message: 'Sorry, that gift has already been used.',
	soft: true
});

addError({
	name: 'BadSnippet'
});

addError({
	name: 'Superseded',
	soft: true
});

addError({
	name: 'HealthError'
});

addError({
	name: 'NodeRequestError'
});

addError({
	name: 'NotAuthorised'
});

addError({
	name: 'MinecraftNameInvalid',
	soft: true
});

addError({
	name: 'SubscriptionWillNotRenew'
});

addError({
	name: 'NoMinecraftRole',
	message:
		'That user does not have permission to use Code Kingdoms for Modding, please check account status at https://codekingdoms.com/account/',
	soft: true
});

addError({
	name: 'NoUserFromProxy',
	message:
		'We could not find this server, please check you have correctly copied the URL from the editor at https://modding.codekingdoms.com/',
	soft: true
});

addError({
	name: 'NoPaymentMethod',
	message: 'No payment method was provided.',
	testSoft: true
});

addError({
	name: 'StripeCreateTokenFailed',
	soft: true
});

addError({
	name: 'ThreedSecureAuthError',
	message: '3D Secure authentication failed.'
});

addError({
	name: 'ThreedSecureError',
	message: '3D Secure failed.'
});

addError({
	name: 'MissingTask',
	soft: true
});

addError({
	name: 'PageNotFound'
});

addError({
	name: 'InvalidSimpleAuthAttempt',
	testSoft: true
});

addError({
	name: 'BadSimpleAuthCode'
});

addError({
	name: 'IncorrectMinecraftRegion',
	soft: true
});

addError({
	name: 'BadConfirmationCode',
	testSoft: true
});

addError({
	name: 'EmailAlreadyConfirmed',
	soft: true
});

addError({
	name: 'ServerTimeExhausted',
	soft: true
});

addError({
	name: 'MissingAuthProvider',
	testSoft: true
});

addError({
	name: 'GoogleAuthEmailNotVerified',
	soft: true
});

addError({
	name: 'FacebookEmailNotGranted',
	soft: true
});

addError({
	name: 'NativeAuthenticationUnavailable',
	soft: true
});

addError({
	name: 'FacebookSignonHasNoEmail',
	message: 'Facebook account has no email address associated',
	soft: true
});

addError({
	name: 'CannotChangeEmailWithAuthProviders',
	message:
		'Sorry, you cannot change your email when using a third-party authentication provider. Please contact support.',
	soft: true
});

addError({
	name: 'CustomPluginUploadTooLarge',
	message: `The plugin file must be smaller than ${filesize(CK.const.CUSTOM_PLUGIN_SIZE_MAX)}`
});

addError({
	name: 'CustomPluginUploadFormatIncorrect',
	message: 'The plugin file must be a JAR.'
});

addError({
	name: 'UserRetired',
	soft: true
});

addError({
	name: 'EmitCancelledAfterPageCleanup',
	soft: true
});
