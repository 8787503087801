'use strict';

import _ from 'lodash';

module.exports = {
	WORLDS: ['default', 'flat', 'largebiomes', 'amplified'],

	addSuffix: function(worldId) {
		if (_.includes(this.WORLDS, worldId)) {
			worldId += '-' + _.random(1, 100);
		}

		return worldId;
	},

	removeSuffix: function(worldId) {
		if (_.some(this.WORLDS, _.partial(_.startsWith, worldId))) {
			return worldId.split('-')[0];
		}

		return worldId;
	}
};
