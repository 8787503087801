'use strict';

import _ from 'lodash';

module.exports = function() {
	return (
		typeof window !== 'undefined' &&
		(_.includes(window.location.href, '/plans') || _.includes(window.location.href, '/checkout'))
	);
};
