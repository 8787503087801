'use strict';

import _ from 'lodash';
import MenuLinks from '../data/MenuLinks';

const ClientModule = require('../system/ClientModule');
const Frame = require('../ui/Frame');
const startCodingClass = require('../data/startCodingClass');

const Menu = (module.exports = ClientModule.define({
	type: 'MenuService',

	init_menuEntries: function(parent, entries = {}) {
		this.entries = _.defaults(entries, MenuLinks);
	},

	init_change: function() {
		this.loggedIn = null;

		this.change('user', () => {
			let hasUser = this.hasUser();

			if (this.loggedIn === hasUser) return;

			this.loggedIn = hasUser;

			this.broadcast(Menu.EVENTS.MENU_CHANGE);
		});

		this.change('user.subscriptions', () => {
			this.broadcast(Menu.EVENTS.MENU_CHANGE);
		});

		this.change('Camp', () => {
			this.broadcast(Menu.EVENTS.MENU_CHANGE);
		});

		this.on(Frame.EVENTS.NAVIGATE, () => {
			this.broadcast(Menu.EVENTS.MENU_CHANGE);
		});
	},

	hasUser: function() {
		return CK.user && CK.user.$exists();
	},

	checkRole: function(role) {
		if (role === CK.const.ROLES.LOGGED_OUT) {
			if (CK.user.$exists()) {
				return false;
			}
		} else if (role) {
			if (typeof role === 'function') {
				var valid = role();
				if (!valid) {
					return false;
				}
			}

			if (role !== CK.const.ROLES.LOGGED_IN && !CK.user.hasRole(role)) {
				return false;
			}

			if (role === CK.const.ROLES.LOGGED_IN && !CK.user.$exists()) {
				return false;
			}
		}

		return true;
	},

	getLinks: function() {
		let links;

		if (CK.config.name === 'ck-camps') {
			links = this.getWebsiteSiteLinks();
		} else if (CK.config.name === 'ck-minecraft-sequencer') {
			links = this.getEditorLinks();
		} else {
			links = this.getWebsiteLinks();
		}

		var isWebsiteLike = CK.config.name === 'ck-website' || CK.config.name === 'ck-coolmath';

		let accountLink;

		if (typeof window !== 'undefined') {
			if (CK.user.$exists()) {
				accountLink = this.entriesToMenuItemProps({
					name: 'Account',
					href: (!isWebsiteLike ? CK.config.environment.clients.website.address : '/') + 'account/',
					classes: ['userTool']
				});
			} else {
				accountLink = this.entriesToMenuItemProps({
					name: 'Login',
					href: '/login/',
					classes: ['userTool']
				});
			}
		}

		const upgradeLink =
			isWebsiteLike && CK.user.hasHadAPlan()
				? this.entriesToMenuItemProps(this.entries.UPGRADE)
				: null;

		const allLinks = _.compact([...links, accountLink, upgradeLink]);

		// Move start coding to always be at the right
		for (let i = allLinks.length - 1; i >= 0; i--) {
			const link = allLinks[i];

			if (_.includes(link.classes, startCodingClass)) {
				allLinks.splice(i, 1);
				allLinks.push(link);
			}
		}

		return allLinks;
	},

	getWebsiteSiteLinks: function() {
		return this.entriesToMenuItemProps(this.entries);
	},

	getEditorLinks() {
		let websiteAddress = CK.config.environment.clients.website.address;
		let links = [];

		if (this.entries.MINECRAFT) {
			links.push(this.entries.MINECRAFT);
		}
		if (this.entries.PARENTS) {
			links.push(this.entries.PARENTS);
		}

		if (!CK.user.hasHadAPlan()) {
			links.push(this.entries.PRICING);
		}

		//links.push(this.entries.METHOD);

		return links.map(link => {
			link.href = websiteAddress + link.href.substring(1);
			return link;
		});
	},

	getWebsiteLinks: function() {
		let links = [];

		if (this.hasUser()) {
			if (CK.config.name === 'ck-website') {
				if (!CK.user.hasHadAPlan()) {
					links.push(this.entries.PRICING);
				}
				links.push(this.entries.MINECRAFT);
				links.push(this.entries.ROBLOX);
				links.push(this.entries.PARENTS);
				links.push(this.entries.REVIEWS);
				links.push(
					CK.user.isBillingOnlyUser()
						? this.entries.START_CODING_BILLING_ONLY
						: this.entries.START_CODING
				);
			} else {
				links.push(this.entries.HOME);
				if (CK.user.$exists()) {
					links.push(
						CK.user.isBillingOnlyUser()
							? this.entries.START_CODING_BILLING_ONLY
							: this.entries.START_CODING
					);
				}
			}
		} else {
			if (CK.config.name === 'ck-website') {
				links.push(this.entries.MINECRAFT);
				links.push(this.entries.ROBLOX);
				links.push(this.entries.PARENTS);
				links.push(this.entries.REVIEWS);
				links.push(this.entries.PRICING);
				links.push(this.entries.START_CODING);
			} else if (CK.config.name === 'ck-coolmath') {
				links.push(this.entries.HOME);
				links.push(this.entries.MINECRAFT);
				links.push(this.entries.ROBLOX);
				links.push(this.entries.PRICING);
				//links.push(this.entries.START_CODING);
			}
		}

		return this.entriesToMenuItemProps(links);
	},

	entriesToMenuItemProps: function(entry) {
		if (_.isArray(entry)) {
			return _.compact(_.map(entry, this.entriesToMenuItemProps.bind(this)));
		}

		if (entry.display && !entry.display()) {
			return;
		}

		if (!this.checkRole(entry.requireRole)) {
			return;
		}

		return entry;
	}
}));

Menu.EVENTS = {
	MENU_CHANGE: 'MenuService.Change'
};
