'use strict';

import _ from 'lodash';

const GeoInfoService = require('../services/GeoInfoService');

_.extend(CK.fn, {
	userCurrency: function() {
		return CK.user.paymentCurrency() || (GeoInfoService.get() && GeoInfoService.get().currency());
	}
});
