import { CURRENCY } from 'ck-core/source/data/Currency';

export function stripeCouponIdForCurrency(
	id: string,
	percentOff: number | undefined,
	currency: CURRENCY
) {
	if (percentOff) {
		return id;
	} else {
		return id + '-' + currency;
	}
}
