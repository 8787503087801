import { trackFacebookPixel } from './Facebook';
import { trackTiktokPixel } from './TikTok';
import { trackHotjarPixel } from './Hotjar';
import { trackTwitterPixel } from './Twitter';
import { trackAdwordsConversion, trackAdwordsRemarketing } from './Adwords';
import { trackAnalytic } from './Analytic';

export class Trackers {
	public trackFacebookPixel = trackFacebookPixel;
	public trackTiktokPixel = trackTiktokPixel;
	public trackHotjarPixel = trackHotjarPixel;
	public trackTwitterPixel = trackTwitterPixel;
	public trackAdwordsConversion = trackAdwordsConversion;
	public trackAdwordsRemarketing = trackAdwordsRemarketing;
	public trackAnalytic = trackAnalytic;
}
