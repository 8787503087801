'use strict';

module.exports = darc.compile({
	id: darc.types.primary(String),
	active: Boolean,

	entryPredicate: Function,
	exitPredicate: Function,

	/*  NB: Be careful when choosing these ranges when you want to make a sub-test of another test.

		For example if you define a test X with ranges [[0, 0.2]] and then want 20% of the users in that test
		to be in the subtest Y's primary group, then you should use a range like [0, 0.04]

		This is because the users in X will all have a seed >= 0.8 and so [0, 0.04] must be used for sub-test Y
		to correctly split the sub-test.
	*/
	ranges: [[Number, Number]],

	// Deprecated fields
	useAlternate: Boolean,
	groups: [String]
});

module.exports.Set = darc.compile(darc.types.set(module.exports.schema));
