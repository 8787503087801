'use strict';

const CoreFlow = require('ck-core/source/classes/Flow');

const Flow = (CK.classes.Flow = module.exports = CoreFlow.define({
	type: 'ClientFlow',

	user: function() {
		return CK.user;
	}
}));
