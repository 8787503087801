'use strict';

import _ from 'lodash';

const Errors = require('./../services/Errors');
const Extendable = require('./Extendable');

const Verifier = (module.exports = Extendable.define({
	type: 'Verifier',

	init_options: function(options) {
		options = _.defaults(options || {}, {
			required: true
		});

		this.required = options.required;
	},

	isEmptyInput: function(input) {
		return !input && input !== null;
	},

	verify: function(input, strict) {
		if (this.required === false && this.isEmptyInput(input)) {
			return Promise.resolve({
				status: Verifier.STATUS.VALID
			});
		}

		var verifySteps = this.methodsForTrait('verify', false, false, true);

		var output;

		// TODO CORE-238: Verifier system runs verification steps in series which makes verifiers such as EmailVerifier much slower than they need to be
		return Promise.mapSeries(verifySteps, async step => {
			let name = step.__name;

			if (output) {
				return;
			}

			let result = await step.call(this, input);

			if (output && output.status === Verifier.STATUS.INVALID) {
				return;
			}

			if (result === true) {
				return;
			}

			const lang = this.lang || {
				ERROR: 'Verifier error'
			};

			if (result === false) {
				var key = _.snakeCase(name.substring('verify_'.length)).toUpperCase();
				output = {
					status: Verifier.STATUS.INVALID,

					title: lang.ERROR,

					message: lang[key] || lang.ERROR
				};
			} else if (_.isString(result)) {
				output = {
					status: Verifier.STATUS.INVALID,

					title: lang.ERROR,

					message: result
				};
			} else if (_.isObject(result)) {
				output = result;
			}
		}).then(() => {
			if (!output) {
				output = {
					status: Verifier.STATUS.VALID
				};
			}

			if (strict && output.status === Verifier.STATUS.INVALID) {
				throw new Errors.BadRequest({
					message: output.message,
					tags: output.tags,
					soft: true
				});
			}

			return output;
		});
	}
}));
Verifier.STATUS = {
	VALID: 'valid',
	NEUTRAL: 'neutral',
	INVALID: 'invalid'
};

Verifier.verifyAll = function(inputs, verifiers, strict) {
	return Promise.all(
		_.map(verifiers, (verifier, key) => {
			return verifier.verify(inputs[key], strict);
		})
	);
};
