'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'blocky-races',
	name: 'Blocky Races',

	duration: 90,
	secondsDuration: 2812,
	active: true,
	hasPdf: true,
	hasCampPdf: true,
	difficulty: 'Easy',
	unlockXp: 700,

	descriptions: [
		'Try this course to make a simple racing game you can play in almost any world!',
		'Forget Usain Bolt, you’ve got the fastest feet in the land! Add a score system and timers to add rules to a racing game, then see which of your friends is the speediest!',
		'You have the choice of four different tracks to race on. By the end of the course you will have been introduced to methods, timers, conditionals and for loops to make your own racing game.'
	],

	videos: [
		{
			name: 'Blocky Races',
			chapters: [
				{
					name: 'The Starting Block',
					description: 'This chapter starts your mod off by setting the gamemode of the player',
					badges: ['parameters-1', 'ui-1'],
					secondsOffset: 55
				},
				{
					name: 'Nice And Peaceful',
					description: 'This chapter shows you how to turn off player vs player',
					badges: ['booleans-1'],
					secondsOffset: 348
				},
				{
					name: 'Winner Takes All',
					description:
						'This chapter shows you how to make something happen when you press a button',
					badges: ['communication-1', 'communication-2'],
					secondsOffset: 528
				},
				{
					name: 'Button Up',
					description:
						'This chapter introduces you to conditionals to make something happen under certain conditions',
					badges: ['conditions-1', 'conditions-2'],
					secondsOffset: 720
				},
				{
					name: 'The Beginning And The End',
					description: 'This chapter sets up the beginning and the end of the race',
					badges: ['methods-1'],
					secondsOffset: 1190
				},
				{
					name: 'Are You Racing?',
					description: 'This chapter shows you how to ensure only players who are racers can win!',
					badges: ['fields-1', 'booleans-2'],
					secondsOffset: 1407
				},
				{
					name: 'Time',
					description: 'This chapter adds a timer to the start of the game',
					badges: ['timer-1'],
					secondsOffset: 1840
				},
				{
					name: 'Teleport',
					description:
						'This chapter teaches you how to make sure all the players start in the same location at the start of the race',
					badges: ['timer-2', 'loops-2'],
					secondsOffset: 2234
				}
			]
		}
	]
};
