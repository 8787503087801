'use strict';
const getFileBaseClass = require('../../functions/getFileBaseClass');

module.exports = {
	path: darc.types.primary(String),
	oldPath: String,
	name: String,
	icon: function() {
		let name = this.name();

		if (!name) {
			return 'file-java';
		}

		return 'icon-file-' + name.toLowerCase();
	},
	description: String,
	label: String,
	dateCreated: Date,
	dateModified: Date,
	buffer: String,
	active: Boolean,
	progress: {
		video: Number,
		step: Number
	},
	// Simple name e.g. BaseObject
	superclass: String,
	progressCount: Number,

	getBaseClass: function() {
		return getFileBaseClass(this.$());
	}
};
