'use strict';

import _ from 'lodash';

import './Menu.less';

import React from 'react';
import MenuProps from '../data/MenuProps';
import MenuItem from './MenuItem.jsx';
import Frame from './Frame';
import ReactCKImage from './ReactCKImage';
import ProgressBar from './ReactProgressBar';
import classnames from 'classnames';
import RoundButton from './RoundButton';
import Assets from '../system/Assets';
import { PromoCountdown } from '../components/PromoCountdown';
import { GenericPromoHeader } from '../components/GenericPromoHeader';

const isBefore = require('date-fns/is_before');

export default class Menu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isSlide: false,
			flipped: false
		};

		setTimeout(() => {
			this.setState({
				flipped: true
			});
		}, 3000);

		setInterval(() => {
			this.setState({
				flipped: true
			});
			setTimeout(() => {
				this.setState({
					flipped: false
				});
			}, 6000);
		}, 9000);
	}

	handleNav(href) {
		this.setState({
			isSlide: false
		});

		if (href) {
			CK.frame.navigate(href);
		} else {
			CK.user.$exists()
				? Frame.mod().navigate('/platform-select/')
				: Frame.mod().navigate('/login/'); // must be start coding
		}
	}
	render() {
		const { flipped } = this.state;

		const menuItems = _.map(this.props.entries, (entry, i) => (
			<MenuItem
				key={entry.href + i}
				entry={entry}
				frame={this.props.frame}
				handleClick={(href, ...args) => {
					if (href) {
						this.props.handleClick(href, ...args);
					}
				}}
			/>
		));

		let avatar = null;

		if (this.props.avatar) {
			const accountUrl = CK.config.environment.clients.website.address + 'account/';

			const { image, xpPercentage, currentXp } = this.props.avatar;

			avatar = (
				<div className="avatar">
					<ReactCKImage name={image} options={{ width: '100%', height: '100%' }} />
					<div className="details">
						<a href={accountUrl} className="name">
							{CK.user.name()}
						</a>
						<div className="level">
							<div className="text">
								<ReactCKImage name="icon-level" options={{ width: '100%', height: '100%' }} />{' '}
								{currentXp.toLocaleString()} XP
							</div>
							<ProgressBar percent={xpPercentage} />
						</div>
					</div>
				</div>
			);
		}

		const showActivePromoMenu = this.props.promo && CK.fn.isNonGenericPromo(this.props.promo);

		const classes = classnames('row', 'menu', showActivePromoMenu && 'promo');

		let promo;

		if (showActivePromoMenu) {
			let remainingTitle;

			if (this.props.promo.countdownEnd) {
				remainingTitle = <PromoCountdown countdownEnd={this.props.promo.countdownEnd} />;
			} else if (this.props.promo.remaining) {
				let noOfHours = moment(this.props.promo.endDate).diff(
					moment(this.props.promo.startDate),
					'hours'
				);

				let hoursRemaining = moment(this.props.promo.endDate).diff(moment(), 'hours');

				const pad = val => _.padStart('' + val, 2, '0');

				let remaining = pad(Math.floor((this.props.promo.remaining / noOfHours) * hoursRemaining));

				remainingTitle = 'Limited discounts available';

				// remainingTitle = remaining + ' Deals remaining';

				// remainingTitle = `${this.props.promo.remaining} deal${
				// 	this.props.promo.remaining !== 1 ? 's' : ''
				// } ${this.props.promo.remainingSuffix}`;
			}

			let promoLang = this.props.promo.lang.menu;

			// if (CK.fn.userCurrency && CK.fn.userCurrency() === 'gbp') {
			// 	// GBP/ UK users to get different copy

			// 	promoLang = {
			// 		intro: 'Exclusive savings this Christmas',
			// 		title: 'Christmas Deals',
			// 		button: 'Show me the deals'
			// 	};
			// }

			const backgroundAssetLeft = Assets.get(`promo-${this.props.promo.id}-menu-background-left`);
			const backgroundAssetRight = Assets.get(`promo-${this.props.promo.id}-menu-background-right`);

			promo = (
				<a className={classnames('promo', this.props.promo.id)} href="/plans/">
					<div
						className="backgroundLeft"
						style={{
							backgroundImage: `url(${_.get(backgroundAssetLeft, 'url')})`
						}}
					/>
					<div
						className="backgroundRight"
						style={{
							backgroundImage: `url(${_.get(backgroundAssetRight, 'url')})`
						}}
					/>
					<div className="evenWrapper">
						{/* <RoundButton
							hoverAnimation="hvr-bounce-in"
							size="small"
							colour="blue"
							text={promoLang.button}
							handleClick={() => Frame.mod().navigate('/plans/')}
						/> */}
					</div>

					<div className={classnames('title', flipped && 'flipped')}>
						<div className="front">{promoLang.title}</div>
						<div className="back">{remainingTitle}</div>
					</div>
					<div className="evenWrapper" />
				</a>
			);
		} else if (
			this.props.promo &&
			this.props.promo.endDate &&
			!(CK.user && CK.user.hasHadAPlan())
		) {
			if (isBefore(new Date(), this.props.promo.endDate)) {
				promo = <GenericPromoHeader countdownEnd={this.props.promo.endDate} />;
			}
		}

		const { isSlide } = this.state;

		let menuImage = (
			<ReactCKImage
				name="menu-logo"
				options={{ ignoreDimensions: true }}
				extraClasses={['hvr-buzz-out', 'logo']}
			/>
		);

		if (CK.isCoolMath()) {
			menuImage = (
				<ReactCKImage
					name="coolmath-coding-logo"
					options={{ ignoreDimensions: true }}
					extraClasses={['hvr-buzz-out', 'coolmath-logo']}
				/>
			);
		}

		return (
			<div className={classes}>
				{promo}
				<div className="wrapper">
					<a href="/">{menuImage}</a>
					{menuItems}
					{avatar}

					<div className="Menu__mobile-menu-wrapper">
						<div
							className="Menu__mobile-menu__close"
							onClick={() =>
								this.setState({
									isSlide: !isSlide
								})
							}
						>
							<div
								className={`Menu__mobile-menu__hamburger ${
									isSlide ? 'Menu__mobile-menu__hamburger--active' : ''
								}`}
							>
								{_.times(3, String).map((a, index) => (
									<span
										key={index}
										className={`Menu__mobile-menu__hamburger-line  ${
											isSlide ? 'Menu__mobile-menu__hamburger-line--active' : ''
										}`}
									/>
								))}
							</div>
						</div>
						<div className={`Menu__mobile-menu ${isSlide ? 'Menu__mobile-menu--open' : ''}`}>
							<ul className="Menu__mobile-menu__list">
								{this.props.entries.map(item => {
									return (
										<li key={item.name} className="Menu__mobile-menu__item">
											<a
												onClick={() => this.handleNav(item.href)}
												className="Menu__mobile-menu__href"
												target={item.target}
												href="#"
											>
												{item.name}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Menu.propTypes = MenuProps;
