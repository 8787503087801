'use strict';

import { trackAdwordsRemarketing } from 'ck-core-client/source/trackers/Adwords';

const WebsiteFrame = require('./WebsiteFrame');
const CoreWebSetupTransition = require('../../../../core-web/source/transitions/CoreWebSetupTransition');
const SharedClientSetupTransition = require('ck-core-client/source/transitions/SharedClientSetupTransition');
const Transition = require('ck-core/source/system/Transition');
const activePromo = require('../../data/activePromo');
const StorageManager = require('ck-core-client/source/managers/StorageManager');
var storageAffiliateKey = require('../../data/storageAffiliateKey');

module.exports = Transition.define({
	type: 'WebsiteSetupTransition',

	init_steps: function() {
		this.step(new SharedClientSetupTransition())
			.step(this.initPromo)
			.step(
				new CoreWebSetupTransition({
					getFrameInstance: () => {
						return new WebsiteFrame(CK.moduleContainer, {
							footerEntries: require('../../data/WebsiteFooter')
						});
					},
					menuEntries: require('../../data/WebsiteLinks')
				})
			)
			.step(this.loadAsyncScripts)
			.step(this.trackGoogleRemarketing)
			.step(this.loadHotjar);
	},

	loadAsyncScripts: function() {
		(async function() {
			while (document.readyState !== 'complete') {
				await Promise.delay(100);
			}

			const tag = document.createElement('script');
			tag.src = 'https://js.stripe.com/v3/';
			tag.id = 'stripe-js';
			const firstScriptTag = document.getElementsByTagName('script')[0];
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


		})();


	},

	loadHotjar: function() {

		/*eslint-disable */

		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
			h._hjSettings={hjid:3066363,hjsv:6};
			a=o.getElementsByTagName('head')[0];
			r=o.createElement('script');r.async=1;
			r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
			a.appendChild(r);
	  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

	  /*eslint-enable */

	},

	trackGoogleRemarketing: function() {
		trackAdwordsRemarketing();
	},

	getAffiliateFromStorage() {
		const storageManager = StorageManager.mod();
		return storageManager ? storageManager.get(storageAffiliateKey) : undefined;
	},

	initPromo: function() {
		if (activePromo && activePromo.getProps()) {
			$('.pageContainer').addClass('promoFooter ' + activePromo.id);
		}
	},
});
