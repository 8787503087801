'use strict';

import _ from 'lodash';

const LocalStorageBackend = require('../storageBackends/LocalStorageBackend');
const MemoryStorageBackend = require('../storageBackends/MemoryStorageBackend');
const ClientModule = require('../system/ClientModule');
const CKError = require('ck-core/source/classes/Error');

module.exports = ClientModule.define({
	type: 'StorageManager',

	init_Storage: function(parent, options) {
		options = _.defaults(options || {}, {
			backends: [LocalStorageBackend, MemoryStorageBackend]
		});
		this._backends = _.map(options.backends, BackendClass => {
			return new BackendClass(this);
		});
	},

	set: function(key, value) {
		let errors = [];

		for (let backend of this._backends) {
			try {
				backend.set(key, value);
				return true;
			} catch (e) {
				errors.push(e.message);
			}
		}

		CK.logger.log(
			new CKError({
				message: 'Storage set failed',
				tags: {
					errors
				}
			})
		);

		return false;
	},

	get: function(key) {
		let errors = [];

		for (let backend of this._backends) {
			try {
				return backend.get(key);
			} catch (e) {
				errors.push(e.message);
			}
		}

		CK.logger.log(
			new CKError({
				message: 'Storage get failed',
				tags: {
					errors
				}
			})
		);

		return null;
	},

	remove: function(key) {
		let errors = [];

		for (let backend of this._backends) {
			try {
				return backend.remove(key);
			} catch (e) {
				errors.push(e.message);
			}
		}

		CK.logger.log(
			new CKError({
				message: 'Storage remove failed',
				tags: {
					errors
				}
			})
		);
	},

	exists: function(key) {
		return this.get(key) !== null;
	},

	getBoolean: function(key) {
		return this.get(key) === 'yes';
	},

	setBoolean: function(key, value) {
		if (value) {
			this.set(key, 'yes');
		} else {
			this.set(key, 'no');
		}
	},

	setObject: function(key, object) {
		this.set(key, JSON.stringify(object));
	},

	getObject: function(key) {
		try {
			return JSON.parse(this.get(key));
		} catch (e) {
			CK.logger.debug(e);
			return null;
		}
	},

	keys: function() {
		let errors = [];

		for (let backend of this._backends) {
			try {
				return backend.keys();
			} catch (e) {
				errors.push(e.message);
			}
		}

		CK.logger.log(
			new CKError({
				message: 'Storage keys enumeration failed',
				tags: {
					errors
				}
			})
		);
	}
});
