'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'the-great-blockish-bake-off',
	name: 'The Great Blockish Bake-Off',
	projectNamePrefix: 'bakeoff',
	duration: 150,
	secondsDuration: 5191,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,

	cues: [
		{
			name: 'message',
			time: 433,
			lookAhead: true,
			message:
				"If you're using the Bedrock Edition of Minecraft just put a normal bucket here instead of a milk bucket!"
		}
	],

	descriptions: [
		'Race to bake more food than your opponents to get as many points as you can!',
		'Welcome to the Great Blockish Bake-Off! Bake and sell as many items as you can before the time runs out to see if you can get the highest score! Will you rise and become this year’s star baker or will you crumble under the pressure?',
		'Use loops and arrays to give players points in this timed baking game where the aim is to make more food than your opponents!'
	],

	difficulty: 'Hard',
	unlockXp: 32000,

	videos: [
		{
			name: 'The Great Blockish Bake-Off',
			chapters: [
				{
					name: 'Soggy Bottoms',
					description: 'Equip the player by adding items to their inventory.',
					badges: ['methods-1', 'communication-1', 'inventory-1'],
					secondsOffset: 171
				},
				{
					name: 'All or Muffin',
					description: 'Set up teleporting players.',
					badges: ['parameters-1'],
					secondsOffset: 513
				},
				{
					name: 'Bake No Mistake',
					description: 'Add in a timer and a setTimeout.',
					badges: ['timer-1', 'timer-2'],
					secondsOffset: 601
				},
				{
					name: 'Lets Get Growing',
					description: 'Using set interval to spawn items at a certain interval.',
					badges: ['timer-1', 'parameters-2'],
					secondsOffset: 810
				},
				{
					name: 'I Knead A Hero',
					description: 'Using setTimeout to regrow crops.',
					badges: ['timer-1', 'logic-2', 'booleans-2'],
					secondsOffset: 969
				},
				{
					name: 'Lets Get Ready To Crumble',
					description: 'Using if statements to re-grow crops.',
					badges: ['logic-2', 'booleans-2', 'generalisation-1'],
					secondsOffset: 1267
				},
				{
					name: 'Crop It Like Its Hot',
					description: 'Using get relative to regrow sugar cane.',
					badges: ['parameters-2', 'booleans-2'],
					secondsOffset: 1449
				},
				{
					name: 'Ready Steady Bake',
					description: 'Implementing a money system.',
					badges: ['parameters-2', 'booleans-2'],
					secondsOffset: 1672
				},
				{
					name: 'You Meringue My Lord',
					description: 'Creating a new method to handle the prices of items.',
					badges: ['parameters-3', 'methods-2'],
					secondsOffset: 2270
				},
				{
					name: 'Are You Trying To Get A Rise Out Of Me',
					description:
						'Using return types to return the amount of money the baked goods have sold for.',
					badges: ['loops-3', 'arrays-1', 'arrays-2'],
					secondsOffset: 2569
				},
				{
					name: 'Bonus Banquet',
					description:
						'Creating a bonus event using timeouts, where certain items are worth more money.',
					badges: ['communication-2', 'timer-2', 'arrays-1'],
					secondsOffset: 3237
				},
				{
					name: 'Flan I Ask You A Favour?',
					description:
						'Using generateInteger to make the bonus item random, and the multiplier random.',
					badges: ['random-1', 'arrays-2'],
					secondsOffset: 3944
				},
				{
					name: 'Winner Bakes All',
					description: 'Write a summary to tell players their scores ',
					badges: ['communication-3', 'timer-1'],
					secondsOffset: 4135
				},
				{
					name: 'Head Chef',
					description: 'Set up a restart using a setTimeout.',
					badges: ['loops-2', 'nesting-1', 'conditions-3'],
					secondsOffset: 4484
				}
			]
		}
	]
};
