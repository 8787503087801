'use strict';

import _ from 'lodash';

const MonitorContextService = require('ck-core/source/services/MonitorContextService');
const StorageManager = require('./StorageManager');
const ClientModule = require('../system/ClientModule');

// NOOP by default
CK.trackAnalytic = CK.fn.zero;

module.exports = ClientModule.define({
	type: 'AnalyticsManager',

	init_AnalyticsManager: function(parent, options) {
		this.options = _.defaults(options || {}, {});

		// TODO: Fix require from devtools
		CK.analyticsManager = this;

		this._queue = StorageManager.mod().get('Analytics/queue');

		if (this._queue) {
			this._queue = JSON.parse(this._queue);
		} else {
			this._queue = {};
		}

		if (_.size(this._queue)) {
			this._queueCounter = +_.max(Object.keys(this._queue)) + 1;
		} else {
			this._queueCounter = 0;
		}
	},

	bind_globalTrackFunction: function() {
		if (CK.trackAnalytic !== CK.fn.zero) {
			CK.logger.warn('CK.trackAnalytic not previously unbound');
		}

		CK.trackAnalytic = this.track.bind(this);
	},

	unbind_globalTrackFunction: function() {
		CK.trackAnalytic = CK.fn.zero;
	},

	_saveQueue: function() {
		StorageManager.mod().set('Analytics/queue', JSON.stringify(this._queue));
	},

	_send: function(message, origin) {
		if (!origin) {
			return Promise.resolve();
		}

		const data = {
			message
		};

		const options = {};

		if (!origin.userId) {
			data.trackingData = origin;
			options.waitForVerify = false;
		} else {
			options.waitForVerify = true;
		}

		// TODO 2022: Uncomment these lines if you want logs again
		// CK.logger.debug('\u{1F4CA}', 'Mixpanel Track', _.get(data, 'message.event'), data);
		// return CK.clientSockets.monitor.emit('trackAnalytic', data, options);
		return Promise.resolve();
	},

	track: function(event, data) {
		try {
			if (!this.bound) {
				return;
			}

			data = data || {};

			const message = {
				data,
				event,
				dateCreated: Date.now()
			};

			const contextService = this.container().module(MonitorContextService);

			if (contextService) {
				message.context = contextService.getContext();
			}

			var origin;

			if (CK.user.$exists()) {
				origin = {
					userId: CK.user.id()
				};
			} else {
				origin = CK.trackingData;
			}

			if (this.options.mixpanelWhitelist) {
				if (!this.options.mixpanelWhitelist[event]) {
					data.skipMixpanel = true;
				}
			}

			if (!CK.clientSockets.monitor.isConnected()) {
				/* Unlikely as it is, if you have stored analytics for user A and login as user B, then user A's
				 * analytic sends will fail and be discarded */
				this._queue[this._queueCounter++] = {
					message,
					origin
				};

				this._saveQueue();

				// TODO 2022: Uncomment these lines if you want monitor to work again
				// CK.clientSockets.monitor.once('online', this.processQueue, this);
				// CK.clientSockets.monitor.connect();

				// TODO: When we move analytics to use sendBeacon remove this (2019)
				return Promise.delay(3000);
			} else {
				let promise = this._send(message, origin);

				promise.finally(() => {
					this.processQueue();
				});

				return promise;
			}
		} catch (err) {
			CK.logger.error(err);
		}
	},

	processQueue: function() {
		_.each(this._queue, (analytic, id) => {
			delete this._queue[id];

			// Drop analytics with no origin
			if (!analytic.origin) {
				return;
			}

			return this._send(analytic.message, analytic.origin).catch(err => {
				CK.logger.error(err);
			});
		});

		this._saveQueue();
	}
});
