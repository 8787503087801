import _ from 'lodash';
import { randomString } from './randomString';
import { parseIsoDate } from './parseIsoDate';

export const TRACKING_ID_PREFIX = 'ckAnonymous-';

export function generateTrackingData() {
	return {
		id: TRACKING_ID_PREFIX + randomString(64),
		testSeed: Math.random(),
		testCohorts: {}
	};
}

export function validateTrackingData(request) {
	const data = request.trackingData;

	if (!_.isPlainObject(data)) {
		throw new Errors.InvalidTrackingData({
			message: 'Bad request',
			tags: {
				trackingData: data
			}
		});
	}

	const trackingId = data.id,
		testSeed = data.testSeed,
		testCohorts = data.testCohorts;

	if (!_.startsWith(trackingId, TRACKING_ID_PREFIX)) {
		throw new Errors.InvalidTrackingData({
			message: 'Bad id',
			tags: {
				trackingData: data
			}
		});
	}

	if (!require('ck-core/source/functions/isValidTestSeed')(testSeed)) {
		throw new Errors.InvalidTrackingData({
			message: 'Bad testSeed',
			tags: {
				trackingData: data
			}
		});
	}

	if (!_.isObject(testCohorts)) {
		throw new Errors.InvalidTrackingData({
			message: 'No testCohorts',
			tags: {
				trackingData: data
			}
		});
	}

	const abTests = require('ck-core/source/collections/ABTests');

	for (let id of _.keys(testCohorts)) {
		const test = abTests(id);

		if (!test.$exists()) {
			CK.logger.warn(
				new CKError({
					message: 'Attempt to track unknown test',
					tags: {
						id
					}
				})
			);

			delete testCohorts[id];

			continue;
		}

		let val = testCohorts[id];

		if (val !== id || !_.isBoolean(val.control) || _.size(val) !== 3) {
			if (!test.$exists()) {
				CK.logger.warn(
					new CKError({
						message: 'Attempt to track with bad testCohort',
						tags: {
							id
						}
					})
				);

				delete testCohorts[id];
			}
		}

		val.dateEntered = parseIsoDate(val.dateEntered);
	}

	return true;
}
