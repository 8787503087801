'use strict';

import _ from 'lodash';

const CKImage = require('./image');
const EventContrib = require('ck-core/source/system/EventContrib');
const Component = require('./Component');

const Button = (module.exports = Component.define(EventContrib(), {
	init_Button: function(text, action, options) {
		options = _.defaults(options || {}, {
			size: 'medium',
			icon: false,
			colour: 'blue',
			target: null
		});

		this.dom = $('<a class="button">')
			.addClass(options.size)
			.addClass(options.width)
			.addClass(options.colour);

		if (options.onClick) {
			this.dom.on('click', options.onClick);
		} else {
			this.setAction(action);
		}

		if (options.target) {
			this.dom.attr('target', options.target);
		}

		this.textDom = $('<div>').addClass('text');
		this.dom.append(this.textDom);

		if (options.icon) {
			this.icon = CKImage(options.icon, {
				ignoreDimensions: true,
				tag: 'img'
			}).addClass('icon');
			this.dom.append(this.icon);
		} else {
			this.dom.addClass('noIcon');
		}

		this.setText(text);

		this.dom.data('model', this);

		this.pendingImage = CKImage('loading-wheel', {
			ignoreDimensions: true
		}).addClass('loadingWheel');
	},

	setAction: function(action) {
		this.dom.attr('href', CK.fn.hrefForAction(action));
	},

	setText: function(text) {
		this.text = text;

		this.textDom.html(this.text);

		return this;
	},

	disable: function() {
		this.dom.addClass('disabled noStatus');

		return this;
	},

	enable: function() {
		if (this.status) {
			this.status.remove();
			this.status = null;

			this.dom.append(this.textDom);
		}

		this.dom.removeClass('disabled pending noStatus hasStatus');

		return this;
	},

	displayStatus: function(output) {
		// Already cleaned up
		if (!this.dom || !this.dom.parent().length) {
			return;
		}

		if (this.warning) {
			this.warning.remove();
			this.warning = null;
		}

		switch (output.status) {
			case Button.STATUS.ERROR:
				this.warning = $('<div class="warning">');
				this.warning.append(CKImage('warning-white'), $('<div class="text">').html(output.message));
				this.enable();
				this.dom.parent().append(this.warning);

				this.dom.animateCss('shake', '300ms');

				break;

			case Button.STATUS.SUCCESS:
				this.status = $('<div class="status">');
				this.status.append(CKImage('tick-white'), $('<div class="text">').html(output.message));
				this.dom
					.empty()
					.append(this.status)
					.removeClass('noStatus')
					.addClass('disabled hasStatus');

				break;

			case Button.STATUS.DISABLE:
				this.disable();
				break;
		}
	},

	setPending: function(pending) {
		if (pending === this.pending) {
			return;
		}

		this.pending = pending;

		if (!this.dom) {
			return;
		}

		if (pending) {
			this.textDom.hide();

			if (this.icon) {
				this.icon.hide();
			}

			this.dom.addClass('pending').append(this.pendingImage);
		} else {
			this.textDom.show();

			if (this.icon) {
				this.icon.show();
			}

			this.dom.removeClass('pending');
			this.pendingImage.detach();
		}
	},

	onAction: function(result) {
		if (this.pending) {
			return;
		}

		if (this.warning) {
			this.warning.remove();
			this.warning = null;
		}

		let actionCallback = output => {
			// Convert default values for result to statuses
			if (output === true || _.isNil(output)) {
				output = {
					status: Button.STATUS.SUCCESS,
					message: require('./ButtonLang').SUCCESS
				};
			} else if (output === false) {
				output = {
					status: Button.STATUS.ERROR,
					message: require('./ButtonLang').ERROR
				};
			}

			try {
				if (_.isError(output)) {
					CK.logger.log(output);

					this.displayStatus({
						status: Button.STATUS.ERROR,
						message: output.message
					});

					this.publish(Button.STATUS.ERROR, output);
				} else if (_.isObject(output) && output.status) {
					this.displayStatus(output);
					this.publish(output.status);
				}
			} catch (e) {
				CK.logger.warn(e);
			} finally {
				this.setPending(false);
			}
		};

		if (result && result.then) {
			this.setPending(true);
			result.then(actionCallback, actionCallback);
		} else if (_.isObject(result)) {
			actionCallback(result);
		}
	},

	type: 'Button'
}));

Button.STATUS = {
	SUCCESS: 'success',
	DISABLE: 'disable',
	RETRY: 'retry',
	ERROR: 'error'
};
