'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'sheep-city',
	name: 'Sheep City',
	duration: 30,
	secondsDuration: 1332,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	descriptions: [
		'Get started in the Code Editor by learning how to code custom Minecraft items.',
		'Learn how to code custom exploding arrows, a custom bouncy block, and a custom speedy sheep that gives you gold when you catch it!',
		'This starter course shows you the basics of the Code Editor, as well as some simple coding constructs such as methods and variables.'
	],

	difficulty: 'Starter',
	unlockXp: 0,

	additionalFiles: [
		{
			path: '/Sheep',
			name: 'Sheep',
			superclass: 'BaseSheep'
		}
	],

	cues: [
		{
			time: 60,
			name: 'downloadMinecraft',
			lookAhead: true
		},
		{
			time: 140,
			name: 'server',
			lookAhead: true
		},
		{
			time: 218,
			name: 'pause'
		},
		{
			time: 454,
			name: 'run',
			lookAhead: true
		}
	],

	videos: [
		{
			name: 'Sheep City',
			chapters: [
				{
					name: 'Exploding Arrows',
					description: 'This chapter will show you how to make an exploding bow and arrows',
					secondsOffset: 220,
					badges: ['methods-1', 'parameters-1']
				},
				{
					name: 'Bouncy Gold',
					description: 'This chapter will show you how to make a custom bouncy block',
					secondsOffset: 476,
					badges: ['conditions-1', 'logic-2']
				},
				{
					name: 'Evil Sheep',
					description: 'This chapter will show you how to create a custom speedy red sheep',
					secondsOffset: 725,
					badges: ['variables-1', 'parameters-1', 'mob-1', 'mob-2']
				}
			]
		}
	]
};
