'use strict';

import _ from 'lodash';

const Extendable = require('./../system/Extendable');

// Initially map it to console until it's been initialised
CK.logger = console;
CK.logger.critical = console.error.bind(console);

if (typeof window !== 'undefined') {
	CK.logger.debug = console.log.bind(console, 'DEBUG');
} else {
	// In Node, we use console.error to ensure that logs go to STDERR
	CK.logger.debug = console.error.bind(console, 'DEBUG');
}

const Logger = (module.exports = Extendable.define({
	type: 'Logger',

	init_Logger: function(options) {
		Error.stackTraceLimit = Infinity;

		this.options = _.defaults(options || {}, {
			drains: []
		});

		if (!this.options.drains.length) {
			console.warn('No drains configured for logger');
		}

		for (let level in Logger.LEVELS) {
			this[level] = (...args) => {
				this._dispatch.call(this, Logger.LEVELS[level], args);
			};
		}
	},

	bindDrains: function() {
		for (let i in this.options.drains) {
			let drain = this.options.drains[i];

			drain.bind();
		}
	},

	_dispatch: function(level, args) {
		if (!args.length) {
			return;
		}

		for (let i = 0; i < this.options.drains.length; i++) {
			// Clone args before dispatch
			this.options.drains[i].dispatch(level, args.slice());
		}
	}
}));

Logger.LEVELS = {
	trace: 0,
	debug: 1,
	log: 2,
	info: 3,
	warn: 4,
	error: 5,
	critical: 6
};

Logger.LEVEL_NAMES = _.map(Logger.LEVELS, (level, name) => {
	return name.toUpperCase();
});

Logger.errorWithStack = function(name, trimCalls) {
	var e = new Error(name || 'GeneratedError');

	if (!e.stack) {
		// "The stack property is set to undefined when the error is constructed, and gets the trace information when the error is raised."
		// https://msdn.microsoft.com/en-us/library/hh699850(v=vs.94).aspx

		try {
			throw e;
		} catch (e2) {
			e = e2;
		}
	}

	if (trimCalls) {
		e.stack = e.stack
			.split('\n')
			.slice(trimCalls)
			.join('\n');
	}

	return e;
};

Logger.getSourceFileAndLine = function(offset) {
	offset = offset || 5;

	let splitSource;

	try {
		var stack = new Error().stack;

		let stackArray = stack.split('\n');

		if (!stackArray[offset]) {
			return {
				file: '?',
				line: '?'
			};
		}

		splitSource = stackArray[offset].split('/');

		splitSource = splitSource[splitSource.length - 1].split(':');
	} catch (err) {
		console.warn('Error creating stack', err);

		return {
			file: '?',
			line: '?'
		};
	}

	return {
		file: splitSource[0],
		line: splitSource[1]
	};
};

Logger.NO_MESSAGE_AVAILABLE = 'No message available';
