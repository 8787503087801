'use strict';

import _ from 'lodash';
import { STRIPE_DURATION } from 'ck-core/source/data/StripeDuration';
import { STRIPE_STATUS } from 'ck-core/source/data/StripeStatus';

const CKError = require('../../classes/Error');
const CouponSchema = require('../CouponSchema');
const purchaseStateConstants = require('./purchaseStateConstants');
const subscriptionPlans = require('../../collections/subscriptionPlans');

module.exports = async function() {
	const userCurrency = CK.fn.userCurrency ? CK.fn.userCurrency() : this.paymentCurrency();
	let sub = this.getActiveOrPastDueSubscription();
	let state;
	let isWarning = false;
	let nextPayment = null;
	let endDate = this.getCreditEndDate();
	let nextEndDate = endDate;
	let currentCoupon, nextCoupon;
	let plan = null;
	let credit;
	let nextSubscription = null;

	const currentCredit = this.getCurrentCredit();
	let currentPlan = currentCredit ? subscriptionPlans(currentCredit.planId) : null;

	if (!this.hasHadAPlan()) {
		if (this.isCampAdmin()) {
			state = purchaseStateConstants.CAMP_ADMIN;
		} else if (this.isCampLeader()) {
			state = purchaseStateConstants.CAMP_LEADER;
		} else if (this.isCampStudent()) {
			if (
				this.getMinecraftDenyStatus() ===
				require('../../data/editorDenyStatus').CAMP_NO_LICENCED_GROUPS
			) {
				state = purchaseStateConstants.CAMP_STUDENT_NO_LICENCED_GROUP;
			} else {
				state = purchaseStateConstants.CAMP_STUDENT;
			}
		} else {
			state = purchaseStateConstants.NO_PLAN;
		}
	} else if (!sub) {
		let latestCredit = this.getLatestCredit();

		if (latestCredit) {
			plan = subscriptionPlans(latestCredit.planId);

			let couponData;

			if (_.get(CK, 'clientSockets.users')) {
				if (latestCredit.coupon) {
					couponData = await CK.clientSockets.users
						.emit('verifyCoupon', {
							hasHadAPlan: this.hasHadAPlan(),
							code: latestCredit.coupon,
							planId: latestCredit.planId,
							currency: userCurrency,
							forExistingPlan: true
						})
						.catch(() => {
							CK.logger.warn(
								new CKError({
									message: 'Invalid existing coupon',
									tags: {
										coupon: latestCredit.coupon
									}
								})
							);
							return null;
						});
				}
			}

			if (couponData) {
				currentCoupon = new CouponSchema(couponData.coupon);
			}
		}

		// We might have active latestCredit
		if (this.hasActiveCredit()) {
			credit = latestCredit;

			// If they have credit(s) set to start in the future but their current subscription
			// was cancelled immediately they will actually have access so we need to show the
			// 'currentPlan' here

			currentPlan = currentPlan || subscriptionPlans(credit.planId);

			nextSubscription = this.getPendingSubscription();

			if (nextSubscription) {
				state = purchaseStateConstants.ONE_OFF_PENDING_SUBSCRIPTION;
			} else {
				state = purchaseStateConstants.ONE_OFF;
			}
		} else {
			if (latestCredit && latestCredit.revoked) {
				state = purchaseStateConstants.REVOKED;
				isWarning = true;
			} else {
				state = purchaseStateConstants.EXPIRED;
				isWarning = true;
			}
		}
	} else {
		plan = subscriptionPlans(sub.planId());

		if (!currentPlan) {
			currentPlan = plan;
		}

		endDate = sub.current_period_end();

		if (sub.willRenew()) {
			state = purchaseStateConstants.SUBSCRIPTION;
			nextSubscription = sub;
		} else {
			state = purchaseStateConstants.SUBSCRIPTION_CANCELLED;
		}

		if (this.isTrialling()) {
			if (this.hasActiveCredit()) {
				state = purchaseStateConstants.TRIAL_ONE_OFF;
			} else if (nextSubscription) {
				state = purchaseStateConstants.TRIAL;
			} else {
				state = purchaseStateConstants.TRIAL_CANCELLED;
			}
		} else if (sub.status() === STRIPE_STATUS.PAST_DUE) {
			state = purchaseStateConstants.SUBSCRIPTION_PAST_DUE;
			isWarning = true;
		} else if (this.hasActiveCredit()) {
			state = purchaseStateConstants.SUBSCRIPTION_PENDING_ONE_OFF;
		}
	}

	if (nextSubscription) {
		let nextPlan = subscriptionPlans(nextSubscription.planId());

		if (nextSubscription.coupon()) {
			let [currentResult, nextResult] = await Promise.all(
				_.map([true, false], forExistingPlan => {
					return CK.clientSockets.users
						.emit('verifyCoupon', {
							hasHadAPlan: this.hasHadAPlan(),
							code: nextSubscription.coupon(),
							planId: nextPlan.id(),
							currency: userCurrency,
							forExistingPlan
						})
						.catch(() => {
							return null;
						});
				})
			);

			const checkLimitedDurationCoupon = (coupon, billingDate) => {
				if (!coupon) {
					return coupon;
				}

				if (coupon.duration() === STRIPE_DURATION.FOREVER) {
					return coupon;
				}

				if (nextPlan.interval() !== 'month') {
					CK.logger.warn(
						new CKError({
							message: 'Cannot check coupon duration for non-month interval subscription',
							tags: {
								planId: nextPlan.id()
							}
						})
					);

					return coupon;
				}

				let durationInMonths =
					coupon.duration() === STRIPE_DURATION.ONCE ? 1 : coupon.duration_in_months() || 1;

				if (nextPlan.intervalCount()) {
					durationInMonths = durationInMonths * nextPlan.intervalCount();
				}

				const couponValidForBillingDate =
					Math.round(
						moment(billingDate).diff(nextSubscription.created(), nextPlan.interval(), true)
					) <= durationInMonths;

				if (couponValidForBillingDate) {
					return coupon;
				}
			};

			if (currentResult && currentResult.coupon) {
				currentCoupon = new CouponSchema(currentResult.coupon);
				currentCoupon = checkLimitedDurationCoupon(
					currentCoupon,
					moment(nextSubscription.current_period_end())
						.subtract(1, 'month')
						.toDate()
				);
			}

			if (nextResult && nextResult.coupon) {
				nextCoupon = new CouponSchema(nextResult.coupon);
				nextCoupon = checkLimitedDurationCoupon(nextCoupon, nextSubscription.current_period_end());
			}
		}

		if (nextSubscription.willRenew()) {
			nextPayment = nextPlan.priceInCurrency(userCurrency, {
				coupon: nextCoupon
			});
		}
	}

	if (!nextEndDate || endDate > nextEndDate) {
		nextEndDate = endDate;
	}

	const hasLifetimePlan = this.hasLifetimePlan();

	return {
		state,
		endDate,
		subscription: sub,
		credit: credit && credit.id ? this.credits(credit.id) : null,
		nextSubscription,
		nextPayment,
		nextCoupon,
		nextEndDate,
		currentCoupon,
		isWarning,
		plan,
		currentPlan,
		hasLifetimePlan
	};
};
