'use strict';

const ProjectSchema = require('./ProjectSchema');
const SnippetSchema = require('./SnippetSchema');
const BadgeSchema = require('../BadgeSchema');

module.exports = {
	roblox: {
		username: String,
		token: String,
		privateToken: String,
		projects: [ProjectSchema],
		snippets: [SnippetSchema],
		badges: [BadgeSchema],
		xp: Number
	},

	// TODO move this somewhere better when solution for projects
	getActiveProject: function(name) {
		return this.getVertical().projects({
			name: name,
			active: {
				$not: false
			}
		});
	},

	getVertical: function(vertical) {
		if (
			vertical === require('../../data/verticals').ROBLOX ||
			CK.config.name === 'ck-roblox-sequencer'
		) {
			return this.roblox();
		} else {
			return this;
		}
	},

	assertHasPrivateToken: async function(privateToken) {
		await CK.fn.verify(
			{
				privateToken
			},
			{
				privateToken: String
			}
		);

		const userPrivateToken = this.roblox().privateToken();

		if (!userPrivateToken) {
			const PrivateTokenNotProvidedError = require('ck-core/source/errors/PrivateTokenNotProvidedError');
			throw new PrivateTokenNotProvidedError();
		} else if (userPrivateToken !== privateToken) {
			const InvalidPrivateTokenError = require('ck-core/source/errors/InvalidPrivateTokenError');
			throw new InvalidPrivateTokenError();
		}
	}
};
