import Bluebird from 'bluebird';

type Executor = (...args: any[]) => Promise<any> | void;
type PromiseResolutionHandler = (value: any) => void;

export class SerialPromiseQueue {
	private _queue: {
		fulfil: PromiseResolutionHandler;
		reject: PromiseResolutionHandler;
		name: string;
		executor: Executor;
		args: any[];
		processing?: boolean;
	}[];

	constructor() {
		this._queue = [];
	}

	size() {
		return this._queue.length;
	}

	queue(name: string, executor: Executor, ...args: any[]) {
		return new Promise((fulfil, reject) => {
			this._queue.push({
				fulfil,
				reject,
				name,
				executor,
				args
			});

			this._processQueue();
		});
	}

	private _processQueue() {
		if (!this._queue.length) {
			return;
		}

		var element = this._queue[0];

		if (element.processing) {
			return;
		}

		element.processing = true;

		Bluebird.resolve(element.executor(...element.args))
			.then(element.fulfil)
			.catch(element.reject)
			.finally(() => {
				this._queue.shift();

				this._processQueue();
			});
	}
}
