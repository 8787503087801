'use strict';

import _ from 'lodash';

const ErrorMessageModal = require('../ui/modals/messageModals/ErrorMessageModal');

(function() {
	var poisoned;

	CK.endpoints.poisonPill = function() {
		if (poisoned) return;

		poisoned = true;
		ErrorMessageModal.create(
			_.extend(
				{
					closeOnBackgroundTap: false,
					actions: {
						ok: () => {
							window.location.reload();
						}
					}
				},
				require('./poisonPillLang')
			)
		);
	};
})();
