'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands')
			.MINECRAFT_JAVA /*, require( '../../../../data/brands' ).MINECRAFT_PE*/
	], // Not supported in pocket
	id: 'trolling-2',
	name: 'Trolling 2',
	duration: 40,
	secondsDuration: 583,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'You can never do too much trolling... Tune in for part two!',
		'Create a creeper trap, make players float and confuse players when they join your server.',
		'The second part of the trolling two-parter to teach you the best tricks!'
	],
	difficulty: 'Medium',
	unlockXp: 27000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Trolling 2',
			chapters: [
				{
					name: 'Creeper Trap',
					description: 'Surprise your friends with a creeper trap.',
					secondsOffset: 20,
					badges: []
				},
				{
					name: 'Float',
					description: 'Send your friends up, up and away!',
					secondsOffset: 261,
					badges: []
				},
				{
					name: 'Burn Baby Burn!',
					description: 'Confuse and set your players on fire when they join the server.',
					secondsOffset: 447,
					badges: []
				}
			]
		}
	]
};
