'use strict';

import _ from 'lodash';

const Time = require('../services/Time');

_.extend(CK.const, {
	LIMITS: {
		COURSES: 'allowedCourses',
		WORLD_BACKUPS: 'denyWorldBackups',
		CODE_BACKUPS: 'denyCodeBackups',
		PROJECT_COUNT: 'projectCount',
		SERVER_TIME: 'serverTime',
		WORLDS: 'allowedWorlds',
		SKIP_STEP: 'skipStep',
		PLUGINS: 'denyPlugins',
		WORLD_UPLOAD: 'denyWorldUpload',
		WORLD_DOWNLOAD: 'denyWorldDownload'
	},

	LIMIT_VALUES: {
		allowedCourses: [
			'arrowmageddon',
			'finders-keepers',
			'simple-lucky-blocks',
			'blocky-races',
			'high-noon-saloon',
			'minute-mods',
			'minute-mods.0',
			'minute-mods.1',
			'minute-mods.2',
			'minute-mods.3',
			'mod-blog',
			'mod-blog.1',
			'mod-blog.17',
			'mod-blog.23',
			'become-an-enderdragon',
			'team-armour',
			'thunderblocks',
			'custom-mobs',
			'magic-wand',
			'float-players',
			'prison-escape'
		],

		allowedWorlds: [
			'default',
			'blocky-races',
			'high-noon-saloon',
			'arrowmageddon',
			'finders-keepers'
		],

		projectCount: 5,

		denyWorldBackups: true,

		denyCodeBackups: true,

		serverTime: Time.HOUR_MS * 3,

		skipStep: false,

		denyWorldDownload: true,

		denyWorldUpload: true,

		denyPlugins: true
	}
});
