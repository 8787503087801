'use strict';

import _ from 'lodash';
import { randomString } from 'ck-core/source/functions/randomString';

const CKError = require('./../classes/Error');
const Module = require('./../system/Module');

const Space = (module.exports = Module.define({
	type: 'SpaceManager',

	init_domains: function() {
		this._domainRegister = {};
		this._domainGetters = {};
		this._domainPruners = {};
		this._locks = {};
	},

	addDomain: function(domain, schema, provider, getter, pruner) {
		let DocSet = schema.Set;

		if (!DocSet) {
			CK.logger.error('Missing domain Set', domain);
			return;
		}

		// Create each time to avoid shared cache
		this._domainRegister[domain] = new DocSet({}).$enforceType();

		if (provider) {
			this._domainRegister[domain] = this._domainRegister[domain].$provider(provider);
		}

		if (getter) {
			this._domainGetters[domain] = getter;
		}

		if (pruner) {
			this._domainPruners[domain] = pruner;
		}

		this[domain] = this._domainRegister[domain].$proxy();
	},

	domains: function() {
		return _.keys(this._domainRegister);
	},

	setDomainProvider: function(domain, provider) {
		this._domainRegister[domain] = this._domainRegister[domain].$provider(provider);
		this[domain] = this._domainRegister[domain].$proxy();
	},

	getPruner(domain) {
		return this._domainPruners[domain];
	},

	model: function(spaceRoot) {
		var domain = Space.domain(spaceRoot),
			doc = Space.document(spaceRoot);

		return this._domainRegister[domain].$primary(doc);
	},

	collection: function(path) {
		return Space.domain(path);
	},

	join: function(spaceRoot, socket, waitForVerify) {
		const lockId = 'join-' + randomString();

		return this.lock(spaceRoot, lockId)
			.then(() => {
				return (socket || CK.clientSockets.users)
					.emit(
						'join',
						{
							root: spaceRoot
						},
						{
							waitForVerify
						}
					)
					.then(data => {
						this.addModelToRegister(spaceRoot, data.model);

						return data;
					});
			})
			.finally(() => {
				this.unlock(spaceRoot, lockId);
			});
	},

	addModelToRegister: function(spaceRoot, model) {
		const domain = Space.domain(spaceRoot),
			doc = Space.document(spaceRoot);

		// Store the document in the register
		if (
			CK.serverDaemon ||
			(typeof process !== 'undefined' && process.env.NO_IMPLICIT_PROVIDER_SPACE_WRITES)
		) {
			this._domainRegister[domain].$primary(doc).$(
				model,
				{
					ignoreProvider: true
				},
				{
					ignoreProvider: true
				}
			);
		} else {
			this._domainRegister[domain]
				.$try('BadValue', error => {
					CK.logger.warn(
						new CKError({
							message: 'Error adding model to register',
							tags: {
								model: model,
								root: spaceRoot,
								originalError: error,
								originalMessage: error.message,
								originalStack: error.stack
							}
						})
					);
				})
				.$primary(doc)
				.$(model);
		}
	},

	leave: function(spaceRoot, socket) {
		var domain = Space.domain(spaceRoot),
			doc = Space.document(spaceRoot);

		this._domainRegister[domain].$remove(doc);

		return (socket || CK.clientSockets.users).emit(
			'leave',
			{
				root: spaceRoot
			},
			{
				buffer: false
			}
		);
	},

	handleSync: function(spaceRoot, deltas, messageOptions) {
		const lockId = 'sync-' + randomString();

		return this.lock(spaceRoot, lockId)
			.then(() => {
				const model = this.model(spaceRoot);

				if (!model.$exists()) {
					return CK.logger.debug({
						message: 'Ignoring sync for model which is not cached',
						tags: {
							spaceRoot,
							deltas
						}
					});
				}

				const verifyType = (part, schema, options) => {
					const pair = darc.pair(part);

					if (pair) {
						switch (pair[0]) {
							case '$push':
								schema = schema[0];
								break;
						}

						part[pair[0]] = verifyType(pair[1], schema, options);

						return part;
					} else if (darc.isAtom(schema)) {
						return darc.verifyType(part, schema, options);
					}

					if (_.isPlainObject(schema)) {
						_.each(part, (value, key) => {
							part[key] = verifyType(value, schema[key], options);
						});

						return part;
					} else if (_.isArray(schema)) {
						_.each(part, (value, key) => {
							part[key] = verifyType(value, schema[0], options);
						});

						return part;
					}

					return darc.verifyType(part, schema, options);
				};

				try {
					deltas = verifyType(deltas, model.$.schema, {
						strict: false
					});
				} catch (err) {
					CK.logger.error(err);
				}

				const msg = new darc.messages.Update(
					_.extend(
						{
							deltas,
							ignoreProvider:
								CK.serverDaemon ||
								(typeof process !== 'undefined' && process.env.NO_IMPLICIT_PROVIDER_SPACE_WRITES)
						},
						messageOptions
					)
				);

				return darc.maybe(model.$(), () => {
					msg.send(model);
				});
			})
			.finally(() => {
				this.unlock(spaceRoot, lockId);
			});
	},

	lock: function(spaceRoot, id, flow) {
		var promise = new Promise(fulfil => {
			if (!this._locks[spaceRoot]) {
				this._locks[spaceRoot] = [];
			}

			this._locks[spaceRoot].push({
				id: id,
				flow: flow,

				fulfil: fulfil
			});

			this._checkLocks(spaceRoot);
		});

		return promise;
	},

	unlock: function(spaceRoot, id) {
		if (!id) {
			throw new Error('An id is needed to unlock');
		}

		if (
			this._locks[spaceRoot] &&
			this._locks[spaceRoot][0] &&
			this._locks[spaceRoot][0].id === id
		) {
			this._locks[spaceRoot].shift();
			this._checkLocks(spaceRoot);
		}
	},

	_checkLocks: function(spaceRoot) {
		var first = this._locks[spaceRoot][0];
		if (first) {
			first.fulfil();
		} else {
			delete this._locks[spaceRoot];
		}
	}
}));

_.extend(Space, {
	domain: function(spaceRoot) {
		if (!_.isString(spaceRoot)) {
			return false;
		}

		var index = Space._domainDelimiterIndex(spaceRoot);

		if (index === -1) {
			return spaceRoot;
		}

		return spaceRoot.substring(0, index);
	},

	document: function(spaceRoot) {
		if (!_.isString(spaceRoot)) {
			return false;
		}

		var index = Space._domainDelimiterIndex(spaceRoot);

		if (index === -1) {
			return spaceRoot;
		}

		spaceRoot = spaceRoot.substring(index + 1);

		if (spaceRoot.indexOf('.') === -1) {
			return spaceRoot;
		}

		return spaceRoot.substring(0, spaceRoot.indexOf('.'));
	},

	_domainDelimiterIndex: function(spaceRoot) {
		var slashIndex = spaceRoot.indexOf('/'),
			dotIndex = spaceRoot.indexOf('.');

		if (slashIndex === -1 && dotIndex === -1) {
			return -1;
		}

		if (slashIndex === -1) {
			return dotIndex;
		} else if (dotIndex !== -1) {
			return Math.min(slashIndex, dotIndex);
		}

		return slashIndex;
	},
	root: function(path) {
		var domain = Space.domain(path);
		var doc = Space.document(path);
		if (!domain || !doc) {
			return false;
		}
		return domain + '/' + doc;
	},

	userIdFromRoot: function(root) {
		const domain = Space.domain(root);

		if (domain === 'users') {
			return Space.document(root);
		}
	}
});
