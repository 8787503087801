'use strict';

module.exports = require('../data/createCollection')(require('../schemas/MinecraftPluginSchema'), [
	{
		id: 'worldedit',
		name: 'WorldEdit',
		version: '7.2.4',
		description: 'Edit your world in-game.',
		command: '/help worldedit'
	},
	{
		id: 'clearlagg',
		name: 'ClearLagg',
		version: '3.1.6',
		description: 'Reduce lag by limiting the number of entities in your server.',
		command: '/lagg'
	}
]);
