'use strict';

import _ from 'lodash';

module.exports = require('ck-core/source/system/Extendable').define({
	init_Component: function() {
		this.visible = true;
	},

	bind: function() {
		if (this.bound) {
			return;
		}

		this.active = true;

		if (this.visible) {
			if (this.onInitiallyVisible) {
				this._visiblityCheck = _.debounce(() => {
					// CK.logger.debug( '_visiblityCheck', this.dom && this.dom.isInViewport(), this.dom );

					if (this.dom && this.dom.isInViewport()) {
						this.onInitiallyVisible();
						this._removeVisibilityCheck();
					}
				}, 500);

				window.addEventListener('scroll', this._visiblityCheck);
				window.addEventListener('resize', this._visiblityCheck);
			}

			// Only bind if it's visible
			this.Extendable_bind();
		}
	},

	_removeVisibilityCheck: function() {
		if (this._visiblityCheck) {
			window.removeEventListener('scroll', this._visiblityCheck);
			window.removeEventListener('resize', this._visiblityCheck);
			this._visiblityCheck = null;
		}
	},

	unbind_Component: function() {
		this._removeVisibilityCheck();

		this.active = false;
	},

	show: function(displayMode) {
		if (!this.dom) {
			return CK.logger.warn('element cleaned up', this.type);
		}
		if (displayMode) {
			this.dom.css('display', displayMode);
		} else {
			this.dom.show();
		}

		this.visible = true;

		if (this.active) {
			this.bind();
		}

		return this;
	},

	hide: function() {
		this.dom.hide();

		this.visible = false;

		if (this.active) {
			this.unbind();
			// Reset active to true after unbind
			this.active = true;
		}

		return this;
	},

	appendTo: function(e) {
		e.append(this.dom);

		return this;
	},

	prepend: function(element) {
		if (!this.dom || !element) {
			return;
		}

		if (element.dom) {
			this.dom.prepend(element.dom);
		} else {
			this.dom.prepend(element);
		}

		return this;
	},

	append: function(...elements) {
		for (let element of elements) {
			if (!this.dom || !element) {
				continue;
			}

			if (_.isArray(element)) {
				this.append(...element);

				return this;
			}

			if (element.dom) {
				this.dom.append(element.dom);
			} else {
				this.dom.append(element);
			}
		}

		return this;
	},

	addClass: function(className) {
		this.dom.addClass(className);

		return this;
	},

	removeClass: function(className) {
		this.dom.removeClass(className);

		return this;
	},
	remove: function() {
		if (this.dom) {
			this.dom.remove();
			this.dom = null;
		}

		return this;
	},

	cleanup_Component: function() {
		return this.remove();
	},

	assignId: function(id) {
		if (this.dom) {
			const currentId = this.dom.attr('id');

			if (currentId) {
				throw new Error('Overwriting id ' + currentId);
			}

			this.dom.attr('id', id);
		} else {
			throw new Error('assignId with missing dom');
		}

		return this;
	},

	type: 'Component'
});
