'use strict';

import _ from 'lodash';
import { LogSource } from 'ck-core/source/classes/LogSource';
import { default as randomUuid } from 'ck-core-server/source/functions/randomUuid';

const CKError = require('./Error');
const Errors = require('./../services/Errors');
const SpaceManager = require('./../managers/SpaceManager');
const Extendable = require('./../system/Extendable');

const Flow = (module.exports = Extendable.define({
	type: 'Flow',

	DEFAULT_FLOW_TIMEOUT: 10000,

	init_Flow: function(endpointName, request, socket, spaceManager) {
		this.name = endpointName;
		this.request = request;
		this.socket = socket;

		if (this.socket && this.socket.randomFlowId) {
			this.id = this.socket.randomFlowId();
		} else {
			this.id = randomUuid();
		}

		_.each(CK.flowMethods, (method, name) => {
			this[name] = method.bind(this);
		});

		this.spaceManager = spaceManager || SpaceManager.mod();

		this.loggerContext = {
			flowId: this.id,
			flowName: this.name,
			request: this.request,
			logStackOffset: 6
		};

		this.logger = new LogSource(() => this.loggerContext);

		if (endpointName === 'log') {
			this.logger.trace = function() {
				// NOOP
			};
		}

		_.each(this.spaceManager.domains(), this._domainMethodFactory.bind(this));
	},

	_domainMethodFactory: function(name) {
		this[name] = this.spaceManager[name];

		this['transact' + _.upperFirst(name)] = function() {
			return Promise.resolve();
		};
	},

	runPostCommit: function(fn) {
		if (!this._postCommitFunctions) {
			this._postCommitFunctions = [];
		}
		this._postCommitFunctions.push(fn);
	},

	process: function(fn, ...args) {
		this.logger.trace('Processing flow');

		if (this._processCalled) {
			throw new Error('Process called twice on same flow');
		}

		this._processCalled = true;

		const startTime = Date.now();

		let cleanup;

		const slowTimeout = setTimeout(() => {
			this.logger.warn('Slow flow');

			// If it times out we remove the lock but do not do anything more forcible to allow it to finish slowly if necessary

			this._unlockTransactions();

			cleanup && cleanup();

			CK.metric.timing('endpointTime', Date.now() - startTime, 1, [`name:${this.name}`]);
		}, fn.FLOW_TIMEOUT || this.DEFAULT_FLOW_TIMEOUT);

		cleanup = () => {
			clearTimeout(slowTimeout);

			if (this.socket && this.socket.processingFlows) {
				delete this.socket.processingFlows[this.id];
			}
		};

		CK.metric.increment('endpointRequest', 1, 1, [`name:${this.name}`]);

		let promise;
		const flowCall = this.argsOnly
			? () => fn.call(this, ...args)
			: () => fn.call(this, this.request, ...args);

		try {
			if (fn.verifiers) {
				promise = CK.fn.verify(this.request, fn.verifiers).then(() => {
					return flowCall();
				});
			} else {
				promise = flowCall();
			}

			promise = Promise.resolve(promise);
		} catch (e) {
			clearTimeout(slowTimeout);
			promise = Promise.reject(e);
		}

		this.promise = promise = promise
			.then(val => {
				return this._commit()
					.then(() => {
						try {
							if (this._postCommitFunctions) {
								for (let fn of this._postCommitFunctions) {
									setImmediate(fn);
								}
							}
						} catch (err) {
							this.logger.error(err);
						}
					})
					.then(() => {
						return val;
					});
			})
			.catch(err => {
				CK.metric.increment('endpointError', 1, 1, [`name:${this.name}`]);

				try {
					if (_.isNil(err)) {
						this.logger.error('Nil error thrown');
						err = new Errors.BadRequest();
					}

					if (!err.darc && !(err instanceof CKError)) {
						err = new CKError(err);
					}
					const context = this.getContext();

					if (context) {
						err.tags = err.tags || {};
						_.extend(err.tags, this.getContext());
					}

					if (err.soft) {
						this.logger.debug(err);
					} else {
						this.logger.error(err);
					}
				} catch (err2) {
					this.logger.error(err2);
				}

				this._cancel();

				cleanup();

				this.logger.trace('Flow done with error');
				throw err;
			})
			.then(val => {
				const timeTaken = Date.now() - startTime;

				this.logger.trace('Flow done', {
					timeTaken
				});

				if (val !== Flow.NO_RESPONSE) {
					CK.metric.timing('endpointTime', timeTaken, 1, [`name:${this.name}`]);
				}

				cleanup();

				return val;
			});

		if (this.socket && this.socket.processingFlows) {
			this.socket.processingFlows[this.id] = promise;
		}

		return promise;
	},

	model: function() {
		return this.spaceManager.model(this.request.root);
	},

	addLoggerContext: function(obj) {
		_.extend(this.loggerContext, obj);
	},

	_cancel: function() {},

	getContext: function() {},

	transactUser: function() {
		return Promise.resolve();
	},

	_commit: function() {
		return Promise.resolve();
	},

	_unlockTransactions: function() {},

	waitForOtherFlowsToProcess: function() {
		if (!this.socket || !this.socket.processingFlows) {
			return Promise.resolve();
		}

		return Promise.all(
			_.map(this.socket.processingFlows, (flowPromise, id) => {
				if (id === this.id) {
					return;
				}

				this.logger.debug('Waiting for flow', id, 'to process...');
				// Give flows some time to resolve before assuming they are done
				return Promise.race([flowPromise, Promise.delay(this.DEFAULT_FLOW_TIMEOUT)]).reflect();
			})
		);
	}
}));
Flow.NO_RESPONSE = Symbol('Flow.NO_RESPONSE');
