import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';

const isProduction =
	(typeof process !== 'undefined' && process.env.CK_ENV === CK_ENV.PRODUCTION) ||
	(CK.config && CK.config.environment && CK.config.environment.name === CK_ENV.PRODUCTION);

const setActiveState = function(state, tests) {
	for (let test of tests) {
		if (test.active === undefined) {
			test.active = state;
		}
	}

	return tests;
};

// TODO: Use it to automate subtest independent partitioning when sub-test-range-automation branch is ready
// const proportionOfRanges = require( '../functions/proportionOfRanges' );
const abTestData = setActiveState(true, require('../data/ActiveABTests')).concat(
	isProduction ? [] : setActiveState(true, require('../data/TestABTests')),

	setActiveState(false, require('../data/OldABTestDefinitions')),

	require('../data/ABFlagDefinitions').map(key => {
		return {
			id: key,
			ranges: [[0, 1]],

			active: true
		};
	})
);

const rangeBoundValid = value => _.isFinite(value) && value >= 0 && value <= 1;

for (let test of abTestData) {
	if (!test.active) {
		continue;
	}

	if (!test.ranges || !test.ranges.length) {
		throw new Error(`No ranges for ${test.id}`);
	}

	for (let range of test.ranges) {
		if (!rangeBoundValid(range[0]) || !rangeBoundValid(range[1]) || range[0] > range[1]) {
			throw new Error(`Range is invalid for ${test.id}: ${range}`);
		}
	}
}

module.exports = require('../data/createCollection')(
	require('../schemas/ABTestSchema'),
	abTestData
);
