'use strict';

module.exports = {
	NO_PLAN: 'noPlan',
	TRIAL: 'trial',
	TRIAL_ONE_OFF: 'trialOneOff',
	TRIAL_CANCELLED: 'trialCancelled',
	ONE_OFF: 'oneOff',
	ONE_OFF_PENDING_SUBSCRIPTION: 'oneOffPendingSubscription',
	SUBSCRIPTION: 'subscription',
	SUBSCRIPTION_CANCELLED: 'subscriptionCancelled',
	SUBSCRIPTION_PAST_DUE: 'subscriptionPastDue',
	SUBSCRIPTION_PENDING_ONE_OFF: 'subscriptionPendingOneOff',
	CAMP_ADMIN: 'campAdmin',
	CAMP_LEADER: 'campLeader',
	CAMP_STUDENT: 'campStudent',
	CAMP_STUDENT_NO_LICENCED_GROUP: 'campStudentNoGroup',
	EXPIRED: 'expired',
	REVOKED: 'revoked'
};
