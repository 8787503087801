'use strict';

const CoreWebFrame = require('../../../../core-web/source/ui/CoreWebFrame');

const storageAffiliateKey = require('../../data/storageAffiliateKey');

const WebsiteFrame = (module.exports = CoreWebFrame.define({
	type: 'WebsiteFrame',

	defaultPage: 'modding/home',

	init: function(parent, options) {

		

		this.activePromo = !options.noPromo && require('../../data/activePromo');

		if (CK.config.name === 'ck-coolmath') {
			this.activePromo = !options.noPromo && require('../../data/CoolmathPromo');
		}

		options.routes = WebsiteFrame.ROUTES;

		this.CoreWebFrame_init(parent, options);

		this.checkRoutes();
		this.setAutoAffiliate();
	},

	getDefaultPage: function() {
		if (location.pathname === '/') {
			if (CK.user.$exists()) {
				let path = CK.user.canUseMinecraft() ? '/platform-select/' : '/start-modding/';

				path += document.location.search;
				path += document.location.hash;

				return path;
			}
		}

		return this.CoreWebFrame_getDefaultPage();
	},

	setAutoAffiliate: async function() {
		const StorageManager = require('ck-core-client/source/managers/StorageManager');
		const storage = StorageManager.mod();

		const updateAffiliate = async () => {
			let affiliate = storage.get(storageAffiliateKey);

			const campCouponAvailable = !CK.user.hasHadAPlan() && CK.user.isCampStudent();

			if (campCouponAvailable) {
				CK.logger.log('User is a camp student, attempting to set affiliate');

				const campId = CK.user
					.roles()
					.$one({
						name: CK.const.ROLES.CAMP_STUDENT.name
					})
					.camps()
					.$eq(0)
					.id();

				const campDetails = await CK.clientSockets.users.emit('getCampDetails', {
					id: campId
				});

				affiliate = campDetails.couponPrefix;
			} else if (this.activePromo && this.activePromo.getProps()) {
				affiliate = this.activePromo.getProps().affiliate;
			}
			if (affiliate) {
				storage.set(storageAffiliateKey, affiliate);
				this.broadcast(this._class.EVENTS.AFFILIATE_SET);
			}
		};

		CK.user.$bind(updateAffiliate);

		const UserManager = require('ck-core-client/source/managers/UserManager');

		this.on(UserManager.EVENTS.LOGOUT, () => {
			storage.remove(storageAffiliateKey);
		});

		await updateAffiliate();
	}
}));

WebsiteFrame.ROUTES = {
	// Active pages
	'forgot-password': 'ForgotPassword',
	'reset-password': 'ResetPassword',
	faq: 'Faq',
	reset: 'ResetPassword',
	gift: 'RedeemLegacyGift',
	policies: 'Policy',
	jobs: 'Jobs',
	educators: 'Educators',
	'teachers-subscribe': 'TeachersSubscribe',
	schools: 'Schools',
	'camps-faq': 'CampsFaq',
	'educators-new': 'Educators',

	// If this landing page changes, CDN purging also needs to be updated
	'code-your-own-minecraft-mods': 'MinecraftLanding',
	'coding-for-kids': 'Home',
	redeem: 'RegisterWithSubUserToken',
	'roblox-parents': 'Home',
	'minecraft-parents': 'Home',
	parents: 'ParentsLanding',
	'roblox-coding': 'RobloxLanding',
	minecraft: 'MinecraftLanding',
	courses: 'PlatformSelect',
	coupon: 'CampCoupon',

	'your-purchase': 'LegacyYourPurchase',

	'staff/search': 'StaffSearch',
	'staff/user': 'StaffUser',
	'staff/camp': 'StaffCamp',
	'staff/email-campaigns': 'StaffEmailCampaigns',
	staff: 'StaffSearch',

	// Old routes
	'modding/home': 'Home',
	'intro-to-the-code-editor': 'Home',
	'about-us': 'About',
	'frequently-asked-questions': 'Faq',
	'get-started': 'Home',
	'start-modding': 'Home',
	register: 'MinecraftLanding',
	blog: 'MainBlog',
	'ck-game': 'GameOver',

	// Search ad routes just in case someone tries to copy the fake URL
	'code-your-own-mods': 'MinecraftLanding',
	'refer-friends': 'MinecraftLanding',
	testimonials: 'Home',

	'verify-to-start': 'PlatformSelect',

	// Fake blog pages for content discovery platforms
	'need-to-learn-coding': 'StaticLearnCoding',

	camp: 'CampCoupon'
};

WebsiteFrame.EVENTS = {
	AFFILIATE_SET: 'WebsiteFrame.affiliateSet'
};
