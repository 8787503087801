'use strict';

import _ from 'lodash';

const StorageManager = require('../managers/StorageManager');
const ContextContributor = require('ck-core/source/services/ContextContributor');
const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define(ContextContributor, {
	type: 'UtmTracking',

	CONVERSION_PREFIX: 'conversion_',

	init_UtmTracking: function() {
		this.update(CK.fn.getQueryVariables());
	},

	update: function(params) {
		// Set manual UTM for referrer
		if (document.referrer && document.referrer.length) {
			params['mp_referrer'] = document.referrer;
		}

		_.each(CK.const.UTM_TRACKING_FIELDS, field => {
			if (params[field]) {
				var existingField = StorageManager.mod().get(field);

				if (!existingField) {
					StorageManager.mod().set(field, params[field]);
				}

				// Set conversion UTM source every time
				StorageManager.mod().set(this.CONVERSION_PREFIX + field, params[field]);
			}
		});
	},

	getContext: function() {
		var context = {};

		var trackingFields = _.clone(CK.const.UTM_TRACKING_FIELDS);
		// Add conversion prefix to each field

		_.each(CK.const.UTM_TRACKING_FIELDS, fieldName => {
			trackingFields.push(this.CONVERSION_PREFIX + fieldName);
		});

		_.each(trackingFields, field => {
			var existingField = StorageManager.mod().get(field);

			if (existingField) {
				context[field] = existingField;
			}
		});

		return context;
	}
});
