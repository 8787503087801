'use strict';

if (typeof jQuery !== 'undefined') {
	jQuery.fn.animateCss = function(animationName, duration, preserveClass) {
		if (duration) {
			$(this).css('animation-duration', duration);
			$(this).css('-webkit-animation-duration', duration);
			$(this).css('-moz-animation-duration', duration);
		}

		const animationEnd =
			'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

		return new Promise(fulfil => {
			this.addClass('animated ' + animationName).one(animationEnd, function() {
				if (!preserveClass) {
					$(this).removeClass('animated ' + animationName);
				}
				fulfil();
			});
		});
	};
}
