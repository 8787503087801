'use strict';

import 'ck-core-client';

import './source/globalLess/CoreWeb.less';
import './source/globalLess/Boxes.less';
import './source/globalLess/button.less';

require('./source/globals/CoreWebConstants');
require('./source/globals/CoreWebFunctions');

if (CK.pageContexts) {
	CK.pageContexts.push(
		require.context('bundle?lazy&name=[name]!./source/pages', true, /Page\.jsx?$/)
	);
}
