'use strict';

import _ from 'lodash';

const ORDER = ['Starter', 'Easy', 'Medium', 'Hard'];

const NAMES = _.keyBy(ORDER);

module.exports = {
	ORDER,
	NAMES
};
