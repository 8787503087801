'use strict';

module.exports = require('ck-core/source/functions/makeLang')({
	TITLE: 'Take Minecraft to the next level.',
	SUBTITLE: 'Learn how to code Minecraft mods on your own server. Ages 8+',
	SUBTITLE_ACTIVITIES: [
		'your own Lucky Blocks server',
		'a magic wand that shoots fireballs',
		'an infinite parkour creator',
		'a never-ending mob survival arena',
		'a treasure-hunting multiplayer server game',
		'your own Hunger Games style server',
		'a "one in the chamber" server',
		'a stick that knocks enemies across the world',
		'custom commands',
		'unbreakable items',
		'a cow-themed nightclub dancing game',
		'a fast-paced food making and farming game',
		'a Viking themed PVP minigame'
	],
	SEO_TITLE: 'Learn how to build your own Minecraft mods and Roblox games',
	START_MODDING: 'Start Modding',
	LEARN_MORE: 'Learn More',
	COURSE_BUTTON: 'View courses',
	VIDEO_FALLBACK_URL: 'landing-video-fallback-lucky-block',
	DYNAMIC_AD_LANDING: {},
	CHECKLIST: [
		{
			title: 'Get started quickly and easily',
			description:
				"We're browser based, meaning nothing to install and all of the difficult server setup is done for you"
		},
		{
			title: 'Code your own server',
			description: 'Invite friends, deploy changes during live game-play and use your own maps!'
		},
		{
			title: 'Easy to follow project tutorials',
			description: 'Learn to mod through step-by-step videos and guides'
		},
		{
			title: 'Design and build your own server games',
			description: 'Get a head start in game development with skills in coding and game design'
		},
		{
			title: 'Get coding support 7 days a week',
			description:
				"If you get stuck, we're only a message away with developers and game designers ready to help you"
		}
	],
	MODS_IN_ACTION: {
		TITLE: 'See our mods in action',
		SUBTITLE: 'Live modding session<br>with Youtuber JeromeASF'
	}
});
