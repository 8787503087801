'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'egg-wars',
	name: 'Egg Wars',
	duration: 120,
	secondsDuration: 3876,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Create your own PVP Egg Wars server!',
		'Battle your friends to have the last egg still in tact and become champion of the server!',
		'This course will allow you to expand your knowledge of adding rules to a PVP game, as well as learn more about structure generation and for loops.'
	],

	difficulty: 'Easy',
	unlockXp: 12000,

	videos: [
		{
			name: 'Egg Wars',
			chapters: [
				{
					name: `Give 'Em Shell`,
					description:
						'In this chapter you will add a conditional to send a message to a player if they place a beacon block',
					badges: ['communication-1', 'booleans-2'],
					secondsOffset: 29
				},
				{
					name: 'Live And Let Fry',
					description: 'This chapter uses booleans to detect if a player has placed an egg',
					badges: ['communication-2', 'nesting-1'],
					secondsOffset: 282
				},
				{
					name: 'Stay Yolk!',
					description: 'This chapter teaches you how to use code to build a base around your egg',
					badges: ['general-1', 'operators-2', 'generalisation-1', 'decomposition-1'],
					secondsOffset: 602
				},
				{
					name: 'Eggsperience Points',
					description: 'This chapter teaches you how to set your egg as your spawn point',
					badges: ['methods-1', 'booleans-1', 'conditions-1'],
					secondsOffset: 1230
				},
				{
					name: 'Double Egged Sword',
					description:
						'This chapter is where you add the code for when a player has their egg is broken',
					badges: ['nesting-1', 'loops-2', 'logic-2'],
					secondsOffset: 1628
				},
				{
					name: 'Everyone Cracks',
					description: 'This chapter changes defeated players to spectator mode if they are killed',
					badges: ['logic-1', 'inventory-1'],
					secondsOffset: 2076
				},
				{
					name: 'Shellshock & Awe',
					description: 'This chapter shows you how to add some checks into the start of the game',
					badges: ['nesting-1', 'logic-1', 'loops-2'],
					secondsOffset: 2228
				},
				{
					name: 'Egg & Toast Soldiers',
					description: 'This chapter uses booleans to detect if the fight has started or not',
					badges: ['methods-2', 'fields-1'],
					secondsOffset: 2568
				},
				{
					name: 'The Shelling Begins',
					description:
						'This chapter adds some code for the player at the start to set them up for the fight',
					badges: ['parameters-1', 'methods-2'],
					secondsOffset: 3002
				},
				{
					name: `I'm Egg Up With This World`,
					description: 'This chapter shows you how to add a survivor count and restart the game',
					badges: ['nesting-1', 'loops-1', 'conditions-1', 'time-1'],
					secondsOffset: 3354
				}
			]
		}
	]
};
