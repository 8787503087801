'use strict';

const isAnonymousUser = require('../functions/entryPredicates/isAnonymousUser');

module.exports = [
	{
		id: 'LandingPageCopyAll1',
		ranges: [[0, 0.5]],
		entryPredicate: isAnonymousUser
	},
	{
		id: 'LandingPageValidation',
		ranges: [[0, 0]],
		entryPredicate: isAnonymousUser
	},
	{
		id: 'TrialThenAnnual',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'OneUnitMonth',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'AnimatedStartTrial',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'HomeBuyButtonWording',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'PromotionalCodeWording',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageEarlyBirdSummaryBox',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'MonthlyAnnualPricing',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'StripeCheckoutModal',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPages',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'LandingPages2',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'DemoLaunch',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'OnboardingVideosInSequencer',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},
	// Control is A, B, C

	{
		id: 'LandingPageDemoButtons',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageDemoButtons2',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'RegisterPageSingle',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'RegisterPageSingle2',
		groups: ['A', 'B', 'C']
	},
	{
		id: 'TrialExpiryReminderEmail',
		groups: ['A', 'B', 'C']
	},
	{
		id: 'RunButtonCardDetails',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'CreditCardFormNewDesign1',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'RegisterFormLandingPage',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'CheckoutDetailsSimple',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'CheckoutDetailsStopAndModal',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'TrialExpiryReminderEmail2',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'FirstMonthHalfPriceCoupon',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageTrialValueCopy',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'NewSequencerOnboardingFlow',
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'LandingPageAgeCopy',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageButtonsReturn',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'NewStartTrialPage',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'NewStartTrialPageFixed',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageRegistrationFlow',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageRegistrationFlowFixed',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageVariations',
		useAlternate: true,
		groups: ['A', 'B', 'C', 'D', 'E', 'F']
	},

	{
		id: 'LandingPageVideoDesign',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPagePurpleBackground',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingPageVideoDesignNewCopy',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'RegisterPageNewFlow',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'RegisterPageNewFlowFixed',
		groups: ['A', 'B', 'C']
	},
	{
		id: 'LuckyBlocksOrVideoLandingPages',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingCopySimpleWhatYouGet',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingCopyAnimatedWhatYouGet',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'IntegratedStartTrialPage',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingBigButton',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'BasicRegister',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'FreeTrialAndAgeLanding',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'OneMonthFreeTrial',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'AnimatedStartTrialFix',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'ImprovedControlOnAnimatedStartTrial',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'LandingLuckyBlockVideo',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'HidePaypal',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'StartModdingCourses',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'ContinuousStartTrialFlow',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'StartTrialChoosePlan',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'IntercomForLeads',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'StartTrialChoosePlanChristmasDiscount',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'CardWall',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'WhatModWillYouMakeCopy',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'OriginalLandingPageCopy',
		useAlternate: true,
		groups: ['A', 'B', 'C']
	},

	{
		id: 'MonthlyHeadlinePrice',
		groups: ['A', 'B', 'C']
	},

	{
		id: 'VideoLandingBackgroundCreeper',
		ranges: [[0, 0.5]]
	},

	{
		id: 'ConfirmEmailForServer',
		ranges: [[0.2, 0.4]]
	},

	{
		id: 'CKFreeArrowmageddon',
		ranges: [
			[0, 0.025],
			[0.05, 0.075]
		]
	},

	{
		id: 'PlaintextConfirmationEmail',
		ranges: [[0, 0.05]]
	},

	{
		id: 'EarlyIntroVideo',
		ranges: [[0.05, 0.1]]
	},

	{
		id: 'ThirdPartySignOn',
		ranges: [
			[0.05, 0.1],
			[0.1, 0.55]
		],
		exitPredicate: function(user) {
			// Remove users who signed up before the test launched
			return user.dateCreated() < new Date('2017-03-16T22:25:00Z');
		}
	},

	{
		id: 'NoArrowmageddonTooltip',
		ranges: [[0.05, 0.1]]
	},

	{
		id: 'SetupServerVideoOnly',
		ranges: [
			[0, 0.2],
			[0.4, 0.6],
			[0.8, 0.85],
			[0.9, 0.95]
		]
	},

	{
		id: 'NewOneOffPricing',
		ranges: [[0, 0.4]]
	},

	{
		id: 'CKFreeReferrals'
	},

	{
		id: 'CKFree'
	},

	{
		id: 'TryBeforeTrial'
	},

	{
		id: 'OnePlanOnlyPricing'
	},

	{
		id: 'AprilCoupon',
		ranges: [
			[0, 0.4],
			[0.8, 0.9]
		]
	},

	{
		id: 'EmailCaptureNewModalMessaging',
		ranges: [
			[0, 0.4],
			[0.9, 1]
		]
	},

	{
		id: 'NewLandingVideoParents',
		ranges: [
			[0, 0.2],
			[0.4, 0.6],
			[0.8, 0.85],
			[0.9, 0.95]
		]
	},

	{
		id: 'NoTrialMonthlyPlan',
		ranges: [
			[0, 0.1],
			[0.2, 0.3],
			[0.4, 0.5],
			[0.6, 0.7],
			[0.8, 0.9]
		]
	},

	{
		id: 'TryBeforeBuy',
		ranges: [[0, 0.8]]
	},

	{
		id: 'LifetimePlan',
		ranges: [[0, 0.5]]
	},

	{
		id: 'HighNoonLong',
		ranges: [
			[0, 0.4],
			[0.8, 0.9]
		]
	},

	{
		id: 'LongLandingPage',
		ranges: [[0, 0.5]]
	},

	{
		id: 'numberOfAccounts',
		ranges: [[0, 0.5]],
		entryPredicate: isAnonymousUser
	},

	{
		id: 'numberOfAccounts2',
		ranges: [[0, 0.5]],
		entryPredicate: isAnonymousUser
	},

	{
		id: 'FindersKeepersOnboarding',
		entryPredicate: isAnonymousUser,
		ranges: [[0, 1]]
	},

	{
		id: 'FindersKeepersOnboarding2',
		entryPredicate: isAnonymousUser,
		ranges: [[0, 1]]
	},

	{
		id: 'MostPopularPlanHighlight',
		entryPredicate: isAnonymousUser,
		ranges: [[0.25, 0.75]]
	},

	{
		id: 'FindersKeepersOnboarding3',
		entryPredicate: isAnonymousUser,
		ranges: [[0, 0.5]]
	},

	{
		id: 'ThreeMonthRecurringPlan',
		ranges: [[0.25, 0.75]]
	},

	{
		id: 'AdwordsFreeTrial',
		ranges: [[0, 0.5]]
	},

	{
		id: 'AdwordsNoTryBeforeBuy',
		ranges: [[0, 0.75]]
	},

	{
		id: 'FixedAdwordsFreeTrial',
		ranges: [[0, 0.5]]
	},

	{
		id: 'FixedAdwordsNoTryBeforeBuy',
		ranges: [[0, 0.75]]
	},

	{
		id: 'TimedDiscountForVisitors',
		ranges: [[0, 0.5]]
	},

	{
		id: 'ObviousGiftPlans',
		ranges: [
			[0, 0.25],
			[0.75, 1]
		]
	},

	{
		id: 'FamilyPlanAtEnd',
		ranges: [[0, 0.5]]
	},

	{
		id: 'LandingPageCopyMinecraft1',
		ranges: [[0.4, 0.6]]
	},

	{
		id: 'LandingPageCopyMinecraft2',
		ranges: [
			[0.3, 0.4],
			[0.6, 0.7]
		]
	},

	{
		id: 'LandingPageCopyMinecraft3',
		ranges: [
			[0.2, 0.3],
			[0.7, 0.8]
		]
	},

	{
		id: 'FamilyPlanAtEndFixed',
		ranges: [[0, 0.5]]
	},

	{
		id: 'LandingPageCopyMinecraft1Fixed',
		ranges: [[0.4, 0.6]]
	},

	{
		id: 'LandingPageCopyMinecraft2Fixed',
		ranges: [
			[0.3, 0.4],
			[0.6, 0.7]
		]
	},

	{
		id: 'LandingPageCopyMinecraft3Fixed',
		ranges: [
			[0.2, 0.3],
			[0.7, 0.8]
		]
	},

	{
		id: 'MinecraftRobloxPageVideoFrames',
		ranges: [
			[0, 0.1],
			[0.2, 0.25],
			[0.3, 0.35],
			[0.4, 0.45],
			[0.5, 0.55],
			[0.6, 0.65],
			[0.7, 0.75],
			[0.8, 0.9]
		]
	},

	{
		id: 'LandingPageCopyAll2',
		ranges: [[0.25, 0.5]]
	},

	{
		id: 'LandingPageCopyAll3',
		ranges: [[0.5, 0.75]]
	},

	{
		id: 'Recurring12MonthPlan',
		ranges: [
			[0, 0.05],
			[0.3, 0.5],
			[0.5, 0.55],
			[0.7, 0.9]
		]
	},

	{
		id: 'EasilyAccessibleTrialMode',
		ranges: [
			[0, 0.1],
			[0.5, 0.6]
		]
	},

	{
		id: 'PlanSizeTabs',
		ranges: [[0, 1]]
	},
	{
		id: 'InlineCheckout',
		ranges: [[0, 0.5]]
	},
	{
		id: 'PlanRows',
		ranges: [[0, 0.5]]
	},
	{
		id: 'VaguePromoCountdown',
		ranges: [[0, 0.5]],
		entryPredicate: isAnonymousUser
	}
];
