'use strict';

import _ from 'lodash';

import './Footer.less';
import MenuProps from '../data/MenuProps';
import React from 'react';
import MenuItem from './MenuItem';
import ReactCKImage from './ReactCKImage';
import FooterEntries from '../data/FooterEntries';
import classnames from 'classnames';
import FooterPromo from './FooterPromo.jsx';

export default class Footer extends React.Component {
	render() {
		const menuItems = _.map(this.props.entries, entry => (
			<li key={entry.href}>
				<MenuItem entry={entry} handleClick={this.props.handleClick} />
			</li>
		));

		const showActivePromo = CK.fn.isNonGenericPromo(this.props.promo);

		const classes = classnames('footer', showActivePromo && 'promo');

		return (
			<div className={classes}>
				{showActivePromo && <FooterPromo {...this.props.promo} />}

				<div className="footerWrapper">
					<div className="logo">
						<ReactCKImage name="footer-logo" />
					</div>

					<div className="copyright">&copy; 2013-{new Date().getFullYear()} Codebr Ltd</div>

					<div className="links">
						<ul>{menuItems}</ul>
					</div>
				</div>
			</div>
		);
	}
}

Footer.defaultProps = {
	entries: FooterEntries
};

Footer.propTypes = MenuProps;
