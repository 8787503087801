'use strict';

import _ from 'lodash';

const ModuleContainer = require('ck-core/source/system/ModuleContainer');
const Extendable = require('ck-core/source/system/Extendable');
const CKError = require('ck-core/source/classes/Error');
const Errors = require('ck-core/source/services/Errors');

module.exports = require('ck-core/source/system/Module').define({
	type: 'ClientModule',

	emit: function(socketName, endpoint, data, options) {
		return CK.clientSockets[socketName]
			.emit(endpoint, data, options)
			.then(result => {
				if (this._parent === null) {
					throw new Errors.EmitCancelledAfterPageCleanup();
				} else {
					return result;
				}
			})
			.catch(err => {
				if (this._parent === null) {
					CK.logger.warn(err);
					throw new Errors.EmitCancelledAfterPageCleanup();
				} else {
					throw err;
				}
			});
	},

	actions: function(viewClass, controller) {
		var viewType = viewClass.NAME;

		if (!this._declaredActions) this._declaredActions = {};

		if (!this._declaredActions[viewType]) this._declaredActions[viewType] = {};

		if (Extendable.isInstance(controller)) {
			// TODO: Only issue here is that a lot of rubbish gets assigned to the actions object. We should make a filter by super type and pass it ModuleController
			var names = Object.keys(Object.getPrototypeOf(controller));
			for (var i = 0; i < names.length; i++) {
				if (typeof controller[names[i]] === 'function') {
					this._declaredActions[viewType][names[i]] = controller[names[i]].bind(controller);
				}
			}
		} else if (_.isPlainObject(controller)) {
			_.each(controller, fn => {
				fn.self = this;
			});

			_.extend(this._declaredActions[viewType], controller);
		}

		// TODO: Warn if you overwrite your own actions?
	},

	inputs: function(context) {
		if (!this._declaredInputs) this._declaredInputs = {};

		_.extend(this._declaredInputs, context); // TODO: Warn if you overwrite your own inputs?
	},

	/**
	 * Call recursively up to your parent with some data from a declaration field.
	 * When the root module is reached.

	 */

	_bubbleToRoot: function(data, sourceModule, field, method) {
		var dataDeclarationField = '_declared' + field;

		if (!data) {
			// Terminate if the first caller does not have any declarations of this type

			if (!this[dataDeclarationField]) return;
			data = this[dataDeclarationField];
			sourceModule = this;
		}

		if (this._parent.instanceOf(ModuleContainer)) {
			var mergedDeclarationsField = dataDeclarationField + 'Merged';
			if (!this[mergedDeclarationsField]) {
				this[mergedDeclarationsField] = [];
			}

			if (method === 'bind') {
				this[mergedDeclarationsField].push({
					data: data,

					sourceModule: sourceModule
				});
			} else if (method === 'unbind') {
				var index = _.findIndex(this[mergedDeclarationsField], {
					sourceModule
				});

				this[mergedDeclarationsField].splice(index, 1);
			} else {
				throw new Error('Unhandled method for bubble: ' + method);
			}

			// TODO: Currently recalculates from merged declaration fields, could be more efficient
			this._parent['_' + method + dataDeclarationField]();
		} else {
			this._parent[method + '_' + field](data, sourceModule, field, method);
		}
	},

	promiseAlive: function(promise, silent) {
		return promise
			.catch(err => {
				if (this.dom || !silent) {
					throw err;
				}
			})
			.then(result => {
				if (this.dom) {
					return result;
				}

				if (silent) {
					return darc.defer();
				} else {
					throw new CKError({
						message: 'Module cleaned up before promise resolved',
						tags: {
							type: this.type,
							result: CK.fn.safeFormat(result)
						}
					});
				}
			});
	},

	setTimeout: function(fn, delay) {
		if (!this._timeouts) {
			this._timeouts = [];
		}

		this._timeouts.push(setTimeout(fn.bind(this), delay));
	},

	setInterval: function(fn, delay, runImmediately) {
		if (!this._intervals) {
			this._intervals = [];
		}

		fn = fn.bind(this);

		let interval = null;

		if (this.bound) {
			interval = setInterval(fn, delay);

			if (runImmediately) {
				fn.call(this, true);
			}
		}

		this._intervals.push({
			interval,
			fn,
			delay,
			runImmediately
		});
	},

	setMeasure: function(name, timeout) {
		let perf = CK.moduleContainer.module('ClientPerformanceMonitor');
		let currentId = this[`_measure-${name}`];

		if (currentId) {
			perf.cancelMeasure(currentId);
		}

		this[`_measure-${name}`] = perf.startMeasure(name, timeout);
	},

	clearMeasure: function(name, waitForDraw = true) {
		let perf = this.module('ClientPerformanceMonitor');
		let currentId = this[`_measure-${name}`];

		if (waitForDraw) {
			requestAnimationFrame(() => {
				perf && perf.endMeasure(currentId);
			});
		} else {
			perf.endMeasure(currentId);
		}
	},

	cancelMeasure: function() {
		let perf = this.module('ClientPerformanceMonitor');
		let currentId = this[`_measure-${name}`];

		perf.cancelMeasure(currentId);
	},

	bind_intervals: function() {
		if (this._intervals) {
			for (let intervalData of this._intervals) {
				if (intervalData.interval === null) {
					intervalData.interval = setInterval(intervalData.fn, intervalData.delay);

					if (intervalData.runImmediately) {
						intervalData.fn.call(this, true);
					}
				}
			}
		}
	},

	unbind_intervals: function() {
		if (this._intervals) {
			for (let intervalData of this._intervals) {
				if (intervalData.interval !== null) {
					clearInterval(intervalData.interval);
					intervalData.interval = null;
				}
			}
		}
	},

	unbind_timeouts: function() {
		if (this._timeouts) {
			for (let timeout of this._timeouts) {
				clearTimeout(timeout);
			}

			this._timeouts.length = 0;
		}
	},

	bind_Actions: function(data, sourceModule) {
		this._bubbleToRoot(data, sourceModule, 'Actions', 'bind');
	},

	unbind_Actions: function(data, sourceModule) {
		this._bubbleToRoot(data, sourceModule, 'Actions', 'unbind');
	},
	bind_Inputs: function(data, sourceModule) {
		this._bubbleToRoot(data, sourceModule, 'Inputs', 'bind');
	},
	unbind_Inputs: function(data, sourceModule) {
		this._bubbleToRoot(data, sourceModule, 'Inputs', 'unbind');
	}
});
