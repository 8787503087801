'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'heist-and-seek',
	name: 'Heist and Seek',
	duration: 100,
	secondsDuration: 3010,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,

	cues: [
		{
			name: 'urgentMessage',
			time: 120,
			lookAhead: true,
			message:
				'Please note that the "gamemode" command no longer accepts "c" as an argument - you need to write "creative" instead.'
		}
	],

	descriptions: [
		'Be the player to mine the most blocks in this timed hunt for stolen goods.',
		'Watch out, there’s a bank robber in town and they’ve hidden their stolen goods all over the map! It’s your job to go head to head against the other players and find the most boxes to win!',
		'Use loops, conditionals and timers to add players into different teams and add different rounds into a treasure hunt game.'
	],

	difficulty: 'Easy',
	unlockXp: 3000,

	videos: [
		{
			name: 'Heist and Seek',
			chapters: [
				{
					name: 'Dial M For Minecraft',
					description: 'This chapter introduces conditionals to check the type of a mined block',
					badges: ['conditions-1', 'booleans-2'],
					// sendMessage is used but then deleted later on in step 1
					additionalFilterBlocks: ['sendMessage'],
					secondsOffset: 46
				},
				{
					name: 'Hider',
					description:
						'This chapter shows you how to assign a player as the hider and introduces for loops',
					badges: ['loops-1', 'arrays-1', 'conditions-2', 'booleans-3'],
					secondsOffset: 616
				},
				{
					name: 'Random Hider',
					description:
						'This chapter shows you how to randomise which player is picked to hide the boxes',
					badges: ['arrays-2', 'random-1'],
					secondsOffset: 1186
				},
				{
					name: 'Jail and Outside',
					description:
						'This chapter teleports players to different positions based on if they are a hider or not',
					badges: ['parameters-1', 'methods-2'],
					secondsOffset: 1420
				},
				{
					name: `Freezing And Unfreezing`,
					description: 'This chapter adds a timer to split the game into different rounds',
					badges: ['nesting-1', 'loops-1', 'timer-1'],
					secondsOffset: 1728
				},
				{
					name: `You'll Get Blocked Away`,
					description: 'This chapter shows you how to give the hider boxes to hide',
					badges: ['methods-2', 'inventory-1'],
					secondsOffset: 2102
				},
				{
					name: 'Sherblock Holmes',
					description: 'This chapter splits the code up depending if a player is a hider or seeker',
					badges: ['timer-2', 'conditions-1'],
					secondsOffset: 2411
				},
				{
					name: 'The Maltese Sheep',
					description:
						'This chapter shows you how to restart your game so you can play endless rounds!',
					badges: ['timer-2', 'conditions-2'],
					secondsOffset: 2604
				}
			]
		}
	]
};
