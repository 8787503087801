'use strict';

import _ from 'lodash';

const products = require('../data/products');
const planSizes = require('../data/planSizes');
const SINGLE = 'single';
const NON_LIFETIME = 'nonLifetime';

const planDurations = require('../data/planDurations');
const isRecurringPlanDuration = require('./isRecurringPlanDuration');
const activePlanDurations = planDurations;

const paths = {
	product: [SINGLE, products.BUNDLE],
	size: [planSizes.INDIVIDUAL, planSizes.FAMILY],
	duration: [NON_LIFETIME, planDurations.LIFETIME]
};

const singleProductPredicate = product => product !== products.BUNDLE;

const nonLifetimePredicate = duration => duration !== planDurations.LIFETIME;

const replaceInArray = function(array, needle, replacement) {
	for (let i = array.length - 1; i >= 0; i--) {
		if (array[i] === needle) {
			array.splice(i, 1, ...replacement);
		}
	}
};

module.exports = function(start) {
	start = start || {};

	const startClone = _.cloneDeep(start);

	if (singleProductPredicate(start.product)) {
		startClone.product = SINGLE;
	}

	if (nonLifetimePredicate(start.duration)) {
		startClone.duration = NON_LIFETIME;
	}

	const remainingInPaths = {};

	for (let pathName in paths) {
		remainingInPaths[pathName] = paths[pathName].slice(
			Math.max(paths[pathName].indexOf(startClone[pathName]), 0)
		);

		if (pathName === 'product') {
			replaceInArray(
				remainingInPaths[pathName],
				SINGLE,
				_.filter(
					products,
					product =>
						singleProductPredicate(product) && (!start.product || product === start.product)
				)
			);
		}

		if (pathName === 'duration') {
			replaceInArray(
				remainingInPaths[pathName],
				NON_LIFETIME,
				_.filter(activePlanDurations, nonLifetimePredicate)
			);
		}
	}

	if (isRecurringPlanDuration(start.duration)) {
		remainingInPaths.product = _.values(products);
	}

	const available = [];

	for (let product of remainingInPaths.product) {
		for (let size of remainingInPaths.size) {
			for (let duration of remainingInPaths.duration) {
				// Don't allow adding more lifetime credit as this doesn't make sense
				if (
					duration === planDurations.LIFETIME &&
					product === start.product &&
					size === start.size &&
					duration === start.duration
				) {
					continue;
				}

				// These planDurations are not offered to new customers
				if (_.includes([planDurations.THREE_MONTHS, planDurations.MONTH], duration)) {
					continue;
				}

				if (isRecurringPlanDuration(duration)) {
					// Don't allow switching to a recurring plan with a shorter duration and the same product and size
					// TODO CORE-2233: Refactor planDurations to be classes to make this robust
					if (
						isRecurringPlanDuration(start.duration) &&
						product === start.product &&
						size === start.size &&
						(duration === start.duration ||
							duration === planDurations.MONTHLY ||
							duration === planDurations.THREE_MONTHLY)
					) {
						continue;
					}

					// Don't allow switching to a bundle subscription from a product credit
					if (
						start.duration &&
						!isRecurringPlanDuration(start.duration) &&
						start.product !== products.BUNDLE &&
						product === products.BUNDLE
					) {
						continue;
					}
				} else {
					// Don't allow switching products for credits
					if (
						!isRecurringPlanDuration(start.duration) &&
						start.product &&
						product !== start.product &&
						product !== products.BUNDLE
					) {
						continue;
					}
				}

				available.push({
					product,
					size,
					duration
				});
			}
		}
	}

	return available;
};

module.exports.paths = paths;
