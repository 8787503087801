'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'trolling-1',
	name: 'Trolling 1',
	duration: 40,
	secondsDuration: 578,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		"Want to troll your friends? You're in the right place!",
		'The first part of the trolling two-parter shows you how to play tricks on your friends!',
		'Want to troll your friends? This mini mod will show you how.'
	],
	difficulty: 'Medium',
	unlockXp: 16000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Trolling 1',
			chapters: [
				{
					name: 'Booby Trap',
					description: 'Trick your friends into a trap.',
					secondsOffset: 19,
					badges: []
				},
				{
					name: 'Singled out',
					description: 'Target specific players with code!',
					secondsOffset: 376,
					badges: []
				},
				{
					name: 'Locked Up',
					description: 'Send your players to prison.',
					secondsOffset: 465,
					badges: []
				}
			]
		}
	]
};
