import { ObjectOf } from 'ck-core/source/types/ObjectOf';
import { twitterInit } from './TwitterInit';
import { CK_ENV } from 'ck-core/source/data/ckEnv';

export function trackTwitterPixel(name: string, options?: ObjectOf<any>) {
	const twq = twitterInit();

	if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
		CK.logger.debug('🔇 Not posting twitterPixelTrack as not on production', name, options);
		return;
	}

	try {
		if (twq) {
			twq('track', name, options);
		}
	} catch (err) {
		CK.logger.error(err);
	}
}
