'use strict';

import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import CKImage from './image';
import Assets from '../system/Assets';
import classnames from 'classnames';

export default class ReactCKImage extends React.Component {
	shouldComponentUpdate(nextProps) {
		return this.props.name !== nextProps.name;
	}

	render() {
		if (!this.props.forceImgTag && typeof window !== 'undefined') {
			const image = CKImage(this.props.name, this.props.options);

			_.each(this.props.extraClasses, extraClass => {
				image.addClass(extraClass);
			});

			const inner = {
				__html: image.prop('outerHTML')
			};

			return (
				<div dangerouslySetInnerHTML={inner} className="Image" onClick={this.props.handleClick} />
			);
		} else {
			const asset = Assets.get(this.props.name);

			let className = classnames('sprite', this.props.extraClasses);
			return (
				<img
					name={this.props.name}
					className={className}
					src={_.get(asset, 'url')}
					onClick={this.props.handleClick}
					data-tip={!!this.props.tooltipId}
					data-for={this.props.tooltipId}
				/>
			);
		}
	}
}

ReactCKImage.propTypes = {
	name: PropTypes.string.isRequired,
	src: PropTypes.string,
	options: PropTypes.object,
	forceImgTag: PropTypes.bool,
	extraClasses: PropTypes.arrayOf(PropTypes.string),
	handleClick: PropTypes.func,
	tooltipId: PropTypes.string
};
