import {
	CK_ENV
} from 'ck-core/source/data/ckEnv';
( 'use strict' );

import _ from 'lodash';

const plans = [];

const planDurations = require( '../data/planDurations' );
const bundleRole = require( '../data/roles/bundleRole' );
const products = require( '../data/products' );
const productMeta = require( '../data/productMeta' );
const planSizes = require( '../data/planSizes' );

const productBases = {
	[ products.MINECRAFT ]: {
		roles: [ CK.const.ROLES.MINECRAFT_25 ],
		description: productMeta[ products.MINECRAFT ].name
	},
	[ products.ROBLOX ]: {
		roles: [ require( '../data/roles/robloxRole' ) ],
		description: productMeta[ products.ROBLOX ].name
	},
	[ products.BUNDLE ]: {
		roles: [ bundleRole ],
		description: productMeta[ products.BUNDLE ].name
	}
};

const durations = {
	[ planDurations.MONTH ]: {
		interval: 'month',
		intervalCount: 1,
		oneOff: true
	},
	[ planDurations.MONTHLY ]: {
		interval: 'month',
		intervalCount: 1
	},
	[ planDurations.THREE_MONTHLY ]: {
		interval: 'month',
		intervalCount: 3
	},
	[ planDurations.THREE_MONTHS ]: {
		interval: 'month',
		intervalCount: 3,
		oneOff: true
	},
	[ planDurations.YEAR ]: {
		interval: 'month',
		intervalCount: 12,
		oneOff: true
	},
	[ planDurations.YEARLY ]: {
		interval: 'year',
		intervalCount: 1
	},
	lifetime: {
		intervalCount: 10,
		interval: 'year',
		lifetime: true,
		oneOff: true
	}
};

const sizes = {
	[ planSizes.INDIVIDUAL ]: null,
	[ planSizes.FAMILY ]: {
		family: true
	}
};

const threeMonthPrices = [ {
		currency: 'gbp',
		value: 2499
	},
	{
		currency: 'usd',
		value: 3999
	},
	{
		currency: 'eur',
		value: 3499
	},
	{
		currency: 'cad',
		value: 4499
	},
	{
		currency: 'aud',
		value: 4499
	}
];

const oneMonthPrices = [ {
		currency: 'gbp',
		value: 1499
	},
	{
		currency: 'usd',
		value: 1999
	},
	{
		currency: 'eur',
		value: 1999
	},
	{
		currency: 'cad',
		value: 2499
	},
	{
		currency: 'aud',
		value: 2499
	}
];

const yearPrices = [ {
		currency: 'gbp',
		value: 7999
	},
	{
		currency: 'usd',
		value: 9999
	},
	{
		currency: 'eur',
		value: 9999
	},
	{
		currency: 'cad',
		value: 14999
	},
	{
		currency: 'aud',
		value: 14999
	}
];

const familyOrBundlePrices = {
	gbp: {
		month: {
			value: 1999
		},
		year: {
			value: 9999
		},
		lifetime: {
			value: 24999
		}
	},
	usd: {
		month: {
			value: 2499
		},
		year: {
			value: 14999
		},
		lifetime: {
			value: 29999
		}
	},
	eur: {
		month: {
			value: 2499
		},
		year: {
			value: 12499
		},
		lifetime: {
			value: 24999
		}
	},
	cad: {
		month: {
			value: 3499
		},
		year: {
			value: 19999
		},
		lifetime: {
			value: 39999
		}
	},
	aud: {
		month: {
			value: 3499
		},
		year: {
			value: 19999
		},
		lifetime: {
			value: 39999
		}
	},
};

const familyAndBundlePrices = {
	gbp: {
		month: {
			value: 2499
		},
		year: {
			value: 14999
		},
		lifetime: {
			value: 29999
		}
	},
	usd: {
		month: {
			value: 3499
		},
		year: {
			value: 19999
		},
		lifetime: {
			value: 39999
		}
	},
	eur: {
		month: {
			value: 2999
		},
		year: {
			value: 17999
		},
		lifetime: {
			value: 34999
		}
	},
	cad: {
		month: {
			value: 4499
		},
		year: {
			value: 24999
		},
		lifetime: {
			value: 49999
		}
	},
	aud: {
		month: {
			value: 4499
		},
		year: {
			value: 24999
		},
		lifetime: {
			value: 49999
		}
	},
};

const basePrices = {
	[ planDurations.MONTH ]: oneMonthPrices,
	[ planDurations.MONTHLY ]: oneMonthPrices,
	[ planDurations.THREE_MONTHLY ]: threeMonthPrices,
	[ planDurations.THREE_MONTHS ]: threeMonthPrices,
	[ planDurations.YEAR ]: yearPrices,
	[ planDurations.YEARLY ]: yearPrices,
	[ planDurations.LIFETIME ]: [ {
			currency: 'gbp',
			value: 19999
		},
		{
			currency: 'usd',
			value: 24999
		},
		{
			currency: 'eur',
			value: 19999
		},
		{
			currency: 'cad',
			value: 29999
		},
		{
			currency: 'aud',
			value: 29999
		}
	]
};

const planTypeToPlanId = require( '../functions/planTypeToPlanId' );
const keys = _.map( require( '../functions/availablePlanTypes' )(), planTypeToPlanId );
const availablePlanTypes = _.zipObject( keys, _.times( keys.length, _.constant( true ) ) );

for ( const productName in productBases ) {
	const productMeta = productBases[ productName ];

	for ( const durationName in durations ) {
		const durationMeta = durations[ durationName ];

		for ( const sizeName in sizes ) {
			const sizeMeta = sizes[ sizeName ];

			const genericBasePriceKey = `${durationName}`;
			const basePriceKey = `${productName}-${durationName}`;

			const base = basePrices[ basePriceKey ] || basePrices[ genericBasePriceKey ];

			if ( !base ) {
				throw new Error( 'No base prices' );
			}

			const planId = planTypeToPlanId( {
				product: productName,
				size: sizeName,
				duration: durationName
			} );

			const plan = _.extend( {
					id: planId,
					active: !!availablePlanTypes[ planId ],
					roles: _.cloneDeep( productMeta.roles ),
					description: `${productMeta.description} ${sizeMeta ? `(${sizeName})` : ``}`
				},
				durationMeta,
				sizeMeta
			);

			plan.price = _.map( _.cloneDeep( base ), price => {


				let interval = plan.interval;

				if ( plan.lifetime ) {
					interval = 'lifetime';
				}

				if ( plan.family ) {
					//price.value *= 1.5;

					price.value = familyOrBundlePrices[ price.currency ][ interval ].value;


					if (
						_.find( plan.roles, {
							name: bundleRole.name
						} )
					) {
						// price.value *= 1.5;


						price.value = familyAndBundlePrices[ price.currency ][ interval ].value;
					}

				} else if (
					_.find( plan.roles, {
						name: bundleRole.name
					} )
				) {
					// price.value *= 1.5;

					price.value = familyOrBundlePrices[ price.currency ][ interval ].value;


				}

				price.value = Math.ceil( price.value / 100 ) * 100 - 1;

				return price;
			} );

			plans.push( plan );
		}
	}
}


module.exports = require( '../data/createCollection' )(

	require( '../schemas/SubscriptionPlanSchema' ),
	[].concat(
		plans,

		require( '../data/subscriptionPlans/oldSubscriptionPlans' ),

		typeof process !== 'undefined' && process.env.CK_ENV !== CK_ENV.PRODUCTION ?
		require( '../data/subscriptionPlans/testSubscriptionPlans' )( plans ) : []
	)
);
