'use strict';

import _ from 'lodash';

module.exports = function() {
	return {
		// Find methods in this object that match a
		// particular trait. For example, for the trait
		// 'bind', find all the methods prefixed with
		// 'bind_'. The functions will be ordered in the
		// order that they were defined in the class hierarchy
		methodsForTrait: function(trait, reverseOrder, returnBag, assignNames) {
			var traitPrefix = trait + '_';

			var fns = [];
			var fnBag = {};

			var classList = (this._class || this).getSuperClassList();
			classList.push(this._class || this);

			if (reverseOrder) {
				classList = classList.reverse();
			}

			for (let i = 0, len = classList.length; i < len; i++) {
				let parentClass = classList[i];

				let proto = parentClass.originalPrototype || parentClass;

				_.each(proto, (method, key) => {
					if (
						typeof method === 'function' &&
						key.substring(0, traitPrefix.length) === traitPrefix
					) {
						if (fnBag[key]) {
							if (fnBag[key] === method) {
								return;
							}

							// Remove old method from list as it has been overridden
							var index = fns.indexOf(fnBag[key]);

							if (index !== -1) {
								fns.splice(index, 1);
							}
						}

						fnBag[key] = method;
						fns.push(method);

						if (assignNames) {
							method.__name = key;
						}
					}
				});
			}

			if (returnBag) {
				return fnBag;
			}

			return fns;
		},

		populateTraits: function(traits) {
			for (var i in traits) {
				var trait = traits[i];

				// Don't override default behaviour
				if (this[trait]) this[trait + '_default'] = this[trait];

				this[trait] = this.generateTraitMethod(trait);
			}
		},

		populateBooleanStateTrait: function(trait, setTrait, unsetTrait) {
			this[setTrait] = this.generateTraitMethod(setTrait, function() {
				if (this[trait]) return false;

				this[trait] = true;
			});

			this[unsetTrait] = this.generateTraitMethod(unsetTrait, function() {
				if (!this[trait]) return false;

				this[trait] = false;
			});
		},

		generateTraitMethod: function(trait, onTrait) {
			return function(...args) {
				var result = true;

				if (onTrait) result = onTrait.apply(this, args);

				if (result === false) return Promise.resolve(false);

				var methods = this.methodsForTrait(trait);

				return Promise.all(_.map(methods, method => method.apply(this, args)));
			};
		},

		populateExtendableTraits: function() {
			this.populateTraits(['init', 'cleanup']);
			this.populateBooleanStateTrait('bound', 'bind', 'unbind');
			this.populateBooleanStateTrait('leading', 'lead', 'unlead');
		}
	};
};
