'use strict';

module.exports = {
	brands: [require('../../../../data/brands').MINECRAFT_JAVA],
	id: 'lucky-blocks',
	name: 'Lucky Blocks',
	duration: 180,
	secondsDuration: 5784,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Make your own extended Lucky Blocks server with over 12 different effects!',
		"Think you’ve played every version of Lucky Blocks there is? Think again. We've got knockback 1000, fire, towers of mobs, potions, enchantments, falling anvils and so much more!",
		'Use more complex programming constructs such as switch statements, for loops and arrays to create numerous random block effects.'
	],

	difficulty: 'Medium',
	unlockXp: 18000,

	videos: [
		{
			name: 'Lucky Blocks',
			chapters: [
				{
					name: 'Bouncy Blocks',
					description:
						'This chapter teaches you how to make something happen when you mine a block',
					badges: ['communication-1', 'parameters-1'],
					secondsOffset: 132
				},
				{
					name: 'Add A Little Luck',
					description:
						'This chapter introduces conditionals so effects only happen if you mine a lucky block',
					badges: ['conditions-1', 'logic-2'],
					secondsOffset: 335
				},
				{
					name: 'Switching It Up',
					description:
						'This chapter adds a switch statement so you can swap over different effects',
					badges: ['switch-1', 'methods-1'],
					secondsOffset: 607
				},
				{
					name: 'Stick Of Justice',
					description: 'This chapter teaches you how to make a super powerful knockback stick',
					badges: ['communication-1', 'inventory-2'],
					secondsOffset: 755
				},
				{
					name: 'Super Pickaxe',
					description: 'This chapter teaches you how to make a super powerful pickaxe',
					badges: ['communication-1', 'inventory-2'],
					secondsOffset: 1124
				},
				{
					name: 'Snowbombs',
					description: 'This chapter shows you how to make exploding snowbombs!',
					badges: ['inventory-2'],
					secondsOffset: 1297
				},
				{
					name: 'Excaliboom',
					description: 'This chapter teaches you how to make an exploding sword',
					badges: ['inventory-2', 'logic-2'],
					secondsOffset: 1518
				},
				{
					name: 'Zombie',
					description: 'This chapter teaches you how to spawn a zombie',
					badges: ['parameters-1', 'mob-2'],
					secondsOffset: 1754
				},
				{
					name: 'Mob Stack',
					description: 'This chapter shows you how to spawn a mobstack',
					badges: ['parameters-1', 'mob-2'],
					secondsOffset: 2155
				},
				{
					name: 'Pig Army',
					description: 'This chapter teaches you how to spawn an army of pigs',
					badges: ['mob-1', 'loops-2'],
					secondsOffset: 2370
				},
				{
					name: 'Anvil',
					description:
						'This chapter shows you how to drop an anvil on the player who mined a block',
					badges: ['operations-2', 'variables-1'],
					secondsOffset: 2580
				},
				{
					name: 'Creeper Cage I',
					description: 'This chapter teaches you how to start your creeper cage structure',
					badges: ['general-1', 'variables-1', 'operations-2', 'decomposition-1'],
					secondsOffset: 2828
				},
				{
					name: 'Creeper Cage II',
					description: 'This chapter shows you how to finish your creeper cage structure',
					badges: ['decomposition-1', 'evaluation-1'],
					secondsOffset: 3111
				},
				{
					name: 'Creeper Cage III',
					description: 'This chapter puts a creeper in your creeper cage',
					badges: ['mob-1'],
					secondsOffset: 3236
				},
				{
					name: 'Pit Of Death I',
					description:
						'This chapter shows you how to use your structure building skills to make a pit',
					badges: ['decomposition-1', 'decomposition-2', 'operations-2'],
					secondsOffset: 3315
				},
				{
					name: 'Pit Of Death II',
					description: 'This chapter teaches you how to fill your pit with lava',
					badges: ['generalisation-1', 'variables-2'],
					secondsOffset: 3603
				},
				{
					name: 'Pit Of Death III',
					description: 'This chapter shows you how to add cobwebs on top of your lava',
					badges: ['generalisation-1', 'operations-2'],
					secondsOffset: 3766
				},
				{
					name: 'Lucky Staff',
					description: 'This chapter teaches you how to make the Lucky Staff',
					badges: ['inventory-2', 'communication-1'],
					secondsOffset: 3903
				},
				{
					name: 'Staff Of Speed',
					description: 'This chapter shows you how to add additional effects using the Lucky Staff',
					badges: ['loops-1', 'communication-1'],
					secondsOffset: 4175
				},
				{
					name: 'Staff Switches',
					description:
						'This chapter adds the staff effects into their own switch statement so they can be randomised',
					badges: ['switch-1', 'parameters-1'],
					secondsOffset: 4429
				},
				{
					name: 'Staff Array',
					description: 'This chapter introduces arrays so you can only use each staff effect once',
					badges: ['arrays-1', 'arrays-3'],
					secondsOffset: 4519
				},
				{
					name: 'Staff Fails',
					description:
						'This chapter teaches you how to add a condition if the staff effect has already been used',
					badges: ['arrays-2'],
					secondsOffset: 4603
				},
				{
					name: 'Staff Of Glowing',
					description: 'This chapter shows you how to add a glowing staff effect',
					badges: ['loops-1', 'parameters-1'],
					secondsOffset: 4816
				},
				{
					name: 'Staff Of Anvils',
					description: 'This chapter teaches you how to rain anvils down on the players',
					badges: ['timer-1', 'operations-2'],
					secondsOffset: 4933
				},
				{
					name: 'Frozen Zone Staff',
					description: 'This chapter teaches you how to turn the block beneath the player to ice',
					badges: ['operations-2', 'conditions-1', 'logic-2'],
					secondsOffset: 5262
				}
			]
		}
	]
};
