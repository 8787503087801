'use strict';

const isAnonymousUser = require('../functions/entryPredicates/isAnonymousUser');

module.exports = [
	{
		id: 'test90s',
		ranges: [[0, 1]],
		entryPredicate: options => options.user && options.user.dateOfBirth() < new Date('2000-01-01')
	},

	{
		id: 'testAnonymous',
		ranges: [[0, 1]],
		entryPredicate: isAnonymousUser
	},

	{
		id: 'testNobody',
		ranges: [[0, 0]],
		entryPredicate: () => false
	},

	{
		id: 'testFree',
		ranges: [[0, 0]]
	}
];
