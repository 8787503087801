'use strict';

import _ from 'lodash';

const Logger = require('ck-core/source/services/Logger');
const Extendable = require('ck-core/source/system/Extendable');

module.exports = Extendable.define({
	type: 'ClientConsoleLoggerDrain',

	init_ClientConsoleLoggerDrain: function() {
		this.logFuncs = new Array(Logger.LEVELS.length);

		_.each(Logger.LEVELS, (val, key) => {
			if (key === 'critical') {
				key = 'error';
			} else if (key === 'trace') {
				key = 'log';
			}
			this.logFuncs[val] = console[key].bind(console);
		});
	},

	dispatch: function(level, args) {
		this.logFuncs[level].apply(console, args);
	}
});
