'use strict';

import _ from 'lodash';

const UserManager = require('./UserManager');
const Errors = require('ck-core/source/services/Errors');
const ClientSocket = require('ck-core/source/system/ClientSocket');
const IdleManager = require('./IdleManager');
const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define({
	type: 'ClientSocketManager',

	init_idleListener: function() {
		this.on(IdleManager.EVENTS.IDLE, () => {
			for (let name in CK.clientSockets) {
				let socket = CK.clientSockets[name];

				socket.unbind();
				socket.disconnect();

				CK.logger.log('Idle, disconnected', name);
			}
		});

		this.on(IdleManager.EVENTS.UNIDLE, () => {
			for (let name in CK.clientSockets) {
				let socket = CK.clientSockets[name];

				socket.connect();
				socket.bind();

				CK.logger.log('Unidle, reconnected', name);
			}
		});
	},

	init_monitor: function() {
		const userManager = UserManager.mod();

		CK.clientSockets.monitor = new ClientSocket(CK.config.environment.servers.monitor);

		const doVerify = function() {
			const session = userManager.getSession();

			if (session) {
				CK.clientSockets.monitor
					.emit(
						'verifySession',
						{
							session
						},
						{
							supersede: true
						}
					)
					.catch(err => {
						if (!(err instanceof Errors.Superseded)) {
							throw err;
						}
					});
			}
		};

		CK.clientSockets.monitor.subscribe('online', doVerify);

		CK.moduleContainer.subscribe(UserManager.EVENTS.AUTHENTICATE, doVerify);

		CK.clientSockets.monitor.bind();
	},

	init_users: function(parent, options) {
		options = _.defaults(options || {}, {
			autoVerifyWithUsersService: true
		});

		CK.clientSockets.users = new ClientSocket(CK.config.environment.servers.users);

		if (options.autoVerifyWithUsersService) {
			this.addUsersOnlineListener();
		}

		CK.clientSockets.users.bind();

		const userManager = UserManager.mod();

		if (userManager.getSession()) {
			CK.clientSockets.users.connect();
		}
	},

	addUsersOnlineListener: function() {
		CK.clientSockets.users.subscribe('online', function() {
			const userManager = UserManager.mod();

			const session = userManager.getSession();

			if (session) {
				userManager.verifySession(session);
			}
		});
	}
});
