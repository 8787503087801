import _ from 'lodash';

export function createQueryString(params: any) {
	if (!_.isPlainObject(params)) {
		throw new Error('Params must be object');
	}
	_.each(params, (val, key) => {
		if (val === undefined) {
			delete params[key];
		}
	});

	return (
		'?' +
		_.map(params, (val, key) => {
			return key + '=' + encodeURIComponent(_.isObject(val) ? JSON.stringify(val) : val);
		}).join('&')
	);
}
