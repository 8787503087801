'use strict';

import _ from 'lodash';

// Add a scrollbar system to the scroll_list element passed in

// Scroll lists need to be a div with a single child .wrapper which contains the content to scroll
// The parent element should have a fixed height or position: absolute with bottom and top and should also have
// overflow:hidden. The wrapper should be left to resize to its contents automatically (contents need a height)

// The wrapper also needs to have a defined height for this to work

// alsoHorizontal adds a horizontal scrollbar as well as a vertical one
// noScrollbars hides the scrollbars but keeps the ability to scroll with touch or mouse
// To refresh just run the function again

module.exports = async function(scroll_list, alsoHorizontal, noScrollbars, options) {
	if (!window.IScroll) {
		window.IScroll = (await import('iscroll/build/iscroll-probe')).default;
	}
	let refreshScroller = scroll => {
		scroll_list.data('deferredScroller', null);

		scroll.refresh();
		return scroll;
	};

	if (scroll_list.data('deferredScroller')) {
		return scroll_list.data('deferredScroller').then(refreshScroller);
	}

	let deferred = darc.defer().then(refreshScroller);

	scroll_list.data('deferredScroller', deferred);

	// Delay to allow dom measurements to finalize
	await Promise.delay(10);

	let found = false;

	scroll_list.each(function() {
		if ($(this).data('scroller')) {
			try {
				$(this)
					.data('scroller')
					.destroy();
			} catch (err) {
				CK.logger.debug(err);
			}

			$(this).data('scroller', false);
		}

		found = true;

		try {
			const scroll = new window.IScroll(
				this,
				_.defaults(options, {
					mouseWheel: true,

					scrollbars: noScrollbars ? false : 'custom',
					interactiveScrollbars: true,
					fadeScrollbars: false,
					shrinkScrollbars: 'scale',
					eventPassthrough: alsoHorizontal ? undefined : 'horizontal',
					tap: true,
					click: false,
					preventDefaultException: {
						tagName: /.*/
					},
					probeType: 3,
					directionLockThreshold: 20,
					lockDirection: !alsoHorizontal,
					scrollX: alsoHorizontal,
					freeScroll: alsoHorizontal,
					// https://github.com/cubiq/iscroll/issues/1100
					disablePointer: true,
					disableTouch: false,
					disableMouse: false
				})
			);

			$(this)
				.addClass('scrollable')
				.data('scroller', scroll);

			scroll.on('scrollStart', function() {
				module.exports.isScrolling = true;
			});
			scroll.on('scrollEnd', function() {
				module.exports.isScrolling = false;
			});

			if (deferred.isPending) {
				deferred.fulfil(scroll);
			}
		} catch (e) {
			CK.logger.log('Suppressed IScroll error due to timeout (dom was removed)', e);

			if (deferred.isPending) {
				deferred.reject(e);
			}
		}
	});

	if (!found) {
		deferred.reject('No scroller');
	}

	return deferred;
};
