'use strict';

module.exports = {
    brands: [require('../../../data/brands').ROBLOX],
    id: 'epic-prison-escape',
    name: 'Epic Prison Escape',
    duration: 30,
    secondsDuration: 903,
    active: true,
    isCourse: true,
    shortCourse: false,
    position: 0,
    hasPdf: true,
    hasCampPdf: true,
    unlockXp: 800,
    difficulty: 'Hard',

    additionalFiles: [
        {
            path: '/Laser',
            superclass: 'BaseObject'
        },
        {
            path: '/FlashingLaser',
            superclass: 'BaseObject'
        },
        {
            path: '/DynamiteSlingshot',
            superclass: 'BaseLocalTool'
        },
        {
            path: '/Dynamite',
            superclass: 'BaseObject'
        }
    ],

    cues: [
        {
            name: 'message',
            time: 60,
            lookAhead: true,
            message:
                'Play the completed Epic Prison Escape game by visiting https://www.roblox.com/games/1889313600/Prison-Escape'
        }
    ],

    descriptions: [
        'Escape prison for the second time, this time using only Epic Mode!',
        'Avoid the flashing lasers and use your dynamite slingshot to help Agent Jed escape an even tougher prison!',
        'This course is ideal for getting to grips with syntax and typing Lua. Code a familiar game while learning new skills and take the next step towards becoming a programming pro.'
    ],

    videos: [
        {
            chapters: [
                {
                    name: 'The Shawshank Recursion',
                    description: 'This chapter teaches you how to make deadly lasers!',
                    badges: ['parameters-1', 'workspace-1'],
                    secondsOffset: 143
                },
                {
                    name: `It's a Hard Block Life`,
                    description: 'This chapter teaches you how to make lasers that flash on and off.',
                    badges: ['time-1', 'time-2', 'loops-1', 'booleans-1'],
                    secondsOffset: 334
                },
                {
                    name: 'Dungeons and Dynamite',
                    description: 'This chapter teaches you how to throw dynamite and make it explode!',
                    badges: ['input-1', 'classes-1', 'parameters-1', 'time-1'],
                    secondsOffset: 591
                }
            ]
        }
    ]
}; 