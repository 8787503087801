import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';
import { poll } from 'ck-core/source/functions/poll';
import { createQueryString } from 'ck-core/source/functions/createQueryString';
const CKError = require('./../classes/Error');
const Verifier = require('./../system/Verifier');
const Errors = require('../services/Errors');
const Time = require('../services/Time');

CK.fn = {
	hrefForAction: function(action) {
		if (
			_.startsWith(action, '/') ||
			_.startsWith(action, 'http:') ||
			_.startsWith(action, 'https:')
		) {
			return action;
		}

		if (_.startsWith(action, './')) {
			return action.substring(2);
		}

		return '#' + action;
	},

	zero: function() {
		return true;
	},

	dateToCalendar: function(date) {
		var year = date.getFullYear();
		var month = date.getMonth() + 1;
		var day = date.getDate();

		return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
	},

	dateToTime24: function(date) {
		var hourString = date.getHours() + '';
		if (hourString.length === 1) hourString = '0' + hourString;
		var minuteString = date.getMinutes() + '';

		if (minuteString.length === 1) minuteString = '0' + minuteString;

		var secondString = date.getSeconds() + '';

		if (secondString.length === 1) secondString = '0' + secondString;

		return hourString + ':' + minuteString + ':' + secondString;
	},
	humaniseDate: function(date) {
		if (Date.now() - date.getTime() > Time.DAY_MS) {
			return moment(date).format(CK.const.READABLE_TIME_DATE_FORMAT);
		} else {
			return moment.duration(moment(date).diff(moment())).humanize(true);
		}
	},

	toArray: function(bag, key) {
		var list = [];

		for (var i in bag) {
			if (key === true) {
				list.push(i);
			} else {
				if (key) bag[i][key] = i;
				list.push(bag[i]);
			}
		}
		return list;
	},

	detectCycle: function(object) {
		let seenObjects = [];

		let seenPaths = [];

		let warnings = [];

		function detect(obj, path) {
			if (obj && typeof obj === 'object') {
				let index = seenObjects.indexOf(obj);

				if (index !== -1) {
					return seenPaths[index];
				}

				seenObjects.push(obj);

				seenPaths.push(path);

				for (var key in obj) {
					if (obj.hasOwnProperty(key)) {
						let detected = detect(obj[key], path + '.' + key);

						if (detected) {
							if (detected !== true) {
								warnings.push([path, detected + '.' + key, obj]);
							}

							return true;
						}
					}
				}
				seenObjects.pop();
				seenPaths.pop();
			}
			return false;
		}
		detect(object, '');

		return warnings;
	},
	clone: function(obj) {
		if (obj === undefined) {
			CK.logger.warn('Cloned undefined, returning undefined');

			return undefined;
		}
		return JSON.parse(JSON.stringify(obj));
	},

	/**
	 * Recursively freeze object from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
	 */
	deepFreeze: function(o) {
		if (typeof o !== 'object') {
			return;
		}

		var prop, propKey;
		Object.freeze(o);
		// First freeze the object.

		for (propKey in o) {
			prop = o[propKey];

			if (!o.hasOwnProperty(propKey) || !(typeof prop === 'object') || Object.isFrozen(prop)) {
				// If the object is on the prototype, not an object, or is already frozen,

				// skip it. Note that this might leave an unfrozen reference somewhere in the

				// object if there is an already frozen object containing an unfrozen object.

				continue;
			}
			CK.fn.deepFreeze(prop); // Recursively call deepFreeze.
		}

		return o;
	},

	safeExtend: function() {
		var target = arguments[0];
		if (arguments.length < 1) return undefined;
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var j in source) {
				if (target[j]) throw new Error('Cannot override property ' + j + ' of target object');

				target[j] = source[j];
			}
		}

		return target;
	},

	lowerFirst: function(original) {
		if (!_.isString(original) || !original.length) {
			return original;
		}

		return original[0].toLowerCase() + original.substring(1);
	},

	sanitiseVariableName: function(variableName, scope, defaultPrefix) {
		// Sanitise the variable name
		// Disallow anything that isn't a letter or number or underscore
		var bannedChars = /(?![a-z_0-9A-Z])./gi;
		var fixedVariableName = variableName
			.trim()
			.split(bannedChars)
			.filter(function(word) {
				return !!word;
			})
			.join('_');

		let numericSuffix = 1;
		let prefix = fixedVariableName;

		if (fixedVariableName.match(/^[0-9]/)) {
			prefix = (defaultPrefix || 'var') + fixedVariableName;
			fixedVariableName = prefix;
		} else if (!fixedVariableName.length) {
			prefix = defaultPrefix || 'var';
			fixedVariableName = 'var1';
		}

		let variable = scope.get(fixedVariableName);
		let isExistingNameInClass = variable && !variable.type.typeInstance;

		while (isExistingNameInClass && numericSuffix < 100) {
			numericSuffix++;
			fixedVariableName = prefix + numericSuffix;

			variable = scope.get(fixedVariableName);
			isExistingNameInClass = variable && !variable.type.typeInstance;
		}

		return fixedVariableName;
	},
	/**
	 * Cycle safe way to produce a stringy version of an object. Note it does not produce valid JSON
	 */

	safeFormat: function(obj) {
		var formatio;
		if (typeof require !== 'undefined') {
			formatio = require('formatio');
		} else {
			formatio = window.formatio;
		}

		return formatio.ascii(obj);
	},
	/**

	 * Assign 1-indexed ids for indexes
	 */
	assignOneIndexedIds: function(array, offset = 0) {
		var i = 1 + offset;

		if (
			_.some(array, obj => {
				return _.has(obj, 'id');
			})
		) {
			throw new Error('ids already present');
		}

		_.each(array, obj => {
			if (!_.isPlainObject(obj)) {
				throw new Error('Not an object');
			}
			obj.id = i++;
		});
	},

	calculateDiscount: function(price, percentOff) {
		return Math.round(price * (percentOff / 100));
	},

	currencySymbol: function(currency, html) {
		var symbols;

		if (html) {
			symbols = {
				gbp: '&pound;',

				eur: '&euro;',

				usd: '&#36;',
				cad: 'C&#36;',
				aud: 'A&#36;'
			};
		} else {
			symbols = {
				gbp: '£',
				eur: '€',
				usd: '$',
				cad: 'C$',
				aud: 'A$'
			};
		}

		var symbol = symbols[currency];
		if (!symbol) {
			CK.logger.warn('No symbol for', currency);
			symbol = currency.toUpperCase();
		}
		return symbol;
	},
	/**
	 * @deprecated
	 */
	formatPennies: function(value) {
		const pennies = Math.abs(value) % 100;

		var pricePennies = '' + pennies;

		if (pricePennies.length < 2) {
			// Requires a leading 0

			pricePennies = '0' + pricePennies;
		}

		return pricePennies;
	},

	/**
	 * @deprecated
	 */
	formatPrice: function(currency, value, options) {
		options = _.defaults({}, options || {}, {
			stylish: false,
			spaceCurrencySymbol: true,
			html: true
		});

		value = Math.floor(value);

		const formattedPennies = CK.fn.formatPennies(value);

		if (options.stylish) {
			return (
				'<small>' +
				CK.fn.currencySymbol(currency, options.html) +
				'</small> ' +
				Math.floor(value / 100) +
				'<small class="pence">&nbsp;' +
				formattedPennies +
				'</small>'
			);
		}

		return (
			(value < 0 ? '-' : '') +
			CK.fn.currencySymbol(currency, options.html) +
			(options.spaceCurrencySymbol ? ' ' : '') +
			Math.floor(Math.abs(value) / 100) +
			'.' +
			formattedPennies
		);
	},

	createQueryString,

	percentile: require('../functions/percentile'),

	verifyAtom: async function(input, mask, options, path) {
		if (mask instanceof darc.classes.Type) {
			mask = mask.value;
		}

		if (options.required === false && input === undefined) {
			return true;
		}

		const isBasicType = mask === Number || mask === Boolean || mask === String || mask === Date;
		if (isBasicType) {
			try {
				darc.verifyType.atom.call(darc, input, mask, {
					strict: true
				});

				return true;
			} catch (err) {
				throw new Errors.BadRequest(`Verifying type at path ${path}: ${err.message}`);
			}
		}

		if (_.isArray(mask)) {
			if (mask.length !== 1) {
				throw new Error('Verifier array must be of length 1');
			}

			if (!_.isArray(input)) {
				throw new Errors.BadRequest(`Value at path ${path} is not an array`);
			}
			let results = [];

			for (let i = 0; i < input.length; i++) {
				let value = input[i];

				if (_.isPlainObject(mask[0]) && !_.isPlainObject(value)) {
					throw new Errors.BadRequest(`Element in array should be an object but it isn't: ${path}`);
				}

				results.push(await CK.fn.verifyAtom(value, mask[0], options, path + '.' + i));
			}

			return results;
		}
		if (Verifier.isInstance(mask)) {
			mask = _.partialRight(mask.verify.bind(mask), true);
		}

		if (typeof mask === 'function') {
			const result = mask(input);

			return result;
		}

		if (mask instanceof RegExp) {
			if (typeof input !== 'string') {
				throw new Errors.BadRequest(`Value at path ${path} is not a string`);
			}
			const match = input.match(mask);

			return match !== null && match[0] === input;
		}
	},

	verify: async function(input, mask, options, path) {
		options = options || {};
		path = path || '(root)';

		var result;

		if (_.isPlainObject(mask)) {
			result = {};
			for (let key in mask) {
				let verifier = mask[key];

				let data = _.get(input, key);

				if (_.isPlainObject(verifier)) {
					result[key] = await CK.fn.verify(data, verifier, options, path + '.' + key);
				} else {
					result[key] = await CK.fn.verifyAtom(data, verifier, options, path + '.' + key);
					if (verifier.verifierReplaces) {
						input[key] = result[key];
					}
					if (result[key] === false) {
						throw new Errors.BadRequest(`Value at path ${path + '.' + key} failed verification`);
					}
				}
			}

			return result;
		} else {
			return await CK.fn.verifyAtom(input, mask, options, path);
		}
	},

	isPositiveInteger: function(n) {
		return CK.fn.isInteger(n) && n > 0;
	},

	isInteger: function(n) {
		return typeof n === 'number' && n % 1 === 0;
	},

	buildUrl: function(server) {
		return server.protocol + '://' + server.host + ':' + server.port;
	},

	parseableAsNumber: function(val) {
		return !isNaN(+val);
	},
	poll,
	flattenObject: function(obj, out, stem) {
		stem = stem || '';

		out = out || {};

		for (let key in obj) {
			let val = obj[key];

			if (_.isObject(val)) {
				CK.fn.flattenObject(val, out, stem + key + '.');
			} else {
				out[stem + key] = val;
			}
		}
		return out;
	},

	canonicalWorldId: function(worldId) {
		if (_.startsWith(worldId, CK.const.CUSTOM_ASSET_ID_PREFIX)) {
			return worldId;
		}
		return worldId.replace(/-\d+$/, '');
	},

	prepareIntercomCustomAttributes: function(customAttributes) {
		const errors = [];

		for (let key of _.keys(customAttributes)) {
			let value = customAttributes[key];

			if (_.isDate(value)) {
				delete customAttributes[key];

				customAttributes[key + '_at'] = Math.round(value / 1000);
			} else if (_.isObject(value)) {
				customAttributes[key] = JSON.stringify(value);
			} else if (_.isNil(value)) {
				delete customAttributes[key];
			}

			if (typeof customAttributes[key] === 'string' && customAttributes[key].length > 255) {
				customAttributes[key] = customAttributes[key].substring(0, 255);

				errors.push({
					reason: 'String too long',
					key
				});
			}

			if (key.length > 190) {
				delete customAttributes[key];

				errors.push({
					reason: 'Key too long',
					key
				});
			}
		}

		if (_.size(customAttributes) > 100) {
			// Do not remove basic attributes which are required for the update to work and not create a new user
			let allKeys = _.keys(customAttributes),
				keys = _.difference(allKeys, [
					'app_id',
					'user_id',
					'created_at',
					'externalId',
					'name',
					'email'
				]);

			for (let i = 100; i < allKeys.length; i++) {
				delete customAttributes[keys[i - 100]];
			}

			errors.push({
				reason: 'Too many attributes'
			});
		}

		if (errors.length) {
			CK.logger.warn(
				new CKError({
					message: 'Invalid Intercom Custom Attributes, removed the invalid ones',
					tags: CK.fn.flattenObject({
						customAttributes,
						errors
					})
				})
			);
		}

		return {
			errors,
			customAttributes
		};
	},

	camelCaseToWords: function(str) {
		return _.upperFirst(str.replace(/([A-Z])/g, ' $1'));
	},

	getMinecraftProxyServerParameters: function() {
		var region;

		if (CK.user) {
			region = CK.user.minecraftRegion();
		} else if (typeof process !== 'undefined') {
			region = CK.const.AWS_REGIONS_TO_MINECRAFT_REGIONS[process.env.AWS_REGION];
		}

		region = region || CK.const.MINECRAFT_REGIONS.EU;

		return CK.config.environment.servers[`minecraftProxy-${region}`];
	},

	fullModdingUrl: function(url, env, region) {
		return `${url}.${env === CK_ENV.PRODUCTION ? '' : env + '.'}${region}.${CK.const.MODDING_TLD}`;
	},

	disableFiltering: function() {
		localStorage.globalFilter = 'disabled';
	},

	enableFiltering: function() {
		delete localStorage.globalFilter;
	}
};
