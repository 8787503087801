'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'high-noon-saloon',
	name: 'High Noon Saloon',
	duration: 90,
	secondsDuration: 2531,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Give this one shot PVP Minecraft version of a Western showdown a try.',
		'This multiplayer game equips players with a bow and six arrows, and drops their health to one heart. Last player still alive is the winner!',
		'Learn Java basics such as methods, strings and booleans with this Wild West themed mod aimed at beginners.'
	],

	difficulty: 'Easy',
	unlockXp: 300,

	videos: [
		{
			name: 'High Noon Saloon',
			chapters: [
				{
					name: 'Once Upon A Slime In The West',
					description: 'This chapter shows you how to start your mod by setting the player health',
					badges: ['ui-1', 'parameters-1'],
					secondsOffset: 124
				},
				{
					name: 'For A Few Arrows More',
					description: 'This chapter shows you how to give the player bows and arrows',
					badges: ['methods-1', 'classes-1'],
					secondsOffset: 729
				},
				{
					name: 'The Good, The Bad, The Batman',
					description: 'In this chapter you add a timer into your showdown',
					badges: ['random-1', 'timer-2'],
					secondsOffset: 1178
				},
				{
					name: 'A Fistful Of TNT',
					description: 'This final chapter shows you how to use commands to restart the game',
					badges: ['conditions-1', 'command-1', 'logic-3'],
					secondsOffset: 1844
				}
			]
		}
	]
};
