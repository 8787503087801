'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'ninja-obby',
	name: 'Ninja Obby',
	duration: 160,
	secondsDuration: 2620,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 10,
	unlockXp: 4000,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/ObstacleCourse',
			superclass: 'BaseObject'
		},
		{
			path: '/Checkpoint/CheckpointTile',
			superclass: 'BaseObject'
		},
		{
			path: '/Checkpoint/CheckpointPlayer',
			superclass: 'BasePlayer'
		},
		{
			path: '/Killer',
			superclass: 'BaseObject'
		},
		{
			path: '/Obstacle',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'Play the completed Ninja Obby game by visiting https://www.roblox.com/games/1892540464/Ninja-Obby'
		}
	],

	descriptions: [
		`Work on your parkour skills while navigating this tricky obstacle course!`,
		`Players must use their top ninja skills to navigate their way through the course, avoiding both the deadly obstacles and trying not to fall off the platforms. The player who manages to make it to the end can unlock a ninja outfit as a reward!`,
		`With this Code Kingdoms course, you will learn some of the more complex Lua coding constructs. such as vectors. You'll also improve your knowledge of the basics of coding. This course also covers game development concepts such as procedural generation.`
	],

	videos: [
		{
			name: 'Ninja Obby',
			chapters: [
				{
					name: 'Killer scripts',
					description:
						'The code in this chapter sets up the obstacles which kill the players when they touch them.',
					secondsOffset: 142,
					badges: ['variables-1', 'workspace-1', 'classes-1']
				},
				{
					name: 'Checkpoints',
					description:
						'The code in this chapter sets up the first checkpoint of the obstacle course.',
					secondsOffset: 322,
					badges: ['physics-1']
				},
				{
					name: 'Multiple checkpoints',
					description:
						'The code in this chapter sets up multiple checkpoints in the obstacle course.',
					secondsOffset: 552,
					badges: ['variables-1', 'conditions-1', 'booleans-1']
				},
				{
					name: 'Saved positions',
					description:
						'The code in this chapter saves the last checkpoint the player passed so they can respawn.',
					secondsOffset: 730,
					badges: ['variables-2', 'operators-2']
				},
				{
					name: 'The first obstacle',
					description:
						'The code in this chapter stores the location of the obstacles so they can be used later to build a new course.',
					secondsOffset: 1050,
					badges: ['arrays-1', 'methods-2']
				},
				{
					name: 'Cloning',
					description:
						'The code in this chapter clones an obstacle to make a course which is 1 obstacle long.',
					secondsOffset: 1360,
					badges: ['methods-1', 'arrays-1', 'parameters-2']
				},
				{
					name: 'Randomising',
					description: 'The code in this chapter makes the first obstacle of the course random.',
					secondsOffset: 1551,
					badges: ['random-1', 'random-2', 'arrays-2']
				},
				{
					name: 'More obstacles',
					description:
						'The code in this chapter saves the end position of each obstacle so they can be attached.',
					secondsOffset: 1842,
					badges: ['variables-2']
				},
				{
					name: 'Method splitting',
					description:
						"The code in this chapter is split up into separate methods so it's easier to change.",
					secondsOffset: 2007,
					badges: ['operators-2']
				},
				{
					name: 'Loops',
					description:
						'The code in this chapter loops your code to add more obstacles to the course.',
					secondsOffset: 2183,
					badges: ['classes-1', 'parameters-1']
				},
				{
					name: 'Tidying and rewards',
					description:
						'The code in this chapter adds a bonus ending to reward the player for completing the obby.',
					secondsOffset: 2314,
					badges: ['loops-3']
				}
			]
		}
	]
};
