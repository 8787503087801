'use strict';

const Extendable = require('./../system/Extendable');

const ContextContributor = (module.exports = Extendable.define({
	type: 'ContextContributor',

	getContext: function() {
		CK.logger.info('Abstract getContext method should be overriden for', this.type);
		return;
	}
}));
