'use strict';

import _ from 'lodash';

const input = require('../services/input');
const View = require('./View');
const ModuleContainer = require('ck-core/source/system/ModuleContainer');

module.exports = ModuleContainer.define({
	init_ClientModuleContainer: function() {
		if (typeof window !== 'undefined') {
			this._dom = $('#container');
		}

		this._actionContributions = {};
		this._inputContributions = {};
	},

	/**
	 * Should only be used for root modules if you care about recency, as only they have a recency value for the container.
	 * @returns 1 if a is higher priority/more recent than b.
	 */
	_compareRootModules: function(a, b) {
		var aRecency = a[this._containerRecencyKey];
		var bRecency = b[this._containerRecencyKey];

		if (aRecency < bRecency) {
			return 1;
		} else if (aRecency > bRecency) {
			return -1;
		} else {
			return 0;
		}
	},

	/**
	 * Return a list of the modules sorted by priority first and then by recency (of being added to the container) second.
	 * Highest priority first.
	 */
	_sortedModules: function() {
		return _.values(this._modules).sort(this._compareRootModules.bind(this));
	},

	add: function(module) {
		this._checkModuleCanBeAdded(module);

		if (module.instanceOf(View)) {
			var inserted = false;

			// Find position to add the DOM at
			_.each(this._sortedModules(), otherModule => {
				if (!otherModule.instanceOf(View)) return;

				if (this._compareRootModules(module, otherModule) === 1) {
					otherModule.dom.after(module.dom[0]);
					inserted = true;
					return false;
				}
			});
			// Default if there are no sibling views

			if (!inserted) {
				module.dom.appendTo(this._dom);
			}
		}

		var added = this.ModuleContainer_add(module).then(() => {
			this.publish('moduleAdded', module);
		});

		return added;
	},

	remove: async function(module) {
		await this.ModuleContainer_remove(module);

		this.publish('moduleRemoved', module);
	},

	_bind_declaredActions: function() {
		this._recalculateActions();
	},

	_unbind_declaredActions: function() {
		this._recalculateActions();
	},

	_bind_declaredInputs: function() {
		this._recalculateInputs();
	},

	_unbind_declaredInputs: function() {
		this._recalculateInputs();
	},

	_recalculateActions: function() {
		_.each(this._modules, module => {
			for (var i in module._actions) {
				delete module._actions[i];
			}
		});
		// Iterate over modules in priority order from lowest to highest, for all actions they declare,
		// get the module they are for and merge the actions into the active actions

		_.eachRight(this._sortedModules(), rootModule => {
			// CK.logger.log( rootModule.type, rootModule.bound, rootModule._declaredActionsMerged );

			if (!rootModule.bound) return;

			_.each(rootModule._declaredActionsMerged, actionContribution => {
				var module = actionContribution.sourceModule;

				if (!module.bound) return;

				_.each(actionContribution.data, (actions, targetViewClass) => {
					var targetView = this.module(targetViewClass);
					if (!targetView) {
						// TODO/NB: If you add actions for a view before it is bound they will not be set until the next recalculate

						return; //                  return CK.logger.warn( 'No target module with class', targetViewClass, 'to add actions to from', rootModule.type );
					}
					if (!targetView.instanceOf(View)) {
						CK.logger.warn('Target is not an instance of View, makes no sense to give it actions');
					}

					_.extend(targetView._actions, actions);
				});
			});
		});
	},

	_recalculateInputs: function() {
		var inputs = {};
		// TODO: Child modules cannot surpass their parents even if they have higher priority

		// Iterate over modules in priority order from lowest to highest, merge their declared inputs into the input context.

		// If a module is inputBlocking then wipe out all inputs below it

		_.eachRight(this._sortedModules(), function(rootModule) {
			if (!rootModule.bound) return;

			_.each(rootModule._declaredInputsMerged, function(inputContribution) {
				var module = inputContribution.sourceModule;

				if (!module.bound) return;

				if (module.inputBlocking) inputs = {};

				_.extend(inputs, inputContribution.data);
			});
		});

		input.changeContext(inputs);
	},

	summary: function() {
		var moduleSummaries = _.map(this._sortedModules(), function(module) {
			return {
				type: module.type,

				bound: module.bound,
				_actions: module._actions ? _.keys(module._actions).join(', ') : undefined,
				inputBlocking: module.inputBlocking
			};
		});

		console.table(moduleSummaries);
		console.log('Merged input context', input.mergedContext);
	},
	/**
	 * Bind in priority order

	 */
	bind_modules: function() {
		_.each(this._sortedModules(), function(module) {
			module.bind();
		});

		this._recalculateInputs();

		this._recalculateActions();
	},

	/**
	 * Unbind in reverse priority order
	 */

	unbind_modules: function() {
		CK.logger.warn('ModuleContainer being unbound!');

		_.eachRight(this._sortedModules(), function(module) {
			module.unbind();
		});
		this._recalculateInputs();
		this._recalculateActions();
	},
	type: 'ClientModuleContainer'
});
