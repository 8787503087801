'use strict';

import _ from 'lodash';

const pluginSchema = {
	id: darc.types.primary(String),
	name: String,
	version: String,
	description: String,
	additionalFiles: [
		{
			source: darc.types.primary(String),
			destination: String
		}
	]
};

module.exports = darc.compile(
	_.extend(
		{
			dependencies: [pluginSchema]
		},
		pluginSchema
	)
);

module.exports.Set = darc.compile(darc.types.set(module.exports.schema));
