'use strict';

CK.endpoints.message = module.exports = function(request) {
	CK.logger.trace('message endpoint called', {
		request
	});

	CK.moduleContainer.publish(CK.endpoints.message.EVENTS.MESSAGE + request.name, request.message);
};

CK.endpoints.message.EVENTS = {
	MESSAGE: 'endpointMessage.'
};
