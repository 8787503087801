'use strict';

import _ from 'lodash';

const CKError = require('./../classes/Error');

module.exports = function(defOptions) {
	const err = function(options) {
		if (_.isString(options)) {
			options = {
				name: defOptions.name,
				message: options
			};
		}

		CKError.call(this, _.defaults(options || {}, defOptions));
	};

	err.errorClassName = defOptions.name;
	err.prototype = Object.create(CKError.prototype);
	err.prototype.constructor = err;

	return err;
};
