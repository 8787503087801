'use strict';

import _ from 'lodash';
import { STRIPE_DURATION } from 'ck-core/source/data/StripeDuration';

const CKError = require('../classes/Error');

const products = require('../data/products');
const planDurations = require('../data/planDurations');
const friendlyDateFormat = require('../data/friendlyDateFormat');

module.exports = darc.compile({
	id: darc.types.primary(String),

	// Whether this subscription plan is possible for new users
	active: Boolean,

	family: Boolean,

	description: String,

	interval: String,
	intervalCount: Number,

	price: [
		{
			currency: darc.types.primary(String),
			// ISO 4217 code
			value: Number
		}
	],

	remindRenewal: Boolean,

	// Both subscriptions and coupons can confer a free trial period
	freeTrialDays: Number,

	roles: [
		{
			name: darc.types.primary(String),
			properties: Object
		}
	],

	// One off plans will not bill on a recurring basis
	oneOff: Boolean,

	// This plan will last forever (but actually 10 years)
	lifetime: Boolean,

	// This plan is gifted but can be redeemed by the user themselves
	// @deprecated
	selfRedeemable: Boolean,

	// Whether this subscription plan is free or not (not used on Stripe if free)
	// @deprecated
	free: Boolean,

	// @deprecated
	preAuthUnitPrice: Boolean,

	priceInCurrency: function(currency, options) {
		options = _.defaults(options || {}, {
			html: true,
			coupon: null,
			originalPercentOff: null,
			periodIndex: null,
			spaceCurrencySymbol: true,
			extraChildren: 0
		});

		var price = this.price(currency);

		if (!price.$exists()) {
			throw new Error('No price for currency ' + currency);
		}
		var total = price.value();

		const fullPrice = total;

		var discount = 0,
			upgradeDiscount = options.user ? this.calculateUpgradeDiscount(currency, options.user) : 0;

		if (!this.oneOff()) {
			upgradeDiscount = 0;
		}

		var result = {};

		if (
			options.coupon &&
			options.coupon.$exists() &&
			this.isCouponApplicable(options.coupon, currency, options.periodIndex)
		) {
			if (options.coupon.percentOff()) {
				discount = CK.fn.calculateDiscount(total - upgradeDiscount, options.coupon.percentOff());
			} else {
				discount = options.coupon.amountsOff(currency).value();
			}
		}

		const couponDiscount = discount;
		discount += upgradeDiscount;

		discount = Math.min(total, discount);
		total -= discount;

		if (options.extraChildren) {
			let extraChildPlan = this.getExtraChildPlan();
			total += extraChildPlan.price(currency).value() * options.extraChildren;
		}

		result.originalPrice = fullPrice;

		if (options.originalPercentOff) {
			result.originalPrice = Math.round(fullPrice / (100 - options.originalPercentOff)) * 100 - 1;

			result.originalAmountSaved = result.originalPrice - fullPrice || 0;
		}

		const baseFormatOptions = {
			html: options.html,
			spaceCurrencySymbol: options.spaceCurrencySymbol
		};

		_.extend(result, {
			currency,
			fullPrice,
			price: total,
			perInterval: total / this.intervalCount(),

			symbol: CK.fn.currencySymbol(currency),
			formatted: CK.fn.formatPrice(currency, total, baseFormatOptions),
			formattedStylishly: CK.fn.formatPrice(
				currency,
				total,
				_.extend(
					{
						stylish: true
					},
					baseFormatOptions
				)
			),
			formattedFullPrice: CK.fn.formatPrice(currency, fullPrice, baseFormatOptions),
			formattedFullPriceStylishly: CK.fn.formatPrice(
				currency,
				fullPrice,
				_.extend(
					{
						stylish: true
					},
					baseFormatOptions
				)
			),
			formattedPerInterval: CK.fn.formatPrice(
				currency,
				total / this.intervalCount(),
				baseFormatOptions
			),
			formattedPerIntervalStylishly: CK.fn.formatPrice(
				currency,
				total / this.intervalCount(),
				_.extend(
					{
						stylish: true
					},
					baseFormatOptions
				)
			),
			upgradeDiscount,
			formattedUpgradeDiscount: CK.fn.formatPrice(currency, upgradeDiscount, baseFormatOptions),
			discount,
			formattedDiscount: CK.fn.formatPrice(currency, discount, baseFormatOptions),
			couponDiscount,
			formattedCouponDiscount: CK.fn.formatPrice(currency, couponDiscount, baseFormatOptions)
		});

		return result;
	},

	isUpgradeFromPlan: function(currentPlan) {
		return (
			this.isUpgradeFromPlanByBenefits(currentPlan) || this.isUpgradeFromPlanByPeriod(currentPlan)
		);
	},

	/**
	 * Whether this plan is an upgrade from the current plan with regards to benefits (product, size)
	 */
	isUpgradeFromPlanByBenefits: function(currentPlan) {
		return !(currentPlan.product() === this.product() && currentPlan.family() === this.family());
	},

	/**
	 * Whether this plan is an upgrade from the currentPlan with regards to period (this is lifetime and the other isn't, or it has a longer billing period and is a recurring plan)
	 */
	isUpgradeFromPlanByPeriod: function(currentPlan) {
		const neitherLifetime = !this.lifetime() && !currentPlan.lifetime();
		return (
			(this.lifetime() && !currentPlan.lifetime()) ||
			(neitherLifetime &&
				!currentPlan.oneOff() &&
				currentPlan.intervalInMonths() < this.intervalInMonths())
		);
	},

	calculateUpgradeDiscount: function(currency, user) {
		try {
			if (user.linkedTo()) {
				return 0;
			}

			let currentPlanId, startDate, endDate;

			const credit = user.getLatestCredit();

			if (credit && credit.endDate >= new Date()) {
				currentPlanId = credit.planId;
				startDate = credit.startDate;
				endDate = credit.endDate;
			}

			const subscriptionPlans = require('../collections/subscriptionPlans');

			if (!currentPlanId) {
				const activeSubscription = user.getActiveSubscription();

				if (activeSubscription) {
					const planId = activeSubscription.planId();

					const plan = subscriptionPlans(planId);

					// Give an upgrade discount from an annually (or longer period) recurring plan
					if (plan.intervalInMonths() >= 12) {
						currentPlanId = planId;
						endDate = activeSubscription.current_period_end();
						startDate = moment(endDate)
							.subtract(plan.intervalInMonths(), 'month')
							.toDate();
					}
				}
			}

			if (!currentPlanId) {
				return 0;
			}

			const currentPlan = subscriptionPlans(currentPlanId);

			if (!currentPlan) {
				return 0;
			}

			if (!this.isUpgradeFromPlan(currentPlan)) {
				return 0;
			}

			if (this.intervalInMonths() < currentPlan.intervalInMonths()) {
				return 0;
			}

			const startTime = startDate.getTime(),
				endTime = endDate.getTime(),
				consumedTime = _.clamp(Date.now(), startTime, endTime);

			if (!endTime || !startTime) {
				return 0;
			}

			const proportionUsed = (consumedTime - startTime) / (endTime - startTime);

			const { price } = currentPlan.priceInCurrency(currency);

			return Math.round(price * (1 - proportionUsed) / 100) * 100;
		} catch (err) {
			CK.logger.warn(err);
		}

		return 0;
	},

	moneyBackGuaranteeMessage: function(options) {
		options = _.defaults(options || {}, {
			asGift: false
		});

		if (!this.oneOff() && this.durationKey() !== planDurations.YEARLY) {
			return false;
		}

		const alreadyHasProduct =
			options.user &&
			options.user.$exists() &&
			_.intersection([products.BUNDLE, this.product()], options.user.getActiveProducts()).length;

		if (alreadyHasProduct) {
			return false;
		}

		const days = 30;

		if (options.asGift) {
			return `A ${days}-day money back guarantee applies from when the gift is redeemed.`;
		}

		let endDate;

		if (options.startDate) {
			endDate = moment(options.startDate)
				.add(days, 'days')
				.format(friendlyDateFormat);
		}

		return `A ${days}-day money back guarantee applies from ${
			options.startDate
				? moment(options.startDate).format(friendlyDateFormat)
				: 'the plan start date'
		}${endDate ? ` until ${endDate}` : ''}.`;
	},

	getExtraChildPlan: function() {
		const subscriptionPlans = require('../collections/subscriptionPlans');
		let planId = this.id();
		planId = planId.replace('-selfRedeemed', '').replace('-gift', '');
		return subscriptionPlans(planId + '-extraChild');
	},

	/** @deprecated see Purchase.getPlanDescription */
	formattedIntervalTitle: function() {
		if (this.lifetime()) {
			return 'Lifetime';
		} else if (this.oneOff()) {
			return this.formattedInterval();
		} else {
			switch (this.interval()) {
				case 'month':
					if (this.intervalCount() === 1) {
						return 'Monthly';
					} else {
						return this.formattedInterval();
					}

				case 'year':
					return 'Annually';
			}
		}
	},

	/** @deprecated see Purchase.getPlanDescription */
	formattedInterval: function(skipCountOne) {
		let output = '';

		if (!skipCountOne || this.intervalCount() !== 1) {
			output += this.intervalCount() + ' ';
		}

		output +=
			require('./SubscriptionPlanSchemaLang')[this.interval()] +
			(this.intervalCount() !== 1 ? 's' : '');

		return output;
	},

	stripePlanIdForCurrency: function(currency) {
		var price = this.price(currency);

		if (!price.$exists()) {
			throw new Error('No price for currency ' + currency);
		}

		return this.id() + '-' + price.currency();
	},

	monthsPerInterval: function() {
		switch (this.interval()) {
			case 'year':
				return 12;

			case 'week':
				return 0.25;

			case 'month':
				return 1;

			default:
				throw new CKError({
					message: 'Unknown interval',
					tags: {
						interval: this.interval(),
						planId: this.id()
					}
				});
		}
	},

	intervalInMonths: function() {
		return this.monthsPerInterval() * this.intervalCount();
	},

	endDate: function(created) {
		if (this.lifetime()) {
			// TODO Andrew 2038
			return moment('2038-01-01').toDate();
		} else {
			const months = this.intervalInMonths();

			const wholeMonths = Math.floor(months);

			if (!_.isInteger(months)) {
				throw new CKError(
					'Cannot calculate credit for plan which is not an integer number of months',
					{
						planId: this.id()
					}
				);
			}

			return moment(created)
				.add(wholeMonths, 'months')
				.toDate();
		}
	},

	product: function() {
		const products = require('../data/products');

		const roleMatches = {
			[products.MINECRAFT]: require('../data/roles/minecraft25Role'),
			[products.ROBLOX]: require('../data/roles/robloxRole'),
			[products.BUNDLE]: require('../data/roles/bundleRole')
		};

		let product = _.findKey(roleMatches, role => {
			return _.find(this.roles().$(), {
				name: role.name
			});
		});

		return product;
	},

	isCouponApplicable: function(coupon, currency, periodIndex) {
		if ((coupon.oneOffOnly() && !this.oneOff()) || (coupon.subscriptionOnly() && this.oneOff())) {
			return false;
		}

		const monthIndex = Math.round(periodIndex * this.intervalCount() * this.monthsPerInterval());

		const durationInMonths =
			coupon.duration() === STRIPE_DURATION.ONCE ? 1 : coupon.duration_in_months();
		const isWithinUsablePeriod = !periodIndex || !durationInMonths || monthIndex < durationInMonths;

		const tooShort =
			coupon.minimumPlanMonths() && this.intervalInMonths() < coupon.minimumPlanMonths();

		const tooLong =
			coupon.maximumPlanMonths() && this.intervalInMonths() > coupon.maximumPlanMonths();

		const applicableProducts = coupon.applicableProducts().$();

		let productsApplicable = true;

		if (applicableProducts && applicableProducts.length) {
			productsApplicable = false;

			for (let productName of applicableProducts) {
				if (this.roles(productName).$exists()) {
					productsApplicable = true;
					break;
				}
			}
		}

		return (
			(coupon.percentOff() || coupon.amountsOff(currency).$exists()) &&
			isWithinUsablePeriod &&
			!tooShort &&
			!tooLong &&
			productsApplicable
		);
	},

	hasTrial: function() {
		return !!this.freeTrialDays();
	},

	durationKey: function() {
		if (this.lifetime()) {
			return planDurations.LIFETIME;
		} else if (this.intervalInMonths() === 12 && !this.oneOff()) {
			return planDurations.YEARLY;
		} else if (this.intervalInMonths() === 12 && this.oneOff()) {
			return planDurations.YEAR;
		} else if (this.intervalInMonths() === 3 && !this.oneOff()) {
			return planDurations.THREE_MONTHLY;
		} else if (this.intervalInMonths() === 3 && this.oneOff()) {
			return planDurations.THREE_MONTHS;
		} else {
			return planDurations.MONTHLY;
		}
	},

	getStartDate: function(user) {
		if (!user || !user.$exists()) {
			return new Date();
		}

		const currentPlanId = user.getCurrentPlanId();

		if (currentPlanId) {
			const subscriptionPlans = require('../collections/subscriptionPlans');

			const currentPlan = subscriptionPlans(currentPlanId);

			if (this.isUpgradeFromPlanByBenefits(currentPlan) || this.lifetime()) {
				return new Date();
			}
		}

		return _.max([new Date(), user.getNextInvoiceDate(), user.getExpiryDate()]);
	}
});

module.exports.Set = darc.compile(darc.types.set(module.exports.schema));
