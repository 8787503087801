import { LogSource } from 'ck-core/source/classes/LogSource';
import _ from 'lodash';

export function replaceTemplateVariables(
	template: string,
	variables: { [key: string]: string },
	logger?: LogSource
): string {
	if (!logger) {
		logger = CK.logger;
	}

	const matches = template.match(/\$([A-Za-z0-9_]+|\[[A-Za-z0-9_]+\])/g);

	if (!matches) {
		return template;
	}

	for (let i = 0; i < matches.length; i++) {
		let matcher = matches[i];
		let key = matcher.substring(1);
		if (key[0] === '[') {
			key = key.substring(1, key.length - 1);
		}

		let replacement = variables[key];
		if (_.isNil(replacement)) {
			logger.warn('mapVariables missing replacement', {
				tags: {
					replacementKey: key,
					template
				}
			});

			continue;
		}

		if (_.isObject(replacement)) {
			logger.warn('mapVariables replacement is an object', {
				tags: {
					replacementKey: key,
					template
				}
			});

			continue;
		}

		template = template.replace(matcher, replacement);
	}

	return template;
}
