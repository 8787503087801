'use strict';

import _ from 'lodash';

const ClientModule = require('../system/ClientModule');

const ClientStatsManager = (module.exports = ClientModule.define({
	type: 'ClientStatsManager',

	init_ClientStats: function() {
		this._metrics = _.zipObject(
			CK.const.CLIENT_METRIC_TYPES,
			_.map(CK.const.CLIENT_METRIC_TYPES, () => ({}))
		);

		this.flushNow = this.flushNow.bind(this);
		this.flush = _.throttle(this.flushNow, ClientStatsManager.FLUSH_INTERVAL);

		_.extend(
			CK.metric,
			_.zipObject(
				CK.const.CLIENT_METRIC_TYPES,
				_.map(CK.const.CLIENT_METRIC_TYPES, type => this._track.bind(this, type))
			)
		);

		CK.metric.flush = this.flushNow;
	},

	bind_periodicFlush: function() {
		this._flushInterval = setInterval(this.flush, 30000);
	},

	unbind_periodicFlush: function() {
		if (this._flushInterval) {
			clearInterval(this._flushInterval);
			this._flushInterval = null;
		}
	},

	bind_beforeunload: function() {
		window.addEventListener('beforeunload', this.flushNow);
	},

	unbind_beforeunload: function() {
		window.removeEventListener('beforeunload', this.flushNow);
	},

	_track: function(type, name, value) {
		if (name === 'endpointRequest' || name === 'endpointTime') {
			return;
		}

		let values = this._metrics[type][name];

		if (!values) {
			values = this._metrics[type][name] = [];
		}

		values.push(value);

		this._dirty = true;
	},

	emit: function(data) {
		const server = CK.config.environment.servers.monitor;

		const url = CK.fn.buildUrl(server) + server.path + '/clientMetrics';

		if (navigator && navigator.sendBeacon) {
			navigator.sendBeacon(url, JSON.stringify(data));
		} else {
			$.post({
				url: url,
				data: JSON.stringify(data),
				dataType: 'text',
				contentType: 'text/plain'
			});
		}
	},

	flushNow: function() {
		if (!this._dirty) {
			return;
		}

		const request = {
			service: CK.config.name,
			data: _.omitBy(this._metrics, names => !_.size(names))
		};

		this.emit(request);

		this._dirty = false;

		for (let type in this._metrics) {
			this._metrics[type] = {};
		}
	}
}));

ClientStatsManager.FLUSH_INTERVAL = 30000;
