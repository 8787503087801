import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';

const Assets = require('../system/Assets');

module.exports = function(name, options) {
	const CKError = require('ck-core/source/classes/Error');

	if (!name) {
		CK.logger.warn(new CKError('Missing image'));
		return $('<div>');
	}

	if (name === 'todo') {
		CK.logger.warn(new CKError('Todo image'));

		if (CK.config.environment.name === CK_ENV.PRODUCTION) {
			return $('<div>');
		}
	}

	options = _.defaults(options || {}, {
		action: false,
		tag: false,
		ignoreDimensions: false,
		ignoreNotFoundWarning: false,
		size: false, // cover | contain
		position: null,
		fillParent: null
	});

	if (options.fillParent) {
		_.extend(options, {
			size: 'contain',
			width: '100%',
			height: '100%'
		});
	}

	let externalUrl = !!name.match(/^https?\:/);

	if (externalUrl) {
		options.tag = 'img';
		options.ignoreNotFoundWarning = true;
	}

	var sprite = Assets.get(name, options.ignoreNotFoundWarning);

	// Choose a tag
	if (!options.tag) {
		if (options.action) {
			options.tag = 'a';
		} else if (sprite && sprite.url && !_.endsWith(sprite, '.svg')) {
			options.tag = 'img';
		} else {
			options.tag = 'div';
		}
	}

	let element;

	if (options.tag === 'svg') {
		if (!sprite) {
			return $('svg');
		}

		let domString;

		if (sprite.image) {
			domString = sprite.image.substring(CK.config.SVG_DATA_URL_PREFIX_LENGTH, sprite.image.length);
			if (sprite.urlEncoded) {
				domString = decodeURIComponent(domString);
			}
		} else {
			CK.logger.warn("Can't construct an <svg> tag with an external file");

			return $('svg');
		}

		element = $(domString);
		if (options.action) {
			element = $('<a>')
				.append(element)
				.attr('name', name);
		}
	} else {
		element = $('<' + options.tag + '>').attr('name', name);
	}

	if (options.tag === 'img') {
		if (externalUrl) {
			element.attr('src', name);
		} else {
			element.attr('src', sprite.url || sprite.image);
		}
	}

	// Add a hyperlink to the element
	if (options.action) {
		if (options.action.startsWith('http') || options.action.startsWith('/')) {
			element.attr('href', options.action);
		} else {
			element.attr('href', '#' + options.action);
		}
	}

	if (sprite && options.tag !== 'svg' && options.tag !== 'img') {
		var url = "url('" + (sprite.image ? sprite.image : sprite.url) + "')";
		element.css({
			'background-image': url,
			'background-size': options.size ? options.size : '100% 100%',
			'background-position': options.position || (options.size ? 'center center' : 'top left')
		});
	}

	var setWidth, setHeight;

	if (sprite && options.tag !== 'img') {
		setWidth = sprite.width;
		setHeight = sprite.height;
	}

	if (options.width) {
		setWidth = options.width;
	}
	if (options.height) {
		setHeight = options.height;
	}

	if (!options.ignoreDimensions) {
		element.css({
			width: setWidth,
			height: setHeight
		});
	}

	return element.addClass('sprite');
};
