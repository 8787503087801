'use strict';

import _ from 'lodash';

const WebPageContainer = require('./WebPageContainer');
const Frame = require('ck-core-client/source/ui/Frame');

import shouldHideMenuPromo from 'ck-core-client/source/functions/shouldHideMenuPromo';
import Menu from 'ck-core-client/source/ui/Menu';
import Footer from 'ck-core-client/source/ui/Footer';
import NotificationBar from './NotificationBar';
import React from 'react';
import ReactDOM from 'react-dom';
import MenuService from 'ck-core-client/source/services/MenuService';

module.exports = Frame.define({
	type: 'CoreWebFrame',

	init: function(parent, options) {
		this.dom = $('#container > .frame');

		// TODO: Init system seems to make it impossible to nicely override init_ViewCreateDom
		this.init_Module(parent, options);
		this.init_Component(parent, options);
		this.init_View(parent, options);
		this.init_routes(parent, options);
		this.init_ui(parent, options);
		this.init_frameGlobal(parent, options);
		this.init_ui_around(parent, options);
		this.setupModalsOverlay(parent, options);
		this.init_AuthProviders(parent, options);
	},

	init_ui_around: function(parent, options) {
		options = options || {};

		this.reactMenuWrapper = $('.reactMenuWrapper', this.dom);
		this.reactFooterWrapper = $('.reactFooterWrapper', this.dom);
		this.reactNotificationWrapper = $('.reactNotificationWrapper', this.dom);

		const menuService = MenuService.mod();

		let activePromo = this.activePromo ? this.activePromo.getProps() : null;

		let genericPromo = this.genericPromo && this.genericPromo.getProps();

		let promo = activePromo || genericPromo;

		const MenuComponent = options.Menu || Menu;

		const updateMenu = () => {
			ReactDOM.render(
				React.createElement(MenuComponent, {
					entries: menuService.getLinks(),
					handleClick: this.binder.handleHref,
					promo: !shouldHideMenuPromo() && promo,
					frame: this
				}),
				this.reactMenuWrapper[0]
			);
		};

		this.on(MenuService.EVENTS.MENU_CHANGE, updateMenu);
		this.on(Frame.EVENTS.NAVIGATE, updateMenu);

		const FooterComponent = options.Footer || Footer;

		const updateFooter = () => {
			ReactDOM.render(
				React.createElement(FooterComponent, {
					entries: _.map(
						options.footerEntries,
						menuService.entriesToMenuItemProps.bind(menuService)
					),
					handleClick: this.binder.handleHref,
					promo
				}),
				this.reactFooterWrapper[0]
			);
		};

		updateFooter();
		updateMenu();

		const updatePromo = () => {
			const menuClass = 'promoHeader';
			const showPromo = this.activePromo && this.activePromo.getProps() && !shouldHideMenuPromo();

			(showPromo &&
				$('.pageContainer').addClass(menuClass + ' promoFooter ' + this.activePromo.id)) ||
				$('.pageContainer').removeClass(menuClass);
			updateMenu();
			updateFooter();
		};

		setInterval(updatePromo, 1000);

		CK.moduleContainer.subscribe(Frame.EVENTS.NAVIGATE, updatePromo);

		const updateNotificationBar = () => {
			let element = null;

			if (CK.user.pendingLinkInvitation().$()) {
				element = React.createElement(NotificationBar, {
					text: `${CK.user
						.pendingLinkInvitation()
						.fromEmail()} invited you to link to their billing account.`,
					onAccept: () => {
						return CK.clientSockets.users.emit('acceptSubUserLink');
					},
					onDecline: () => {
						return CK.clientSockets.users.emit('declineSubUserLink');
					}
				});
			}

			ReactDOM.render(element, this.reactNotificationWrapper[0]);
		};

		CK.user.pendingLinkInvitation().$bind(updateNotificationBar);

		updateNotificationBar();
	},

	init_AuthProviders: function() {
		const UserManager = require('ck-core-client/source/managers/UserManager');

		CK.moduleContainer.subscribe(UserManager.EVENTS.LOGOUT, () => {
			CK.user.authProviders().$each(provider => {
				require('./AuthProviders').handleLogout(provider.type());
			});
		});
	},

	createPageContainer: function() {
		return new WebPageContainer(this, {
			dom: $('.pageContainer', this.dom)
		});
	}
});
