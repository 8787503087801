'use strict';

if (typeof jQuery !== 'undefined') {
	$.fn.fadeInNicely = function(time) {
		if (!this.length) {
			CK.logger.debug('Fade Nicely called on a jQuery object with no elements');
			return Promise.resolve();
		} else {
			return this.removeClass('fadeOut')
				.show()
				.animateCss('fadeIn', time + 'ms', true);
		}
	};

	$.fn.fadeOutNicely = function(time) {
		if (!this.length) {
			CK.logger.debug('Fade Nicely called on a jQuery object with no elements');
			return Promise.resolve();
		} else {
			return this.removeClass('fadeIn')
				.animateCss('fadeOut', time + 'ms', true)
				.then(() => {
					this.hide();
				});
		}
	};
}
