'use strict';

const Extendable = require('ck-core/source/system/Extendable');

module.exports = Extendable.define({
	init_ModuleController: function(parent) {
		if (!parent) throw 'ParentRequired';

		this._parent = parent;
	},

	type: 'ModuleController'
});
