'use strict';

import _ from 'lodash';

import PropTypes from 'prop-types';
import { nonNegativeInteger } from 'airbnb-prop-types';

const langPropType = PropTypes.object.isRequired;

export const propTypes = {
	id: PropTypes.string.isRequired,
	affiliate: PropTypes.string.isRequired,
	upgradeBanner: PropTypes.string,
	phases: PropTypes.arrayOf(
		PropTypes.shape({
			startDate: PropTypes.instanceOf(Date).isRequired,
			endDate: PropTypes.instanceOf(Date),
			lang: langPropType,
			countdown: PropTypes.shape({
				endDate: PropTypes.instanceOf(Date).isRequired
			}),
			remaining: PropTypes.objectOf(Number),
			remainingSuffix: PropTypes.string
		})
	).isRequired
};

export default class Promo {
	constructor(params) {
		PropTypes.checkPropTypes(propTypes, params, 'prop', 'Promo');

		this.id = params.id;
		this.affiliate = params.affiliate;
		this.upgradeBanner = params.upgradeBanner;

		// Get current phase
		this.phases = _.cloneDeep(params.phases);

		if (!this.phases.length) {
			throw new Error('Must have at least one phase');
		}

		for (let i = 0; i < this.phases.length; i++) {
			let phase = this.phases[i];
			let nextPhase;

			if (i < this.phases.length - 1) {
				nextPhase = this.phases[i + 1];
			}

			if (!nextPhase && !phase.endDate) {
				throw new Error('No endDate for promo in final phase');
			} else if (nextPhase) {
				phase.endDate = nextPhase.startDate;
			}
		}
	}

	getProps() {
		if (new Date() < this.phases[0].startDate) {
			return null;
		}

		for (let phase of this.phases) {
			if (phase.endDate > new Date()) {
				let currentRemaining, remainingSuffix;

				if (phase.remaining) {
					const list = _.sortBy(
						_.map(phase.remaining, (count, key) => ({
							date: new Date(key),
							count
						})),
						'date'
					);

					const now = new Date();

					for (let i = 0; i < list.length; i++) {
						let { date, count } = list[i];

						let next;

						if (list[i + 1]) {
							next = list[i + 1];
						}

						if (date <= now) {
							if (!next) {
								currentRemaining = count;
							} else if (next.date > now) {
								// Linearly Interpolate

								const progress = (now - date.getTime()) / (next.date.getTime() - date.getTime());
								const delta = count - next.count;

								currentRemaining = Math.round(count - progress * delta);
							}

							if (currentRemaining !== undefined) {
								break;
							}
						}
					}

					remainingSuffix = phase.remainingSuffix || 'left!';
				}

				return _.omitBy(
					{
						id: this.id,
						affiliate: this.affiliate,
						countdownEnd: phase.countdownEnd,
						remainingSuffix,
						lang: phase.lang,
						startDate: phase.startDate,
						endDate: phase.endDate,
						remaining: phase.remaining
					},
					_.isNil
				);
			}
		}

		return null;
	}
}

export const EVENTS = {
	UPDATE: 'promoUpdate'
};

export const PromoPropType = PropTypes.shape({
	id: PropTypes.string.isRequired,
	lang: langPropType,
	countdownEnd: PropTypes.instanceOf(Date),
	remaining: nonNegativeInteger
});
