'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'wrestleball',
	name: 'Wrestleball',
	duration: 180,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 20,
	unlockXp: 5500,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/PlayerScore',
			superclass: 'BasePlayer'
		},
		{
			path: '/Slingshot/Slingshot',
			superclass: 'BaseLocalTool'
		},
		{
			path: '/Slingshot/Ball',
			superclass: 'BaseObject'
		},
		{
			path: '/Arena',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'Give the finished version of Wrestleball a go by visiting https://www.roblox.com/games/1892461305/Wrestleball'
		}
	],

	descriptions: [
		`Stay in the centre of the arena the longest and throw balls at your opponents to keep them out!`,
		`In Wrestleball, players must face off in the wrestling arena, firing balls to knock each other out of the centre. The player who stays in the centre the longest is the winner!`,
		`In this course you'll learn how to make an arena-based PVP game using vectors, loops and a score system. It's perfect for confident Roblox fans who are familiar with the Code Editor.`
	],

	videos: [
		{
			name: 'Wrestleball',
			// Total XP: 1550
			chapters: [
				{
					name: 'Firing balls',
					description: 'The code in this chapter fires balls from the slingshot.',
					secondsOffset: 168,
					badges: ['classes-1', 'parameters-1']
				},
				{
					name: 'Reload',
					description:
						'The code in this chapter ensures the balls can only be fired once per second.',
					secondsOffset: 470,
					badges: ['conditions-1', 'booleans-1', 'time-2']
				},
				{
					name: 'Danger balls',
					description: 'The code in this chapter makes players fall over when a ball hits them.',
					secondsOffset: 764,
					badges: ['time-1', 'parameters-1']
				},
				{
					name: 'Loops',
					description:
						'The code in this chapter shows you how to repeat code for all of the players in the game.',
					secondsOffset: 1047,
					badges: ['time-1', 'loops-1', 'debugging-1']
				},
				{
					name: 'Distances',
					description: 'The code in this chapter selects the players in the centre of the arena.',
					secondsOffset: 1354,
					badges: ['physics-1', 'booleans-2']
				},
				{
					name: 'Scores',
					description:
						'The code in this chapter gives points to players in the centre of the arena.',
					secondsOffset: 1531,
					badges: ['variables-2', 'methods-1']
				},
				{
					name: 'Display scores',
					description: "The code in this chapter displays each player's score in the game.",
					secondsOffset: 1822,
					badges: ['ui-1']
				},
				{
					name: 'Winners',
					description: 'The code in this chapter chooses the winner.',
					secondsOffset: 1983,
					badges: ['measure-2', 'variables-1', 'variables-2', 'flow-2', 'booleans-2']
				},
				{
					name: 'Ending and restarting',
					description: 'The code in this chapter announces the winner and resets the game.',
					secondsOffset: 2229,
					badges: ['strings-1', 'strings-2', 'methods-1']
				}
			]
		}
	]
};
