'use strict';

import _ from 'lodash';

const cssUtils = (module.exports = {
	/**
	 * Create a transform for all supported browser prefixes
	 */
	transform: function(transform) {
		return {
			transform: transform,
			'-webkit-transform': transform,
			'-ms-transform': transform,
			'-moz-transform': transform
		};
	},

	translate: function(left, top) {
		return this.transform('translate(' + left + 'px, ' + top + 'px)');
	},

	translateOffset: function(element) {
		var match = element.css('transform').match(/(?:[^,]+,){4}([^,)]+),([^,)]+)/);

		if (!match) {
			return {
				left: 0,
				top: 0
			};
		}

		return {
			left: parseInt(match[1], 10),
			top: parseInt(match[2], 10)
		};
	},

	naturalOffset: function(element) {
		return {
			left: parseInt(element.css('left'), 10) || 0,
			top: parseInt(element.css('top'), 10) || 0
		};
	},

	combinedOffset: function(element) {
		var translateOffset = cssUtils.translateOffset(element),
			naturalOffset = cssUtils.naturalOffset(element);

		return {
			left: translateOffset.left + naturalOffset.left,
			top: translateOffset.top + naturalOffset.top
		};
	},

	filter: function(value) {
		var result = {};

		_.each(['filter', '-webkit-filter', '-o-filter', '-ms-filter'], property => {
			result[property] = value;
		});
		return result;
	},

	addDisabledOverlay: function(dom) {
		if (dom.children('.disabledOverlay').length) {
			return;
		}

		dom.css('position', 'relative');

		$('<div>')
			.addClass('disabledOverlay')
			.appendTo(dom);
	},
	removeDisabledOverlay: function(dom) {
		$(dom)
			.find('.disabledOverlay')
			.remove();
	}
});
