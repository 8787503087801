'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'riddle-ruins',
	name: 'Riddle Ruins',
	duration: 140,
	secondsDuration: 2311,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 40,
	unlockXp: 2500,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/PlayerRuins',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Display',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/DisplayKey',
			superclass: 'BaseLocalObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'Play our completed Riddle Ruins game before you start by visiting https://www.roblox.com/games/1888189907/Riddle-Ruins'
		},
		{
			name: 'urgentMessage',
			time: 90,
			lookAhead: true,
			message:
				"You will spawn in the main room, not the tunnel, if you play your game at this point. If you'd like to start directly in the tunnel, use the 'Play Here' option in the drop-down menu on the Play button in the toolbar while your camera is in the tunnel."
		},
		{
			name: 'urgentMessage',
			time: 1708,
			lookAhead: true,
			message:
				"Remember to click the update space and press the tick in order to create a blank string - don't just leave the update space empty!"
		},
		{
			name: 'urgentMessage',
			time: 2035,
			lookAhead: true,
			message:
				"The footage in the video shows the code resetting each time a wrong answer is put in. We'll be coding this next chapter so don't worry if your keypad doesn't do this yet"
		}
	],

	descriptions: [
		`Crack the codes inside the pyramid to set yourself free!`,
		`Code a series of puzzles including a secret door, combination lock and blocked corridors to trap players inside a forbidden temple!`,
		`This course show you how to use code to create and change behaviours in your Roblox map. You’ll also get introduced to some key game design concepts that you can apply to future games.`
	],

	videos: [
		{
			name: 'Riddle Ruins',
			chapters: [
				{
					name: 'Fake wall',
					description: 'The code in this chapter sets up the fake wall in the temple.',
					secondsOffset: 244,
					badges: ['variables-1', 'booleans-2']
				},
				{
					name: 'Tools (Axe)',
					description: 'The code in this chapter makes the axe chop down planks.',
					secondsOffset: 539,
					badges: ['ui-1', 'booleans-1', 'conditions-2']
				},
				{
					name: 'Tools (Torch)',
					description: 'The code in this chapter makes the torch burn through cobwebs',
					secondsOffset: 911,
					badges: ['workspace-1', 'parameters-1']
				},
				{
					name: 'Keypad',
					description:
						'The code in this chapter sets up the keypad so the player can crack the code.',
					secondsOffset: 1186,
					badges: ['debugging-1', 'parameters-1']
				},
				{
					name: 'Keypad display',
					description:
						'The code in this chapter displays the number combination to the player in the game.',
					secondsOffset: 1597,
					badges: ['operators-1', 'parameters-2']
				},
				{
					name: 'Answer',
					description: "The code in this chapter makes sure there's only one correct answer.",
					secondsOffset: 1802,
					badges: ['conditions-2', 'booleans-2']
				},
				{
					name: 'Reset',
					description:
						'The code in this chapter unlocks the door and resets the game if the player gets the right answer!',
					secondsOffset: 2054,
					badges: ['presentation-1', 'variables-2']
				}
			]
		}
	]
};
