'use strict';

import _ from 'lodash';

import React from 'react';
import MenuItemProps from '../data/MenuItemProps';

export default class MenuItem extends React.Component {
	render() {
		const classnames = ['item'];

		if (this.props.entry.classes) {
			classnames.push(...this.props.entry.classes);
		}

		const self = this;

		const handleClick = event => {
			if (self.props.entry.modal) {
				self.props.frame
					.getModalsOverlay()
					.addModal(React.createElement(_.get(this, 'props.entry.modal.default')));
			}

			self.props.handleClick(self.props.entry.href, self.props.entry.target);

			event.preventDefault();
			event.stopPropagation();
		};

		return (
			<div className={classnames.join(' ')}>
				<a
					target={this.props.entry.target}
					href={this.props.entry.href}
					onClick={handleClick}
					className="ignoreBinder"
				>
					{this.props.entry.name}
				</a>
			</div>
		);
	}
}

MenuItem.propTypes = MenuItemProps;
