'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'flappy-block',
	name: 'Flappy Block',
	duration: 120,
	secondsDuration: 3847,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Rack up a high score and try not to touch the lava in our 3D version of Flappy Bird in Minecraft.',
		'Think you’ve mastered Flappy Bird? Think again! Try our 3D Minecraft version, complete with the original flapping motion.',
		'This course shows you how to create unlimited obstacle courses using methods, variables and for loops, so do your best to rack up the highest score possible without touching the lava!'
	],

	difficulty: 'Medium',
	unlockXp: 20000,

	videos: [
		{
			name: 'Flappy Block',
			chapters: [
				{
					name: 'Leaving The Nest',
					description: 'This chapter teaches you to set up your world on Minecraft. ',
					badges: ['parameters-1', 'methods-1'],
					secondsOffset: 0
				},
				{
					name: 'Flap, Flap and Away',
					description:
						'This chapter teaches you to use velocities to control how the player moves.',
					badges: ['fields-1', 'operators-2'],
					secondsOffset: 302
				},
				{
					name: 'Feather Forecast',
					description: "This chapter teaches you to control the player's vertical velocity. ",
					badges: ['double-1', 'conditions-1', 'booleans-1'],
					secondsOffset: 529
				},
				{
					name: 'Flappy Meal',
					description: 'This chapter teaches you to use booleans.',
					badges: ['operators-2', 'logic-2', 'methods-1', 'parameters-3'],
					secondsOffset: 824
				},
				{
					name: 'Just Wing it',
					description: 'This chapter teaches you to set your spawn point.',
					badges: ['operators-1', 'operators-2', 'variables-1'],
					secondsOffset: 1443
				},
				{
					name: 'Flock Together',
					description: 'This chapter teaches you to build your own Minecraft structures.',
					badges: ['evaluation-1', 'operators-2', 'general-1', 'generalisation-1'],
					secondsOffset: 1831
				},
				{
					name: 'What do penguins wear on their heads?',
					description: 'This chapter teaches you to build obstacles in your course.',
					badges: ['generalisation-2', 'generalisation-3', 'random-1'],
					secondsOffset: 2726
				},
				{
					name: "Swing When You're Winging",
					description: 'This chapter teaches you to create a full obstacle course. ',
					badges: ['loops-3', 'flow-2'],
					secondsOffset: 3358
				},
				{
					name: 'Flappy Ending',
					description: 'This chapter teaches you to finish offyour obstacle course. ',
					badges: ['loops-3', 'flow-2'],
					secondsOffset: 3499
				}
			]
		}
	]
};
