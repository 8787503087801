'use strict';

import _ from 'lodash';

const Component = require('../ui/Component');
const ClientModule = require('./ClientModule');

module.exports = ClientModule.define(Component, {
	init_ViewGetDom: function() {
		this.dom = $('<div>');
	},

	init_View: function() {
		this._actions = {};

		this.dom.addClass('view').addClass(_.snakeCase(this.type));

		const HyperlinkBinder = require('../binders/HyperlinkBinder');

		this.binder = new HyperlinkBinder(this._actions, this.dom);

		this.content = this.dom;
	},

	bind_view: function() {
		this.show();
	},

	unbind_view: function() {
		this.hide();
	},

	cleanup_view: function() {
		// TODO: Remove when we have mixin ordering, this is here as the Component cleanup is called first
		if (this.dom) {
			this.dom.remove();
		}
	},

	append: function(...elements) {
		for (let element of elements) {
			if (!element) {
				continue;
			}

			if (element.dom) {
				this.content.append(element.dom);
			} else {
				this.content.append(element);
			}
		}

		return this;
	},

	close: function() {
		CK.moduleContainer.remove(this);
	},

	type: 'View'
});
