import _ from 'lodash';

import { CK_ENV } from 'ck-core/source/data/ckEnv';
import { SerialPromiseQueue } from 'ck-core/source/classes/SerialPromiseQueue';

const MonitorContextService = require('ck-core/source/services/MonitorContextService');
const Frame = require('../ui/Frame');
const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define({
	type: 'IntercomManager',

	init_ui: function() {
		const intercomKey = (this.intercomKey =
			CK.config.environment.name === CK_ENV.PRODUCTION ? 'sk7o182l' : 'e4pzvu7f');

		this._viewStateQueue = new SerialPromiseQueue();

		try {
			// Remove intercom-state as this can crash the browser due to a bug in their code
			localStorage.removeItem('intercom-state');
		} catch (err) {
			CK.logger.debug('Error removing intercom state from localStorage');
		}

		if (typeof $ !== 'undefined') {
			this._blanker = $('<div class="intercomBlanker">').append(
				$('<img>').attr('src', '/graphics/icons/intercom-placeholder.svg')
			);

			let intercomPlaceholder;

			this._blanker.on('tap', () => {
				if (intercomPlaceholder) {
					intercomPlaceholder.remove();
					intercomPlaceholder = null;

					return;
				}

				intercomPlaceholder = $('<div class="intercomPlaceholder">').append(
					'Live chat could not be loaded. Please contact ',
					$('<a href="mailto:support@codekingdoms.com">support@codekingdoms.com</a>'),
					' for help.'
				);

				$('body').append(intercomPlaceholder);
			});

			this._blanker.hide();

			// Disabled because Intercom change the CSS classes unpredictably
			// $('body').append(this._blanker);
		} else {
			this._blanker = {
				show: _.noop,
				hide: _.noop
			};
		}

		window.intercomSettings = {
			app_id: this.intercomKey
		};

		(function() {
			var w = window;
			var ic = w.Intercom;
			if (typeof ic === 'function') {
				ic('reattach_activator');
				ic('update', window.intercomSettings);
			} else {
				var d = document;
				var i = function() {
					i.c(arguments);
				};
				i.q = [];
				i.c = function(args) {
					i.q.push(args);
				};
				w.Intercom = i;

				const l = function() {
					var s = d.createElement('script');
					s.type = 'text/javascript';
					s.async = true;
					s.src = 'https://widget.intercom.io/widget/' + intercomKey;
					var x = d.getElementsByTagName('script')[0];
					x.parentNode.insertBefore(s, x);
				};

				l();
			}
		})();

		window.Intercom(
			'onShow',
			_.debounce(() => {
				// No point calling it twice if the other one is waiting
				if (this.userId) {
					this.update();
				}
			}, 5000)
		);

		this.on(Frame.EVENTS.NAVIGATE, () => {
			this.update();
		});

		this.change('Sequencer.currentProject', () => {
			this.update();
		});

		this.on('Sequencer.stepDetection.ProjectProgressUpdated', () => {
			this.update();
		});

		this.userId = null;
	},

	update: function() {
		const updateParameters = this._getUpdateParameters();

		if (updateParameters) {
			window.Intercom('update', updateParameters);
		} else {
			this.emptyUpdate();
		}
	},

	init_changeUser: function() {
		this.change('user', this.changeUser.bind(this));
	},

	bind_changeUser: function() {
		this.changeUser(CK.user);
	},

	emptyUpdate: function() {
		window.Intercom('update');
	},

	_getUpdateParameters: function() {
		if (!CK.user.$exists()) {
			return;
		}

		var envPrefix = '';

		if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
			envPrefix = CK.config.environment.name + '-';
		}

		var customAttributes = {};

		if (MonitorContextService.mod()) {
			_.extend(customAttributes, MonitorContextService.mod().getContext());
			_.extend(customAttributes, {
				mixpanelUrl: 'https://mixpanel.com/report/803475/explore/#user?name=' + CK.user.name()
			});
		}

		var trackingFields = _.clone(CK.const.UTM_TRACKING_FIELDS);

		// Add conversion prefix to each field
		_.each(CK.const.UTM_TRACKING_FIELDS, fieldName => {
			trackingFields.push('conversion_' + fieldName);
		});

		_.each(trackingFields, field => {
			delete customAttributes[field];
		});

		if (CK.user.isTrialling()) {
			const { trialDaysIn } = CK.user.getTrialInfo();

			customAttributes.trialDaysIn = trialDaysIn;
		}

		CK.user.cancellationAttempts().$each(attempt => {
			customAttributes['cancelAttempt_' + attempt.category()] = true;
		});

		var stripePaymentDeclines = CK.user
			.paymentDeclines()
			.$find({
				type: CK.const.DECLINE_TYPES.STRIPE
			})
			.$size();

		if (CK.fn.userCurrency) {
			let currency = CK.fn.userCurrency();

			customAttributes.currency = currency;
		}

		_.extend(customAttributes, {
			user_id: CK.user.id(),
			user_hash: CK.user.intercomUserHash(),
			externalId: envPrefix + CK.user.id(),
			projects:
				CK.user
					.getVertical()
					.projects()
					.$size() || 0,
			created_at: Math.round(new Date(CK.user.dateCreated()).getTime() / 1000), // Unix timestamp,
			aHelpUrl: `${
				CK.config.environment.clients.minecraftSequencer.address
			}#!/help/${CK.user.name()}/${customAttributes.currentProjectName || ''}`,
			stripePaymentDeclines
		});

		if (CK.user.simpleRegister() && CK.user.isNoCardUser()) {
			delete customAttributes.name;
		}

		CK.fn.prepareIntercomCustomAttributes(customAttributes);

		return customAttributes;
	},

	waitForContainerToDraw: async function() {
		await CK.fn.poll(
			() => {
				return $('div#intercom-container').length > 0;
			},
			{
				timeout: 30000
			}
		);
	},

	_clearCheckShownTimeout: function() {
		if (this._checkShownTimeout) {
			clearTimeout(this._checkShownTimeout);
			this._checkShownTimeout = null;
		}
	},

	_enableAutoBlanker: function() {
		const check = () => {
			if (!$('.intercom-launcher').length) {
				this._blanker.show();
			} else {
				this._blanker.hide();
			}
		};

		check();

		this._autoBlankerInterval = setInterval(check, 2000);
	},

	_disableAutoBlanker: function() {
		if (this._autoBlankerInterval) {
			clearInterval(this._autoBlankerInterval);
			this._autoBlankerInterval = null;
		}

		this._blanker.hide();
	},

	open: async function() {
		await this.waitForContainerToDraw();

		window.Intercom('show');
	},

	populate: async function(message) {
		await this.waitForContainerToDraw();

		window.Intercom('show');

		window.Intercom('showNewMessage', message);
	},

	show: async function() {
		if (this.isNoIntercomUser()) {
			return;
		}
		CK.logger.debug('ℹ️ Queued Showing Intercom for', this.userId || 'logged out user');
		this._viewStateQueue.queue('show', async () => {
			if (!this._initialShowTimeout) {
				this._initialShowTimeout = setTimeout(() => {
					if (this._blanker.css('display') === 'none') {
						CK.logger.log('Intercom was shown successfully');
					} else {
						CK.logger.log('Intercom could not be shown');
					}
				}, 30000);
			}

			// Disabled because Intercom change the CSS classes unpredictably
			// this._enableAutoBlanker();

			await this.waitForContainerToDraw();

			CK.logger.debug('ℹ️ Showing Intercom for', this.userId || 'logged out user');

			$('div#intercom-container').show();
		});
	},

	hide: async function() {
		CK.logger.debug('ℹ️ Queued Hiding Intercom');
		this._viewStateQueue.queue('hide', async () => {
			if (this._initialShowTimeout) {
				clearTimeout(this._initialShowTimeout);
			}

			this._disableAutoBlanker();

			await this.waitForContainerToDraw();

			CK.logger.debug('ℹ️ Hiding Intercom');

			$('div#intercom-container').hide();
		});
	},

	isNoIntercomUser: function(user) {
		user = user || CK.user;

		const StaffModeManager = require('./StaffModeManager');

		const staffModeManager = StaffModeManager.mod();

		const staffId = staffModeManager && _.get(staffModeManager.getSession(), 'userId');

		const staffActingAsUser = staffId && staffId !== user.id();

		const isLead = !this.userId;

		return (
			(user.$exists() && user.isCampStudent() && !user.hasHadAPlan()) ||
			staffActingAsUser ||
			(isLead && !!window.Cookies.get('hasVisitedStudioPlus'))
		);
	},

	changeUser: async function(user) {
		if (this.userId === user.id()) {
			return;
		}

		window.Intercom('shutdown');

		this.userId = user.id();

		const noIntercomUser = this.isNoIntercomUser(user);

		if (noIntercomUser) {
			this.hide();

			return;
		}

		const showPromise = this.show();

		if (this.userId) {
			window.Intercom(!window.Intercom.booted ? 'boot' : 'update', this._getUpdateParameters());
		} else {
			window.Intercom('boot');
		}

		return showPromise;
	}
});
