module.exports = function(file) {
	if (file.superclass) {
		return file.superclass;
	}

	const extendsMatch = /public\sclass\s[A-Za-z0-9]+\sextends\s([A-Za-z0-9]+)/;

	let match = file.buffer.match(extendsMatch);

	if (!match) {
		// TODO CORE-1393: When we add a renaming file system we need to
		// explicitly store the original base class in file.superclass in case
		// it gets deleted. Currently, having no superclass prevents the
		// AutoClassDefinition fixer from working if the buffer
		// is missing a class definition

		// For now, approximate based on capital case
		return file.name.match(/([A-Z][a-z]+)?([A-Z].*)$/)[2];
	}

	return match[1];
};
