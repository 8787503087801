'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'music-room',
	name: 'Music Room',
	duration: 60,
	secondsDuration: 1928,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 10,
	unlockXp: 2350,
	comingSoon: false,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Player',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/Drum',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/Speaker',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/PianoKey',
			superclass: 'BaseLocalObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 102,
			lookAhead: true,
			message:
				'Test out our completed Music Room course by visiting https://www.roblox.com/games/1667150941/Music-Room'
		}
	],

	descriptions: [
		`Code your own musical instruments for Roblox.`,
		`Time to get the band back together and start making music with your custom coded instruments!`,
		`Learn how to link audio files with objects in the workspace to make a playable piano and drumkit.`
	],

	videos: [
		{
			name: 'Music Room',
			chapters: [
				{
					name: 'Music For The Jilted Generation',
					description: 'Get to grips with audio in Roblox Studio.',
					secondsOffset: 112,
					badges: ['workspace-1', 'presentation-1'],
					noCodeChapter: true
				},
				{
					name: 'Chord Of The Rings',
					description: 'Add code to make your drum play sound when you click on it.',
					secondsOffset: 417,
					badges: ['conditions-1', 'classes-1']
				},
				{
					name: "Cello, Is It Me You're Looking For?",
					description:
						'Add code such that you can pause and play music from the speakers by clicking.',
					secondsOffset: 863,
					badges: ['generalisation-1', 'conditions-2']
				},
				{
					name: "Snare's No Other Way",
					description: 'Play around with sound effects in Roblox Studio.',
					secondsOffset: 1149,
					badges: ['workspace-2', 'presentation-2'],
					noCodeChapter: true
				},
				{
					name: 'Push The Note Out',
					description: 'Add code to make your piano keys play different notes.',
					secondsOffset: 1386,
					badges: ['generalisation-2', 'methods-3']
				}
			]
		}
	]
};
