'use strict';

import _ from 'lodash';

module.exports = require('./ExtendableContainer').define({
	type: 'Module',

	init_Module: function(parent) {
		if (!parent) {
			CK.logger.log('No parent passed to module constructor', this.type);
		}

		this._parent = parent;
	},
	// Get information from the parent datastore

	registerForLeadership: function() {
		const leadership = CK.moduleContainer.module('ServerLeadershipManager');

		if (leadership) {
			if (leadership.isLeader()) {
				this.lead();
			}

			this.on(leadership._class.EVENTS.ROLE_CHANGE, () => {
				if (leadership.isLeader()) {
					this.lead();
				} else {
					this.unlead();
				}
			});
		} else {
			CK.logger.warn('ServerLeadershipManager not found');
		}
	},

	get: function(path) {
		if (!this._parent) return;

		return this._parent.get(path);
	},

	// Get a module from the parent's registry

	module: function(type) {
		if (!this._parent) return;

		return this._parent.module(type);
	},

	broadcast: function(event, data) {
		if (!this._parent) {
			return;
		}

		var args = [event, data];

		for (var i = 2; i < arguments.length; i++) {
			args.push(arguments[i]);
		}

		return this._parent.broadcast.apply(this._parent, args);
	},

	// Bind to an event

	on: function(path, listener, self) {
		if (!path) return CK.logger.error('Attempt to bind to undefined path with listener', listener);

		listener.self = self || this;

		if (!this._eventListeners) this._eventListeners = {};

		if (!this._eventListeners[path]) this._eventListeners[path] = [];

		if (_.includes(this._eventListeners[path], listener)) throw 'DuplicateListenerForPath: ' + path;

		this._eventListeners[path].push(listener);

		if (this.bound) {
			this._addEventListener(path, listener);
		}
	},

	// Bind to a change in data

	change: function(path, listener) {
		if (!path) return CK.logger.error('Attempt to bind to undefined path with listener', listener);

		listener = listener.bind(this);

		if (!this._dataListeners) this._dataListeners = {};

		if (!this._dataListeners[path]) this._dataListeners[path] = [];

		this._dataListeners[path].push(listener);

		if (this.bound) {
			this._addDataListener(path, listener);
		}
	},

	_addEventListener: function(path, listener) {
		if (!this._parent) return;

		this._parent._addEventListener(path, listener);
	},

	_removeEventListener: function(path, listener) {
		if (!this._parent) return;

		this._parent._removeEventListener(path, listener);
	},

	bind_eventListeners: function() {
		if (!this._parent) return;

		_.each(this._eventListeners, (list, key) => {
			for (var i = 0; i < list.length; i++) {
				this._addEventListener(key, list[i]);
			}
		});
	},

	unbind_eventListeners: function() {
		if (!this._parent) return;

		_.each(this._eventListeners, (list, key) => {
			for (var i = 0; i < list.length; i++) {
				this._removeEventListener(key, list[i]);
			}
		});
	},

	_addDataListener: function(path, listener) {
		if (!this._parent) return;

		this._parent._addDataListener(path, listener);
	},

	_removeDataListener: function(path, listener) {
		if (!this._parent) return;

		this._parent._removeDataListener(path, listener);
	},

	bind_dataListeners: function() {
		if (!this._parent) return;

		_.each(this._dataListeners, (list, key) => {
			for (var i = 0; i < list.length; i++) {
				this._addDataListener(key, list[i]);
			}
		});
	},

	unbind_dataListeners: function() {
		if (!this._parent) return;

		_.each(this._dataListeners, (list, key) => {
			for (var i = 0; i < list.length; i++) {
				this._removeDataListener(key, list[i]);
			}
		});
	},

	_addAssignment: function(name, cursor) {
		if (!this._parent) return;

		this._parent._addAssignment(name, cursor);
	},

	_removeAssignment: function(name, cursor) {
		if (!this._parent) return;
		this._parent._removeAssignment(name, cursor);
	},

	bind_assignments: function() {
		if (!this._parent) return;

		_.each(this._assignments, (value, key) => {
			this._addAssignment(key, value);
		});
	},
	unbind_assignments: function() {
		if (!this._parent) return;
		_.each(this._assignments, (value, key) => {
			this._removeAssignment(key, value);
		});
	},
	container: function() {
		return this._parent.container();
	},
	cleanup_Module: function() {
		if (!this._parent) return;
		if (this.bound) {
			return this.unbind().finally(() => {
				this._eventListeners = null;
				this._assignments = null;
				this._parent = null;
			});
		} else {
			this._eventListeners = null;
			this._assignments = null;
			this._parent = null;
		}
	}
});
