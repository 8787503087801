'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'spleef',
	name: 'Spleef',
	duration: 120,
	secondsDuration: 3707,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Knock your friends into the lava by creating your own winter version of a PVP Spleef world!',
		'Winter is coming but not as you know it! Find out how to create your very own version of Spleef. The aim of the game is to knock blocks out from underneath the other players so they tumble into the lava.',
		'You’ll use loops and methods to establish the rules of the game and create your own multi-platform PVP world!'
	],

	difficulty: 'Medium',
	unlockXp: 16000,

	videos: [
		{
			name: 'Spleef',
			chapters: [
				{
					name: 'Chill Out',
					description: 'The first step sets up the gamemode of the players for the game',
					badges: ['methods-1', 'loops-2'],
					secondsOffset: 68
				},
				{
					name: 'Break The Ice',
					description:
						'This chapter shows you how to equip the players with spades and extra health!',
					badges: ['inventory-1', 'parameters-1'],
					secondsOffset: 515
				},
				{
					name: 'The Heat Is On',
					description:
						'This chapter teaches you how to teleport all the players to random locations',
					badges: ['random-1', 'loops-2', 'methods-1'],
					secondsOffset: 738
				},
				{
					name: 'What Killed The Dinosaurs?',
					description: 'This chapter teaches you how to turn the snowballs into a weapon',
					badges: ['operators-2'],
					secondsOffset: 1352
				},
				{
					name: 'The Ice Age',
					description:
						'This chapter teaches you how to restart the game once a survivor has been found',
					badges: ['variables-1', 'variables-2', 'communication-2', 'timer-1', 'operators-1'],
					secondsOffset: 1786
				},
				{
					name: 'Alright, Everyone! Chill!',
					description: 'This chapter adds a countdown before the game begins',
					badges: ['variables-1', 'timer-2', 'conditions-2', 'loops-1', 'operations-1'],
					secondsOffset: 2214
				},
				{
					name: 'Cool Party',
					description: 'This is the first step in creating the base of your own Spleef platform',
					badges: ['operations-2', 'generalisation-2'],
					secondsOffset: 2637
				},
				{
					name: 'You Have To Let It Go',
					description: 'The final chapter teaches you how to create a full snow arena using code',
					badges: ['algorithms-1', 'operations-2'],
					secondsOffset: 3132
				}
			]
		}
	]
};
