'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'rise-of-the-werebunnies',
	name: 'Rise of the Werebunnies',
	projectNamePrefix: 'werebunnies',
	duration: 120,
	secondsDuration: 2942,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	descriptions: [
		'Create a multiplayer fight for survival, complete with exploding eggs.',
		'Beware! The moon is full and the werebunnies are out… This course shows you how to split players into different teams and add a score system.',
		'This course uses loops and conditionals to split players into different teams with different characteristics, then gives players scores when they defeat an enemy.'
	],

	difficulty: 'Easy',
	unlockXp: 6000,

	videos: [
		{
			name: 'Rise of the Werebunnies',
			chapters: [
				{
					name: 'Dawn Of The Werebunnies',
					description: 'This chapter is where you set the time of day and create a world border',
					badges: ['methods-1', 'parameters-1'],
					secondsOffset: 31
				},
				{
					name: 'Wrath Of The Werebunnies',
					description: 'This chapter teaches you how to add items to player inventories',
					badges: ['classes-1', 'inventory-1'],
					secondsOffset: 327
				},
				{
					name: 'Bad Hare Day',
					description: 'This chapter shows you how to make the eggs explode',
					badges: ['parameters-1', 'methods-1'],
					secondsOffset: 583
				},
				{
					name: `Don't Worry, Be Hoppy`,
					description:
						'In this chapter you add different code to run depending if a player is a bunny or human',
					badges: ['communication-1', 'methods-2', 'loops-1'],
					secondsOffset: 759
				},
				{
					name: 'What A Terrible Tail',
					description:
						'This chapter introduces loops to turn every player human at the start of the round',
					badges: ['conditions-1', 'booleans-1'],
					secondsOffset: 1123
				},
				{
					name: 'Revenge Of The Werebunnies',
					description:
						'This chapter shows you how to use an if statement to run code under different conditions',
					badges: ['conditions-1', 'methods-1'],
					secondsOffset: 1338
				},
				{
					name: 'Spread Of The Werebunnies',
					description:
						'This chapter teaches you how to turn a human into a werebunny if they are killed by a bunny',
					badges: ['logic-1', 'logic-2'],
					secondsOffset: 1623
				},
				{
					name: `You've Got More Rabbit Than Sainsbury's`,
					description: 'This chapter adds a game over check once all the humans have been defeated',
					badges: ['flow-1', 'nesting-1'],
					secondsOffset: 1866
				},
				{
					name: 'Hare Today, Gone Tomorrow',
					description: 'In this chapter you restart the game and announce the last survivor',
					badges: ['flow-1', 'loops-2'],
					secondsOffset: 2122
				},
				{
					name: 'War Of The Werebunnies',
					description: 'This chapter teaches you how to add a score system',
					badges: ['operators-1', 'fields-1'],
					secondsOffset: 2310
				},
				{
					name: 'Day Of The Werebunnies',
					description:
						'This chapter removes points from player scores if they kill someone on the same team and adds timers to the start',
					badges: ['operators-1', 'conditions-2'],
					secondsOffset: 2500
				}
			]
		}
	]
};
