'use strict';

import './source/globalLess/fonts.less';
import './source/globalLess/ModalView.less';
import './source/globalLess/ck.less';
import './source/globalLess/index.less';

require('ck-core');

if (typeof window !== 'undefined') {
	window.Cookies = require('js-cookie');
	window.bowser = require('bowser');

	window.$ = window.jQuery = require('jquery');
	window.$.fn.isInViewport = function(partial) {
		var $t = $(this),
			offset = $t.offset();

		if (!offset) {
			return false;
		}

		let $w = $(window),
			viewTop = $w.scrollTop(),
			viewBottom = viewTop + $w.height(),
			_top = offset.top,
			_bottom = _top + $t.height(),
			compareTop = partial === true ? _bottom : _top,
			compareBottom = partial === true ? _top : _bottom;

		return compareBottom <= viewBottom && compareTop >= viewTop;
	};

	require('script!./lib/libraries/js/jquery.mobile.custom.min'); // eslint-disable-line import/no-unresolved

	(CK.pageContexts = []), (CK.pagePreloads = []);

	CK.pageContexts.push(
		require.context('bundle?lazy&name=[name]!./source/ui/pages', true, /Page\.(j|t)sx?$/)
	);

	require('./lib/libraries/css/Animate.css');
	require('./lib/libraries/css/hover.css');
}

require('./source/ui/FadeNicely');
require('./source/ui/insertAt');
require('./source/ui/animateHelper');

require('./source/globals/ClientFunctions');

require('./source/endpoints/disconnect');
require('./source/endpoints/message');
require('./source/endpoints/poisonPill');
require('./source/endpoints/sync');

CK.ui = {};

if (CK.pageContexts) {
	CK.pageContexts.push(
		require.context('bundle?lazy&name=[name]!./source/pages', true, /Page\.(j|t)sx?$/)
	);
}
