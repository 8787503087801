import { CK_ENV } from 'ck-core/source/data/ckEnv';
import { ObjectOf } from 'ck-core/source/types/ObjectOf';


export enum HOTJAR_STANDARD_EVENT {
	ADD_PAYMENT_INFO = 'AddPaymentInfo',
	ADD_TO_CART = 'AddToCart',
	ADD_TO_WISHLIST = 'AddToWishlist',
	COMPLETE_REGISTRATION = 'CompleteRegistration',
	INITIATE_CHECKOUT = 'InitiateCheckout',
	LEAD = 'Lead',
	PURCHASE = 'PlaceAnOrder',
	SEARCH = 'Search',
	VIEW_CONTENT = 'ViewContent'
}

export enum HOTJAR_CUSTOM_EVENT {
	ADD_CARD_DETAILS = 'AddCardDetails',
	VIEW_CARD_FORM = 'ViewCardForm',
	SELECT_PLAN = 'SelectPlan',
	SELECT_PLAN_SIZE = 'SelectPlanSize',
	VIEW_PLANS = 'ViewPlans',
	JOIN_SERVER = 'JoinServer',
	RUN_BUTTON_FIRST_SUCCESS = 'RunButtonFirstSuccess',
	STUDIO_FIRST_CONNECTED = 'StudioFirstConnected',
	CREATE_PROJECT = 'CreateProject',
	PROJECT_PROGRESS_UPDATED = 'ProjectProgressUpdated',
	COMPLETED_ONBOARDING = 'CompletedOnboarding',
	NON_ADWORDS_VIEW_CONTENT = 'NonAdwordsViewContent'
}

export type HOTJAR_EVENT = HOTJAR_STANDARD_EVENT | HOTJAR_CUSTOM_EVENT;

export function trackHotjarPixel(
	name: HOTJAR_EVENT,
	options?: ObjectOf<any>,
	eventMetadata?: any
) {


	// if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
	// 	CK.logger.debug(
	// 		'🔇 Not posting HOTJAR pixel as not on production or test',
	// 		name,
	// 		options
	// 	);
	// 	return;
	// }

	try {
		options = options || {};

		let hotjarTracking = window.ht;

		if (hotjarTracking) {
			
			if (Object.values(HOTJAR_STANDARD_EVENT).includes(name)) {
				hotjarTracking.track(name);
			} else {
				
			}
		}
	} catch (err) {
		CK.logger.error(err);
	}
}
