'use strict';

import PropTypes from 'prop-types';
import { ItemProps } from './MenuItemProps';
import { PromoPropType } from '../classes/Promo';

export default {
	productLayer: PropTypes.object,
	entries: PropTypes.arrayOf(ItemProps).isRequired,
	handleClick: PropTypes.func.isRequired,
	getAvatarImage: PropTypes.func,
	promo: PromoPropType,
	frame: PropTypes.object
};
