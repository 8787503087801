'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands')
			.MINECRAFT_JAVA /*, require( '../../../../data/brands' ).MINECRAFT_PE*/
	], // Not supported in pocket
	id: 'parkour',
	name: 'Parkour',
	duration: 40,
	secondsDuration: 538,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Hop, skip and jump around your world.',
		'This three part mini mod shows you how to create a safety net, turn off fall damage and generate your own parkour course!',
		'This advanced mini mod will show you how to generate structures based on your location.'
	],
	difficulty: 'Hard',
	unlockXp: 30000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Parkour',
			chapters: [
				{
					name: 'Trip and Fall',
					description: 'Turn off fall damage.',
					secondsOffset: 11,
					badges: []
				},
				{
					name: 'Par-course',
					description:
						'Learn how to build your own unique parkour course at the click of a button.',
					secondsOffset: 60,
					badges: []
				},
				{
					name: 'Safety First',
					description: "Create a safety net, so you'll never fall.",
					secondsOffset: 320,
					badges: []
				}
			]
		}
	]
};
