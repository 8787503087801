'use strict';

module.exports = darc.compile({
	id: darc.types.primary(String),
	position: Number,
	name: String,
	projectNamePrefix: String,
	descriptions: [String],
	brands: [String],

	hasCampPdf: Boolean,

	unlockXp: Number,
	comingSoon: Boolean,
	cues: Object,

	worldId: String,

	// Deprecated flags
	shortCourse: Boolean,
	isModOfTheMonth: Boolean,
	noTutorial: Boolean,

	// In minutes
	duration: Number,

	hasPdf: Boolean,

	difficulty: String,

	active: Boolean,

	additionalFiles: [
		{
			path: darc.types.primary(String),
			name: String,
			superclass: String
		}
	],

	videos: [
		{
			id: darc.types.primary(Number),
			name: String,
			chapters: [
				{
					id: darc.types.primary(Number),
					secondsOffset: Number,
					badges: [String],
					description: String,
					additionalFilterBlocks: [String],
					noCodeChapter: Boolean
				}
			]
		}
	]
});

module.exports.Set = darc.compile(darc.types.set(module.exports.schema));
