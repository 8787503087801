'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'spartan-school',
	name: 'Spartan School',
	duration: 120,
	secondsDuration: 3472,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Build an infinite mob arena game, to fight alone or with friends! Oh, and did we mention you’ll be fighting blazes whilst you do it?',
		'Want to be the greatest Minecraft Spartan warrior of all time? Learn programming basics while creating wave after wave of mobs to fight in a Spartan training arena.',
		'Learn programming basics such as loops, methods and variables to create this mob fighting mini-game. Battle increasingly harder waves of enemies that multiply every round.'
	],

	difficulty: 'Easy',
	unlockXp: 1500,

	videos: [
		{
			name: 'Spartan School',
			chapters: [
				{
					name: 'The Mobyssey',
					description:
						'In this step you will change the time of day and stop random mobs from spawning',
					badges: ['parameters-1'],
					secondsOffset: 33
				},
				{
					name: 'Hide And Greek',
					description:
						'In this step you will set up the players with weapons and change their game mode',
					badges: ['classes-1', 'ui-1'],
					secondsOffset: 422
				},
				{
					name: 'The Centaur Of Attention',
					description: 'This chapter teaches you how to spawn your first enemy to fight',
					badges: ['mob-1', 'mob-2'],
					secondsOffset: 650
				},
				{
					name: 'Arte-missing The Point',
					description: 'This chapter teaches you how to add a second mob enemy to join your zombie',
					badges: ['mob-1'],
					secondsOffset: 1010
				},
				{
					name: `I'm Feta-up With You`,
					description: 'In this step you will give your players more weapons to defend themselves',
					badges: ['classes-1', 'inventory-1'],
					secondsOffset: 1123
				},
				{
					name: 'Troy Story',
					description: 'This chapter shows you how to set up the basics of your waves of enemies',
					badges: ['methods-2', 'fields-2'],
					secondsOffset: 1250
				},
				{
					name: 'My Big Fat Greek Hackathon',
					description:
						'In this chapter you will spawn more enemies when you have defeated the previous wave',
					badges: ['methods-1', 'fields-1'],
					secondsOffset: 1730
				},
				{
					name: 'Greece Of Cake',
					description: 'This step introduces for loops to repeat the enemy spawning code',
					badges: ['loops-3'],
					secondsOffset: 2022
				},
				{
					name: 'Greece Lightning',
					description: 'This step teaches you how to change the gamemode of defeated players',
					badges: ['loops-1', 'booleans-1'],
					secondsOffset: 2218
				},
				{
					name: 'Then We Shall Fight In The Blaze',
					description: 'This chapter teaches you how to add a more difficult mob... Blazes!',
					badges: ['mob-1', 'random-1'],
					secondsOffset: 2560
				},
				{
					name: `Don't Make A Blind Pitta Difference`,
					description: 'This chapter teaches you how to add a score system and reset the game',
					badges: ['logic-1', 'loops-2'],
					secondsOffset: 2986
				}
			]
		}
	]
};
