'use strict';

import _ from 'lodash';

module.exports = _.extend(
	{
		ROBLOX: 'roblox',
		NONE: 'none'
	},
	require('./minecraftBrands')
);
