'use strict';

import _ from 'lodash';

const subscriptionPlans = require('ck-core/source/collections/subscriptionPlans');
const CouponSchema = require('ck-core/source/schemas/CouponSchema');

_.extend(CK.fn, {
	getConversionContent: function() {
		const utmContext = CK.moduleContainer.module('UtmTracking').getContext();

		if (utmContext) {
			let utmContent = utmContext.conversion_mp_content;

			if (utmContent) {
				if (_.includes(utmContext.conversion_mp_campaign, 'Search')) {
					return utmContent;
				}

				let utmContentParts = utmContent.split('-');

				if (utmContentParts) {
					return (
						utmContentParts[utmContentParts.length - 2] +
						'-' +
						utmContentParts[utmContentParts.length - 1]
					);
				}
			}
		}

		return false;
	},

	getLandingPageContentLang: function() {
		if (this.getConversionContent) {
			const dynamicLang = require('./WebsiteFunctionsLang').DYNAMIC_AD_LANDING[
				this.getConversionContent()
			];

			if (dynamicLang) {
				return dynamicLang;
			} else {
				return require('./WebsiteFunctionsLang');
			}
		} else {
			return require('./WebsiteFunctionsLang');
		}
	}
});
