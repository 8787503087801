/* eslint-disable */
export function facebookInit(PIXEL_ID) {
	if (typeof window === 'undefined') {
		return;
	}

	if (typeof fbq !== 'undefined') {
		return fbq;
	}

	!(function(f, b, e, v, n, t, s) {
		if (f.fbq) return;
		n = f.fbq = function() {
			n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		};
		if (!f._fbq) f._fbq = n;
		n.push = n;
		n.loaded = !0;
		n.version = '2.0';
		n.queue = [];
		t = b.createElement(e);
		t.async = !0;
		t.src = v;
		s = b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t, s);
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

	fbq('init', PIXEL_ID);

	const img = document.createElement('img');

	img.style = 'display: none';
	img.src = `https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=`;
	img.height = 1;
	img.width = 1;

	document.getElementsByTagName('body')[0].appendChild(img);

	return fbq;
}
