import { CK_ENV } from 'ck-core/source/data/ckEnv';
('use strict');

import _ from 'lodash';

import './AuthProviders.less';

const Component = require('ck-core-client/source/ui/Component');

const GOOGLE_AUTH_SCOPES = 'profile openid email';

const AuthProviders = (module.exports = Component.define({
	type: 'AuthProviders',

	init_ui: function(options) {
		this.options = options;

		this.googleSignOnContainer = $('<div class="googleSignOn">');
		this.populateGoogleSignOn();

		this.facebookSignOnContainer = $('<div class="facebookSignOn">');
		this.populateFacebookSignOn();

		this.dom = $('<div class="authProviders">').append([
			this.googleSignOnContainer,
			this.facebookSignOnContainer
		]);

		if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
			const ckStubSignOnContainer = $('<div class="ckStubSignOn">');

			ckStubSignOnContainer.append(
				$('<button class="ckStubSignOn">')
					.text('Sign in with CK Stub')
					.tap(() => {
						const ConfirmMessageModal = require('ck-core-client/source/ui/modals/messageModals/ConfirmMessageModal');

						let emailInput;

						const modal = ConfirmMessageModal.create({
							title: 'CK Stub Auth Provider',
							message: 'Enter an email address',
							actions: {
								ok: () => {
									this.options.handleAuthRequest(
										{
											type: require('ck-core/source/data/authProviders').STUB,
											data: {
												email: emailInput.val()
											}
										},
										CK.fn.zero
									);
								}
							}
						});

						emailInput = $('<input type="email">').insertAfter(modal.message);
					})
			);

			this.dom.append(ckStubSignOnContainer);
		}

		this.dom.append(
			$('<div class="break">')
				.append($('<div class="left line">'))
				.append($('<div class="text">').html('OR'))
				.append($('<div class="right line">'))
		);
	},

	populateGoogleSignOn: async function() {
		const { auth } = await AuthProviders.initGoogleAuth();

		const reset = () => {
			auth.signOut();
		};

		$('<div id="googleSignOnButton">').appendTo(this.googleSignOnContainer);

		window.gapi.signin2.render('googleSignOnButton', {
			scope: GOOGLE_AUTH_SCOPES,
			width: 240,
			height: 50,
			longtitle: true,
			theme: 'dark',
			onsuccess: googleUser => {
				CK.logger.debug(googleUser.getBasicProfile());
				CK.logger.debug(googleUser.getAuthResponse());

				this.options.handleAuthRequest(
					{
						type: require('ck-core/source/data/authProviders').GOOGLE,
						data: {
							token: googleUser.getAuthResponse().id_token
						}
					},
					reset
				);
			},
			onfailure: err => {
				CK.logger.debug('gapi signOn error', err);

				CK.metric.increment('GoogleAuthSignOnError', 1, 1, [
					`code:${_.get(err, 'error', 'unknown')}`
				]);
			}
		});
	},

	populateFacebookSignOn: async function() {
		await AuthProviders.initFacebookAuth();

		const CKImage = require('ck-core-client/source/ui/image');

		this.facebookSignOnContainer.html(
			CKImage('facebook-login').tap(async () => {
				const response = await new Promise(fulfil => {
					window.FB.login(fulfil, {
						scope: 'public_profile,email',
						auth_type: 'rerequest'
					});
				});

				CK.logger.debug('FB.login response', response);

				if (response.status === 'connected') {
					const reset = async () => {
						await new Promise(fulfil => window.FB.logout(fulfil));
					};

					this.options.handleAuthRequest(
						{
							type: require('ck-core/source/data/authProviders').FACEBOOK,
							data: response.authResponse
						},
						reset
					);
				}
			})
		);
	}
}));

let fbInitialised = false;

AuthProviders.initFacebookAuth = async function() {
	if (fbInitialised) {
		return;
	}

	/* eslint-disable */

	window.fbAsyncInit = function() {
		FB.init({
			appId: CK.config.environment.FACEBOOK_SIGNON_APP_ID,
			cookie: true,
			xfbml: true,
			version: 'v2.8'
		});
		FB.AppEvents.logPageView();
		fbInitialised = true;
	};

	(function(d, s, id) {
		var js,
			fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.src = '//connect.facebook.net/en_US/sdk.js';
		fjs.parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');

	/* eslint-enable */

	await CK.fn.poll(() => fbInitialised, {
		timeout: 20000
	});

	return await new Promise(fulfil => {
		window.FB.getLoginStatus(response => {
			fulfil(response);
		});
	});
};

AuthProviders.initGoogleAuth = async function() {
	await CK.fn.poll(() => !!window.gapi, {
		timeout: 20000
	});

	CK.logger.debug('Loading gapi.auth2');

	await new Promise(fulfil => {
		window.gapi.load('auth2', () => {
			fulfil();
		});
	});

	CK.logger.debug('Initialising gapi.auth2');

	return await new Promise((fulfil, reject) => {
		window.gapi.auth2
			.init({
				client_id: CK.config.environment.GOOGLE_SIGNON_CLIENT_ID,
				fetch_basic_profile: false,
				scope: GOOGLE_AUTH_SCOPES
			})
			.then(
				auth => {
					CK.logger.debug('gapi onsuccess');

					// auth has a then method so wrap it in an object to avoid infinite recursion
					fulfil({
						auth
					});
				},
				result => {
					const err = result.err;

					CK.logger.debug('gapi err', err);

					CK.metric.increment('GoogleAuthInitOnError', 1, 1, [
						`code:${_.get(err, 'error', 'unknown')}`
					]);

					reject(err);
				}
			);
	});
};

AuthProviders.shouldUse = () => CK.config.name === 'ck-website';

AuthProviders.handleLogout = async function(type) {
	if (!AuthProviders.shouldUse()) {
		return;
	}

	CK.logger.debug('AuthProviders.handleLogout', type);

	if (type === require('ck-core/source/data/authProviders').GOOGLE) {
		const { auth } = await AuthProviders.initGoogleAuth();
		auth.signOut();
	} else if (type === require('ck-core/source/data/authProviders').FACEBOOK) {
		await AuthProviders.initFacebookAuth();

		await new Promise(fulfil => {
			window.FB.logout(fulfil);
		});
	}
};
