'use strict';

const Extendable = require('ck-core/source/system/Extendable');

// Blur binder - run the callback if the player clicks outside the region of an element
// callback		function() -> Boolean		return true if the blur binder should remain bound
// self 		Object						passed to callback as 'this'
/* jshint esnext: true */
const Blur = (module.exports = Extendable.define({
	init_BlurBinder: function(callback, element) {
		this.callback = callback || CK.fn.zero;
		this.watching = false;
		this.element = element;

		// Enable after a certain amount of time so that
		// you can't close the popup just as you open it
		setTimeout(() => {
			this.enable();
		}, 300);

		this.update = event => {
			if (!this.shouldBlur) {
				return;
			}
			if ($(event.target).parents('#colormap').length) {
				return;
			}

			if ($(event.target).parents('.ui-widget').length) {
				return;
			}

			if (!$(event.target).parents('body').length) {
				return;
			}

			if (
				!$(event.target)
					.parents()
					.andSelf()
					.filter(this.element && this.element.dom ? this.element.dom : this.element).length
			) {
				if (!this.callback) return;
				var result = this.callback.call(this.element);
				// Keep bound if true

				if (!result) {
					event.stopPropagation();

					this.unbind();

					return false;
				}
			}
		};
	},
	enable: function() {
		this.shouldBlur = true;
	},
	disable: function() {
		this.shouldBlur = false;
	},
	bind: function(e) {
		var t = this;
		if (t.watching) return;
		t.watching = true;

		if (e) t.element = e;
		setTimeout(function() {
			$('body').on('tap', t.update);
		}, 1);
	},
	unbind: function() {
		this.watching = false;

		$('body').off('tap', this.update);
	},

	cleanup_BlurBinder: function() {
		this.callback = null;
		this.element = null;
	},
	type: 'BlurBinder'
}));
