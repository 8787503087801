export function adwordsInit() {
	if (typeof window === 'undefined') {
		return;
	}

	if (window.google_trackConversion) {
		return window.google_trackConversion;
	}

	!(function(e, n, t, a, o, c, r, s, i) {
		e[o] ||
			((r = e[o] = function() {
				r.track = arguments;
			}),
			(c = n.createElement(t)),
			(c.src = a),
			(c.async = !0),
			c.addEventListener('load', function() {
				r.track && e[o].apply(this, r.track);
			}),
			(s = n.getElementsByTagName(t)[0]),
			s.parentNode.insertBefore(c, s));
	})(
		window,
		document,
		'script',
		'//www.googleadservices.com/pagead/conversion_async.js',
		'google_trackConversion'
	);

	return window.google_trackConversion;
}
