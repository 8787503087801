'use strict';

import _ from 'lodash';

import './errorMessageModal.less';

const Language = require('ck-core/source/services/Language');
const MessageModal = require('../MessageModal');

// @abstract
const ErrorMessageModal = (module.exports = MessageModal.define({
	type: 'ErrorMessageModal',

	init_ui: function(parent, options) {
		this.dom.addClass('error');

		if (options.background) {
			this.dom.addClass(options.background);
		}

		this.MessageModal_init_ui(parent, options);
	}
}));

ErrorMessageModal.create = MessageModal.create;

ErrorMessageModal.createFromError = function(err, extra) {
	if (!_.get(err, 'soft')) {
		CK.logger.error(err);
	}

	ErrorMessageModal.create(
		_.extend(
			{
				title: require('./ErrorMessageModalLang').TITLE,
				message: Language.mapVariables(require('./ErrorMessageModalLang').MESSAGE, {
					message: err && err.message ? err.message + '<br><br>' : ''
				})
			},
			extra || {}
		)
	);
};
