'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'barrel-roll',
	name: 'Barrel Roll',
	duration: 100,
	secondsDuration: 1636,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 10,
	unlockXp: 500,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Kong',
			superclass: 'BaseObject'
		},
		{
			path: '/Barrel',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 147,
			lookAhead: true,
			message:
				'Test out our completed Barrel Roll course by visiting https://www.roblox.com/games/1242199886/Barrel-Roll'
		},

		{
			name: 'message',
			time: 575,
			message: `Need help moving the barrel around? Make sure that you've reset the Move studs to 0 in the "Model" menu in Roblox, and the barrel isn't Locked in the Properties window. Try our Introduction to Roblox video for more help in our FAQs: https://faq.codekingdoms.com/en/`
		},
		{
			name: 'message',
			time: 617,
			lookAhead: true,
			message:
				'In case you skipped a step, make sure you move the barrel object into ServerStorage in Roblox Studio like the video describes at the start of Chapter 3, as the code from this point needs it there!'
		}
	],

	descriptions: [
		`Dodge the rolling barrels to reach the top of the hill before your opponents!`,
		`Barrel Roll is a fast-paced Roblox game where players must dodge and duck an endless stream of giant rolling barrels to get to the top of the hill! The player who reaches the top first can claim the golden throne and the title of Barrel Roll champion.`,
		`This is a simple Roblox course where you will learn how to use core programming concepts such as functions, variables and for loops to create a barrel-dodging Roblox game.`
	],

	videos: [
		{
			name: 'Barrel Roll',
			// Total XP: 700
			chapters: [
				{
					name: 'Barrels',
					description:
						'The code in this chapter will show a message when the player touches a barrel.',
					secondsOffset: 155,
					badges: ['classes-1', 'debugging-1']
				},
				{
					name: 'Health',
					description:
						"The code in this chapter drops the player's health to 0 when they touch a barrel.",
					secondsOffset: 321,
					badges: ['variables-1', 'workspace-1']
				},
				{
					name: 'Spawning',
					description: 'The code in this chapter spawns one barrel at the top of the hill.',
					secondsOffset: 533,
					badges: ['templates-1', 'physics-1']
				},
				{
					name: 'Loop',
					description: 'The code in this chapter loops your code to spawn lots more barrels.',
					secondsOffset: 846,
					badges: ['while-1', 'time-1']
				},
				{
					name: 'Method splitting',
					description:
						'The code in this chapter splits up the methods so the code is easier to work with.',
					secondsOffset: 1046,
					badges: ['classes-2']
				},
				{
					name: 'Offset',
					description: 'The code in this chapter spawns the barrels from different places.',
					secondsOffset: 1234,
					badges: ['random-1', 'variables-1', 'operators-2']
				}
			]
		}
	]
};
