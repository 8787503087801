'use strict';

import _ from 'lodash';

const PerformanceMonitorService = require('ck-core/source/services/PerformanceMonitorService');

const ClientPerformanceMonitor = (module.exports = PerformanceMonitorService.define({
	type: 'ClientPerformanceMonitor',

	init: function(parent) {
		this.PerformanceMonitor_init(parent, {
			longDelayMs: 500
		});

		this._trackInFlight = this._trackInFlight.bind(this);
	},

	init_measurements: function() {
		this._measurements = {};
		this._id = 0;
	},

	init_mode: function() {
		this.setPerformance(this.isLowPerformanceDevice());
	},

	bind_beforeUnload: function() {
		window.addEventListener('beforeunload', this._trackInFlight);
	},

	unbind_beforeUnload: function() {
		window.removeEventListener('beforeunload', this._trackInFlight);
	},

	_trackInFlight: function() {
		for (let id in this._measurements) {
			let measurement = this._measurements[id];

			CK.metric.increment('closedInFlight_' + measurement.name);
		}

		CK.metric.flush();
	},

	inLowPerformanceMode: function() {
		return this._lowPerformance;
	},

	isLowPerformanceDevice: function() {
		return !!CK.mobile;
	},

	setPerformance: function(low) {
		if (this._lowPerformance === low) {
			return false;
		}

		this._lowPerformance = low;

		CK.logger.debug(
			'\u{23F2}',
			'Changing performance mode to',
			low ? '\u{1F422} low' : '\u{1F407} high'
		);

		if (low) {
			CK.metric.increment('lowPerformanceMode');

			$('body')
				.addClass('lowPerformanceMode')
				.removeClass('highPerformanceMode');
		} else {
			CK.metric.increment('highPerformanceMode');

			$('body')
				.removeClass('lowPerformanceMode')
				.addClass('highPerformanceMode');
		}

		return true;
	},

	bind_fpsChecker: function() {
		if (this.isLowPerformanceDevice()) {
			return;
		}

		const MAX_FPS = 60;
		const HORIZON = 5;

		this.THRESHOLD_FPS = 50;
		this.COOLDOWN_MS = 8000;

		this._cooldown = this.getPreciseTimeMs() + this.COOLDOWN_MS;

		// Rolling clock of fps e.g. [60,60,60,60,60]
		this._history = _.times(HORIZON, _.constant(MAX_FPS));

		this._checkFps = this._checkFps.bind(this);

		this._frames = 0;
		this._secondsEdge = Math.floor(this.getPreciseTimeMs() / 1e6);

		requestAnimationFrame(this._checkFps);
	},

	_trackFps: function(fps) {
		this._history.shift();
		this._history.push(fps);

		if (this.getPreciseTimeMs() < this._cooldown) {
			return;
		}

		let changed = this.setPerformance(
			_.sum(this._history) / this._history.length < this.THRESHOLD_FPS
		);

		if (changed) {
			this._cooldown = this.getPreciseTimeMs() + this.COOLDOWN_MS;
		}
	},

	_checkFps: function() {
		let currentEdge = Math.floor(this.getPreciseTimeMs() / 1e3);

		if (currentEdge > this._secondsEdge) {
			let fps = Math.round(this._frames / (currentEdge - this._secondsEdge));

			// Don't post more than one reading as blurred tabs don't call animation frames
			this._trackFps(fps);

			this._secondsEdge = currentEdge;
			this._frames = 0;
		}

		this._frames++;
		requestAnimationFrame(this._checkFps);
	},

	getPreciseTimeMs: function() {
		if (typeof _.get(window, 'performance.now') !== 'function') {
			return Date.now();
		}

		return performance.now();
	},

	bind_blurChecker: function() {
		// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

		// Set the name of the hidden property and the change event for visibility
		let hidden, visibilityChange;
		if (typeof document.hidden !== 'undefined') {
			// Opera 12.10 and Firefox 18 and later support
			hidden = 'hidden';
			visibilityChange = 'visibilitychange';
		} else if (typeof document.msHidden !== 'undefined') {
			hidden = 'msHidden';
			visibilityChange = 'msvisibilitychange';
		} else if (typeof document.webkitHidden !== 'undefined') {
			hidden = 'webkitHidden';
			visibilityChange = 'webkitvisibilitychange';
		}

		// If the page is hidden, pause the video;
		// if the page is shown, play the video
		let handleVisibilityChange = () => {
			if (document[hidden]) {
				this._blurred = true;
			} else {
				this._blurred = false;
			}
		};

		// If the browser doesn't support the Page Visibility API
		if (typeof document[hidden] === 'undefined') {
			window.addEventListener('blur', () => {
				this._blurred = true;
			});

			window.addEventListener('focus', () => {
				this._blurred = false;
			});
		} else {
			// Handle page visibility change
			document.addEventListener(visibilityChange, handleVisibilityChange, false);

			handleVisibilityChange();
		}
	},

	handleLongDelay: function(delay) {
		if (!this._blurred) {
			CK.metric.timing('clientLongEventLoopDelay', delay);
			CK.logger.debug('\u{1F4A4} Event loop delay', delay);
		}
	},

	startMeasure: function(name, timeout) {
		let time = this.getPreciseTimeMs();

		let id = ++this._id;

		const timeoutDuration = timeout || ClientPerformanceMonitor.DEFAULT_TIMEOUT;

		this._measurements[id] = {
			name,
			start: time,
			timeout: setTimeout(this._handleTimeout.bind(this, id), timeoutDuration),
			timeoutDuration
		};

		return id;
	},

	_handleTimeout: function(id) {
		const measurement = this._measurements[id];

		let name = measurement.name;
		measurement.timedOut = true;

		CK.logger.debug('\u{1F321}\u{1F4A4} Measure timed out', name);

		CK.metric.timing(name, measurement.timeoutDuration);

		delete this._measurements[id];
	},

	endMeasure: function(id) {
		let end = this.getPreciseTimeMs();

		let measurement = this._measurements[id];
		if (!measurement) {
			return;
		}
		if (measurement.ended) {
			CK.logger.warn('Duplicate performance measurement', measurement.name);
			return;
		}

		clearTimeout(measurement.timeout);

		measurement.ended = true;
		measurement.duration = end - measurement.start;

		CK.logger.debug('\u{1F321} Measure', measurement.name, 'took', measurement.duration + 'ms');

		CK.metric.timing(measurement.name, measurement.duration);

		delete this._measurements[id];
	},

	getMeasure: function(id) {
		return this._measurements[id];
	},

	cancelMeasure: function(id) {
		let measurement = this._measurements[id];
		if (!measurement) {
			return;
		}
		clearTimeout(measurement.timeout);
		measurement.cancelled = true;

		delete this._measurements[id];
	}
}));

ClientPerformanceMonitor.DEFAULT_TIMEOUT = 3000;
