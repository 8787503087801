'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'prison-escape',
	name: 'Prison Escape',
	duration: 30,
	secondsDuration: 1161,
	active: true,
	isCourse: true,
	shortCourse: false,
	position: 0,
	hasCampPdf: true,
	unlockXp: 0,
	difficulty: 'Starter',

	additionalFiles: [
		{
			path: '/Laser',
			superclass: 'BaseObject'
		},
		{
			path: '/FlashingLaser',
			superclass: 'BaseObject'
		},
		{
			path: '/DynamiteSlingshot',
			superclass: 'BaseLocalTool'
		},
		{
			path: '/Dynamite',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'Play the completed Prison Escape game by visiting https://www.roblox.com/games/2166251182/Prison-Escape'
		},
		{
			time: 140,
			name: 'studio',
			lookAhead: true
		},
		{
			time: 175,
			name: 'pause'
		},
		{
			time: 226,
			name: 'plugin',
			lookAhead: true
		},
		{
			time: 247,
			name: 'pause'
		},
		{
			time: 280,
			name: 'downloadProject',
			lookAhead: true
		}
	],

	descriptions: [
		'Escape prison in this starter course designed to teach you some Code Editor basics.',
		'Duck through the maze, avoid the flashing lasers and break out of prison armed with your trusty dynamite slingshot - just try not to get blown up!',
		'This course is great for beginners. Get to grips with the Code Editor and learn how to make your first Roblox game from scratch using functions and conditionals.'
	],

	videos: [
		{
			chapters: [
				{
					name: 'Killer lasers',
					description: 'This chapter teaches you how to make deadly lasers!',
					badges: ['parameters-1', 'workspace-1'],
					secondsOffset: 416
				},
				{
					name: 'Flashing lasers',
					description: 'This chapter teaches you how to make lasers that flash on and off.',
					badges: ['time-1', 'time-2', 'loops-1', 'booleans-1'],
					secondsOffset: 557
				},
				{
					name: 'Dynamite slingshot',
					description: 'This chapter teaches you how to throw dynamite and make it explode!',
					badges: ['input-1', 'classes-1', 'parameters-1', 'time-1'],
					secondsOffset: 825
				}
			]
		}
	]
};
