'use strict';

// TODO: Make this verify better
CK.endpoints.sync = module.exports = function(request) {
	return this.spaceManager.handleSync(request.root, request.deltas).then(() => {
		CK.moduleContainer.publish(CK.endpoints.sync.EVENTS.SYNC + request.root, this.model());
	});
};

CK.endpoints.sync.EVENTS = {
	SYNC: 'sync.'
};
