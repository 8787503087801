'use strict';

import { STRIPE_DURATION } from 'ck-core/source/data/StripeDuration';
import { stripeCouponIdForCurrency } from 'ck-core/source/functions/stripeCouponIdForCurrency';
const Language = require('../services/Language');

module.exports = darc.compile({
	id: darc.types.primary(String),
	name: String,
	active: Boolean,
	maxUses: Number,
	freeMonths: Number,

	percentOff: Number,
	percentOffText: String,

	amountsOff: [
		{
			currency: darc.types.primary(String),
			value: Number
		}
	],

	duration: String,
	duration_in_months: Number,

	// Whether this coupon is only used for a user's first plan
	initialPlanOnly: Boolean,

	// Whether this coupon can only be used for one off plans
	oneOffOnly: Boolean,

	// Whether this coupon can only be used for subscription plans
	subscriptionOnly: Boolean,

	// If set, the coupon can only be used for plans with an interval of at least this many months
	minimumPlanMonths: Number,

	// If set, the coupon can only be used for plans with an interval of at most this many months
	maximumPlanMonths: Number,

	applicableProducts: [String],

	expires: Date,

	getMessage: function(plan, currency) {
		var prependedName = this.name() || this.id();
		currency = currency || (CK.fn.userCurrency && CK.fn.userCurrency());

		let percentOffText = this.percentOff() || this.percentOffText();

		let durationInMonths = this.duration_in_months() || 1;
		let monthLang = durationInMonths > 1 ? 'months' : 'month';

		if (this.duration() === STRIPE_DURATION.REPEATING && (!plan || !plan.oneOff())) {
			if (!percentOffText) {
				let value = this.amountsOff(currency).value();

				percentOffText = Math.round(value / plan.price(currency).value() * 100);
			}

			return Language.mapVariables(require('./CouponSchemaLang').DISCOUNT_REPEATING, {
				prependedName,
				percentOff: percentOffText,
				durationInMonths,
				monthLang
			});
		} else if (percentOffText) {
			return Language.mapVariables(require('./CouponSchemaLang').DISCOUNT, {
				prependedName,
				percentOff: percentOffText
			});
		} else if (this.freeMonths()) {
			let freeMonths = this.freeMonths();

			return Language.mapVariables(require('./CouponSchemaLang').FREE_MONTHS, {
				prependedName,
				freeMonths
			});
		} else if (this.amountsOff().$()) {
			return Language.mapVariables(require('./CouponSchemaLang').DISCOUNT_AMOUNT, {
				prependedName
			});
		}
	},

	getDurationMessage: function() {
		var duration = this.duration_in_months();

		if (duration === 1) {
			return 'the first month';
		} else if (duration) {
			return duration + ' months';
		} else if (this.duration()) {
			return this.duration();
		}

		return 'the first month';
	},

	stripeCouponIdForCurrency: function(currency) {
		return stripeCouponIdForCurrency(this.id(), this.percentOff(), currency);
	}
});

module.exports.Set = darc.compile(darc.types.set(module.exports.schema));
