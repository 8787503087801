'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'game-dev-tycoon',
	name: 'Game Dev Tycoon',
	active: true,
	duration: 180,
	secondsDuration: 2733,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 5,
	unlockXp: 12000,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/Button',
			superclass: 'BaseObject'
		},
		{
			path: '/Dropper',
			superclass: 'BaseObject'
		},
		{
			path: '/Upgrader',
			superclass: 'BaseObject'
		},
		{
			path: '/Collector',
			superclass: 'BaseObject'
		},
		{
			path: '/TycoonPlayer',
			superclass: 'BasePlayer'
		},
		{
			path: '/FinishedGame',
			superclass: 'BaseObject'
		},
		{
			path: '/Studio',
			superclass: 'BaseObject'
		}
	],

	descriptions: [
		`Control your own Game Dev world by earning money so you can upgrade your developer studio.`,
		`This is a course where you'll not only learn how to make a Roblox tycoon game but also how to create a tycoon game kit. Build your empire and take over the Roblox game developer world!`,
		`Game Dev Tycoon covers recursion, point systems and the parent/child structure of Roblox code. This course is best suited for budding game developers who already have a basic understanding of the Code Editor, as well as core coding and game design concepts.`
	],

	cues: [
		{
			name: 'message',
			time: 141,
			lookAhead: true,
			message:
				'Play the completed Game Dev Tycoon game by visiting https://www.roblox.com/games/1892516070/Game-Dev-Tycoon'
		}
	],

	videos: [
		{
			name: 'Game Dev Tycoon',
			chapters: [
				{
					name: 'Claiming',
					description:
						'The code in this chapter sets the tycoon collector up so it can be claimed by a player.',
					secondsOffset: 151,
					badges: ['logic-1', 'presentation-2']
				},
				{
					name: 'Droppers',
					description:
						'The code in this chapter makes the droppers repeatedly create and drop games.',
					secondsOffset: 520,
					badges: ['loops-1', 'time-1', 'workspace-1']
				},
				{
					name: 'Collecting money',
					description:
						'The code in this chapter adds a value to the dropped games so they earn the tycoon owner money.',
					secondsOffset: 892,
					badges: ['workspace-2', 'conditions-1']
				},
				{
					name: 'Upgraders',
					description:
						'The code in this chapter sets up upgrader zones which adds value to the dropped games.',
					secondsOffset: 1154,
					badges: ['classes-1', 'workspace-1', 'operators-1']
				},
				{
					name: 'Recursion',
					description:
						'The code in this chapter hides the premade tycoon so each part of it has to be earnt and bought.',
					secondsOffset: 1454,
					badges: ['algorithms-3', 'methods-2', 'loops-2', 'flow-2']
				},
				{
					name: 'Build',
					description: 'The code in this chapter builds the tycoon part the player just bought.',
					secondsOffset: 2016,
					badges: ['workspace-1', 'booleans-2', 'methods-2']
				},
				{
					name: 'Little fixes',
					description: 'The code in this chapter adds the ability to reset the tycoon.',
					secondsOffset: 2294,
					badges: ['conditions-1', 'classes-1', 'presentation-1', 'presentation-2']
				}
			]
		}
	]
};
