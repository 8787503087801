'use strict';

import _ from 'lodash';
import io from 'socket.io-client';

const Socket = require('./Socket');
const CKError = require('./../classes/Error');
const Errors = require('./../services/Errors');
const Time = require('./../services/Time');
const EventContrib = require('./EventContrib');

const deserializeError = function(sErr) {
	if (!_.isPlainObject(sErr)) {
		return sErr;
	}

	var cls = Errors[sErr.name] || CKError;

	var err = new cls(sErr);

	delete err.stack;

	return err;
};

module.exports = require('./Socket').define(EventContrib(), {
	type: 'ClientSocket',
	init_ClientSocket: function(server, options) {
		this.server = _.defaults(server, {
			protocol: 'http',
			port: 80,
			path: '',
			host: 'localhost'
		});

		this._supersedeEndpoints = {};

		// this.options already assigned in parent
		_.defaults(this.options, options || {}, {
			timeout: 6000,

			autoReconnect: true,
			multiplex: true,

			// See https://devcenter.heroku.com/articles/http-routing#timeouts
			keepAlivePingIntervalMs: 45 * Time.SECOND_MS,

			release: CK.config.release
		});

		this.url = CK.fn.buildUrl(this.server) + '/';

		this._ioSocket = io(this.url, {
			timeout: this.options.timeout,
			path: this.server.path + '/socket.io',
			multiplex: this.options.multiplex,
			transports: ['websocket'],
			autoConnect: false,
			query: {
				release: this.options.release,
				name: CK.config.name
			}
		});

		var onlineEvents = ['connect', 'reconnect'];

		onlineEvents.forEach(onlineEvent => {
			this._ioSocket.on(onlineEvent, info => {
				this.publish('online', info);
			});
		});
		var offlineEvents = ['disconnect', 'connect_timeout', 'connect_error'];
		offlineEvents.forEach(offlineEvent => {
			this._ioSocket.on(offlineEvent, info => {
				this.publish('offline', info);
			});
		});
		const SessionSchema = darc.compile({
			session: Object
		});

		this._clearSessionVerified = this._clearSessionVerified.bind(this);
		this._verifyResult = new SessionSchema({});
	},
	bind_verifyResult: function() {
		this.subscribe('offline', this._clearSessionVerified);
	},
	unbind_verifyResult: function() {
		this._clearSessionVerified();

		this.unsubscribe('offline', this._clearSessionVerified);
	},

	_clearSessionVerified: function() {
		this._verifyResult.session(null);
	},
	isVerified: function() {
		return !!this._verifyResult.session();
	},

	waitForVerify: function() {
		let onLogout = darc.defer();
		// TODO: This event is from UserManager - this seems like core-client functionality that shouldn't be in ClientSocket
		CK.moduleContainer.once('logout', onLogout.fulfil.bind(onLogout));

		return Promise.race([this._verifyResult.$get('session').$when(), onLogout]);
	},

	bind_keepAlive: function() {
		if (this.options.keepAlivePingIntervalMs > 0) {
			this.resetKeepAlive();
		}
	},
	unbind_keepAlive: function() {
		if (this._keepAliveTimeout) {
			clearTimeout(this._keepAliveTimeout);

			this._keepAliveTimeout = null;
		}
	},

	bind_autoReconnect: function() {
		if (!this.options.autoReconnect) {
			return;
		}

		this.subscribe('offline', this._connectionFailureHandler);
	},
	unbind_autoReconnect: function() {
		if (!this.options.autoReconnect) {
			return;
		}

		this.unsubscribe('offline', this._connectionFailureHandler);

		if (this._reconnectionTimeout) {
			clearTimeout(this._reconnectionTimeout);
			this._reconnectionTimeout = null;
		}
	},

	cleanup: function() {
		this.unbind();

		this.disconnect();

		this._cleanedUp = true;
	},

	_connectionFailureHandler: function() {
		if (this._attemptingReconnection) {
			return;
		}

		this._attemptingReconnection = true;

		var delayMs = _.random(1000, 15000);

		if (this._reconnectionTimeout) {
			clearTimeout(this._reconnectionTimeout);
		}

		this._reconnectionTimeout = setTimeout(() => {
			this._attemptingReconnection = false;

			this.connect();
		}, delayMs);
	},
	_addMetadata: function(data) {
		if (data && _.isPlainObject(data)) {
			data.time = Date.now();
		}
	},

	emit: function(endpoint, request, callback, options) {
		if (this._cleanedUp) {
			CK.logger.debug('Not emitting after socket cleaned up');

			return Promise.resolve();
		}

		if (_.isPlainObject(callback)) {
			options = callback;
			callback = CK.fn.zero;
		}

		options = _.defaults(options || {}, {
			supersede: false,
			supersedeKey: endpoint,

			onBuffering: null,

			onBufferingTimeout: 5000,

			buffer: true,

			waitForVerify:
				endpoint !== 'authenticate' &&
				endpoint !== 'simpleAuthenticate' &&
				endpoint !== 'verifySession' &&
				CK.moduleContainer.module('UserManager') &&
				CK.moduleContainer.module('UserManager').getSession()
		});

		if (!this.isConnected()) {
			this.connect();
		}

		request = request || {};

		callback = callback || CK.fn.zero;

		if (!this.isConnected() && !options.buffer) {
			var connectError = new Errors.NoInternet();

			if (callback) {
				callback(connectError);
			}

			return Promise.reject(connectError);
		}

		this._addMetadata(request);

		// Whenever a message is sent, defer the ping again to avoid wasting messages

		this.resetKeepAlive();

		const deferred = darc.defer();

		var doEmit = () => {
			if (deferred.isRejected) {
				return;
			}

			this._ioSocket.emit(endpoint, request, (err, result) => {
				if (err) {
					err = deserializeError(err);

					if (_.isString(err)) {
						deferred.reject(new Error(err));
					} else {
						deferred.reject(err);
					}
				} else {
					// TODO CORE-588
					if (
						endpoint === 'verifySession' ||
						endpoint === 'authenticate' ||
						endpoint === 'simpleAuthenticate' ||
						endpoint === 'autoRegister' ||
						endpoint === 'buyLoggedOut' ||
						endpoint === 'registerWithSubUserToken' ||
						endpoint === 'authenticateAsSubUser' ||
						endpoint === 'authenticateWithBillingToken' ||
						endpoint === 'authenticateWithUserToken'
					) {
						this._verifyResult.session(result.session);
					} else if (endpoint === 'leave') {
						if (
							request &&
							this.isVerified() &&
							request.root === `users/${this._verifyResult.session().userId}`
						) {
							this._clearSessionVerified();
						}
					}

					if (deferred.isPending) {
						deferred.fulfil(result);
					}
				}

				if (callback) {
					callback(err, result);
				} else if (err) {
					CK.logger.error('unhandled error in response to emit', err);
				}
			});

			if (!this._ioSocket.connected) {
				deferred.bufferedPacket = _.last(this._ioSocket.sendBuffer);
			}
		};

		if (options.supersede) {
			let supersedeKey =
				typeof options.supersedeKey === 'function'
					? options.supersedeKey(request)
					: options.supersedeKey;

			let supersededDeferred = this._supersedeEndpoints[supersedeKey];

			if (supersededDeferred && supersededDeferred.isPending) {
				if (supersededDeferred.bufferedPacket) {
					let willSupersede =
						typeof options.supersede !== 'function' ||
						options.supersede(request, supersededDeferred.bufferedRequest);

					if (willSupersede) {
						const positionInBuffer = this._ioSocket.sendBuffer.indexOf(
							supersededDeferred.bufferedPacket
						);

						if (positionInBuffer > -1) {
							CK.logger.debug(
								'Dropping buffered packet for superseded emit',
								supersededDeferred.bufferedPacket
							);

							this._ioSocket.sendBuffer.splice(positionInBuffer, 1);
						}
					}
				}

				supersededDeferred.reject(new Errors.Superseded());
			}

			this._supersedeEndpoints[supersedeKey] = deferred;
			deferred.bufferedRequest = request;
		}

		if (options.waitForVerify) {
			this.waitForVerify(options.waitForVerify).then(() => {
				doEmit();
			});
		} else {
			doEmit();
		}

		if (options.onBuffering) {
			setTimeout(() => {
				if (deferred.isPending) {
					options.onBuffering();
				}
			}, options.onBufferingTimeout);
		}

		return deferred;
	},
	resetKeepAlive: function() {
		if (this.options.keepAlivePingIntervalMs > 0) {
			if (this._keepAliveTimeout) {
				clearTimeout(this._keepAliveTimeout);
			}

			this._keepAliveTimeout = setTimeout(() => {
				this._ioSocket.emit(Socket.PING_PAYLOAD, () => {
					this.publish(Socket.PING_RESPONSE);
				});

				this._keepAliveTimeout = null;

				this.resetKeepAlive();
			}, this.options.keepAlivePingIntervalMs);
		}
	},

	connect: function() {
		if (this._cleanedUp) {
			return CK.logger.debug('Not connecting after socket cleaned up');
		}

		const promise = new Promise(fulfil => {
			this.once('online', () => {
				fulfil();
			});
		});

		if (!this.isConnected()) {
			this._ioSocket.connect();
		}

		return Promise.race([promise, Promise.delay(this.options.timeout)]);
	},

	disconnect: function() {
		if (this._ioSocket) {
			var promise = new Promise(fulfil => {
				this.once('offline', () => {
					fulfil();
				});
			});

			this._ioSocket.disconnect();

			return Promise.race([promise, Promise.delay(this.options.timeout)]);
		} else {
			return Promise.resolve();
		}
	}
});
