'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'the-walls',
	name: 'The Walls',
	duration: 120,
	secondsDuration: 2972,
	active: true,
	isCourse: true,
	hasPdf: true,
	hasCampPdf: true,
	shortCourse: false,
	descriptions: [
		'Five minutes to prepare before the walls come down and the battle begins!',
		'You will spawn in your own segment and have 5 minutes at the start of this PVP game to gather supplies and prepare yourself for battle. When the timer is up, the walls come down! Fight the other players until only one survives!',
		'This course will show you how to build up structures from scratch using cloning and axes, bring them down, and set up the timed rounds of the game.'
	],

	difficulty: 'Medium',
	unlockXp: 22000,

	videos: [
		{
			name: 'The Walls',
			chapters: [
				{
					name: 'Get the Wall Rolling',
					description: 'This chapter teaches you to calculate world borders based on radius.',
					badges: ['methods-1', 'operators-1'],
					secondsOffset: 149
				},
				{
					name: 'Another Brick In The Wall',
					description: 'This chapter teaches you to use strings.',
					badges: ['communication-2', 'timer-1'],
					secondsOffset: 479
				},
				{
					name: 'Border Control',
					description: 'This chapter teaches you to use for loops to iterate over objects.',
					badges: ['loops-2', 'evaluation-1'],
					secondsOffset: 700
				},
				{
					name: 'Wall of Duty',
					description: 'This chapter teaches you to build structures in Minecraft.',
					badges: ['operators-2', 'methods-2'],
					secondsOffset: 969
				},
				{
					name: 'Wall of The Worlds',
					description:
						'This chapter teaches you to spawn players in specific places around the map.',
					badges: ['parameters-1', 'parameters-2', 'generalisation-2'],
					secondsOffset: 1402
				},
				{
					name: 'Abandon Wall Hope',
					description: 'This chapter teaches you to build Minecraft Structures.',
					badges: ['generalisation-3', 'operators-2'],
					secondsOffset: 1562
				},
				{
					name: 'Wall this Way',
					description: 'This chapter teaches you to spawn players at a specific location.',
					badges: ['evaluation-2', 'evaluation-3', 'conditions-2'],
					secondsOffset: 1831
				},
				{
					name: 'Wonder Wall',
					description:
						'This chapter teaches you to use if and else statements to control the flow of code.',
					badges: ['conditions-2', 'variables-2', 'algorithms-2'],
					secondsOffset: 2502
				}
			]
		}
	]
};
