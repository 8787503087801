'use strict';

import _ from 'lodash';
import { replaceTemplateVariables } from '../functions/replaceTemplateVariables';

/**
 * @deprecated
 */
const Language = (module.exports = function(str) {
	var variables = {};

	for (var i = 1; i < arguments.length; i++) {
		variables[i] = arguments[i];
	}

	return Language.mapVariables(str, variables);
});

/**
 * @deprecated
 */
Language.mapVariables = function(text, variables) {
	if (!text) {
		return text;
	}

	if (!_.isString(text)) {
		return text;
	}

	return replaceTemplateVariables(text, variables);
};

/**
 * Map variables for all values in a lang object. Returns a new object
 *
 * @deprecated
 */
Language.mapAllVariables = function(langObj, variables) {
	var mapped = {};
	_.each(langObj, function(value, key) {
		if (_.isObject(value)) {
			mapped[key] = Language.mapAllVariables(value, variables);
		} else {
			mapped[key] = Language.mapVariables(value, variables);
		}
	});

	return mapped;
};
