'use strict';

import _ from 'lodash';

const CSSUtils = require('../utils/CSSUtils');
const CKError = require('ck-core/source/classes/Error');
const BlurBinder = require('../binders/BlurBinder');
const View = require('./View');

module.exports = View.define({
	setup: function(options) {
		options = _.defaults(options || {}, {
			actions: {
				close: () => {
					this.unbind();
					this.container().remove(this);
				}
			}
		});

		this._options = options;

		this.dom.addClass('LegacyModal').append('<div class="background">');
		// Content is separate from dom

		this.content = $('<div class="content">').appendTo(this.dom);
		if (options.closeOnBackgroundTap) {
			var t = this;

			var blurBinderCallback =
				options.blurBinderCallback ||
				function() {
					// Only if on top

					if (!t.container().peek || t.container().peek() === t) {
						t.unbind();

						t.container().remove(t);
						return false;
					}

					// Remain blurred

					return true;
				};

			this.blurBinder = new BlurBinder(blurBinderCallback, this.content);

			this.inputs({
				escape: blurBinderCallback
			});

			this.actions(
				this._class,
				_.defaults(options.actions, {
					close: blurBinderCallback
				})
			);
		} else {
			this.actions(this._class, options.actions || {});
		}
	},

	show: function() {
		var child = this.content;

		this.dom.append(child);

		// Need to wait for components to bind so that

		// zIndex is calculated correctly
		setTimeout(() => {
			if (!this.dom) {
				return CK.logger.warn(
					new CKError({
						message: 'ModalView cleaned up before it could be shown',
						tags: {
							type: this.type
						}
					})
				);
			}
			this.dom.show();
		}, 0);

		$('body').addClass('hasOpenModal');
	},

	hide: function() {
		if (!this.content) return;

		var MODAL_DELAY = 0.3;
		this.content.css(
			_.assign(
				{
					transition: 'all ' + MODAL_DELAY + 's'
				},
				CSSUtils.transform('scale(0)')
			)
		);
		this.dom.css({
			animation: '',
			'-webkit-animation': ''
		});
		this.dom.fadeOutNicely(MODAL_DELAY * 1000);
	},

	cleanup_ModalView: function() {
		this.content.remove();

		this.content = null;

		$('body').removeClass('hasOpenModal');
	},

	type: 'ModalView'
});
