'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'nightlights',
	name: 'Night Lights',
	duration: 60,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 10,
	unlockXp: 2250,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/TimeControl',
			superclass: 'BaseObject'
		},
		{
			path: '/ProximityLight',
			superclass: 'BaseObject'
		},
		{
			path: '/StreetLight',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 87,
			lookAhead: true,
			message:
				'Test out our completed Night Lights course by visiting https://www.roblox.com/games/1637215636/Night-Lights'
		}
	],

	descriptions: [
		'Learn how to code and control lighting in your Roblox games!',
		'Harness the power of the sun and moon to create a day/night cycle, then code spotlights and streetlights in this Roblox Studio lighting crash course.',
		'Create your own lighting systems using code and build on your knowledge of Roblox Studio tools.'
	],

	videos: [
		{
			name: 'Night Lights',
			chapters: [
				{
					name: 'Here Codes the Sun',
					description: 'Familiarise yourself with the day/night cycle in Roblox Studio',
					secondsOffset: 97,
					badges: ['workspace-1', 'presentation-1'],
					noCodeChapter: true
				},
				{
					name: 'Once in a Blue Moon',
					description:
						'Add code to speed up time and make the sun and moon chase each other across the sky',
					secondsOffset: 384,
					badges: ['loops-1', 'time-1', 'workspace-2']
				},
				{
					name: 'Light of my Life',
					description: 'Add a part to Roblox Studio and learn how to add light to it',
					secondsOffset: 896,
					badges: ['presentation-1'],
					noCodeChapter: true
				},
				{
					name: 'Spotlight the Difference',
					description: 'Add code to control the street lights in the project',
					secondsOffset: 1232,
					badges: ['methods-1', 'loops-2']
				},
				{
					name: 'Night in Shining Armour',
					description: 'Add code so that your street lights only come on at night',
					secondsOffset: 1633,
					badges: ['logic-1', 'logic-2']
				},
				{
					name: 'The Dark Night',
					description: 'Get to grips with the ambient lighting in Roblox Studio',
					secondsOffset: 2088,
					badges: ['presentation-1'],
					noCodeChapter: true
				},
				{
					name: 'Lights Out',
					description: 'Add code for automatic lights in your house',
					secondsOffset: 2416,
					badges: ['loops-1', 'logic-2', 'presentation-2']
				}
			]
		}
	]
};
