'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'zombie-smash',
	name: 'Zombie Smash',
	duration: 180,
	secondsDuration: 2863,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	position: 30,
	unlockXp: 10000,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/Gun',
			superclass: 'BaseLocalTool'
		},
		{
			path: '/Bullet',
			superclass: 'BaseObject'
		},
		{
			path: '/Zombie',
			superclass: 'BaseLocalCharacter'
		},
		{
			path: '/ZombieSpawner',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/PlayerCamera',
			superclass: 'BaseLocalPlayer'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'Give the complete version of Zombie Smash a try by visiting https://www.roblox.com/games/1252745846/Zombie-Smash'
		},
		{
			name: 'urgentMessage',
			time: 848,
			lookAhead: true,
			message:
				'You need to put the self.GetHumanoid().MoveTo call inside an if statement. The condition of the if statement should be "torso and self:GetHumanoid()".'
		}
	],

	descriptions: [
		`Battle waves of zombies in this top down zombie shooter`,
		`This is Zombie Smash, a top down shooter game where players have to fight off waves of zombies. The aim of the game is to survive through as many waves of zombies as possible!`,
		`In this course you will learn how to use loops and the random math function to randomly spawn waves of zombies across the map. The zombie waves will increase in size as the player progresses through the levels!`
	],

	videos: [
		{
			name: 'Zombie Smash',
			// Total XP: 2000
			chapters: [
				{
					name: 'Car chase',
					description:
						'The code in this chapter ensures the cars kill the zombies when you hit them.',
					secondsOffset: 126,
					badges: ['physics-1', 'workspace-1', 'logic-2']
				},
				{
					name: 'Gun control',
					description: 'The code in this chapter sets up the gun to fire bullets.',
					secondsOffset: 472,
					badges: ['classes-1', 'conditions-2']
				},
				{
					name: 'Zombies',
					description: 'The code in this chapter makes the zombies chase and kill the player.',
					secondsOffset: 644,
					badges: ['loops-1', 'time-1', 'variables-1']
				},
				{
					name: 'Zombie spawns',
					description: 'The code in this chapter makes the zombies spawn in a random location.',
					secondsOffset: 991,
					badges: ['arrays-1', 'templates-1', 'classes-2']
				},
				{
					name: 'Zombie waves',
					description:
						'The code in this chapter repeats the code to spawn one zombie to spawn lots of zombies.',
					secondsOffset: 1294,
					badges: ['variables-2', 'loops-3']
				},
				{
					name: 'Zombie scatter',
					description:
						'The code in this chapter makes all of the zombies in each wave spawn in different places.',
					secondsOffset: 1563,
					badges: ['random-1', 'arrays-2']
				},
				{
					name: 'New wave zombie-ism',
					description:
						"The code in this chapter spawns an increasing amount of zombies based on the player's level.",
					secondsOffset: 1714,
					badges: ['booleans-2', 'methods-2']
				},
				{
					name: 'Reset',
					description: 'The code in this chapter resets the game when the player dies.',
					secondsOffset: 1949,
					badges: ['workspace-1', 'loops-2']
				},
				{
					name: 'Custom camera',
					description: "The code in this chapter changes the game so it's played from above.",
					secondsOffset: 2350,
					badges: ['operators-2', 'camera-2']
				},
				{
					name: 'Bullet fixes',
					description:
						'The code in this chapter improves the bullets so they work with the top down view.',
					secondsOffset: 2652,
					badges: ['physics-1', 'time-2']
				}
			]
		}
	]
};
