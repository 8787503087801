'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands')
			.MINECRAFT_JAVA /*, require( '../../../../data/brands' ).MINECRAFT_PE*/
	], // Not supported in pocket
	id: 'construction-collection',
	name: 'Construction Collection',
	projectNamePrefix: 'construction',
	duration: 60,
	secondsDuration: 1055,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Learn how to build yourself a whole new world.',
		'Chop trees in one click and build yourself a log flume.',
		"With just two clicks, you'll be able to build entire structures in this advanced mini mod. "
	],
	difficulty: 'Hard',
	unlockXp: 45000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Construction Collection',
			chapters: [
				{
					name: "You Can't Teach An Old Log New Tricks",
					description: "In this chapter you'll learn how to build your very own log flume.",
					secondsOffset: 21,
					badges: []
				},
				{
					name: 'Over The Chop',
					description: 'Become a lumberjack and chop trees down in one click.',
					secondsOffset: 301,
					badges: []
				},
				{
					name: 'In The Click Of Time',
					description: 'Build entire structures in two clicks!',
					secondsOffset: 749,
					badges: []
				}
			]
		}
	]
};
