'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'treasure-island',
	name: 'Treasure Island',
	duration: 120,
	secondsDuration: 1675,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: true,
	hasCampPdf: true,
	unlockXp: 3000,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Ore',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/Stone',
			superclass: '/Ore'
		},
		{
			path: '/Coal',
			superclass: '/Ore'
		},
		{
			path: '/Diamond',
			superclass: '/Ore'
		},
		{
			path: '/Player',
			superclass: 'BaseLocalPlayer'
		},
		{
			path: '/UpgradeStation',
			superclass: 'BaseLocalObject'
		},
		{
			path: '/Pickaxe',
			superclass: 'BaseLocalTool'
		}
	],

	cues: [
		{
			name: 'message',
			time: 60,
			lookAhead: true,
			message:
				'If you want to give the finished Treasure Island game a try, just visit https://www.roblox.com/games/1928105182/Treasure-Island'
		},
		{
			name: 'urgentMessage',
			time: 826,
			lookAhead: true,
			message:
				"We've removed the random mine generator from the game completely, to make it less laggy. You can ignore this section!"
		}
	],

	descriptions: [
		`Mine your way through the layers to find buried treasure!`,
		`You’re a swashbuckling pirate! In this game, you'll have to mine as much as you can to become the wealthiest plunderer!`,
		`This Roblox course shows you how to use inheritance.`
	],

	videos: [
		{
			name: 'Treasure Island',
			chapters: [
				{
					name: 'Get (Ship)wrecked',
					description: 'Get to grips with the code inside the Ore file',
					secondsOffset: 24,
					badges: ['syntax-1', 'evaluation-1'],
					noCodeChapter: true
				},
				{
					name: 'Curse Of The Black Lerp',
					description: 'Familiarise yourself with linear interpolation.',
					secondsOffset: 484,
					badges: ['evaluation-1', 'generalisation-1'],
					noCodeChapter: true
				},
				{
					name: "I Don't Feel So Good",
					description: 'Try some code in the Pickaxe file.',
					secondsOffset: 657,
					badges: ['evaluation-1', 'decomposition-1'],
					noCodeChapter: true
				},
				{
					name: 'The Treasure Is Mine',
					description: 'Create a setup method in the Stone file',
					secondsOffset: 903,
					badges: ['fields-2', 'classes-2']
				},
				{
					name: 'Hook Before You Leap',
					description: 'Create a setup method in the Coal file',
					secondsOffset: 1263,
					badges: ['fields-2', 'classes-2', 'generalisation-2']
				},
				{
					name: 'The Adventures Of Shiprock Bones',
					description: 'Add a diamond ore with a special bonus effect',
					secondsOffset: 1445,
					badges: ['classes-2', 'classes-3']
				}
			]
		}
	]
};
