'use strict';

import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

export default class ModalsOverlay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modals: []
		};

		this._uid = 0;

		this.handleClose = this.handleClose.bind(this);
	}

	addModal(modal) {
		const clone = React.cloneElement(modal, {
			key: this._uid++,
			handleClose: () => {
				if (modal.props.handleClose) {
					modal.props.handleClose();
				}
				this.handleClose();
			}
		});

		this.setState(prevState => ({
			modals: prevState.modals.concat(clone)
		}));
	}

	closeModal(modal) {
		this.setState(prevState => ({
			modals: _.filter(prevState.modals, current => current !== modal)
		}));
	}

	clearModals() {
		this.setState({ modals: [] });
	}

	handleClose() {
		this.setState(prevState => {
			const toClose = _.last(prevState.modals);

			if (!toClose) {
				return;
			}

			// We use a negative property because otherwise every component that composes Modal would need to have a defaultProp of closeOnBlur = true
			if (toClose.props.noCloseOnBlur) {
				return prevState;
			}

			this.props.onClose && this.props.onClose(toClose);

			return {
				modals: prevState.modals.slice(0, -1)
			};
		});
	}

	render() {
		if (!this.state.modals.length) {
			return null;
		}

		return (
			<div className="ModalsOverlay" onClick={this.handleClose}>
				{this.state.modals}
			</div>
		);
	}
}

ModalsOverlay.propTypes = {
	onClose: PropTypes.func
};
