'use strict';

module.exports = require('../functions/makeLang')({
	DISCOUNT:
		'<div class="Coupon__name">$prependedName</div><div class="Coupon__save">$percentOff% OFF</div>',
	DISCOUNT_REPEATING:
		'<div class="Coupon__name">$prependedName</div><div class="Coupon__save">$percentOff% OFF</div><div class="Coupon__duration">for $durationInMonths $monthLang</div>',
	DISCOUNT_AMOUNT: '<div class="Coupon__name">$prependedName</div>',
	FREE_MONTHS:
		'<div class="Coupon__name">$prependedName</div><div class="Coupon__save">+$freeMonths MONTH FREE</div>'
});
