'use strict';

import _ from 'lodash';

const Flow = require('../classes/ClientFlow');
const Random = require('ck-core/source/services/Random');
const Errors = require('ck-core/source/services/Errors');
const ClientModule = require('../system/ClientModule');

module.exports = ClientModule.define({
	type: 'TransformManager',

	init_TransformManager: function() {
		CK.do = {};

		this._active = false;

		for (var i in CK.transforms) {
			CK.do[i] = this.createTransform(i, CK.transforms[i]);
		}
	},

	active: function() {
		return this._active;
	},

	createTransform: function(name, fn) {
		return (request, serverCallback) => {
			// A transform has to run with an active internet connection
			if (!CK.clientSockets.users.isConnected()) {
				CK.logger.debug('Offline transforms not yet supported');
				return Promise.reject(new Errors.NoInternet());
			}

			if (!CK.user.$exists()) {
				CK.logger.debug('Transforms when not logged in not supported');
				return Promise.reject(new Errors.NoUser());
			}

			request = _.cloneDeep(request);

			if (request.randomStream) {
				// Restore prototype methods after serialization
				request.randomStream = Random.copy(request.randomStream);
			}

			this._active = true;

			const clientPromise = new Flow(name, request).process(fn);

			clientPromise.then(function(result) {
				const message = {
					transform: name,
					transformRequest: request
				};

				// Send off to server
				CK.clientSockets.users
					.emit('transform', message, {
						supersedeKey:
							typeof fn.supersedeKey === 'function'
								? fn.supersedeKey(message)
								: fn.supersedeKey || name,
						supersede: fn.supersede
					})
					.then(() => {
						CK.logger.info('Transform:', name, 'ran on the server successfully');

						if (serverCallback) {
							serverCallback();
						}
					})
					.catch(err => {
						if (serverCallback) {
							serverCallback(err);
						}

						CK.logger.log(err);
					});

				return result;
			});

			return clientPromise;
		};
	}
});
