'use strict';

import './PageContainer.less';

const Scroller = require('./scroller');
const Component = require('./Component');
const ClientModule = require('../system/ClientModule');

const PageContainer = (module.exports = ClientModule.define(Component, {
	init_PageContainer: function() {
		this.dom = $('<div class="pageContainer">');
	},

	setPage: function(page) {
		if (this._currentPage) {
			this._currentPage.unbind();
		}

		this._currentPage = page;

		page.setContainer(this);
		page.bind();

		this.detachChildDom();

		this.dom.append(page.dom);

		if (page.refreshScroller) {
			page.refreshScroller();
		}
	},

	// This method must be used instead of $.empty or $.remove as they destroy event handlers and associated data
	detachChildDom: function() {
		this.dom.children('.page').detach();
	},

	setTitle: function() {},

	refreshScroller: function() {
		if (this.dom.data('scroller')) {
			setImmediate(() => {
				if (this.dom.data('scroller').scroller === this._currentPage.dom[0]) {
					this.dom.data('scroller').refresh();
				} else {
					this.dom.data('scroller').destroy();

					Scroller(this.dom);
				}
			});
		} else {
			Scroller(this.dom);
		}
	},

	flushPages: function() {
		if (this._currentPage) {
			this._currentPage.unbind();
		}

		this.detachChildDom();
	},

	unbind_PageContainer: function() {
		if (this._currentPage) {
			this._currentPage.unbind();
		}
	},

	cleanup: function() {
		this.flushPages();

		this.dom.remove();

		this.dom = null;
	},

	type: 'PageContainer'
}));
