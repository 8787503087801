'use strict';

if (typeof jQuery !== 'undefined') {
	jQuery.fn.insertAt = function(index, element, lastIndex) {
		lastIndex = lastIndex !== undefined ? lastIndex : this.children().size();
		if (index < 0) {
			index = Math.max(0, lastIndex + 1 + index);
		}
		if (index === 0) {
			this.prepend(element);
		} else if (index < lastIndex) {
			this.children()
				.eq(index)
				.before(element);
		} else {
			this.children()
				.eq(lastIndex - 1)
				.after(element);
		}
		return index;
	};
}
