'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'marketing-crash-course',
	name: 'Marketing Crash Course',
	duration: 45,
	secondsDuration: 1892,
	active: true,
	isCourse: true,
	shortCourse: false,
	position: 0,
	hasCampPdf: true,
	unlockXp: 3450,
	difficulty: 'Medium',

	additionalFiles: [
		{
			path: '/Object',
			superclass: 'BaseObject'
		}
	],

	cues: [
		{
			name: 'message',
			time: 10,
			lookAhead: true,
			message:
				"There's no coding in this course, so make the video fullscreen!"
		}
	],

	descriptions: [
		'Learn how to market your course on Roblox!',
		'Learn about advertisements and how to use social media to promote your games.',
		'This course is great for game devs who want to market their games and build an audience!'
	],

	videos: [
		{
			chapters: [
				{
					name: 'Marketing Wahlberg',
					description: 'Publish a game and see what happens!',
					badges: [],
					secondsOffset: 75,
					nnoCodeChapter: true
				},
				{
					name: 'Marketing Zuckerberg',
					description: 'Learn about how to make people aware of your game',
					badges: [],
					secondsOffset: 314,
					noCodeChapter: true
				},
				{
					name: 'Marketing Hamill',
					description: 'Learn what makes a good game icon and title',
					badges: [],
					secondsOffset: 604,
					noCodeChapter: true
				},{
					name: 'Marketing Henry',
					description: 'Learn what makes a good thumbnails and description',
					badges: [],
					secondsOffset: 932,
					noCodeChapter: true
				},
				{
					name: 'Marketing Ruffalo',
					description: 'Learn how to make players play your game over and over again',
					badges: [],
					secondsOffset: 1086,
					noCodeChapter: true
				},
				{
					name: 'Marketing Polo',
					description: 'Learn how to use advertising to advertise your game',
					badges: [],
					secondsOffset: 1380,
					noCodeChapter: true
				}
			]
		}
	]
};
