'use strict';

import _ from 'lodash';

import './confirmMessageModal.less';

const Button = require('../../Button');
const MessageModal = require('../MessageModal');

// @abstract
const ConfirmMessageModal = (module.exports = MessageModal.define({
	type: 'ConfirmMessageModal',

	init: function(parent, options) {
		// Pass these options to the super constructor
		options = _.defaultsDeep(options || {}, {
			okColour: 'red',
			cancelButtonText: 'Cancel',
			cancelColour: 'grey',
			actions: {
				cancel: CK.fn.zero
			}
		});

		// Call the super constructor
		this.MessageModal_init(parent, options);
	},

	// Contribution method called by extendable init
	init_ConfirmMessageModal_ui: function(parent, options) {
		this.dom.addClass('confirm');

		this.cancelButton = new Button(options.cancelButtonText, 'cancel', {
			colour: options.cancelColour
		}).addClass('cancelButton');

		this.content.append(this.cancelButton.dom);

		this.actions(this._class, {
			cancel: () => {
				try {
					options.actions.cancel();
				} finally {
					this.close();
				}
			}
		});
	}
}));

ConfirmMessageModal.create = MessageModal.create;
