'use strict';

module.exports = {
	brands: [require('../../../data/brands').ROBLOX],
	id: 'terrain-tools',
	name: 'Terrain Tools',
	duration: 60,
	secondsDuration: 1870,
	active: true,
	isCourse: true,
	shortCourse: false,
	hasPdf: false,
	hasCampPdf: false,
	position: 10,
	unlockXp: 550,
	difficulty: 'Easy',

	additionalFiles: [
		{
			path: '/Shovel',
			superclass: 'BaseTool'
		},
		{
			path: '/Hammer',
			superclass: 'BaseTool'
		}
	],

	cues: [
		{
			name: 'urgentMessage',
			time: 1,
			lookAhead: true,
			message:
				'Note: Roblox has updated the terrain tools UI. All the tools are still there - they just look a little different!'
		},

		{
			name: 'urgentMessage',
			time: 2,
			lookAhead: true,
			message:
				"We've removed the hide and seek mechanics from this project so you can focus on the terrain tools."
		},

		{
			name: 'message',
			time: 100,
			lookAhead: true,
			message:
				'Test out our completed Terrain Tools course by visiting https://www.roblox.com/games/1607227139/Terrain-Tools'
		},

		{
			name: 'message',
			time: 20,
			lookAhead: true,
			message: `The terrain tab has moved in Roblox Studio - you can now find them in the Home tab of Roblox Studio under 'Editor'.`
		}
	],

	descriptions: [
		`Note: Roblox has updated the terrain tools UI. All the tools are still there - they just look a little different!`,
		`Learn how to use the terrain and generation tools in Roblox Studio to create your own worlds, then code some tools to help you play!`,
		`This course teaches you how to get to grips with Roblox Studio tools. You will also code tools which change and affect the world within a hide and seek game.`
	],

	videos: [
		{
			name: 'Terrain Tools',
			chapters: [
				{
					name: 'The Generation Game',
					description:
						'This chapter teaches you the basics of terrain generation in Roblox Studio.',
					secondsOffset: 110,
					badges: ['workspace-1', 'random-2'],
					noCodeChapter: true
				},
				{
					name: 'Brush Hour',
					description:
						'This chapter shows you how to use the paint tools to customise your Roblox terrain.',
					secondsOffset: 423,
					badges: ['presentation-1'],
					noCodeChapter: true
				},
				{
					name: 'Land Canyon',
					description:
						'This chapter introduces you to the terrain object in the Roblox Studio Workspace.',
					secondsOffset: 740,
					badges: ['presentation-1'],
					noCodeChapter: true
				},
				{
					name: 'Terracing Day',
					description:
						'This chapter teaches you how to use the region tools to adjust your terrain.',
					secondsOffset: 984,
					badges: ['physics-1', 'presentation-1'],
					noCodeChapter: true
				},
				{
					name: "It's Terraining Men",
					description:
						'This chapter teaches you how to code a shovel to dig holes in your terrain.',
					secondsOffset: 1164,
					badges: ['presentation-2']
				},
				{
					name: "It's Never Tool Late",
					description:
						'This chapter teaches code a terrain generating tool to use in a hide and seek game.',
					secondsOffset: 1494,
					badges: ['presentation-2', 'data-2', 'operators-2']
				}
			]
		}
	]
};
