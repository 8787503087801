'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'epic-mods',
	name: 'Epic Mods',
	duration: 60,
	secondsDuration: 1750,
	active: true,
	isCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	shortCourse: false,
	descriptions: [
		'Practice writing in text Java code to make 3 different mods!',
		'This selection of mods builds on your text coding skills. Whether you want to add code to a lobby, create a timer or simply make it rain anvils, each video shows you how to code a new mod entirely from scratch.'
	],

	difficulty: 'Hard',
	unlockXp: 50000,

	videos: [
		{
			name: 'Epic Mods',
			chapters: [
				{
					name: 'Lobby',
					description: 'This chapter teaches you how to make a lobby.',
					badges: [
						'syntax-1',
						'syntax-2',
						'syntax-3',
						'decomposition-1',
						'conditions-1',
						'parameters-3'
					],
					secondsOffset: 137
				},
				{
					name: 'Scoreboard',
					description: 'This chapter teaches you how to make a scoreboard ranking for players.',
					badges: [
						'syntax-1',
						'syntax-2',
						'syntax-3',
						'timer-1',
						'parameters-3',
						'nesting-2',
						'loops-3',
						'arrays-2',
						'arrays-3',
						'communication-2'
					],
					secondsOffset: 666
				},
				{
					name: 'Anvil Rain',
					description: 'This chapter teaches you how to make your server rain anvils.',
					badges: [
						'syntax-1',
						'syntax-2',
						'syntax-3',
						'timer-2',
						'random-1',
						'variables-1',
						'operations-2'
					],
					secondsOffset: 1431
				}
			]
		}
	]
};
