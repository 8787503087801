'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'mob-madness',
	name: 'Mob Madness',
	duration: 40,
	secondsDuration: 281,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'Mad for mobs? So are we!',
		'Turn yourself into an Enderdragon and learn how to write code that lets you catapult creepers.',
		'Control mobs and give yourself enderdragon flight with this easy mob based mini mod.'
	],
	difficulty: 'Easy',
	unlockXp: 3000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'Mod Madness',
			chapters: [
				{
					name: 'Creeper Catapult',
					description: 'Catapult creepers towards your friends.',
					secondsOffset: 14,
					badges: []
				},
				{
					name: 'Stop Dragon Your Heels',
					description: 'Turn yourself into a mighty Enderdragon!',
					secondsOffset: 157,
					badges: []
				}
			]
		}
	]
};
