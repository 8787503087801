'use strict';

const Time = require('../services/Time');

CK.const = {
	DEFAULT_PROJECT_BANNER: 'banner-default',

	PROJECT: {
		NAME_MIN_LENGTH: 4,
		NAME_MAX_LENGTH: 20
	},

	FILE: {
		NAME_MIN_LENGTH: 2,
		NAME_MAX_LENGTH: 20
	},

	MINECRAFT_STATUS_CATEGORY: {
		OFFLINE: 'offline',
		ONLINE: 'online',
		PENDING: 'pending',
		ERROR: 'error'
	},

	MODDING_TLD: 'codeminecraft.com',

	PROGRESSION_LEVELS: {
		NOT_SEEN: 0,
		INTRODUCED: 1,
		REINFORCED: 3
	},

	TRIAL_THEN_ANNUAL_PLAN_ID: 'ck-modding-recurring-6',

	// We use underscores because that is the standard third parties recognise
	UTM_TRACKING_FIELDS: [
		'utm_medium',
		'utm_source',

		'utm_campaign',

		'utm_content',

		'utm_term',
		'mp_medium',

		'mp_source',
		'mp_campaign',
		'mp_content',
		'mp_term',
		'mp_referrer',
		'affiliate'
	],
	TEMPLATE_REVISION_ID: 'template',
	LATEST_REVISION_ID: 'latest',
	// 200 MiB
	WORLD_UPLOAD_SIZE_MAX: 200 * Math.pow(1024, 2),
	WORLD_ARCHIVE_UNCOMPRESSED_SIZE_MAX: 1 * Math.pow(1024, 3),

	// 100 MiB
	CUSTOM_PLUGIN_SIZE_MAX: 100 * Math.pow(1024, 2),

	CAMP: {
		NOT_A_STUDENT: 'NotAStudent',

		NOT_A_NAME: 'NotAName',

		GROUP_CODE_LENGTH: 8,

		LICENCES: {
			day: 'day'
		},

		LICENCE_LENGTHS: {
			day: Time.DAY_MS
		},

		ALLOCATION_TYPES: {
			fixed: 'fixed',
			flexible: 'flexible'
		},

		INSTRUCTOR_TYPES: {
			ADMINS: 'admins',
			LEADERS: 'leaders'
		}
	},

	MINECRAFT_DENY_STATUS: {
		NO_SUBSCRIPTION: 'noSubscription',
		CANCELLED_SUBSCRIPTION: 'cancelledSubscription',
		NO_CARD: 'noCard',
		REVOKED: 'revoked',
		FREE: 'free'
	},

	// This is a edge case with Vimeo's analytics library throwing an error and we want to hide it.
	SPURIOUS_ERROR_PREFIXES: [
		`Warning: It looks like you're using a minified copy of the development build of React`,
		'Uncaught Error: React is running in production mode, but dead code elimination has not been applied',
		'Script error',
		'Superseded',
		'Loading chunk',
		'3D Secure authentication failed',
		"Can't execute code from a freed script", // Spam from Edge/IE
		"Cannot read property 'style' of null", // Spam from google api
		"Uncaught TypeError: Cannot read property 'removeEventListener' of null" // Spam from Intercom
	],

	TEST_TRIAL_COUPON_ID: 'firstmonthhalfprice',

	EXTERNAL_BILLING_SOURCE: {
		BRAINTREE: 'braintree'
	},
	// Ensure that the ID does not contain any special characters (because it is used as a path fragment and we need to avoid traversal)
	CUSTOM_ASSET_ID_PREFIX: 'custom-',
	CUSTOM_ASSET_ID_REGEX: /custom\-[A-Za-z0-9]+/,
	WORLD_UPLOAD_REASON: {
		SHUTDOWN: 'SHUTDOWN',

		AUTOSAVE: 'AUTOSAVE',
		WORLD_CHANGED: 'WORLD_CHANGED',
		PROJECT_CHANGED: 'PROJECT_CHANGED',
		TEMPLATE: 'TEMPLATE'
	},

	DECLINE_TYPES: {
		STRIPE: 'Stripe',
		BRAINTREE: 'Braintree'
	},

	BASE_BADGE_POINTS: 150,

	MINECRAFT_VERSION: '1.16.5',
	MINECRAFT_DEBUGGER_PORT: 9997,

	READABLE_TIME_DATE_FORMAT: 'MMM Do [at] HH:mm',

	MINECRAFT_REGIONS: {
		US: 'us',
		EU: 'eu',
		AP: 'ap'
	},

	AWS_REGIONS_TO_MINECRAFT_REGIONS: {
		'eu-west-1': 'eu',
		'us-east-1': 'us',
		'ap-southeast-2': 'ap'
	},

	CLIENT_METRIC_TYPES: ['increment', 'decrement', 'gauge', 'timing'],

	SENTINEL_SOFT_RESTART_DONE: 'Your server has finished restarting, reloading your project...'
};

const minecraftStatuses = (CK.const.MINECRAFT_STATUSES = {});
minecraftStatuses[CK.const.MINECRAFT_STATUS_CATEGORY.PENDING] = [
	'SENTINEL_CONNECTION_LOST',
	'CONNECTING_TO_MINECRAFT_PROXY',
	'CONNECTED_TO_MINECRAFT_PROXY',
	'VERIFYING_SESSION',
	'PROVISIONING',
	'PROVISIONING_WAITING_FOR_CAPACITY',
	'PROVISIONED_WAITING_FOR_CONTACT',
	'PROVISIONED',
	'STARTING_MINECRAFT'
];
minecraftStatuses[CK.const.MINECRAFT_STATUS_CATEGORY.ONLINE] = [
	'NO_WORLD_CHANGE_NEEDED',
	'READY',
	'BACK_ONLINE',
	'CHANGING_WORLD',
	'CHANGED_WORLD'
];
minecraftStatuses[CK.const.MINECRAFT_STATUS_CATEGORY.OFFLINE] = [
	'OFFLINE',
	'TIME_EXHAUSTED',
	'CONNECTION_LOST',
	'IDLE',
	'SKIPPING_PROVISIONING',
	'PAUSED'
];
minecraftStatuses[CK.const.MINECRAFT_STATUS_CATEGORY.ERROR] = ['ERROR', 'CHANGE_WORLD_ERROR'];
CK.const.MINECRAFT_STATUS = {};

for (let category in minecraftStatuses) {
	for (let id of minecraftStatuses[category]) {
		CK.const.MINECRAFT_STATUS[id] = {
			id: id,
			category: category
		};
	}
}
