'use strict';
const Errors = require('../services/Errors');

CK.flowMethods.assertUserWriteAccess = function(vertical) {
	if (vertical === require('../data/verticals').ROBLOX) {
		return;
	}

	if (this.user().getMinecraftDenyStatus() && !this.user().isFreeUser()) {
		throw new Errors.LimitedUser({
			tags: {
				limit: 'writeAccess'
			}
		});
	}
};

CK.flowMethods.assertUserCourseAccess = function(courseId, vertical) {
	if (!courseId) {
		return;
	}

	if (!this.user().allowCreateCourse(courseId, vertical)) {
		throw new Errors.LimitedUser({
			tags: {
				limit: 'course'
			}
		});
	}
};

CK.flowMethods.assertUserCanCreateProject = function(vertical) {
	if (!this.user().allowCreateProject(vertical)) {
		throw new Errors.LimitedUser({
			tags: {
				limit: 'createProject'
			}
		});
	}
};

CK.flowMethods.assertUserWorldAccess = function(worldId, vertical) {
	let suffixer = require('../functions/worldSuffixer');
	worldId = suffixer.removeSuffix(worldId);

	if (!this.user().allowCreateWorld(worldId, vertical)) {
		throw new Errors.LimitedUser({
			tags: {
				limit: 'world'
			}
		});
	}
};
