'use strict';

import _ from 'lodash';

const Time = require('./../services/Time');
const Errors = require('./../services/Errors');
const Extendable = require('./Extendable');

const Transition = (module.exports = Extendable.define({
	init_Transition: function() {
		this._steps = [];

		this._name = this.type;

		_.each(Object.keys(Object.getPrototypeOf(this)), name => {
			if (typeof this[name] === 'function') {
				this[name] = this[name].bind(this);
				this[name]._name = name;
			}
		});
	},

	step: function(step) {
		this._steps.push(step);

		return this;
	},

	stop: function() {
		this._stopped = true;
	},

	run: async function() {
		const runStep = async step => {
			if (_.isNil(step)) {
				throw new Error('Undefined transition step');
			}

			// Parallel steps
			if (_.isArray(step)) {
				await Promise.all(_.map(step, runStep));
			} else if (Transition.isInstance(step)) {
				await step.run();
			} else {
				var startTime = Date.now();

				var slowTimeout = setTimeout(() => {
					CK.logger.warn(
						new Errors.SlowTransitionStep({
							tags: {
								type: this.type,
								stepName: step._name
							}
						})
					);
				}, step.slowTimeout || Time.SECOND_MS * 10);

				try {
					var result = step.call(this);

					if (result) {
						await result;
					}

					CK.logger.debug(this.type, 'finished step', {
						duration: Math.ceil(Date.now() - startTime),
						stepName: step._name
					});
				} catch (e) {
					CK.logger.error(e);
					throw e;
				} finally {
					clearTimeout(slowTimeout);
				}
			}
		};

		for (var i = 0; i < this._steps.length; i++) {
			await runStep(this._steps[i]);

			if (this._stopped) {
				break;
			}
		}

		return Promise.resolve();
	},

	doneLoading: function() {
		$('#container').css('display', 'block');
	},

	type: 'Transition'
}));
