'use strict';

module.exports = {
	brands: [
		require('../../../../data/brands').MINECRAFT_JAVA,
		require('../../../../data/brands').MINECRAFT_PE
	],
	id: 'one-in-the-chamber',
	name: 'One In The Chamber',
	duration: 40,
	secondsDuration: 138,
	active: true,
	isCourse: true,
	shortCourse: true,
	hasPdf: false,
	hasCampPdf: false,
	descriptions: [
		'One arrow. One heart. One winner. Welcome to one in the chamber.',
		'Looking for tension and a high risk of death? This mini mod is one for you.',
		'Try this mini mod for the ultimate simple PVP mini game.'
	],
	difficulty: 'Easy',
	unlockXp: 7000,
	worldId: 'default',

	additionalFiles: [],

	cues: [],

	videos: [
		{
			name: 'One In The Chamber',
			chapters: [
				{
					name: 'Trigger Happy',
					description:
						"In this chapter you'll make a mini game where there can only be one winner..",
					secondsOffset: 32,
					badges: []
				}
			]
		}
	]
};
