'use strict';

const globalObj = typeof global !== 'undefined' ? global : window;

globalObj.Promise = require('bluebird');

globalObj.Promise.config({
	longStackTraces: false
});

globalObj.moment = require('moment');

const Darc = require('darc');

globalObj.darc = new Darc({
	console: {
		debug: function(...args) {
			globalObj.CK.logger.log(...args);
		},
		warn: function(...args) {
			globalObj.CK.logger.warn(...args);
		},

		error: function(...args) {
			globalObj.CK.logger.error(...args);
		}
	},

	defaultOptions: {
		enforceType: {
			strict: false,
			preventDelete: false,
			preventMissing: false,
			allowEmbeddedDelete: true,
			warnCustomKeys: true
		}
	}
});

globalObj.darc.addMethod('then', function(handler) {
	return globalObj.darc.defer(this.$()).then(handler);
});

globalObj.darc.addMethod('catch', function(handler) {
	return globalObj.darc.defer(this.$()).catch(handler);
});

// Allow pre-definition of CK.config in clients
const CK = globalObj.CK || {};
globalObj.CK = CK;

CK.classes = {};

CK.flags = {
	noPaypal: false
};

CK.managers = {};
CK.transforms = {};

CK.clientSockets = {};
CK.endpoints = {};

CK.flowMethods = {};

CK.metric = {
	increment: function() {},
	decrement: function() {},
	gauge: function() {},
	timing: function() {},
	trackEvent: function() {}
};

require('./source/services/Logger');

require('./source/globals/const');
require('./source/globals/Functions');
require('./source/globals/RoleConstants');
require('./source/globals/LimitConstants');

require('./source/flowMethods/assertUserAccessMethods');
