'use strict';

import _ from 'lodash';

const Extendable = require('./Extendable');

module.exports = require('./Extendable').define(require('./EventContrib')(), {
	type: 'ModuleContainer',

	init_ModuleContainer: function() {
		this._data = {};
		this._dataListeners = {};

		this._latestModulesOfType = {};
		this._modules = {};
		this._recency = 0;

		this._containerRecencyKey = '__ModuleContainer_' + this._id + '_recency';
	},

	// This would be pretty slow if there were a lot of modules being added and removed frequently
	_recalculateLatestModules: function() {
		this._latestModulesOfType = {};

		_.each(this._modules, module => {
			_.each(module._class.TYPES, type => {
				if (
					!this._latestModulesOfType[type] ||
					this._latestModulesOfType[type][this._containerRecencyKey] <
						module[this._containerRecencyKey]
				) {
					this._latestModulesOfType[type] = module;
				}
			});
		});
	},

	_checkModuleCanBeAdded: function(module) {
		if (module._parent !== this) {
			throw new Error('ModuleParentIsNotContainer: ' + module.type);
		}

		if (this._modules[module.type]) {
			throw new Error('Not adding module which already exists: ' + module.type);
		}
	},

	add: function(module) {
		this._checkModuleCanBeAdded(module);

		this._modules[module.type] = module;

		module[this._containerRecencyKey] = this._recency++;
		this._recalculateLatestModules();

		var promise;

		if (this.bound) {
			promise = module.bind();
		}

		return promise || Promise.resolve();
	},

	addFactory: function(typeName, ClassDefinition) {
		const Module = require('./Module');

		this.add(
			new (Module.define({
				type: typeName,
				newInstance: function(...args) {
					this._instance = new ClassDefinition(...args);
					return this._instance;
				},
				getInstance: function(...args) {
					return this._instance || this.newInstance(...args);
				}
			}))(this)
		);
	},

	/**
	 * Removes all modules which inherit from, or are, the given type
	 */
	remove: function(module) {
		var type;

		if (Extendable.isClass(module)) {
			type = module.NAME;
		} else if (typeof module === 'string') {
			type = module;
		} else {
			type = module.type;
		}
		var modulesToRemove = _.filter(this._modules, function(existingModule) {
			return existingModule.type === type || existingModule.instanceOf(type);
		});
		if (!modulesToRemove.length) {
			return CK.logger.warn('Could not find any module satisfying type', type, 'to remove');
		}

		const promises = [];

		_.each(modulesToRemove, existingModule => {
			CK.logger.info('Removing module with type', existingModule.type);
			promises.push(existingModule.cleanup() || Promise.resolve());

			delete this._modules[existingModule.type];
		});
		this._recalculateLatestModules();

		return Promise.all(promises);
	},

	// Get a data value

	get: function(path) {
		if (_.isObject(path)) {
			if (Extendable.isClass(path)) {
				path = path.NAME;
			} else {
				throw new Error('Cannot pass a non-Extendable object to get');
			}
		}

		return this._data[path];
	},

	reset: function() {
		_.each(this._modules, module => {
			this.remove(module);
		});
	},
	/**
	 * Gets a module with the given type.
	 * If there is no concrete module of that type, the most recently added module which inherits from the type is returned.

	 */

	module: function(type) {
		if (!_.isString(type)) {
			type = type.NAME;
		}

		var concreteModule = this._modules[type];
		if (concreteModule) {
			return concreteModule;
		} else {
			return this._latestModulesOfType[type];
		}
	},

	// Get a list of modules that inherit from, or are, the given type

	modules: function(type) {
		if (type && !_.isString(type)) {
			type = type.NAME;
		}

		var moduleList = [];

		_.each(this._modules, function(module) {
			if (!type || (module.instanceOf && module.instanceOf(type))) {
				moduleList.push(module);
			}
		});

		return moduleList;
	},

	broadcast: function(event, data) {
		var args = [event, data];

		for (var i = 2; i < arguments.length; i++) {
			args.push(arguments[i]);
		}

		return this.publish.apply(this, args);
	},

	_addEventListener: function(path, listener) {
		this.subscribe(path, listener, listener.self);
	},

	_removeEventListener: function(path, listener) {
		this.unsubscribe(path, listener);
	},

	_triggerListener: function(listener, child) {
		listener(child, {
			prev: function() {
				return;
			},
			// TODO darc
			prevs: {}
		});
	},

	_addDataListener: function(path, listener) {
		var dotIndex = path.indexOf('.');

		var name = path;

		var subPath = '';

		if (dotIndex !== -1) {
			name = path.substring(0, path.indexOf('.'));
			subPath = path.substring(name.length + 1);
		}

		if (!this._dataListeners[name]) this._dataListeners[name] = [];
		this._dataListeners[name].push({
			subPath,

			listener
		});

		if (this._data[name]) {
			var child = this._getCursorForSubPath(this._data[name], subPath);
			child.$bind(listener);

			this._triggerListener(listener, child);
		}
	},
	_removeDataListener: function(path, listener) {
		var dotIndex = path.indexOf('.');

		var name = path;

		var subPath = '';

		if (dotIndex !== -1) {
			name = path.substring(0, path.indexOf('.'));

			subPath = path.substring(name.length + 1);
		}

		var listenerData = this._dataListeners[name];

		if (listenerData) {
			for (var i = 0; i < listenerData.length; i++) {
				if (listenerData[i].listener === listener) {
					listenerData.splice(i, 1);
					break;
				}
			}
		}

		if (this._data[name]) {
			var child = this._getCursorForSubPath(this._data[name], subPath);

			child.$unbind(listener);
		}
	},

	_getCursorForSubPath: function(cursor, subPath) {
		if (!subPath) return cursor;

		var splitPath = subPath.split('.');

		var child = cursor;

		_.each(splitPath, function(component) {
			child = child.$get(component);
		});

		return child;
	},
	_addAssignment: function(name, cursor) {
		if (this._data[name]) throw 'DataCursorAlreadyExists: ' + name;
		this._data[name] = cursor;
		_.each(this._dataListeners[name], listenerData => {
			var child = this._getCursorForSubPath(cursor, listenerData.subPath);
			child.$bind(listenerData.listener);

			this._triggerListener(listenerData.listener, child);
		});
	},

	_removeAssignment: function(name, cursor) {
		delete this._data[name];

		_.each(this._dataListeners[name], listenerData => {
			var child = this._getCursorForSubPath(cursor, listenerData.subPath);

			child.$unbind(listenerData.listener);
		});
	},

	bind_modules: function() {
		_.each(this._modules, function(module) {
			module.bind();
		});
	},

	unbind_modules: function() {
		CK.logger.info('ModuleContainer being unbound!');

		_.each(this._modules, function(module) {
			module.unbind();
		});
	},
	container: function() {
		return this;
	}
});
