import { CK_ENV } from 'ck-core/source/data/ckEnv';
import { adwordsInit } from './AdwordsInit';

export const CONVERSION_ID = 931916065;

export enum ADWORDS_CONVERSION_LABEL {
	REGISTER = 'TvgUCPeMnmcQodKvvAM',
	AUTHORISE = 'CKJcCJ2nimMQodKvvAM',
	CREATE_PROJECT = 'XIyjCK225m4QodKvvAM',
	JOIN_SERVER = 'doa1COji8m4QodKvvAM',
	PROJECT_PROGRESS_UPDATED = 'gFazCKn3yH0QodKvvAM',
	STUDIO_FIRST_CONNECTED = 'OtiwCLbm330QodKvvAM',
	RUN_BUTTON_FIRST_SUCCESS = 'y8OVCN3d230QodKvvAM',
	COMPLETED_ONBOARDING = 'SuchCPz8yH0QodKvvAM',
	INITIATE_CHECKOUT = '-1p8CPfBgHUQodKvvAM',
	EMAIL_LEAD_CAPTURE = 'vs3tCJOM6HQQodKvvAM',
	VIEW_CARD_FORM = 'WqoKCKyXl3kQodKvvAM',
	VIEW_PLANS = 'o-baCOipl3kQodKvvAM'
}



export function trackAdwordsConversion(label: ADWORDS_CONVERSION_LABEL) {
	const trackConversion = adwordsInit();

	// Stub in tests
	if (typeof window !== 'undefined' && CK.googleTracks) {
		CK.googleTracks.push(label);
	}

	if (CK.config.environment.name !== CK_ENV.PRODUCTION) {
		// CK.logger.debug('🔇 Not posting trackAdwordsConversion as not on production', { label });
		// return;
	}

	try {
	
		if (document.location.href.includes('coolmath') || CK.config.name === 'ck-coolmath') {

			
			// We use the Coolmath Games Adwords account/ budget for tracking so need to call here

			const COOLMATH_CONVERSION_ID = 386269532;


			// Orginal adwords label mapping to the Coolmath account label
			const ADWORDS_CONVERSION_LABEL_COOLMATH: {[key: string]: string} = {
				'CKJcCJ2nimMQodKvvAM': '-_PkCMDT4IoDENyCmLgB', // AUTHORISE (purchase)
				'-1p8CPfBgHUQodKvvAM': 'CYfZCKG50KIDENyCmLgB', // INITIATE_CHECKOUT
			};

			trackConversion({
				google_conversion_id: COOLMATH_CONVERSION_ID,
				google_conversion_label: ADWORDS_CONVERSION_LABEL_COOLMATH[label]
			});


		} else {
			trackConversion({
				google_conversion_id: CONVERSION_ID,
				google_conversion_label: label
			});
	
		}

	} catch (err) {
		CK.logger.error(err);
	}

	try {

		if (window.ga !== undefined) {
			window.ga('send', {
				hitType: 'event',
  				eventCategory: 'User actions',
  				eventAction: 'Conversion',
  				eventLabel: label
			});
		}
	}

	catch (err) {
		CK.logger.error(err);
	}
}

export function trackAdwordsRemarketing() {
	const trackConversion = adwordsInit();

	try {
		trackConversion({
			google_conversion_id: CONVERSION_ID,
			google_remarketing_only: true
		});
	} catch (err) {
		CK.logger.error(err);
	}
}
