'use strict';

import _ from 'lodash';

const CKError = require('../classes/Error');
const statusInTestCohort = require('./statusInTestCohort');

module.exports = function(options) {
	options = _.defaults(options || {}, {
		abTests: require('../collections/ABTests')
	});

	if (!require('./isValidTestSeed')(options.testSeed)) {
		throw new CKError({
			message: 'testSeed is invalid',
			tags: {
				testSeed: options.testSeed,
				userData: options.user ? CK.fn.safeFormat(options.user.$()) : undefined
			}
		});
	}

	const activeTests =
		options
			.abTests()
			.$find({
				active: true
			})
			.$() || [];

	const cohorts = {};

	const userId = options.user ? options.user.id() : undefined;

	let currentCohorts = options.currentCohorts;

	if (!currentCohorts && options.user) {
		options.currentCohorts = currentCohorts = _.keyBy(options.user.testCohorts().$(), 'id');
	}

	for (let test of activeTests) {
		const context = {
			testId: test.id,
			userId
		};

		CK.logger.debug('🆎', 'Checking user against AB test', context);

		const currentCohort = _.get(currentCohorts, test.id);

		if (currentCohort) {
			CK.logger.debug('🆎', 'User is already in AB cohort', context, {
				currentCohort: currentCohort
			});
			continue;
		}

		let status = statusInTestCohort(
			test,
			_.pick(options, [
				'testSeed',
				'user',
				'abTests',
				'currentCohorts',
				'originalPersistentTrackingId'
			])
		);

		if (status) {
			cohorts[test.id] = status;
			CK.logger.debug('🆎', 'Adding user to AB cohort', context, status);
		}
	}

	return cohorts;
};
